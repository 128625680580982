<template>
	<div class="main-panel main-list">
		<div class="left-panel">
			<div class="statistic-panel">
				<!--饼图-->
				<doughnut-chart
					:dataList="countryStatistics"
					totalDesc="文献总数"
					@click="countrySelect"
					isDataFixed="true"
				></doughnut-chart>
			</div>
			<div class="statistic-panel" style="margin-top: 20px;">
				<!--柱状图-->
				<BarChart :dataList="annualCountStatistics" yName="发表数量"></BarChart>
			</div>
			<div class="filter-panel">
				<paperfilter
					@filterchang="getFilter"
					:filterinfo="filerInfo"
				></paperfilter>
			</div>
		</div>
		<div class="list-panel">
			<paperlist
				@orderchange="getOrder"
				:orderBy="orderby"
				:datalist="datalist"
				:resultAmount="resultAmount"
				ref="list"
			></paperlist>
			<div class="pagination">
				<el-pagination
					id="pagination"
					:current-page="pageIndex"
					:page-size="pageSize"
					:page-sizes="[10, 25, 50]"
					layout="prev, pager, next, jumper, sizes"
					:total="resultAmount"
					@current-change="queryChange"
					@size-change="sizeChange"
					@prev-click="queryChange"
					@next-click="queryChange"
				>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import paperlist from "./list/list.vue"
import paperfilter from "./list/filter.vue"
import { ApiClient, ArticleApi } from "../../api"
import DoughnutChart from "../../components/charts/DoughnutChart.vue"
import BarChart from "../../components/charts/BarChart.vue"
import FileSaver from "file-saver"
import moment from "moment"
import "../../assets/css/list.less"
import "../../assets/css/list-pager@index.less"

export default {
	data() {
		return {
			articleApi: new ArticleApi(ApiClient.instance),
			resultAmount: 0,
			datalist: [],
			searchType: 0, // 0-一般搜索，1-高级搜索，2-一般+筛选搜索
			pageIndex: 1,
			pageSize: 10,
			orderby: 0, // 排序字段，0-发表时间，1-引用频次
			annualCountStatistics: [],
			countryStatistics: [],
			filerInfo: {
				aptamerID: "", // 关联适体id
				author: "", // 作者
				_abstract: "", // 摘要
				title: "", // 标题
				keyword: "", // 关键字
				country: "", // 国家
				language: "", // 语言
				targetName: "",
				targetType: "" // 靶标类型
			}
		}
	},
	methods: {
		getData() {
			const opts = {
				aptamerID: this.filerInfo.aptamerID,
				_abstract: this.filerInfo._abstract,
				author: this.filerInfo.author,
				title: this.filerInfo.title,
				keyWord: this.filerInfo.keyword,
				country: this.filerInfo.country,
				language: this.filerInfo.language,
				targetName: this.filerInfo.targetName,
				index: this.pageIndex,
				size: this.pageSize
			}
			// console.log(opts)
			this.articleApi.apiArticleQueryGet(
				this.orderby,
				this.searchType,
				opts,
				(error, data, response) => {
					// console.log(response)
					if (error) {
						console.log(error)
					}
					if (data.success) {
						// console.log(data.result)
						this.datalist = data.result.records
						this.annualCountStatistics = data.result.annualCountStatistics
						this.countryStatistics = data.result.countryStatistics
						this.resultAmount = data.result.total
						// console.log(this.datalist)
					}
				}
			)
		},
		// 筛选条件改变
		getFilter(data) {
			this.filerInfo.targetName = data.targetName
			this.filerInfo.country =
				data.country === undefined ? this.$route.query.country : data.country
			this.searchType = 2
			// this.getData()
			const opts = {
				aptamerID: this.filerInfo.aptamerID,
				_abstract: this.filerInfo._abstract,
				author: this.filerInfo.author,
				title: this.filerInfo.title,
				keyWord: this.filerInfo.keyword,
				country: this.filerInfo.country,
				language: this.filerInfo.language,
				targetName: this.filerInfo.targetName,
				index: this.pageIndex,
				size: this.pageSize
			}
			this.replaceUrl(opts)
		},
		// 分页条件改变
		queryChange(val) {
			this.pageIndex = val
			// this.getData()
			const opts = {
				aptamerID: this.filerInfo.aptamerID,
				_abstract: this.filerInfo._abstract,
				author: this.filerInfo.author,
				title: this.filerInfo.title,
				keyWord: this.filerInfo.keyword,
				country: this.filerInfo.country,
				language: this.filerInfo.language,
				targetName: this.filerInfo.targetName,
				index: this.pageIndex,
				size: this.pageSize
			}
			this.replaceUrl(opts)
		},
		sizeChange(val) {
			this.pageIndex = 1
			this.pageSize = val
			// this.getData()
			const opts = {
				aptamerID: this.filerInfo.aptamerID,
				_abstract: this.filerInfo._abstract,
				author: this.filerInfo.author,
				title: this.filerInfo.title,
				keyWord: this.filerInfo.keyword,
				country: this.filerInfo.country,
				language: this.filerInfo.language,
				targetName: this.filerInfo.targetName,
				index: this.pageIndex,
				size: this.pageSize
			}
			this.replaceUrl(opts)
		},
		// 排序条件改变
		getOrder(data) {
			this.orderby = data
			// this.getData()
			const opts = {
				aptamerID: this.filerInfo.aptamerID,
				_abstract: this.filerInfo._abstract,
				author: this.filerInfo.author,
				title: this.filerInfo.title,
				keyWord: this.filerInfo.keyword,
				country: this.filerInfo.country,
				language: this.filerInfo.language,
				targetName: this.filerInfo.targetName,
				index: this.pageIndex,
				size: this.pageSize,
				orderby: this.orderby
			}
			this.replaceUrl(opts)
		},
		replaceUrl(opts) {
			console.log("进入文章replaceUrl", opts)
			this.$set(opts, "searchtype", this.searchType)
			this.$set(opts, "orderby", this.orderby)

			this.$router.replace({ query: opts })
		},
		// 文章导出方法
		// 在list页面调用
		exportArticle(exportType, exportFileType, selectData, startNo, endNo) {
			// console.log("exportArticle")
			// console.log(exportType, exportFileType, selectData, startNo, endNo)

			const opts = {
				aptamerID: this.filerInfo.aptamerID,
				_abstract: this.filerInfo._abstract,
				author: this.filerInfo.author,
				title: this.filerInfo.title,
				keyWord: this.filerInfo.keyword,
				country: this.filerInfo.country,
				language: this.filerInfo.language,
				targetType: this.filerInfo.targetType,
				targetName: this.filerInfo.targetName,
				index: this.pageIndex,
				size: this.pageSize,
				exportType: exportType,
				exportFileType: exportFileType,
				selectRecords: selectData,
				startNo: startNo,
				endNo: endNo
			}
			// console.log(opts)
			this.articleApi.apiArticleExportdataGet(
				this.orderby,
				this.searchType,
				opts,
				(error, data, response) => {
					// console.log(response)
					if (error) {
						this.$message.error("导出失败")
					}
					if (data.success) {
						// console.log(data.result)

						var exportData = data.result.exportData

						if (exportData.length > 0) {
							// console.log(exportData)
							var fileName =
								"导出文献资料信息" + moment(new Date()).format("YYYYMMDDHHmmss")

							var time = moment(new Date())
							var exportDate =
								time.format("D") +
								" " +
								(time.month() + 1) +
								" " +
								time.format("yyyy")
							var content = []
							if (exportFileType !== 2) {
								content.push(
									"TY  - 杂志类型\nTI  - 标题\nT2  - 杂志\nVL  - 期刊号\nPY  - 发布年份\nDO  - Doi\nAU  - 作者\nN1  - 导出时间\nM3  - 文章类型\nC7  - 页码号\nAB  - 摘要\n\n"
								)
								exportData.forEach((item, index) => {
									var str = ""
									// 字段解释，基于20210907 和谢总、倩倩确定
									// 20210930修改 李星确定暂定空值的字段不导出
									// TY：杂志类型，固定值 JOUR
									// TI：标题
									// T2：杂志
									// VL：ISSN冒号截取前前段
									// PY：发布年份
									// DO：Doi
									// AU：作者
									// N1：导出时间
									// M3：文章类型，Review、Article
									// DB：暂定空值
									// C7：ISSN冒号截取后段
									// UR：暂定空值
									// ER：暂定空值
									str += "TY  - JOUR\n"
									str += "TI  - " + (item.articleTitle || "") + "\n"
									str += "T2  - " + (item.journal || "") + "\n"
									str +=
										"VL  - " +
										(item.issn && item.issn.split(":")[0]
											? item.issn.split(":")[0]
											: "") +
										"\n"
									str +=
										"PY  - " +
										(item.publishedDate
											? moment(item.publishedDate).format("yyyy")
											: "") +
										"\n"
									str += "DO  - " + (item.doi || "") + "\n"
									// 拼接作者信息
									if (item.author) {
										item.author.split(";").forEach((au) => {
											str += "AU  - " + au + "\n"
										})
									} else {
										str += "AU  - "
									}
									str += "N1  - " + (exportDate || "") + "\n"
									str +=
										"M3  - " + (item.isReview ? "Review" : "Article") + "\n"
									// str += "DB  - \n"
									str +=
										"C7  - " +
										(item.issn && item.issn.split(":")[1]
											? item.issn.split(":")[1]
											: "") +
										"\n"
									// str += "UR  - \n"
									str += "AB  - " + item._abstract + "\n"
									str += "\n"
									content.push(str)
								})
							} else {
								content.push(
									"杂志类型,标题,杂志,期刊号,发布年份,Doi,作者,导出时间,文章类型,页码号,摘要\n"
								)
								exportData.forEach((item, index) => {
									var str = ""
									str += "JOUR"
									str +=
										"," +
										this.$options.filters.replaceStringForExportCSV(
											item.articleTitle
										)
									str +=
										"," +
										this.$options.filters.replaceStringForExportCSV(
											item.journal
										)
									str +=
										"," +
										(item.issn && item.issn.split(":")[0]
											? item.issn.split(":")[0]
											: "")
									str +=
										"," +
										(item.publishedDate
											? moment(item.publishedDate).format("yyyy")
											: "")
									str += "," + (item.doi || "")
									str += "," + (item.author || "")
									str += "," + (exportDate || "")
									str += "," + (item.isReview ? "Review" : "Article")
									str +=
										"," +
										(item.issn && item.issn.split(":")[1]
											? item.issn.split(":")[1]
											: "")
									str += "," + item._abstract
									str += "\n"
									content.push(str)
								})
							}
							// console.log(content)
							// console.log(content)
							if (exportFileType === 1) {
								// FileSaver.saveAs(blob, fileName + ".txt")
							} else if (exportFileType === 2) {
								FileSaver.saveAs(
									new Blob(["\ufeff" + content.join("")], {
										type: "text/csv;charset=gb2312"
									}),
									fileName + ".csv"
								)
							} else if (exportFileType === 0) {
								FileSaver.saveAs(
									new Blob(["\ufeff" + content.join("")], {
										type: "text/plain;charset=utf-8"
									}),
									fileName + ".ris"
								)
							}
							this.$message.success("导出成功")
						} else {
							this.$message.warning("导出失败！未查询到数据！")
						}
					}
				}
			)
		},
		countrySelect(arg) {
			// if (!arg.isOther) {
			// 	const opts = {
			// 		aptamerID: this.filerInfo.aptamerID,
			// 		_abstract: this.filerInfo._abstract,
			// 		author: this.filerInfo.author,
			// 		title: this.filerInfo.title,
			// 		keyWord: this.filerInfo.keyword,
			// 		country: arg.select.length == 0 ? "" : arg.select[0],
			// 		language: this.filerInfo.language,
			// 		targetType: this.filerInfo.targetType,
			// 		index: this.pageIndex,
			// 		size: this.pageSize
			// 	}
			// 	this.getFilter(opts)
			// }
			// console.log(arg)

			const opts = {
				aptamerID: this.filerInfo.aptamerID,
				_abstract: this.filerInfo._abstract,
				author: this.filerInfo.author,
				title: this.filerInfo.title,
				keyWord: this.filerInfo.keyword,
				country: arg?.select.length > 0 ? arg.select[0] : null,
				language: this.filerInfo.language,
				targetType: this.filerInfo.targetType,
				index: this.pageIndex,
				size: this.pageSize
			}
			this.getFilter(opts)
		}
	},
	mounted: function () {
		// console.log("进入mounted")
		// console.log(this.$route)
		this.searchType = this.$route.query.searchtype
			? parseInt(this.$route.query.searchtype)
			: this.searchType
		if (this.searchType === 1) {
			this.filerInfo.author = this.$route.query.author || ""
			this.filerInfo._abstract = this.$route.query.abstract || ""
			this.filerInfo.title = this.$route.query.title || ""
		}
		this.filerInfo.country = this.$route.query.country
		this.filerInfo.targetName = this.$route.query.targetName

		this.pageIndex = this.$route.query.index
			? parseInt(this.$route.query.index)
			: this.pageIndex

		this.pageSize = this.$route.query.size
			? parseInt(this.$route.query.size)
			: this.pageSize

		this.filerInfo.aptamerID = this.$route.query.aptamerID || ""
		this.getData()
	},
	watch: {
		$route(to, from) {
			console.log("进入文章监听")
			console.log(to)
			this.searchType = to.query.searchtype
				? parseInt(to.query.searchtype)
				: this.searchType

			this.filerInfo.author = to.query.author || ""
			this.filerInfo._abstract = to.query.abstract || ""
			this.filerInfo.title = to.query.title || ""

			this.pageIndex = to.query.index
				? parseInt(to.query.index)
				: this.pageIndex

			this.pageSize = to.query.size ? parseInt(to.query.size) : this.pageSize

			this.filerInfo.keyword = to.query.keyWord
				? to.query.keyWord
				: to.query.keyword || ""

			this.filerInfo.country = to.query.country || this.filerInfo.country

			this.filerInfo.language = to.query.language || this.filerInfo.language

			this.filerInfo.targetName =
				to.query.targetName || this.filerInfo.targetName

			this.filerInfo.aptamerID = to.query.aptamerID || ""

			console.log(this.filerInfo)
			this.getData()
		}
	},
	components: {
		paperlist,
		paperfilter,
		DoughnutChart,
		BarChart
	}
}
</script>
