<template>
	<div
		class="chart-con"
		ref="chart"
		style="height: 350px; width: 85%; margin: 15px auto;"
	></div>
</template>
<script>
import * as echarts from "echarts"
export default {
	name: "BarChart",
	props: ["dataList", "totalDesc"],
	data() {
		return {
			option: {
				tooltip: {
					trigger: "item",
					formatter: "{b} : {c}"
				},
				color: [
					"#2C3FA0",
					"#CB332A",
					"#F4B200",
					"#6D4BB2",
					"#10BADF",
					"#FF9000",
					"#08A216"
				],
				title: {
					text: "",
					left: "center",
					top: "30%",
					textStyle: {
						color: "black",
						fontSize: 25,
						align: "center"
					}
				},
				graphic: {
					type: "text",
					left: "center",
					top: "42%",
					style: {
						text: "",
						textAlign: "center",
						fill: "#333",
						fontSize: 18
					}
				},
				legend: {
					bottom: "0%",
					left: "center"
				},
				series: [
					{
						type: "pie",
						radius: ["40%", "52%"],
						center: ["50%", "40%"],
						labelLine: {
							normal: {
								show: true
							}
						},
						itemStyle: {
							borderRadius: 0,
							borderColor: "#fff",
							borderWidth: 2
						},
						label: {
							normal: {
								formatter: function (data) {
									return data.percent.toFixed(0) + "%"
								}
							}
						},
						data: []
					}
				]
			}
		}
	},
	watch: {
		dataList: function (newList, oldList) {
			const chart =
				echarts.getInstanceByDom(this.$refs.chart) ||
				echarts.init(this.$refs.chart, "shine")
			window.addEventListener("resize", function () {
				chart.resize()
			})
			this.option.title.text = newList.reduce(
				(sum, e) => sum + Number(e.value || 0),
				0
			)

			this.option.graphic.style.text = this.totalDesc
			// 深拷贝副本 防止排序时影响原数组
			var foo = newList.concat([])
			foo.sort((a, b) => b.value - a.value)
			if (foo.length > 5) {
				var foo1 = foo.slice(0, 5)
				var foo2 = foo.slice(5, foo.length)
				var other = {
					name: "Others",
					value: foo2.reduce((sum, e) => sum + Number(e.value || 0), 0)
				}
				foo1.push(other)
				foo = foo1
			}
			this.option.series[0].data = foo
			chart.setOption(this.option)
		},
		totalDesc: function (old, newDesc) {
			const chart =
				echarts.getInstanceByDom(this.$refs.chart) ||
				echarts.init(this.$refs.chart, "shine")

			this.option.graphic.style.text = this.totalDesc
			chart.setOption(this.option)
		}
	},
	mounted() {},
	methods: {
		loadChart(data) {}
	}
}
</script>

<style scoped lang="less">
.chart-con {
	width: 290px;
	height: 315px;
}
</style>
