<template>
	<div class="main-panel">
		<div class="tab-panel">
			<ul class="tab-tilte" style="margin: 0px;">
				<li :class="{ active: showType == 1 }" @click="changeShow(1)">
					Descriptions
				</li>
				<li :class="{ active: showType == 2 }" @click="changeShow(2)">
					Alignments
				</li>
			</ul>
		</div>
		<div class="list-panel">
			<Descriptions
				v-if="showType == 1"
				:dataList="aptamerInfo"
				:loading="loading"
				@optionselected="optionSelected"
			></Descriptions>
			<Alignments v-if="showType == 2" :info="selectSequence"></Alignments>
		</div>
	</div>
</template>

<script>
import Descriptions from "./Descriptions.vue"
import Alignments from "./Alignments.vue"
import { ApiClient, SequenceApi } from "../../api"

export default {
	data() {
		return {
			loading: true,
			sequenceApi: new SequenceApi(ApiClient.instance),
			keyword: "",
			showType: 1, // 展示类型，1-Descriptions,2-Alignments
			sequenceInfo: [],
			aptamerInfo: [],
			selectSequence: {}
		}
	},
	methods: {
		getData() {
			this.keyword = this.$route.query.seq
			if (this.keyword) {
				this.sequenceApi.apiSequenceGetseqdataGet(
					{ keyword: this.keyword },
					(error, data, response) => {
						console.log(response)
						if (error) {
							console.log(error)
						}
						if (data.success) {
							console.log(data.result)
							this.loading = false
							if (data.result.aptamerInfo.length <= 0) {
								this.$message.warning("未找到相似序列！")
							}
							this.sequenceInfo = data.result.sequenceInfo
							this.aptamerInfo = data.result.aptamerInfo
							console.log(this.aptamerInfo)
							// this.optionSelected(1432268136089907200)
						}
					}
				)
			}
		},
		optionSelected(val) {
			var result = this.sequenceInfo.filter((item) => {
				if (item.id === val) {
					return item
				}
			})
			console.log(result)
			this.selectSequence = result.length > 0 ? result[0] : {}
			console.log(this.selectSequence)
			this.showType = 2
		},
		changeShow(val) {
			// console.log(val)
			// console.log(this.selectSequence)
			if (val === 2 && !this.selectSequence.id) {
				this.showType = 1
				this.$message.error("请选择对比序列！")
			} else {
				this.showType = val
			}
		}
	},
	created: function () {
		this.showType = 1
		this.getData()
	},
	components: {
		Descriptions,
		Alignments
	},
	watch: {
		$route(to, from) {
			this.getData()
		}
	}
}
</script>

<style lang="less" scoped>
.main-panel {
	width: 1170px;
	height: 100%;
	min-height: 100vh;
	margin: 0 auto;
}

.tab-panel {
	background: white;
	width: 100%;
	padding-left: 16px;
}

.list-panel {
	width: 100%;
	height: 100%;
	margin-top: 20px;
}

.tab-tilte {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	list-style: none;
	white-space: nowrap;
	transition: width 0.5s ease;
}

.tab-tilte li {
	padding: 10px 20px;
	.c-font-size-14();
	.c-font-family-PF();
	font-weight: bolder;
	line-height: 20px;
	color: #333333;
}

.active {
	border-bottom: 2px solid #3463ea;
}
</style>
