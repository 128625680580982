var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "favorite-container" },
    [
      _c(
        "div",
        { staticClass: "nav-bar" },
        _vm._l(_vm.linkList, function (link, index) {
          return _c(
            "router-link",
            {
              key: index,
              staticClass: "item",
              class: { active: _vm.current === link.url },
              attrs: { to: "/usercenter/myupload/" + link.url },
            },
            [_vm._v(_vm._s(link.name))]
          )
        }),
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }