/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {ArticleCommentDto} from './ArticleCommentDto';

/**
 * The ArticleDetailDto model module.
 * @module model/ArticleDetailDto
 * @version v1
 */
export class ArticleDetailDto {
  /**
   * Constructs a new <code>ArticleDetailDto</code>.
   * @alias module:model/ArticleDetailDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ArticleDetailDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ArticleDetailDto} obj Optional instance to populate.
   * @return {module:model/ArticleDetailDto} The populated <code>ArticleDetailDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ArticleDetailDto();
      if (data.hasOwnProperty('publishDate'))
        obj.publishDate = ApiClient.convertToType(data['publishDate'], 'Date');
      if (data.hasOwnProperty('journal'))
        obj.journal = ApiClient.convertToType(data['journal'], 'String');
      if (data.hasOwnProperty('issn'))
        obj.issn = ApiClient.convertToType(data['issn'], 'String');
      if (data.hasOwnProperty('author'))
        obj.author = ApiClient.convertToType(data['author'], 'String');
      if (data.hasOwnProperty('relationPatentNo'))
        obj.relationPatentNo = ApiClient.convertToType(data['relationPatentNo'], ['String']);
      if (data.hasOwnProperty('fulltextLink'))
        obj.fulltextLink = ApiClient.convertToType(data['fulltextLink'], 'String');
      if (data.hasOwnProperty('collected'))
        obj.collected = ApiClient.convertToType(data['collected'], 'Boolean');
      if (data.hasOwnProperty('body'))
        obj.body = ApiClient.convertToType(data['body'], 'String');
      if (data.hasOwnProperty('comments'))
        obj.comments = ApiClient.convertToType(data['comments'], [ArticleCommentDto]);
      if (data.hasOwnProperty('abstract'))
        obj._abstract = ApiClient.convertToType(data['abstract'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Date} publishDate
 */
ArticleDetailDto.prototype.publishDate = undefined;

/**
 * @member {String} journal
 */
ArticleDetailDto.prototype.journal = undefined;

/**
 * @member {String} issn
 */
ArticleDetailDto.prototype.issn = undefined;

/**
 * @member {String} author
 */
ArticleDetailDto.prototype.author = undefined;

/**
 * @member {Array.<String>} relationPatentNo
 */
ArticleDetailDto.prototype.relationPatentNo = undefined;

/**
 * @member {String} fulltextLink
 */
ArticleDetailDto.prototype.fulltextLink = undefined;

/**
 * @member {Boolean} collected
 */
ArticleDetailDto.prototype.collected = undefined;

/**
 * @member {String} body
 */
ArticleDetailDto.prototype.body = undefined;

/**
 * @member {Array.<module:model/ArticleCommentDto>} comments
 */
ArticleDetailDto.prototype.comments = undefined;

/**
 * @member {String} _abstract
 */
ArticleDetailDto.prototype._abstract = undefined;

/**
 * @member {String} title
 */
ArticleDetailDto.prototype.title = undefined;

