/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {NewsListDtoResponse} from '../model/NewsListDtoResponse';
import {StringResponse} from '../model/StringResponse';
import {SysConfigKeyEnum} from '../model/SysConfigKeyEnum';

/**
* SysConfig service.
* @module api/SysConfigApi
* @version v1
*/
export class SysConfigApi {

    /**
    * Constructs a new SysConfigApi. 
    * @alias module:api/SysConfigApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiSysConfigGetNewsGet operation.
     * @callback moduleapi/SysConfigApi~apiSysConfigGetNewsGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/NewsListDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 获取新闻列表
     * @param {module:api/SysConfigApi~apiSysConfigGetNewsGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiSysConfigGetNewsGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = NewsListDtoResponse;

      return this.apiClient.callApi(
        '/api/SysConfig/get-news', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiSysConfigQueryGet operation.
     * @callback moduleapi/SysConfigApi~apiSysConfigQueryGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/StringResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 系统配置查询接口
     * @param {Object} opts Optional parameters
     * @param {module:model/SysConfigKeyEnum} opts.request 配置项Key
     * @param {module:api/SysConfigApi~apiSysConfigQueryGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiSysConfigQueryGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'request': opts['request']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = StringResponse;

      return this.apiClient.callApi(
        '/api/SysConfig/query', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}