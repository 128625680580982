<template>
	<div class="filter-panel-container">
		<form @submit.prevent="submit" novalidate="true">
			<div class="main-filter-panel">
				<div class="filter">
					<div class="label">适应症</div>
					<div class="input">
						<el-input v-model="conditions" style="width: 100%;"></el-input>
					</div>
				</div>
				<!-- <div class="filter">
					<div class="label">适体类型</div>
					<div class="input">
						<el-input v-model="aptamerType" style="width: 100%"></el-input>
					</div>
				</div> -->
				<div class="filter">
					<div class="label">国家</div>
					<div class="input">
						<el-input v-model="country" style="width: 100%;"></el-input>
					</div>
				</div>
			</div>
			<div class="funding-panel">
				<div class="label">招募状态</div>
				<div class="select-panel">
					<el-checkbox-group
						v-model="recruitState"
						@change="recruitStateChange"
					>
						<el-checkbox name="recruitState" label="Completed">
							Completed
						</el-checkbox>
						<el-checkbox name="recruitState" label="Not yet recruiting">
							Not yet recruiting
						</el-checkbox>
						<el-checkbox name="recruitState" label="Recruiting">
							Recruiting
						</el-checkbox>
						<el-checkbox name="recruitState" label="Active, not recruiting">
							Active, not recruiting
						</el-checkbox>
						<!-- <el-checkbox name="recruitState" label="4"  >
							暂停
						</el-checkbox> -->
						<el-checkbox name="recruitState" label="Terminated">
							Terminated
						</el-checkbox>
						<el-checkbox name="recruitState" label="Withdrawn">
							Withdrawn
						</el-checkbox>
						<el-checkbox name="recruitState" label="Unknown status">
							Unknown status
						</el-checkbox>
					</el-checkbox-group>
				</div>
			</div>
			<div class="control-panel">
				<div class="submit">
					<button type="submit">筛选</button>
				</div>
				<div class="reset">
					<button type="reset" @click="resetFilter">重置</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
export default {
	props: ["filerInfo"],
	data() {
		return {
			country: "", // 国家
			// aptamerType: "", // 适体类型
			conditions: "", // 适应症
			recruitState: [] // 招募状态，具体值看el-checkbox绑定
			// studyResult: [], // 研究结果，具体值看el-checkbox绑定
			// eligibilityCriteria: [] // 资格标准，具体值看el-checkbox绑定
		}
	},
	created: function () {
		this.country = this.filerInfo.country || ""
		// this.aptamerType = this.filerInfo.aptamerType || ""
		this.conditions = this.filerInfo.conditions || ""
		this.recruitState = []
		// this.studyResult = []
		// this.eligibilityCriteria = []
		if (this.filerInfo.recruitState) {
			this.recruitState.push(this.filerInfo.recruitState)
		}
		// if (this.filerInfo.studyResult) {
		// 	this.studyResult.push(this.filerInfo.studyResult)
		// }
		// if (this.filerInfo.eligibilityCriteria) {
		// 	this.eligibilityCriteria.push(this.filerInfo.eligibilityCriteria)
		// }
	},
	methods: {
		studyResultChange() {
			// console.log("进入studyResultChange")
			// console.log(this.studyResult)
			if (this.studyResult.length === 2) {
				this.studyResult.splice(0, 1)
			}
			// console.log(this.studyResult)
		},
		recruitStateChange() {
			// console.log("recruitState")
			// console.log(this.recruitState)
			if (this.recruitState.length === 2) {
				this.recruitState.splice(0, 1)
			}
			// console.log(this.recruitState)
		},
		eligibilityChange() {
			// console.log("eligibilityCriteria")
			// console.log(this.eligibilityCriteria)
			if (this.eligibilityCriteria.length === 2) {
				this.eligibilityCriteria.splice(0, 1)
			}
			// console.log(this.eligibilityCriteria)
		},
		submit(event) {
			this.$emit("filterchang", {
				recruitmentStatus:
					this.recruitState.length > 0 ? this.recruitState[0] : "",
				country: this.country,
				conditions: this.conditions,
				aptamerType: this.aptamerType
			})
			// console.log(this.$route.query.keyword)
		},
		resetFilter() {
			this.country = ""
			this.conditions = ""
			this.aptamerType = ""
			this.recruitState = []
			this.$emit("filterchang", {
				recruitmentStatus:
					this.recruitState.length > 0 ? this.recruitState[0] : "",
				country: this.country,
				conditions: this.conditions
			})
		}
	}
}
</script>

<style lang="less" scoped>
.el-checkbox {
	display: block;
	margin: 10px;
}
</style>

<style lang="less" scoped>
.filter-panel-container {
	width: 100%;
	background-color: #ffffff;
	display: inline-block;
}

.main-filter-panel {
	margin: 10px;
}

.filter {
	margin-bottom: 10px;
	text-align: left;
}

.filter .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	line-height: 20px;
	color: #333333;
}

.filter .input input {
	height: 30px;
	width: 98%;
	background-color: #f1f2f7;
	border-radius: 2px;
	border: none;
	text-indent: 2px;
}

.filter .input select {
	height: 30px;
	width: 98%;
	background-color: #f1f2f7;
	border-radius: 2px;
	border: none;
	text-indent: 2px;
}

.funding-panel {
	margin: 10px;
	padding: 10px 0px;
	text-align: left;
	border-top: 1px solid #c0c0c0;
}

.funding-panel .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	line-height: 20px;
	color: #333333;
}

.funding-panel .select-panel {
	display: flex;
	flex-direction: column;
}

.funding-panel .select-panel .input {
	height: 20px;
	margin-top: 10px;
	margin-left: 6px;
	display: flex;
	flex-direction: row;
}

.funding-panel .select-panel .input input {
	margin: auto 4px;
}

.funding-panel .select-panel .input .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	color: #333333;
	line-height: 20px;
}

.control-panel {
	margin: 10px;
	display: flex;
	flex-direction: row;
}

.submit button {
	width: 160px;
	height: 30px;
	color: #ffffff;
	background-color: #3463ea;
	border-radius: 2px;
	border: none;
}

.reset {
	margin-left: auto;
	margin-right: 0;
}

.reset button {
	width: 100px;
	height: 30px;
	color: #333333;
	background-color: #cbcbcb;
	border-radius: 2px;
	border: none;
	text-align: center;
}
</style>
