/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {BarChartDto} from './BarChartDto';
import {PieDoughnutDto} from './PieDoughnutDto';
import {ProjectItemDto} from './ProjectItemDto';

/**
 * The ProjectListDto model module.
 * @module model/ProjectListDto
 * @version v1
 */
export class ProjectListDto {
  /**
   * Constructs a new <code>ProjectListDto</code>.
   * @alias module:model/ProjectListDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ProjectListDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ProjectListDto} obj Optional instance to populate.
   * @return {module:model/ProjectListDto} The populated <code>ProjectListDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ProjectListDto();
      if (data.hasOwnProperty('records'))
        obj.records = ApiClient.convertToType(data['records'], [ProjectItemDto]);
      if (data.hasOwnProperty('total'))
        obj.total = ApiClient.convertToType(data['total'], 'Number');
      if (data.hasOwnProperty('countryCount'))
        obj.countryCount = ApiClient.convertToType(data['countryCount'], [PieDoughnutDto]);
      if (data.hasOwnProperty('yearCount'))
        obj.yearCount = ApiClient.convertToType(data['yearCount'], [BarChartDto]);
      if (data.hasOwnProperty('yearBudgets'))
        obj.yearBudgets = ApiClient.convertToType(data['yearBudgets'], [BarChartDto]);
      if (data.hasOwnProperty('areaBudgets'))
        obj.areaBudgets = ApiClient.convertToType(data['areaBudgets'], [PieDoughnutDto]);
    }
    return obj;
  }
}

/**
 * @member {Array.<module:model/ProjectItemDto>} records
 */
ProjectListDto.prototype.records = undefined;

/**
 * @member {Number} total
 */
ProjectListDto.prototype.total = undefined;

/**
 * @member {Array.<module:model/PieDoughnutDto>} countryCount
 */
ProjectListDto.prototype.countryCount = undefined;

/**
 * @member {Array.<module:model/BarChartDto>} yearCount
 */
ProjectListDto.prototype.yearCount = undefined;

/**
 * @member {Array.<module:model/BarChartDto>} yearBudgets
 */
ProjectListDto.prototype.yearBudgets = undefined;

/**
 * @member {Array.<module:model/PieDoughnutDto>} areaBudgets
 */
ProjectListDto.prototype.areaBudgets = undefined;

