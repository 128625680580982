var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mainPanel" },
    [
      _c("el-row", { staticClass: "timeRow" }, [_vm._v(_vm._s(_vm.data.time))]),
      _c("el-row", { staticClass: "titleRow" }, [
        _vm._v(_vm._s(_vm.data.title)),
      ]),
      _vm.data.relativeImgPath
        ? _c("el-row", { staticClass: "imgRow" }, [
            _c("img", {
              attrs: { src: _vm.imgPath + _vm.data.relativeImgPath },
            }),
          ])
        : _vm._e(),
      _c("el-row", { staticClass: "authorRow" }, [
        _vm._v(_vm._s(_vm.data.authorsStr)),
      ]),
      _c("el-row", {
        staticClass: "contentRow",
        domProps: { innerHTML: _vm._s(_vm.data._abstract) },
      }),
      _c("el-row", { staticClass: "journalRow" }, [
        _vm._v("Journal: " + _vm._s(_vm.data.journal)),
      ]),
      _c("el-row", { staticClass: "urlRow" }, [
        _vm._v("Publisher URL: "),
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                return _vm.openLink(_vm.data.publisherUrl)
              },
            },
          },
          [_vm._v(_vm._s(_vm.data.publisherUrl))]
        ),
      ]),
      _c("el-row", { staticClass: "doiRow" }, [
        _vm._v("DOI: " + _vm._s(_vm.data.doi)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }