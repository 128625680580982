/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.34
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The FeedsArticleItemDto model module.
 * @module model/FeedsArticleItemDto
 * @version v1
 */
export class FeedsArticleItemDto {
  /**
   * Constructs a new <code>FeedsArticleItemDto</code>.
   * @alias module:model/FeedsArticleItemDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>FeedsArticleItemDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/FeedsArticleItemDto} obj Optional instance to populate.
   * @return {module:model/FeedsArticleItemDto} The populated <code>FeedsArticleItemDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new FeedsArticleItemDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('createdDate'))
        obj.createdDate = ApiClient.convertToType(data['createdDate'], 'Number');
      if (data.hasOwnProperty('doi'))
        obj.doi = ApiClient.convertToType(data['doi'], 'String');
      if (data.hasOwnProperty('journal'))
        obj.journal = ApiClient.convertToType(data['journal'], 'String');
      if (data.hasOwnProperty('imageUrl'))
        obj.imageUrl = ApiClient.convertToType(data['imageUrl'], 'String');
      if (data.hasOwnProperty('state'))
        obj.state = ApiClient.convertToType(data['state'], 'Number');
      if (data.hasOwnProperty('authors'))
        obj.authors = ApiClient.convertToType(data['authors'], 'String');
      if (data.hasOwnProperty('abstract'))
        obj._abstract = ApiClient.convertToType(data['abstract'], 'String');
      if (data.hasOwnProperty('dataId'))
        obj.dataId = ApiClient.convertToType(data['dataId'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('pdfUrl'))
        obj.pdfUrl = ApiClient.convertToType(data['pdfUrl'], 'String');
      if (data.hasOwnProperty('publisherUrl'))
        obj.publisherUrl = ApiClient.convertToType(data['publisherUrl'], 'String');
      if (data.hasOwnProperty('matchedFilter'))
        obj.matchedFilter = ApiClient.convertToType(data['matchedFilter'], 'String');
      if (data.hasOwnProperty('relativeImgPath'))
        obj.relativeImgPath = ApiClient.convertToType(data['relativeImgPath'], 'String');
      if (data.hasOwnProperty('batchId'))
        obj.batchId = ApiClient.convertToType(data['batchId'], 'String');
      if (data.hasOwnProperty('createOn'))
        obj.createOn = ApiClient.convertToType(data['createOn'], 'Date');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
FeedsArticleItemDto.prototype.id = undefined;

/**
 * @member {Number} createdDate
 */
FeedsArticleItemDto.prototype.createdDate = undefined;

/**
 * @member {String} doi
 */
FeedsArticleItemDto.prototype.doi = undefined;

/**
 * @member {String} journal
 */
FeedsArticleItemDto.prototype.journal = undefined;

/**
 * @member {String} imageUrl
 */
FeedsArticleItemDto.prototype.imageUrl = undefined;

/**
 * @member {Number} state
 */
FeedsArticleItemDto.prototype.state = undefined;

/**
 * @member {String} authors
 */
FeedsArticleItemDto.prototype.authors = undefined;

/**
 * @member {String} _abstract
 */
FeedsArticleItemDto.prototype._abstract = undefined;

/**
 * @member {String} dataId
 */
FeedsArticleItemDto.prototype.dataId = undefined;

/**
 * @member {String} title
 */
FeedsArticleItemDto.prototype.title = undefined;

/**
 * @member {String} pdfUrl
 */
FeedsArticleItemDto.prototype.pdfUrl = undefined;

/**
 * @member {String} publisherUrl
 */
FeedsArticleItemDto.prototype.publisherUrl = undefined;

/**
 * @member {String} matchedFilter
 */
FeedsArticleItemDto.prototype.matchedFilter = undefined;

/**
 * @member {String} relativeImgPath
 */
FeedsArticleItemDto.prototype.relativeImgPath = undefined;

/**
 * @member {String} batchId
 */
FeedsArticleItemDto.prototype.batchId = undefined;

/**
 * @member {Date} createOn
 */
FeedsArticleItemDto.prototype.createOn = undefined;

