/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The PieDoughnutDto model module.
 * @module model/PieDoughnutDto
 * @version v1
 */
export class PieDoughnutDto {
  /**
   * Constructs a new <code>PieDoughnutDto</code>.
   * @alias module:model/PieDoughnutDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PieDoughnutDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PieDoughnutDto} obj Optional instance to populate.
   * @return {module:model/PieDoughnutDto} The populated <code>PieDoughnutDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PieDoughnutDto();
      if (data.hasOwnProperty('value'))
        obj.value = ApiClient.convertToType(data['value'], 'Number');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Number} value
 */
PieDoughnutDto.prototype.value = undefined;

/**
 * @member {String} name
 */
PieDoughnutDto.prototype.name = undefined;

