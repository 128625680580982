/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {UserSexEnum} from './UserSexEnum';

/**
 * The UserCenterSigninBody model module.
 * @module model/UserCenterSigninBody
 * @version v1
 */
export class UserCenterSigninBody {
  /**
   * Constructs a new <code>UserCenterSigninBody</code>.
   * @alias module:model/UserCenterSigninBody
   * @class
   * @param account {String} 
   * @param password {String} 
   * @param nickName {String} 
   * @param sex {module:model/UserSexEnum} 
   * @param phone {String} 
   * @param title {String} 
   * @param university {String} 
   * @param institution {String} 
   * @param smsCode {String} 
   */
  constructor(account, password, nickName, sex, phone, title, university, institution, smsCode) {
    this.account = account;
    this.password = password;
    this.nickName = nickName;
    this.sex = sex;
    this.phone = phone;
    this.title = title;
    this.university = university;
    this.institution = institution;
    this.smsCode = smsCode;
  }

  /**
   * Constructs a <code>UserCenterSigninBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserCenterSigninBody} obj Optional instance to populate.
   * @return {module:model/UserCenterSigninBody} The populated <code>UserCenterSigninBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserCenterSigninBody();
      if (data.hasOwnProperty('Account'))
        obj.account = ApiClient.convertToType(data['Account'], 'String');
      if (data.hasOwnProperty('Password'))
        obj.password = ApiClient.convertToType(data['Password'], 'String');
      if (data.hasOwnProperty('NickName'))
        obj.nickName = ApiClient.convertToType(data['NickName'], 'String');
      if (data.hasOwnProperty('Sex'))
        obj.sex = UserSexEnum.constructFromObject(data['Sex']);
      if (data.hasOwnProperty('Phone'))
        obj.phone = ApiClient.convertToType(data['Phone'], 'String');
      if (data.hasOwnProperty('Title'))
        obj.title = ApiClient.convertToType(data['Title'], 'String');
      if (data.hasOwnProperty('University'))
        obj.university = ApiClient.convertToType(data['University'], 'String');
      if (data.hasOwnProperty('Institution'))
        obj.institution = ApiClient.convertToType(data['Institution'], 'String');
      if (data.hasOwnProperty('SmsCode'))
        obj.smsCode = ApiClient.convertToType(data['SmsCode'], 'String');
      if (data.hasOwnProperty('Avator'))
        obj.avator = ApiClient.convertToType(data['Avator'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} account
 */
UserCenterSigninBody.prototype.account = undefined;

/**
 * @member {String} password
 */
UserCenterSigninBody.prototype.password = undefined;

/**
 * @member {String} nickName
 */
UserCenterSigninBody.prototype.nickName = undefined;

/**
 * @member {module:model/UserSexEnum} sex
 */
UserCenterSigninBody.prototype.sex = undefined;

/**
 * @member {String} phone
 */
UserCenterSigninBody.prototype.phone = undefined;

/**
 * @member {String} title
 */
UserCenterSigninBody.prototype.title = undefined;

/**
 * @member {String} university
 */
UserCenterSigninBody.prototype.university = undefined;

/**
 * @member {String} institution
 */
UserCenterSigninBody.prototype.institution = undefined;

/**
 * @member {String} smsCode
 */
UserCenterSigninBody.prototype.smsCode = undefined;

/**
 * @member {String} avator
 */
UserCenterSigninBody.prototype.avator = undefined;

