<template>
	<div class="login">
		<div class="login-container">
			<!-- <form @submit.prevent="submit" novalidate="true"> -->
			<div class="modalClose" @click="close"></div>
			<div class="title">修改密码</div>
			<div class="input-box">
				<input
					type="password"
					v-model="oldpassword"
					maxlength="20"
					placeholder="旧密码"
				/>
			</div>
			<div class="input-box">
				<input
					type="password"
					v-model="newpassword"
					maxlength="20"
					placeholder="新密码"
				/>
			</div>
			<div class="input-box">
				<input
					type="password"
					v-model="confirmpassword"
					maxlength="20"
					placeholder="确认新密码"
				/>
			</div>
			<div class="submit">
				<button @click="submit">确认修改</button>
			</div>
			<!-- </form> -->
		</div>
	</div>
</template>
<script>
export default {
	props: ["ischangePassword"],
	data() {
		return {
			oldpassword: "",
			newpassword: "",
			confirmpassword: ""
		}
	},
	methods: {
		close() {
			this.$parent.ischangePassword = false
		},
		submit() {
			if (!this.oldpassword) {
				this.$message.error("请输入旧密码！")
				return
			}
			if (!this.newpassword) {
				this.$message.error("请输入新密码！")
				return
			}
			if (!this.confirmpassword) {
				this.$message.error("请输入确认新密码！")
				return
			}
			if (this.oldpassword === this.newpassword) {
				this.$message.error("新密码不得与旧密码重复！")
				return
			}
			if (this.confirmpassword !== this.newpassword) {
				this.$message.error("二次确认密码输入不一致，请检查！")
				return
			}
			this.$parent.changePasswordSubmit({
				oldPassword: this.oldpassword,
				newPassword: this.newpassword,
				confirmPassword: this.confirmpassword
			})
		}
	}
}
</script>

<style lang="less" scoped>
.login-container {
	width: 300px;
	height: 270px;
	display: inline-block;
	text-align: center;
	border-radius: 6px;
	background-color: #ffffff;
	z-index: 800;
	position: relative;
}

.title {
	margin: 20px auto 20px auto;
	line-height: 20px;
	font-size: 20px;
	.c-font-family-PF();
	font-weight: 500;
	color: #333333;
}
input {
	padding-left: 5px;
	background-color: #f1f2f7;
	border-radius: 2px;
	border: none;
	text-indent: 2px;
	outline: none;
}
input:focus {
	border-color: #3463ea;
	box-shadow: 0 0 3px #3463ea;
}
.input-box input {
	width: 256px;
	height: 30px;
	margin: 10px auto;
	background-color: #f4f4f4;
	border: 1px solid #e0e0e0;
	border-radius: 2px;
	text-indent: 2px;
}

.submit button {
	width: 262px;
	line-height: 30px;
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	border: none;
	border-radius: 2px;
	color: #ffffff;
	background-color: #3462e9;
	margin: 16px auto 0 auto;
}

.bottom-bar {
	width: 262px;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
}

.bottom-bar .auto-login {
	display: flex;
	flex-direction: row;
	height: 26px;
}

.bottom-bar .auto-login input {
	margin: auto 2px;
}

.bottom-bar .auto-login .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	color: #333333;
	line-height: 26px;
}

.bottom-bar .other-options {
	display: flex;
	flex-direction: row;
	margin-left: auto;
	margin-right: 0;
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	color: #999999;
	line-height: 26px;
}

.bottom-bar .seperator {
	width: 10px;
	text-align: center;
}

.bottom-bar .btn-bar {
	cursor: pointer;
}
</style>
