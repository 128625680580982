/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {DataStatusEnum} from './DataStatusEnum';
import {ReferenceTypeEnum} from './ReferenceTypeEnum';

/**
 * The SubmittedSequence model module.
 * @module model/SubmittedSequence
 * @version v1
 */
export class SubmittedSequence {
  /**
   * Constructs a new <code>SubmittedSequence</code>.
   * @alias module:model/SubmittedSequence
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>SubmittedSequence</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SubmittedSequence} obj Optional instance to populate.
   * @return {module:model/SubmittedSequence} The populated <code>SubmittedSequence</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SubmittedSequence();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('type'))
        obj.type = ApiClient.convertToType(data['type'], 'String');
      if (data.hasOwnProperty('target'))
        obj.target = ApiClient.convertToType(data['target'], 'String');
      if (data.hasOwnProperty('targetType'))
        obj.targetType = ApiClient.convertToType(data['targetType'], 'String');
      if (data.hasOwnProperty('affinity'))
        obj.affinity = ApiClient.convertToType(data['affinity'], 'String');
      if (data.hasOwnProperty('screenMethod'))
        obj.screenMethod = ApiClient.convertToType(data['screenMethod'], 'String');
      if (data.hasOwnProperty('sampleType'))
        obj.sampleType = ApiClient.convertToType(data['sampleType'], 'String');
      if (data.hasOwnProperty('appliedteChnology'))
        obj.appliedteChnology = ApiClient.convertToType(data['appliedteChnology'], 'String');
      if (data.hasOwnProperty('combiningCondition'))
        obj.combiningCondition = ApiClient.convertToType(data['combiningCondition'], 'String');
      if (data.hasOwnProperty('sequence'))
        obj.sequence = ApiClient.convertToType(data['sequence'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = DataStatusEnum.constructFromObject(data['status']);
      if (data.hasOwnProperty('auditRemark'))
        obj.auditRemark = ApiClient.convertToType(data['auditRemark'], 'String');
      if (data.hasOwnProperty('referenceType'))
        obj.referenceType = ReferenceTypeEnum.constructFromObject(data['referenceType']);
      if (data.hasOwnProperty('referenceInfo'))
        obj.referenceInfo = ApiClient.convertToType(data['referenceInfo'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
SubmittedSequence.prototype.id = undefined;

/**
 * @member {String} name
 */
SubmittedSequence.prototype.name = undefined;

/**
 * @member {String} type
 */
SubmittedSequence.prototype.type = undefined;

/**
 * @member {String} target
 */
SubmittedSequence.prototype.target = undefined;

/**
 * @member {String} targetType
 */
SubmittedSequence.prototype.targetType = undefined;

/**
 * @member {String} affinity
 */
SubmittedSequence.prototype.affinity = undefined;

/**
 * @member {String} screenMethod
 */
SubmittedSequence.prototype.screenMethod = undefined;

/**
 * @member {String} sampleType
 */
SubmittedSequence.prototype.sampleType = undefined;

/**
 * @member {String} appliedteChnology
 */
SubmittedSequence.prototype.appliedteChnology = undefined;

/**
 * @member {String} combiningCondition
 */
SubmittedSequence.prototype.combiningCondition = undefined;

/**
 * @member {String} sequence
 */
SubmittedSequence.prototype.sequence = undefined;

/**
 * @member {module:model/DataStatusEnum} status
 */
SubmittedSequence.prototype.status = undefined;

/**
 * @member {String} auditRemark
 */
SubmittedSequence.prototype.auditRemark = undefined;

/**
 * @member {module:model/ReferenceTypeEnum} referenceType
 */
SubmittedSequence.prototype.referenceType = undefined;

/**
 * @member {String} referenceInfo
 */
SubmittedSequence.prototype.referenceInfo = undefined;

