/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {ArticleCommentsDtoResponse} from '../model/ArticleCommentsDtoResponse';
import {ArticleDetailDtoResponse} from '../model/ArticleDetailDtoResponse';
import {ArticleExportResponseDtoResponse} from '../model/ArticleExportResponseDtoResponse';
import {ArticleFigureResponseResponse} from '../model/ArticleFigureResponseResponse';
import {ArticleListDtoResponse} from '../model/ArticleListDtoResponse';
import {ArticleProductResultResponse} from '../model/ArticleProductResultResponse';
import {OrderByEnum} from '../model/OrderByEnum';
import {SearchTypeEnum} from '../model/SearchTypeEnum';
import {TargetTypeEnum} from '../model/TargetTypeEnum';

/**
* Article service.
* @module api/ArticleApi
* @version v1
*/
export class ArticleApi {

    /**
    * Constructs a new ArticleApi. 
    * @alias module:api/ArticleApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiArticleArticlefigureGet operation.
     * @callback moduleapi/ArticleApi~apiArticleArticlefigureGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ArticleFigureResponseResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 文献相关图片查询接口
     * @param {Object} opts Optional parameters
     * @param {String} opts.articleid 
     * @param {module:api/ArticleApi~apiArticleArticlefigureGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiArticleArticlefigureGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'articleid': opts['articleid']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ArticleFigureResponseResponse;

      return this.apiClient.callApi(
        '/api/Article/articlefigure', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiArticleArticleproductGet operation.
     * @callback moduleapi/ArticleApi~apiArticleArticleproductGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ArticleProductResultResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 文献相关产品查询接口
     * @param {Object} opts Optional parameters
     * @param {String} opts.articleid 
     * @param {module:api/ArticleApi~apiArticleArticleproductGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiArticleArticleproductGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'articleid': opts['articleid']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ArticleProductResultResponse;

      return this.apiClient.callApi(
        '/api/Article/articleproduct', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiArticleCommentsGet operation.
     * @callback moduleapi/ArticleApi~apiArticleCommentsGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ArticleCommentsDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 单独评论查询
     * @param {Object} opts Optional parameters
     * @param {String} opts.articleid 
     * @param {module:api/ArticleApi~apiArticleCommentsGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiArticleCommentsGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'articleid': opts['articleid']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ArticleCommentsDtoResponse;

      return this.apiClient.callApi(
        '/api/Article/comments', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiArticleExportdataGet operation.
     * @callback moduleapi/ArticleApi~apiArticleExportdataGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ArticleExportResponseDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/OrderByEnum} orderBy 
     * @param {module:model/SearchTypeEnum} searchType 
     * @param {Object} opts Optional parameters
     * @param {String} opts.aptamerID 
     * @param {String} opts.keyWord 
     * @param {module:model/TargetTypeEnum} opts.targetType 
     * @param {String} opts.country 
     * @param {String} opts.language 
     * @param {String} opts.title 
     * @param {String} opts._abstract 
     * @param {String} opts.author 
     * @param {Number} opts.exportType 
     * @param {Array.<String>} opts.selectRecords 
     * @param {Number} opts.startNo 
     * @param {Number} opts.endNo 
     * @param {String} opts.targetName 
     * @param {String} opts.aptamerType 
     * @param {String} opts.sampleType 
     * @param {String} opts.usageType 
     * @param {Number} opts.index 
     * @param {Number} opts.size 
     * @param {module:api/ArticleApi~apiArticleExportdataGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiArticleExportdataGet(orderBy, searchType, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'orderBy' is set
      if (orderBy === undefined || orderBy === null) {
        throw new Error("Missing the required parameter 'orderBy' when calling apiArticleExportdataGet");
      }
      // verify the required parameter 'searchType' is set
      if (searchType === undefined || searchType === null) {
        throw new Error("Missing the required parameter 'searchType' when calling apiArticleExportdataGet");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'AptamerID': opts['aptamerID'],'KeyWord': opts['keyWord'],'TargetType': opts['targetType'],'Country': opts['country'],'Language': opts['language'],'OrderBy': orderBy,'Title': opts['title'],'Abstract': opts['_abstract'],'Author': opts['author'],'ExportType': opts['exportType'],'SelectRecords': this.apiClient.buildCollectionParam(opts['selectRecords'], 'multi'),'startNo': opts['startNo'],'endNo': opts['endNo'],'TargetName': opts['targetName'],'AptamerType': opts['aptamerType'],'SampleType': opts['sampleType'],'UsageType': opts['usageType'],'SearchType': searchType,'Index': opts['index'],'Size': opts['size']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ArticleExportResponseDtoResponse;

      return this.apiClient.callApi(
        '/api/Article/exportdata', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiArticleIdGet operation.
     * @callback moduleapi/ArticleApi~apiArticleIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ArticleDetailDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 文献详情
     * @param {String} id 
     * @param {module:api/ArticleApi~apiArticleIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiArticleIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiArticleIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ArticleDetailDtoResponse;

      return this.apiClient.callApi(
        '/api/Article/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiArticleQueryGet operation.
     * @callback moduleapi/ArticleApi~apiArticleQueryGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ArticleListDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 文献查询  一般查询、高级查询
     * @param {module:model/OrderByEnum} orderBy 
     * @param {module:model/SearchTypeEnum} searchType 
     * @param {Object} opts Optional parameters
     * @param {String} opts.aptamerID 
     * @param {String} opts.keyWord 
     * @param {module:model/TargetTypeEnum} opts.targetType 
     * @param {String} opts.targetName 
     * @param {String} opts.country 
     * @param {String} opts.language 
     * @param {String} opts.aptamerType 
     * @param {String} opts.sampleType 
     * @param {String} opts.usageType 
     * @param {String} opts.title 
     * @param {String} opts._abstract 
     * @param {String} opts.author 
     * @param {Number} opts.index 
     * @param {Number} opts.size 
     * @param {module:api/ArticleApi~apiArticleQueryGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiArticleQueryGet(orderBy, searchType, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'orderBy' is set
      if (orderBy === undefined || orderBy === null) {
        throw new Error("Missing the required parameter 'orderBy' when calling apiArticleQueryGet");
      }
      // verify the required parameter 'searchType' is set
      if (searchType === undefined || searchType === null) {
        throw new Error("Missing the required parameter 'searchType' when calling apiArticleQueryGet");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'AptamerID': opts['aptamerID'],'KeyWord': opts['keyWord'],'TargetType': opts['targetType'],'TargetName': opts['targetName'],'Country': opts['country'],'Language': opts['language'],'AptamerType': opts['aptamerType'],'SampleType': opts['sampleType'],'UsageType': opts['usageType'],'OrderBy': orderBy,'Title': opts['title'],'Abstract': opts['_abstract'],'Author': opts['author'],'SearchType': searchType,'Index': opts['index'],'Size': opts['size']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ArticleListDtoResponse;

      return this.apiClient.callApi(
        '/api/Article/query', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}