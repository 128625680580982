/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {DataStatusEnum} from './DataStatusEnum';
import {LearnTopicTypeEnum} from './LearnTopicTypeEnum';

/**
 * The LearnTopicDetailDto model module.
 * @module model/LearnTopicDetailDto
 * @version v1
 */
export class LearnTopicDetailDto {
  /**
   * Constructs a new <code>LearnTopicDetailDto</code>.
   * @alias module:model/LearnTopicDetailDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>LearnTopicDetailDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/LearnTopicDetailDto} obj Optional instance to populate.
   * @return {module:model/LearnTopicDetailDto} The populated <code>LearnTopicDetailDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new LearnTopicDetailDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('content'))
        obj.content = ApiClient.convertToType(data['content'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = DataStatusEnum.constructFromObject(data['status']);
      if (data.hasOwnProperty('type'))
        obj.type = LearnTopicTypeEnum.constructFromObject(data['type']);
      if (data.hasOwnProperty('createOn'))
        obj.createOn = ApiClient.convertToType(data['createOn'], 'String');
      if (data.hasOwnProperty('createBy'))
        obj.createBy = ApiClient.convertToType(data['createBy'], 'String');
      if (data.hasOwnProperty('createUserName'))
        obj.createUserName = ApiClient.convertToType(data['createUserName'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
LearnTopicDetailDto.prototype.id = undefined;

/**
 * @member {String} title
 */
LearnTopicDetailDto.prototype.title = undefined;

/**
 * @member {String} content
 */
LearnTopicDetailDto.prototype.content = undefined;

/**
 * @member {module:model/DataStatusEnum} status
 */
LearnTopicDetailDto.prototype.status = undefined;

/**
 * @member {module:model/LearnTopicTypeEnum} type
 */
LearnTopicDetailDto.prototype.type = undefined;

/**
 * @member {String} createOn
 */
LearnTopicDetailDto.prototype.createOn = undefined;

/**
 * @member {String} createBy
 */
LearnTopicDetailDto.prototype.createBy = undefined;

/**
 * @member {String} createUserName
 */
LearnTopicDetailDto.prototype.createUserName = undefined;

