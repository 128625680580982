<template>
	<div>
		<div style="margin-bottom: 20px; overflow: hidden">
			<!--一级评论-->
			<div
				class="comFlexDiv"
				:class="{
					comBorder: index >= 1,
					zero: !index,
					one: index,
					two: index > 1
				}"
			>
				<div style="width: 50px">
					<div v-if="!this.commentInfo.avator" class="noAvator" style="width:39px;height:39px;"></div>
					<img v-else
						style="width: 39px; height: 39px; border-radius: 50%"
						:src="avator"
					/>
				</div>
				<div style="width: 713px">
					<span style="color: #3463ea; .c-font-size-16();">{{
						commentInfo.userName || ""
					}}</span
					><span> · {{ commentInfo.createOn | dateFormat("YYYY-MM-DD") }}</span>
				</div>
				<div
					style="
						width: 50px;
						text-align: right;
						position: absolute;
						right: 15px;
					"
				>
					<a v-if="isLogin"
						style="color: #3463ea; margin-left: 10px; cursor: pointer"
						@click="showDialog(commentInfo.commentID)"
						>回复</a
					>
				</div>
			</div>

			<div class="comFlexDiv">
				<div style="width: 50px"></div>
				<div style="width: 778px" class="textOmitted">
					{{ commentInfo.content || "" }}
				</div>
			</div>
			<!--二级评论-->
			<div
				v-if="commentInfo.childs && commentInfo.childs.length > 0"
				class="comFlexDiv"
			>
				<div style="width: 30px"></div>
				<div :class="`level-${index}`" :style="sliderStyle">
					<!-- <div style="width: 800px; background-color: rgba(0, 0, 0, 0.1)"> -->
					<div v-if="!pullDown || commentInfo.childs.length <= 3" ref="wadder">
						<div
							v-for="(chiCom, j) in commentInfo.childs.slice(0, 3)"
							:key="j"
							style="margin: 15px"
						>
							<comment
								@commentSub="getComment"
								:key="j"
								:commentInfo="chiCom"
								:isFirst="false"
								:index="j"
								:isLogin="isLogin"
							></comment>
						</div>
					</div>
					<div v-else ref="wadder">
						<div
							v-for="(chiCom, j) in commentInfo.childs"
							:key="j"
							style="margin: 15px"
						>
							<comment
								@commentSub="getComment"
								:key="j"
								:commentInfo="chiCom"
								:isFirst="false"
								:index="j"
								:isLogin="isLogin"
							></comment>
						</div>
					</div>
					<div
						v-if="commentInfo.childs && commentInfo.childs.length > 3"
						style="text-align: center; margin: 15px"
						class="comBorder"
					>
						<span v-if="!pullDown" @click="pullDown = true"
							>显示全部{{ commentInfo.childs.length }}条</span
						>
						<span v-else @click="pullDown = false">收起评论</span>
					</div>
				</div>
			</div>
		</div>
		<!--评论回复弹窗-->
		<div id="exportDia">
			<el-dialog
				title="回复评论"
				:visible="openDialog"
				@close="closeDialog"
				width="650px"
			>
				<div class="radioDiv">
					<el-input
						maxlength="1000"
						class="el-input_inner"
						type="textarea"
						placeholder="发表评论..."
						rows="3"
						v-model="commentStr"
						show-word-limit
					>
					</el-input>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="commentSub">发表评论</el-button>
					<el-button @click="closeDialog">取消</el-button>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import { ApiClient, ArticleApi } from "../../../api"
export default {
	name: "comment",
	props: ["commentInfo", "isFirst", "index", "commentStr", "isLogin"],
	data() {
		return {
			articleApi: new ArticleApi(ApiClient.instance),
			pullDown: false,
			openDialog: false,
			imgpath: "./img/frontpage-team.92e8db51.png",
			parentID: "",
			avator: "",
			sliderStyle: {}
		}
	},
	mounted() {
		this.sliderStyle.width = "800px"
		this.sliderStyle.backgroundColor = "#EDF1F4"
		this.avator = "data:image/png;base64," + this.commentInfo.avator
	},
	methods: {
		closeDialog() {
			this.openDialog = false
		},
		showDialog(val) {
			this.parentID = val
			this.openDialog = true
		},
		commentSub() {
			var opts = { parentID: this.parentID, commentStr: this.commentStr }
			this.$emit("commentSub", opts)
			this.openDialog = false
			this.commentStr = ""
		},
		getComment(opts) {
			this.$emit("commentSub", opts)
			this.openDialog = false
			this.commentStr = ""
		}
	}
}
</script>

<style lang="less" scoped>
/*div文本过长显示...*/
.textOmitted {
	// display: -webkit-box;
	// -webkit-box-orient: vertical;
	// -webkit-line-clamp: 3;
	// overflow: hidden;
}
.imgs {
	margin: 10px;
	width: 256px;
	height: 256px;
}

.comFlexDiv {
	display: flex;
	align-items: center;
}

.commentLabel {
	.c-font-size-16();
	margin-top: 10px;
	margin-bottom: 10px;
}

.commentSubmit {
	width: 100%;
	background-color: rgb(239, 239, 239);
	display: flex;
	align-items: center;
	padding: 10px;
}
.commentSubmit button {
	color: #fff;
	background-color: #409eff;
	border: 1px solid #409eff;
	border-radius: 4px;
	padding: 10px 10px;
}

.comBorder {
	border-top: 2px solid rgb(239, 239, 239);
}

.tab-tilte {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	list-style: none;
	white-space: nowrap;
	transition: width 0.5s ease;
}

.tab-tilte li {
	padding: 10px 20px;
	.c-font-size-14();
	.c-font-family-PF();
	font-weight: bolder;
	line-height: 20px;
	color: #333333;
}

.active {
	border-bottom: 2px solid #3463ea;
}

.imgpanel {
	width: 80px;
	height: 80px;
	margin: 16px 0 0 16px;
}

.top-bar-container {
	width: 100%;
	background-color: #ffffff;
	display: inline-block;
}

.result {
	.c-font-size-14();
	.c-font-family-PF();
	line-height: 16px;
	color: #666666;
	margin: 10px 20px;
}

.result .value {
	color: #3463ea;
	margin: 0 6px;
}

.team-list {
	width: 100%;
}

.team-container {
	width: 100%;
	display: inline-flex;
	background-color: #ffffff;
	margin-top: 16px;
}

.team {
	width: 100%;
	margin: 16px;
	font-family: PingFang-SC-Medium;
	.c-font-size-14();
}

.team .title {
	.c-font-size-12();
	color: #3463ea;
	line-height: 20px;
	text-align: left;
}

.team .title a {
	text-decoration: none;
	.c-font-size-16();
}
.team .title a:visited {
	color: #3463ea;
}

.team .entry a:visited {
	color: #3463ea;
}

.team .location {
	.c-font-size-12();
	line-height: 20px;
	display: flex;
	flex-direction: row;
	text-align: left;
}

.team .location .entry {
	display: flex;
	flex-direction: row;
	margin-right: 20px;
}

.team .location .entry .value {
	margin-left: 6px;
}

.team .link {
	display: flex;
	flex-direction: row;
	.c-font-size-12();
	line-height: 20px;
}

.team .link .url {
	margin-left: 6px;
}

.pagination {
	display: flex;
	justify-content: center;
	margin: 10px auto;
}
</style>
