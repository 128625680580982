var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-panel main-list" }, [
    _c("div", { staticClass: "left-panel" }, [
      _c(
        "div",
        { staticClass: "statistic-panel" },
        [
          _c("doughnut-chart", {
            attrs: {
              dataList: _vm.statistic,
              totalDesc: "团队总数",
              isDataFixed: "true",
            },
            on: { click: _vm.countrySelect },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-panel" },
        [_c("teamfilter", { on: { filterchang: _vm.getFilter } })],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "list-panel" },
      [
        _c("teamlist", {
          attrs: {
            teams: _vm.teams,
            resultAmount: _vm.resultAmount,
            isLogin: _vm.isLogin,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }