<template>
	<div class="main-panel main-list">
		<div class="left-panel">
			<div class="statistic-panel">
				<doughnut-chart
					:dataList="statistic"
					totalDesc="国家统计"
					@click="countrySelect"
					isDataFixed="true"
				></doughnut-chart>
			</div>
			<div class="filter-panel">
				<paperfilter
					@filterchang="getFilter"
					:filerInfo="filerInfo"
				></paperfilter>
			</div>
		</div>
		<div class="list-panel">
			<div class="source-claim">（数据来源于美国FDA）</div>
			<paperlist
				:orderBy="filerInfo.orderby"
				:datalist="datalist"
				:resultAmount="resultAmount"
			></paperlist>
			<div class="pagination">
				<el-pagination
					id="pagination"
					:current-page="pageIndex"
					:page-size="pageSize"
					:page-sizes="[10, 25, 50]"
					layout="prev, pager, next, jumper, sizes"
					:total="resultAmount"
					@current-change="queryChange"
					@size-change="sizeChange"
					@prev-click="queryChange"
					@next-click="queryChange"
				>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import paperlist from "./list/list.vue"
import paperfilter from "./list/filter.vue"
import { ApiClient, ClinicalApi } from "../../api"
import doughnutChart from "../../components/charts/DoughnutChart.vue"
import "../../assets/css/list.less"
import "../../assets/css/list-pager@index.less"

export default {
	data() {
		return {
			clinicalApi: new ClinicalApi(ApiClient.instance),
			resultAmount: 0,
			datalist: [],
			searchType: 0, // 0-一般搜索，1-高级搜索，2-一般+筛选搜索
			pageIndex: 1,
			pageSize: 10,
			filerInfo: {
				title: "",
				descriptiveInformation: "",
				organization: "",
				orderBy: "", // 排序字段，只有一个值，开始日期
				keyword: "", // 关键字
				conditions: "", // 适应症
				country: "", // 国家
				recruitmentStatus: "" // 招募状态
			},
			statistic: []
		}
	},
	methods: {
		getData() {
			const opts = {
				keyWord: this.filerInfo.keyword,
				country: this.filerInfo.country,
				conditions: this.filerInfo.conditions,
				recruitmentStatus: this.filerInfo.recruitmentStatus,
				title: this.filerInfo.title,
				descriptiveInformation: this.filerInfo.descriptiveInformation,
				organization: this.filerInfo.organization,
				index: this.pageIndex,
				size: this.pageSize
			}
			this.clinicalApi.apiClinicalQueryGet(
				this.searchType,
				opts,
				(error, data, Response) => {
					// console.log("临床列表查询")
					// console.log(Response)
					if (error) {
						console.log(error)
					} else {
						this.datalist = data.result.records
						this.resultAmount = data.result.total
						this.statistic = data.result.countryStatistics
					}
				}
			)
		},
		// 筛选条件改变
		getFilter(data) {
			this.filerInfo.conditions = data.conditions
			this.filerInfo.country = data.country
			this.filerInfo.recruitmentStatus = data.recruitmentStatus
			this.searchType = 2
			// const opts = {
			// 	keyWord: this.filerInfo.keyword,
			// 	country: this.filerInfo.country,
			// 	conditions: this.filerInfo.conditions,
			// 	recruitmentStatus: this.filerInfo.recruitmentStatus,
			// 	index: this.pageIndex,
			// 	size: this.pageSize
			// }
			// this.getData()
			this.replaceUrl()
		},
		// 分页条件改变
		queryChange(val) {
			this.pageIndex = val
			// const opts = {
			// 	keyWord: this.filerInfo.keyword,
			// 	country: this.filerInfo.country,
			// 	conditions: this.filerInfo.conditions,
			// 	recruitmentStatus: this.filerInfo.recruitmentStatus,
			// 	index: this.pageIndex,
			// 	size: this.pageSize
			// }
			// this.getData()
			this.replaceUrl()
		},
		sizeChange(val) {
			this.pageIndex = 1
			this.pageSize = val
			// const opts = {
			// 	keyWord: this.filerInfo.keyword,
			// 	country: this.filerInfo.country,
			// 	conditions: this.filerInfo.conditions,
			// 	recruitmentStatus: this.filerInfo.recruitmentStatus,
			// 	index: this.pageIndex,
			// 	size: this.pageSize
			// }
			// this.getData()
			this.replaceUrl()
		},
		replaceUrl() {
			console.log("进入临床replaceUrl")

			const apiopts = {
				keyWord: this.filerInfo.keyword,
				country: this.filerInfo.country,
				conditions: this.filerInfo.conditions,
				recruitmentStatus: this.filerInfo.recruitmentStatus,
				index: this.pageIndex,
				size: this.pageSize,
				searchtype: this.searchType,
				title: this.filerInfo.title,
				organization: this.filerInfo.organization,
				descriptiveInformation: this.descriptiveInformation
			}

			// this.$set(opts, "searchtype", this.searchType)
			// console.log(apiopts)
			this.$router.replace({ query: apiopts })
		},
		getURLParams() {
			var query = this.$route.query
			// console.log("进入临床url取值", query)
			this.filerInfo.keyword = query.keyWord
				? query.keyWord
				: query.keyword || ""
			this.pageIndex = query.index ? parseInt(query.index) : this.pageIndex

			this.pageSize = query.size ? parseInt(query.size) : this.pageSize

			this.searchType = query.searchtype
				? parseInt(query.searchtype)
				: this.searchType

			this.filerInfo.conditions = query.conditions || this.filerInfo.conditions
			this.filerInfo.recruitmentStatus =
				query.recruitmentStatus || this.filerInfo.recruitmentStatus

			this.filerInfo.title = query.title
			this.filerInfo.descriptiveInformation = query.descriptiveInformation
			this.filerInfo.organization = query.organization
			this.filerInfo.country = query.country
		},
		countrySelect(arg) {
			// if (!arg.isOther) {
			// 	this.filerInfo.country = arg.select[0]
			// 	this.searchType = 2
			// 	this.replaceUrl()
			// }
			this.filerInfo.country = arg?.select.length > 0 ? arg.select[0] : null
			this.searchType = 2
			this.replaceUrl()
		}
	},
	mounted: function () {
		this.getURLParams()
		this.getData()
	},
	watch: {
		$route(to, from) {
			this.getURLParams()
			this.getData()
		}
	},
	components: {
		paperlist,
		paperfilter,
		doughnutChart
	}
}
</script>

<style scoped lang="less">
.list-panel {
	position: relative;
}
.source-claim {
	position: absolute;
	.c-font-size-14();
	color: #666666;
	right: 0;
	top: -22px;
}
</style>
