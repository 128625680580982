/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {ArticleProductResult} from './ArticleProductResult';
import {HttpCodeEnum} from './HttpCodeEnum';

/**
 * The ArticleProductResultResponse model module.
 * @module model/ArticleProductResultResponse
 * @version v1
 */
export class ArticleProductResultResponse {
  /**
   * Constructs a new <code>ArticleProductResultResponse</code>.
   * @alias module:model/ArticleProductResultResponse
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ArticleProductResultResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ArticleProductResultResponse} obj Optional instance to populate.
   * @return {module:model/ArticleProductResultResponse} The populated <code>ArticleProductResultResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ArticleProductResultResponse();
      if (data.hasOwnProperty('success'))
        obj.success = ApiClient.convertToType(data['success'], 'Boolean');
      if (data.hasOwnProperty('errorCode'))
        obj.errorCode = HttpCodeEnum.constructFromObject(data['errorCode']);
      if (data.hasOwnProperty('errorMsg'))
        obj.errorMsg = ApiClient.convertToType(data['errorMsg'], 'String');
      if (data.hasOwnProperty('result'))
        obj.result = ArticleProductResult.constructFromObject(data['result']);
      if (data.hasOwnProperty('errorMsgModel'))
        obj.errorMsgModel = ApiClient.convertToType(data['errorMsgModel'], {'String': ['String']});
    }
    return obj;
  }
}

/**
 * @member {Boolean} success
 */
ArticleProductResultResponse.prototype.success = undefined;

/**
 * @member {module:model/HttpCodeEnum} errorCode
 */
ArticleProductResultResponse.prototype.errorCode = undefined;

/**
 * @member {String} errorMsg
 */
ArticleProductResultResponse.prototype.errorMsg = undefined;

/**
 * @member {module:model/ArticleProductResult} result
 */
ArticleProductResultResponse.prototype.result = undefined;

/**
 * @member {Object.<String, Array.<String>>} errorMsgModel
 */
ArticleProductResultResponse.prototype.errorMsgModel = undefined;

