<template>
	<div class="forgetpwd" @click.stop="isolateClick()">
		<div class="modalClose" @click="close"></div>
		<div class="title">忘记密码</div>
		<div class="container">
			<div class="entity">
				<div class="label">帐号</div>
				<div class="value">
					<el-input
						v-model="account"
						autocomplete="off"
						placeholder="请输入帐号"
						maxlength="15"
					></el-input>
				</div>
			</div>
			<div>
				<div class="inputHint" style="margin-left: 110px;">
					输入长度不小于6且不超过15
				</div>
			</div>
			<div class="entity" style="margin-bottom: 20px;">
				<div class="label">手机号</div>
				<div class="value">
					<el-input
						v-model="phone"
						placeholder="请输入手机号"
						autocomplete="off"
						maxlength="11"
					></el-input>
				</div>
			</div>
			<!-- <div class="entity verification-code" style="margin-bottom: 20px;">
				<div class="label">验证码</div>
				<div class="value">
					<el-input
						v-model="imgcode"
						autocomplete="off"
						class="img-code"
						placeholder="请输入验证码"
						maxlength="4"
					></el-input>
					<img @click="refreshCaptcha" :src="captcha" alt="点击刷新" />
				</div>
			</div> -->
			<div class="entity verification-code" style="margin-bottom: 20px;">
				<div class="label">短信码</div>
				<div class="value">
					<el-input
						v-model="smscode"
						autocomplete="off"
						class="sms-code"
						placeholder="请输入短信码"
						maxlength="4"
					></el-input>
					<el-button type="primary" @click="sendSms()">{{
						buttonText
					}}</el-button>
				</div>
			</div>
			<div class="entity">
				<div class="label">新密码</div>
				<div class="value">
					<el-input
						v-model="password"
						autocomplete="new-password"
						type="password"
						placeholder="请输入新密码"
						maxlength="15"
					></el-input>
				</div>
			</div>
			<div>
				<div class="inputHint" style="margin-left: 110px;">
					输入长度不小于6且不超过15，且至少包含数字、字母、特殊字符中的两种
				</div>
			</div>
			<div class="entity">
				<div class="label"></div>
				<div class="value btn">
					<el-button :disabled="submitDisable" type="primary" @click="submit()"
						>重置密码</el-button
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ApiClient, UserCenterApi } from "../api"
import Swal from "sweetalert2"

export default {
	data() {
		return {
			account: "",
			phone: "",
			smscode: "",
			imgcode: "",
			password: "",
			t: 0,
			submitDisable: true,
			api: new UserCenterApi(ApiClient.instance),
			totalTime: 0,
			buttonText: "获取短信码",
			timer: 0
		}
	},
	mounted() {},
	computed: {
		captcha() {
			return `${process.env.VUE_APP_API_ENDPOINT}/api/UserCenter/captcha?t=${this.t}`
		}
	},
	methods: {
		close() {
			this.$parent.closeForgetPwd()
		},
		submit(event) {
			// const _this = this
			if (!this.account) {
				this.$message({ message: "账号不能为空！", type: "error" })
				return
			}
			if (this.account.length < 6 || this.account.length > 15) {
				this.$message({ message: "账号长度输入不正确", type: "error" })
				return
			}
			if (!this.phone) {
				this.$message({ message: "手机号不能为空！", type: "error" })
				return
			}
			var phoneRe = /^1\d{10}$/
			if (!phoneRe.test(this.phone)) {
				this.$message({ message: "手机号格式输入错误！", type: "error" })
				return
			}
			// if (!this.imgcode) {
			// 	this.$message({ message: "验证码不能为空！", type: "error" })
			// 	return
			// }
			if (!this.smscode) {
				this.$message({ message: "短信码不能为空！", type: "error" })
				return
			}
			if (!this.password) {
				this.$message({ message: "新密码不能为空！", type: "error" })
				return
			}
			if (this.password.length < 6 || this.password.length > 15) {
				this.$message({ message: "密码长度输入不正确", type: "error" })
				return
			}
			this.api.apiUserCenterForgetpasswordPost(
				{
					body: {
						account: this.account,
						phone: this.phone,
						smsCode: this.smscode,
						password: this.password
					}
				},
				(e, data, resp) => {
					if (data && data.success) {
						Swal.fire("重置成功").then(() => this.close())
					} else {
						var err = this.$options.filters.getResponseErrors(resp)
						Swal.fire({ icon: "error", html: err })
					}
				}
			)
			// this.setTimeout(() => {
			// 	_this.submitDisable = false
			// }, 3000)
		},
		isolateClick() {
			// do nothing
		},
		refreshCaptcha() {
			this.t = Date.now()
		},
		sendSms() {
			if (this.totalTime > 0) {
				return
			}
			// if (!this.imgcode || !this.phone) {
			// 	Swal.fire({
			// 		text: "请输入手机号和图形验证码！",
			// 		icon: "error"
			// 	})
			// 	return
			// }
			if (!this.phone) {
				Swal.fire({
					text: "请输入手机号！",
					icon: "error"
				})
				return
			}
			var phoneRe = /^1\d{10}$/
			if (!phoneRe.test(this.phone)) {
				Swal.fire({
					text: "手机号格式输入错误！",
					icon: "error"
				})
				return
			}

			// var captchaSha1 = Cookies.get("captacha_value")
			var captchaSha1 = ""
			this.api.apiUserCenterSmscaptchaGet(
				this.phone,
				{ captcha: this.imgcode, captchaSha1: captchaSha1 },
				(e, data) => {
					Swal.fire(data.result)
					this.totalTime = 60
					this.timer = window.setInterval(() => {
						this.buttonText = this.totalTime + "s后重新发送"
						this.totalTime--
						if (this.totalTime < 0) {
							this.totalTime = 0
							this.buttonText = "获取短信码"
							window.clearInterval(this.timer)
						}
					}, 1000)
					this.submitDisable = false
				}
			)
		}
	}
}
</script>

<style lang="less" scoped>
.forgetpwd {
	width: 700px;
	background: #ffffff;
	box-shadow: 0px 0px 6px 0px rgba(4, 0, 0, 0.07);
	padding: 60px 70px;
	border-radius: 6px;
	position: relative;

	.title {
		display: flex;
		justify-content: center;
		line-height: 20px;
		font-size: 20px;
		.c-font-family-PF();
		font-weight: 500;
		color: #333333;
		margin-bottom: 30px;
	}

	.container {
		.entity {
			display: flex;
			// width: 470px;
			height: 40px;
			// margin-bottom: 20px;
			.label {
				display: flex;

				width: 70px;
				.c-font-size-14();
				.c-font-family-PF();
				font-weight: 500;
				color: #333333;

				margin-right: 30px;
				align-items: center;
				justify-content: flex-end;
			}
			.value {
				display: flex;
				align-items: center;
				width: 400px;
			}

			.btn {
				display: flex;
				justify-content: center;
				button {
					width: 300px;
				}
			}
		}

		.verification-code {
			.value {
				.sms-code,
				.img-code {
					width: 300px !important;
					margin-right: 5px;
				}
				img,
				button {
					width: 120px;
				}
			}
		}
	}
}
</style>
