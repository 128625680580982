/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.34
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {FeedsArticleItemDtoResponse} from '../model/FeedsArticleItemDtoResponse';
import {FeedsArticleListDtoResponse} from '../model/FeedsArticleListDtoResponse';

/**
* FeedsArticle service.
* @module api/FeedsArticleApi
* @version v1
*/
export class FeedsArticleApi {

    /**
    * Constructs a new FeedsArticleApi. 
    * @alias module:api/FeedsArticleApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiFeedsArticleIdGet operation.
     * @callback moduleapi/FeedsArticleApi~apiFeedsArticleIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FeedsArticleItemDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 文献动态详情
     * @param {String} id 
     * @param {module:api/FeedsArticleApi~apiFeedsArticleIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFeedsArticleIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiFeedsArticleIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FeedsArticleItemDtoResponse;

      return this.apiClient.callApi(
        '/api/FeedsArticle/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiFeedsArticleQueryGet operation.
     * @callback moduleapi/FeedsArticleApi~apiFeedsArticleQueryGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FeedsArticleListDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 文献动态列表查询
     * @param {Object} opts Optional parameters
     * @param {Number} opts.state 
     * @param {Number} opts.index 
     * @param {Number} opts.size 
     * @param {module:api/FeedsArticleApi~apiFeedsArticleQueryGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiFeedsArticleQueryGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'State': opts['state'],'Index': opts['index'],'Size': opts['size']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FeedsArticleListDtoResponse;

      return this.apiClient.callApi(
        '/api/FeedsArticle/query', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}