<template>
	<div class="side-float-container">
		<div class="side-float-panel">
			<div class="message-trigger">
				<img src="../assets/message.png" @click="openModal()" />
			</div>
			<!-- TODO: hide when already in top -->
			<div class="slide-top" @click="slideToTop()">
				<img src="../assets/arrow-up.png" />
			</div>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2"
export default {
	name: "SideFloat",
	props: ["isLogin"],
	data() {
		return {
		}
	},
	methods: {
		slideToTop() {
			window.scrollTo(0, 0)
		},
		openModal() {
			if (this.isLogin) {
				this.$parent.$children[0].showMessageBox = true
			} else {
				Swal.fire({ icon: "error", html: "请登录！" }).then(() => { document.getElementById("loginSpan").click() })
			}
		}
	}
}
</script>
<style lang="less" scoped>
textarea {
	padding: 10px 10px;
	outline: none;
	resize: none;
}
.side-float-container {
	width: 30px;
	margin: 0 auto;
}

.side-float-panel {
	margin-left: auto;
	margin-right: 0;
}

.message-trigger {
	background-color: #ffffff;
	width: 40px;
	height: 40px;
	border-bottom: 1px solid #f0f0f0;
	cursor: pointer;
}

.slide-top {
	border-top: 1px solid #f0f0f0;
	width: 40px;
	height: 40px;
	background-color: #ffffff;
	cursor: pointer;
}
</style>
