var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "team-list-panel" }, [
    _c("div", { staticClass: "top-bar-container" }, [
      _c("div", [
        _c("div", { staticClass: "result" }, [
          _c(
            "ul",
            { staticClass: "tab-tilte", staticStyle: { margin: "0px" } },
            [
              _c(
                "li",
                {
                  class: { active: _vm.showIndex == 1 },
                  on: {
                    click: function ($event) {
                      _vm.showIndex = 1
                    },
                  },
                },
                [_vm._v(" 描述性信息 ")]
              ),
              _c(
                "li",
                {
                  class: { active: _vm.showIndex == 2 },
                  on: {
                    click: function ($event) {
                      _vm.showIndex = 2
                    },
                  },
                },
                [_vm._v(" 追踪信息 ")]
              ),
              _c(
                "li",
                {
                  class: { active: _vm.showIndex == 3 },
                  on: {
                    click: function ($event) {
                      _vm.showIndex = 3
                    },
                  },
                },
                [_vm._v(" 招募信息 ")]
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showIndex == 1,
            expression: "showIndex == 1",
          },
        ],
        staticClass: "team-list",
      },
      [
        _c("div", { staticClass: "team-container" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.content.descriptiveInformation.briefTitle)),
          ]),
          _c("div", { staticClass: "lable" }, [_vm._v("正式名称")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(
                _vm.content.descriptiveInformation.officialTitle
              ),
            },
          }),
          _c("div", { staticClass: "lable" }, [_vm._v("简要总结")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(
                _vm.content.descriptiveInformation.briefSummary
              ),
            },
          }),
          _c("div", { staticClass: "lable" }, [_vm._v("详细说明")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(
                _vm.content.descriptiveInformation.detailedDescription
              ),
            },
          }),
          _c("div", { staticClass: "lable" }, [_vm._v("研究类型")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(_vm.content.descriptiveInformation.studyType),
            },
          }),
          _c("div", { staticClass: "lable" }, [_vm._v("条件")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(_vm.content.descriptiveInformation.conditions),
            },
          }),
          _c("div", { staticClass: "lable" }, [_vm._v("干预")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(
                _vm.content.descriptiveInformation.intervention
              ),
            },
          }),
          _c("div", { staticClass: "lable" }, [_vm._v("出版物")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(
                _vm.content.descriptiveInformation.publications
              ),
            },
          }),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showIndex == 2,
            expression: "showIndex == 2",
          },
        ],
        staticClass: "team-list",
      },
      [
        _c("div", { staticClass: "team-container" }, [
          _c("div", { staticClass: "lable" }, [_vm._v("当前的主要成果指标")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(
                _vm.content.trackingInformation.currentPrimaryOutcomeMeasures
              ),
            },
          }),
          _c("div", { staticClass: "lable" }, [_vm._v("原始主要结局指标")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(
                _vm.content.trackingInformation.originalPrimaryOutcomeMeasures
              ),
            },
          }),
          _c("div", { staticClass: "lable" }, [_vm._v("当前的次要成果指标")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(
                _vm.content.trackingInformation.currentSecondaryOutcomeMeasures
              ),
            },
          }),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showIndex == 3,
            expression: "showIndex == 3",
          },
        ],
        staticClass: "team-list",
      },
      [
        _c("div", { staticClass: "team-container" }, [
          _c("div", { staticClass: "lable" }, [_vm._v("招募状态")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(
                _vm.content.recruitmentInformation.recruitmentStatus
              ),
            },
          }),
          _c("div", { staticClass: "lable" }, [_vm._v("预计招募人数")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(
                _vm.content.recruitmentInformation.estimatedEnrollment
              ),
            },
          }),
          _c("div", { staticClass: "lable" }, [_vm._v("预计完成研究日期")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(
                _vm.content.recruitmentInformation.estimatedStudyCompletionDate
              ),
            },
          }),
          _c("div", { staticClass: "lable" }, [_vm._v("资格标准")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(
                _vm.content.recruitmentInformation.eligibilityCriteria
              ),
            },
          }),
          _c("div", { staticClass: "lable" }, [_vm._v("年龄")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(_vm.content.recruitmentInformation.ages),
            },
          }),
          _c("div", { staticClass: "lable" }, [_vm._v("联系方式")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(_vm.content.recruitmentInformation.contacts),
            },
          }),
          _c("div", { staticClass: "lable" }, [_vm._v("国家/地区")]),
          _c("div", {
            staticClass: "text",
            domProps: {
              innerHTML: _vm._s(
                _vm.content.recruitmentInformation.listedLocationCountries
              ),
            },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }