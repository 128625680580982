var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-panel" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "recommended" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "dataTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              border: "",
              "element-loading-text": "结果加载中。。。",
              "empty-text": "-",
            },
            on: { "row-click": _vm.rowClick },
          },
          [
            _c(
              "el-table-column",
              {
                attrs: { align: "center", prop: "name", label: "名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("p", {
                          domProps: { innerHTML: _vm._s(row.name || "-") },
                        }),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c("img", {
                    attrs: { src: require("../../assets/evaluationname.png") },
                  }),
                  _vm._v("名称 "),
                ]),
              ],
              2
            ),
            _c(
              "el-table-column",
              {
                attrs: { align: "center", prop: "target", label: "靶标" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("p", {
                          domProps: { innerHTML: _vm._s(row.target || "-") },
                        }),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/evaluationtarget.png"),
                    },
                  }),
                  _vm._v("靶标 "),
                ]),
              ],
              2
            ),
            _c(
              "el-table-column",
              {
                attrs: {
                  align: "center",
                  prop: "affinity",
                  label: "亲和力(单位：nm)",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              row.affinity && parseFloat(row.affinity) > 0
                                ? parseFloat(row.affinity).toFixed(2)
                                : "-"
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/evaluationaffinity.png"),
                    },
                  }),
                  _vm._v("亲和力(单位：nm) "),
                ]),
              ],
              2
            ),
            _c(
              "el-table-column",
              {
                attrs: { align: "center", prop: "length", label: "长度" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("p", {
                          domProps: { innerHTML: _vm._s(row.length || "-") },
                        }),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/evaluationlength.png"),
                    },
                  }),
                  _vm._v("长度 "),
                ]),
              ],
              2
            ),
            _c(
              "el-table-column",
              {
                attrs: { align: "center", prop: "score", label: "推荐评分" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("p", {
                          domProps: { innerHTML: _vm._s(row.score || "-") },
                        }),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("template", { slot: "header" }, [
                  _c("img", {
                    attrs: { src: require("../../assets/evaluationscore.png") },
                  }),
                  _vm._v("推荐评分 "),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-bar" }, [
      _c("div", [_vm._v("核酸适配体列表")]),
      _c("p", [_vm._v("* 推荐打分公式：sum(IF * 完整度) / 靶标数量")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }