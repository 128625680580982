var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "team-list-panel" }, [
    _c(
      "div",
      { staticClass: "top-bar-container" },
      [
        _c(
          "div",
          { staticStyle: { color: "black" } },
          [
            _c(
              "router-link",
              {
                staticStyle: { "text-decoration": "underline" },
                attrs: {
                  to: "/aptamerdb/" + _vm.info.id,
                  title: "点击查看Aptamer详情",
                },
              },
              [_vm._v(" " + _vm._s(_vm.info.name) + " ")]
            ),
          ],
          1
        ),
        _c("div", { staticStyle: { color: "#c0c0c0", "margin-top": "16px" } }, [
          _vm._v(
            " Sequence ID: " +
              _vm._s(_vm.info.id) +
              "   Length: " +
              _vm._s(_vm.info.length) +
              "  Number of Matches: " +
              _vm._s(_vm.info.count) +
              " "
          ),
        ]),
        _vm._l(_vm.info.hsps, function (dto, k) {
          return _c("div", { key: k }, [
            _c(
              "div",
              {
                staticStyle: {
                  color: "#c0c0c0",
                  "margin-top": "16px",
                  width: "841px",
                },
              },
              [
                _vm._v(
                  " Query：" +
                    _vm._s(dto.rangeOne) +
                    " to " +
                    _vm._s(dto.rangeOneEnd) +
                    "    Sbjct：" +
                    _vm._s(dto.rangeTwo) +
                    " to " +
                    _vm._s(dto.rangeEnd) +
                    " "
                ),
              ]
            ),
            _c("div", { staticClass: "valuePanel" }, [
              _c("div", [
                _c("span", { staticClass: "valueTitle" }, [_vm._v("Score")]),
                _c("br"),
                _c("span", { staticClass: "valueLabel" }, [
                  _vm._v(_vm._s(dto.score)),
                ]),
              ]),
              _c("div", [
                _c("span", { staticClass: "valueTitle" }, [_vm._v("Expect")]),
                _c("br"),
                _c("span", { staticClass: "valueLabel" }, [
                  _vm._v(_vm._s(dto.expect)),
                ]),
              ]),
              _c("div", [
                _c("span", { staticClass: "valueTitle" }, [
                  _vm._v("Identities"),
                ]),
                _c("br"),
                _c("span", { staticClass: "valueLabel" }, [
                  _vm._v(
                    _vm._s(dto.identicalCount) +
                      " / " +
                      _vm._s(dto.alignCount) +
                      " (" +
                      _vm._s((dto.identicalPercent * 100).toFixed(2)) +
                      "%)"
                  ),
                ]),
              ]),
              _c("div", [
                _c("span", { staticClass: "valueTitle" }, [_vm._v("Gaps")]),
                _c("br"),
                _c("span", { staticClass: "valueLabel" }, [
                  _vm._v(
                    " " +
                      _vm._s(dto.gapCount) +
                      " / " +
                      _vm._s(dto.alignCount) +
                      " (" +
                      _vm._s((dto.gapPercent * 100).toFixed(2)) +
                      "%)"
                  ),
                ]),
              ]),
              _c("div", [
                _c("span", { staticClass: "valueTitle" }, [_vm._v("Strand")]),
                _c("br"),
                _c("span", { staticClass: "valueLabel" }, [
                  _vm._v(_vm._s(dto.strand)),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "thanPanel" },
              _vm._l(dto.contractResult, function (item, j) {
                return _c(
                  "div",
                  { key: j, staticStyle: { "margin-top": "10px" } },
                  [
                    _c(
                      "div",
                      { staticClass: "thanDiv" },
                      [
                        _c("div", { staticClass: "label" }, [_vm._v("Query")]),
                        _vm._l(item.align1, function (val, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "valueDiv" },
                            [_vm._v(" " + _vm._s(val) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "thanDiv" },
                      [
                        _c("div", { staticClass: "label" }),
                        _vm._l(item.middle, function (val, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "valueDiv" },
                            [_vm._v(" " + _vm._s(val) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "thanDiv" },
                      [
                        _c("div", { staticClass: "label" }, [_vm._v("Sbjct")]),
                        _vm._l(item.align2, function (val, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "valueDiv" },
                            [_vm._v(" " + _vm._s(val) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }