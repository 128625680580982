/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Country} from './Country';

/**
 * The ProjectItemDto model module.
 * @module model/ProjectItemDto
 * @version v1
 */
export class ProjectItemDto {
  /**
   * Constructs a new <code>ProjectItemDto</code>.
   * @alias module:model/ProjectItemDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ProjectItemDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ProjectItemDto} obj Optional instance to populate.
   * @return {module:model/ProjectItemDto} The populated <code>ProjectItemDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ProjectItemDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('leader'))
        obj.leader = ApiClient.convertToType(data['leader'], 'String');
      if (data.hasOwnProperty('leaderInstitution'))
        obj.leaderInstitution = ApiClient.convertToType(data['leaderInstitution'], 'String');
      if (data.hasOwnProperty('country'))
        obj.country = Country.constructFromObject(data['country']);
      if (data.hasOwnProperty('budgets'))
        obj.budgets = ApiClient.convertToType(data['budgets'], 'Number');
      if (data.hasOwnProperty('beginDate'))
        obj.beginDate = ApiClient.convertToType(data['beginDate'], 'Date');
      if (data.hasOwnProperty('researcherAreas'))
        obj.researcherAreas = ApiClient.convertToType(data['researcherAreas'], 'String');
      if (data.hasOwnProperty('abstract'))
        obj._abstract = ApiClient.convertToType(data['abstract'], 'String');
      if (data.hasOwnProperty('keywords'))
        obj.keywords = ApiClient.convertToType(data['keywords'], 'String');
      if (data.hasOwnProperty('budgetsCNY'))
        obj.budgetsCNY = ApiClient.convertToType(data['budgetsCNY'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
ProjectItemDto.prototype.id = undefined;

/**
 * @member {String} title
 */
ProjectItemDto.prototype.title = undefined;

/**
 * @member {String} leader
 */
ProjectItemDto.prototype.leader = undefined;

/**
 * @member {String} leaderInstitution
 */
ProjectItemDto.prototype.leaderInstitution = undefined;

/**
 * @member {module:model/Country} country
 */
ProjectItemDto.prototype.country = undefined;

/**
 * @member {Number} budgets
 */
ProjectItemDto.prototype.budgets = undefined;

/**
 * @member {Date} beginDate
 */
ProjectItemDto.prototype.beginDate = undefined;

/**
 * @member {String} researcherAreas
 */
ProjectItemDto.prototype.researcherAreas = undefined;

/**
 * @member {String} _abstract
 */
ProjectItemDto.prototype._abstract = undefined;

/**
 * @member {String} keywords
 */
ProjectItemDto.prototype.keywords = undefined;

/**
 * @member {Number} budgetsCNY
 */
ProjectItemDto.prototype.budgetsCNY = undefined;

