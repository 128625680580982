var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "LoadingBody" }, [
    _c("img", { attrs: { src: require("../assets/loading.png") } }),
    _c("p", [_vm._v(_vm._s(_vm.promptText || "加载中，请耐心等待"))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }