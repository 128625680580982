<template>
	<div
		id="myChart2"
		style="height: 350px; width: 92%; margin: 15px auto"
		ref="myChart2"
	></div>
</template>
<script>
import * as echarts from "echarts"
export default {
	props: ["findCountStatisticas"],
	data() {
		return {
			option: {
				tooltip: {
					trigger: "axis",
					axisPointer: {
						type: "cross",
						crossStyle: {
							color: "#999"
						}
					}
				},
				legend: {
					orient: "vertical",
					x: "right",
					y: "top",
					padding: [10, 0, 0, 0],
					data: ["适体数量", "新发现数量", "文献数量", "专利数量"]
				},
				grid: {
					top: "20%",
					left: "0",
					right: "15%",
					bottom: "0",
					containLabel: true
				},
				xAxis: [
					{
						type: "category",
						data: [],
						axisPointer: {
							type: "shadow"
						}
					}
				],
				yAxis: [
					{
						type: "value",
						axisLabel: {
							formatter: "{value}"
						}
					}
				],
				series: [
					{
						name: "适体数量",
						type: "bar",
						stack: "堆叠",
						color: "#2C3FA0",
						data: []
					},
					{
						name: "新发现数量",
						type: "bar",
						stack: "堆叠",
						color: "#009FE1",
						data: []
					},
					{
						name: "文献数量",
						type: "line",
						yAxisIndex: 0,
						color: "#F4B200",
						data: []
					},
					{
						name: "专利数量",
						type: "line",
						color: "#6D4BB2",
						yAxisIndex: 0,
						data: []
					}
				]
			}
		}
	},
	watch: {
		findCountStatisticas: function (newList, oldList) {
			const chart =
				echarts.getInstanceByDom(this.$refs.myChart2) ||
				echarts.init(this.$refs.myChart2, "shine")
			const list = []
			newList.findNewCount.forEach((item) => {
				list.push(item.name)
			})

			const articleList = [
				{ name: "2011", value: 663 },
				{ name: "2012", value: 704 },
				{ name: "2013", value: 850 },
				{ name: "2014", value: 1077 },
				{ name: "2015", value: 1165 },
				{ name: "2016", value: 1233 },
				{ name: "2017", value: 1328 },
				{ name: "2018", value: 1456 },
				{ name: "2019", value: 1456 },
				{ name: "2020", value: 1121 }
			]
			this.option.xAxis[0].data = list
			this.option.series[0].data = newList.aptamerCount
			this.option.series[1].data = newList.findNewCount
			// this.option.series[2].data = newList.articleCount
			this.option.series[2].data = articleList
			this.option.series[3].data = newList.patentCount
			chart.setOption(this.option)
		}
	},
	methods: {
		loadChart(data) {}
	}
}
</script>
