var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "con" }, [
    _c("div", { staticClass: "left-panel" }, [
      _c("div", { staticClass: "title-con" }, [
        _c("h2", { staticClass: "title" }, [_vm._v(_vm._s(_vm.detail.title))]),
        !_vm.favorite && _vm.isLogin
          ? _c(
              "span",
              { staticClass: "sm-btn", on: { click: _vm.addFavorite } },
              [_vm._v(" 收 藏 ")]
            )
          : _vm._e(),
        _vm.favorite && _vm.isLogin
          ? _c(
              "span",
              { staticClass: "sm-btn", on: { click: _vm.removeFavorite } },
              [_vm._v(" 已收藏 ")]
            )
          : _vm._e(),
      ]),
      _c("table", { staticClass: "fields-con" }, [
        _c("tr", { staticClass: "gray-row" }, [
          _c("td", { staticClass: "field-name" }, [_vm._v("项目编号：")]),
          _c("td", { staticClass: "field-value" }, [
            _vm._v(_vm._s(_vm.detail.projectNumber || "-")),
          ]),
          _c("td", { staticClass: "field-name" }, [_vm._v("资助机构：")]),
          _c("td", { staticClass: "field-value" }, [
            _vm._v(_vm._s(_vm.detail.sponsorInstitution || "-")),
          ]),
        ]),
        _c("tr", { staticClass: "normal-row" }, [
          _c("td", { staticClass: "field-name" }, [_vm._v("项目负责人：")]),
          _c("td", { staticClass: "field-value" }, [
            _vm._v(_vm._s(_vm.detail.leader || "-")),
          ]),
          _c("td", { staticClass: "field-name" }, [_vm._v("经费来源：")]),
          _c("td", { staticClass: "field-value" }, [
            _vm._v(_vm._s(_vm.detail.source || "-")),
          ]),
        ]),
        _c("tr", { staticClass: "gray-row" }, [
          _c("td", { staticClass: "field-name" }, [_vm._v("项目参与人：")]),
          _c("td", { staticClass: "field-value" }, [
            _vm._v(_vm._s(_vm.detail.participants || "-")),
          ]),
          _c("td", { staticClass: "field-name" }, [_vm._v("项目类型：")]),
          _c("td", { staticClass: "field-value" }, [
            _vm._v(_vm._s(_vm.detail.projectType || "-")),
          ]),
        ]),
        _c("tr", { staticClass: "normal-row" }, [
          _c("td", { staticClass: "field-name" }, [_vm._v("承担机构：")]),
          _c("td", { staticClass: "field-value" }, [
            _vm._v(_vm._s(_vm.detail.leaderInstitution || "-")),
          ]),
          _c("td", { staticClass: "field-name" }, [_vm._v("项目等级：")]),
          _c("td", { staticClass: "field-value" }, [
            _vm._v(_vm._s(_vm.detail.projectLevel || "-")),
          ]),
        ]),
        _c("tr", { staticClass: "gray-row" }, [
          _c("td", { staticClass: "field-name" }, [_vm._v("开始日期：")]),
          _c("td", { staticClass: "field-value" }, [
            _vm._v(" " + _vm._s(_vm.detail.beginDate || "-") + " "),
          ]),
          _c("td", { staticClass: "field-name" }, [_vm._v("持续时间：")]),
          _c("td", { staticClass: "field-value" }, [
            _vm._v(_vm._s(_vm.detail.durationTime)),
          ]),
        ]),
        _c("tr", { staticClass: "normal-row" }, [
          _c("td", { staticClass: "field-name" }, [_vm._v("结束日期：")]),
          _c("td", { staticClass: "field-value" }, [
            _vm._v(" " + _vm._s(_vm.detail.endDate || "-") + " "),
          ]),
          _c("td", { staticClass: "field-name" }, [_vm._v("项目状态：")]),
          _c("td", { staticClass: "field-value" }, [
            _vm._v(_vm._s(_vm.detail.projectStatus || "-")),
          ]),
        ]),
        _c("tr", { staticClass: "gray-row" }, [
          _c("td", { staticClass: "field-name" }, [_vm._v("经费金额（元）：")]),
          _c("td", { staticClass: "field-value" }, [
            _vm._v(
              _vm._s(
                _vm.detail.budgetsCNY && _vm.detail.budgetsCNY != "0"
                  ? parseFloat(_vm.detail.budgetsCNY).toFixed(2)
                  : "-"
              )
            ),
          ]),
          _c("td", { staticClass: "field-name" }, [_vm._v("国家：")]),
          _c("td", { staticClass: "field-value" }, [
            _vm._v(_vm._s(_vm.countryName || "-")),
          ]),
        ]),
        _c("tr", { staticClass: "normal-row" }, [
          _c("td", { staticClass: "field-name" }, [_vm._v("学科领域：")]),
          _c("td", { staticClass: "field-value" }, [
            _vm._v(_vm._s(_vm.detail.researcherAreas || "-")),
          ]),
          _c("td", { staticClass: "field-name" }, [_vm._v("语言：")]),
          _c("td", { staticClass: "field-value" }, [
            _vm._v(_vm._s(_vm.detail.language || "-")),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "right-panel" },
      [
        _c("div", { staticClass: "title-con" }, [
          _c("h2", { staticClass: "leader-name" }, [
            _vm._v(_vm._s(_vm.detail.leader || "暂无负责人")),
          ]),
        ]),
        _vm.isShowRelated
          ? _c(
              "div",
              { staticClass: "relate-projects-con" },
              _vm._l(_vm.detail.relateProjectItems, function (p) {
                return _c(
                  "a",
                  {
                    key: p.projectId,
                    staticClass: "relate-item",
                    attrs: { href: "/project/" + p.projectId },
                  },
                  [_vm._v(_vm._s(p.title))]
                )
              }),
              0
            )
          : _c("div", { staticClass: "noRelatedTip" }, [
              _vm._v(" 暂无相关项目 "),
            ]),
        _c(
          "el-row",
          { staticStyle: { "text-align": "right", "padding-right": "20px" } },
          [
            _vm.isShowMore
              ? _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/project?searchtype=2&leader=" +
                        _vm.detail.leader +
                        "&leaderInstitution=" +
                        _vm.detail.leaderInstitution,
                    },
                  },
                  [_vm._v("更多>")]
                )
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }