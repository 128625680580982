<template>
	<div class="item-list-panel">
		<div class="top-bar-container">
			<div class="top-bar">
				<div class="result">
					<div class="label count-desc">
						<div v-if="isSelect" class="chk-select">
							<el-checkbox
								v-model="isCheckAll"
								@change="checkAllChange"
								class="checkbox"
								>全选</el-checkbox
							>
						</div>
						<div class="choosepanel">
							<a href="#" @click="isSelect = !isSelect" class="select-switch">{{
								isSelect ? "取消选择" : "选择"
							}}</a>
						</div>
						<div class="label">当前结果</div>
						<div class="value">
							{{ resultAmount }}
						</div>
						<div class="unit">条</div>
					</div>
					<div class="textLabel">
						<a href="#" class="btn-export" @click="openDialog">
							<img src="../../../assets/导出.png" />
							<i class="lbl-export" />导出结果
						</a>
						<div class="orders">
							<div class="orders-container">
								<el-select
									v-model="orderBy"
									class="order-type"
									placeholder="排序方式"
									@change="orderChange"
								>
									<el-option
										v-for="item in orderOptions"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									>
									</el-option>
								</el-select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="item-list">
			<el-checkbox-group v-model="selectData">
				<div class="item-container" v-for="(item, i) in aptamers" :key="i">
					<div class="item">
						<div v-if="isSelect" class="checkbox">
							<el-checkbox
								@change="selectChange"
								:label="item.aptamerID"
								name="record"
								>&nbsp;</el-checkbox
							>
						</div>
						<div class="figure">
							<router-link :to="'/aptamerdb/' + item.aptamerID">
								<img class="defaultImg"
									v-if="!item.secondaryStructureFigure"
									src="@/assets/sequence-default2.png"
								/>
								<img
									v-if="item.secondaryStructureFigure"
									:src="`${imgHost}/${item.secondaryStructureFigure}`"
								/>
							</router-link>
						</div>
						<div class="location">
							<div class="entity-line">
								<router-link
									:to="'/aptamerdb/' + item.aptamerID"
									class="entity entity-title"
									><p v-html="item.mainName" style="width:550px !important"></p
								></router-link>
								<div class="entity">
									<div class="label">发表时间：</div>
									<div class="value">
										{{ item.publishedDate | dateFormat("YYYY-MM-DD") }}
									</div>
								</div>
							</div>
							<div class="entity">
								<div class="label">其他名称：</div>
								<div class="value" v-html="item.otherName"></div>
							</div>
							<div class="entity">
								<div class="label">适体类型：</div>
								<div class="value">
									{{ item.aptamerType }}
								</div>
							</div>
							<div class="entity">
								<div class="label">靶标名称：</div>
								<div class="value" v-html="item.targetName"></div>
							</div>
							<div class="entity">
								<div class="label">长 &nbsp; &nbsp;&nbsp; 度：</div>
								<div class="value">
									{{ item.sequenceLength }}
								</div>
							</div>
							<div class="item-comment">
								<div class="comment">
									<div class="label">被引文章：</div>
									<div class="value">
										{{ item.citedCount || 0 }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pagination">
					<el-pagination
						id="pagination"
						:current-page.sync="pager.index"
						:page-sizes="[10, 50, 100]"
						:page-size.sync="pager.size"
						layout="prev, pager, next, jumper,sizes"
						@current-change="query"
						@size-change="sizeChange"
						:total="resultAmount"
					>
					</el-pagination>
				</div>
			</el-checkbox-group>
		</div>
		<!--导出弹窗-->
		<div id="exportDia">
			<el-dialog
				title="导出结果"
				:visible="openDialogShow"
				@close="closeDialog"
				width="650px"
			>
				<div class="radioDiv">
					选择导出方法：
					<el-radio v-model="exportFileType" :label="0">RIS格式</el-radio>
					<!-- <el-radio v-model="exportFileType" :label="1">TXT</el-radio> -->
					<el-radio v-model="exportFileType" :label="2">CSV</el-radio>
				</div>
				<div class="radioDiv">选择导出记录：</div>
				<div class="radioDiv">
					<el-radio
						v-model="exportType"
						:disabled="selectData.length == 0"
						:label="1"
						>当前选择的</el-radio
					>
				</div>
				<div class="radioDiv">
					<el-radio v-model="exportType" :label="2">当前页面所有记录</el-radio>
				</div>
				<div class="radioDiv">
					<el-radio v-model="exportType" :label="3">
						选择记录
						<el-input-number
							:disabled="exportType != 3"
							style="width: 200px"
							v-model="startNo"
							step-strictly
							:step="1"
							controls-position="right"
							:min="1"
						></el-input-number>
						至
						<el-input-number
							:disabled="exportType != 3"
							style="width: 200px"
							v-model="endNo"
							step-strictly
							:step="1"
							controls-position="right"
							:min="startNo"
							:max="startNo + 499"
						></el-input-number>
						<br /><span
							style="margin-left: 26px; .c-font-size-12(); color: #c0c0c0"
							>单次导出上限500条</span
						>
					</el-radio>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="exportData">确定</el-button>
					<el-button @click="closeDialog">取消</el-button>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
export default {
	props: ["keyword", "aptamers", "resultAmount"],
	data() {
		return {
			openDialogShow: false,
			startNo: 1,
			endNo: 1,
			orderOptions: [
				{
					label: "发表时间",
					value: 0
				},
				{
					label: "被引次数",
					value: 1
				},
				{
					label: "亲和力",
					value: 3
				},
				{
					label: "序列长度",
					value: 4
				}
			],
			orderBy: "",
			pager: {
				index: 1,
				size: 10
			},
			exportType: null, // 1-导出当前选择数据，2-导出当前页面数据，3-导出指定条数数据(单次不超过500)
			exportFileType: 0, // 导出的文件类型 0-ris，1-txt，2-csv
			isSelect: false, // 是否选择记录
			isCheckAll: false, // 是否全选
			selectData: [] // 选择的记录
		}
	},
	computed: {
		imgHost() {
			return process.env.VUE_APP_IMG_API
		}
	},
	watch: {
		aptamers: function (newValue, oldValue) {
			newValue.forEach((item) => {
				// eslint-disable-next-line no-useless-escape
				if (item.targetName) {
					// const pattern = /(?<type>\*{1,2})(?<item>[A-Za-z0-9\-\+]+)(\1)?/g
					const pattern = /(?<type1>\*{1,2})(?<item>[A-Za-z0-9\-\+]+)(\1)(?<type2>\*{1,2})?/g
					var matches = item.targetName.matchAll(pattern)
					for (const match of matches) {
						var type = match.groups.type1
						if (type === "**") {
							item.otherName = item.otherName.replace(
								match[0],
								"<sup>" + match.groups.item + "</sup>"
							)
							item.targetName = item.targetName.replace(
								match[0],
								"<sup>" + match.groups.item + "</sup>"
							)
							item.mainName = item.mainName.replace(
								match[0],
								"<sup>" + match.groups.item + "</sup>"
							)
						} else {
							item.targetName = item.targetName.replace(
								match[0],
								"<sub>" + match.groups.item + "</sub>"
							)
							item.otherName = item.otherName.replace(
								match[0],
								"<sub>" + match.groups.item + "</sub>"
							)
							item.mainName = item.mainName.replace(
								match[0],
								"<sub>" + match.groups.item + "</sub>"
							)
						}
					}
				}
			})
		}
	},
	methods: {
		exportData() {
			if (this.exportType) {
				this.$parent.exportArticle(
					this.exportType,
					this.exportFileType,
					this.selectData,
					this.startNo,
					this.endNo
				)
				this.closeDialog()
			} else {
				this.$message.error("请选择需要导出的数据记录")
			}
		},
		closeDialog() {
			this.startNo = 1
			this.endNo = 0
			this.exportType = ""
			this.exportFileType = 0
			this.openDialogShow = false
			// this.selectData = []
			// this.isSelect = false
			// this.isCheckAll = false
		},
		// 全选按钮改变事件
		checkAllChange() {
			// this.isCheckAll = !this.isCheckAll
			if (this.isCheckAll) {
				this.aptamers.forEach((item) => {
					this.selectData.push(item.aptamerID)
				})
			} else {
				this.selectData = []
			}
			// console.log(this.datalist)
		},
		// 多选记录改变事件
		selectChange() {
			// console.log(this.selectData)
			// console.log(this.datalist)
			this.isCheckAll = this.selectData.length === this.aptamers.length
		},
		query() {
			this.$parent.replaceUrl()
		},
		sizeChange(size) {
			this.pager.index = 1
			this.pager.size = size
			this.$parent.replaceUrl()
		},
		orderChange() {
			this.pager.index = 1
			this.$parent.replaceUrl()
		},
		openDialog() {
			if (this.selectData.length === 0) {
				this.exportType = 2
			} else {
				this.exportType = 1
			}
			this.openDialogShow = true
		}
	}
}
</script>

<style lang="less" scoped>
.radioDiv {
	margin: 20px;
}
</style>

<style lang="less"></style>
