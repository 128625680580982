/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {SearchTypeEnum} from '../model/SearchTypeEnum';
import {TeamListDtoResponse} from '../model/TeamListDtoResponse';

/**
* Team service.
* @module api/TeamApi
* @version v1
*/
export class TeamApi {

    /**
    * Constructs a new TeamApi. 
    * @alias module:api/TeamApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiTeamQueryGet operation.
     * @callback moduleapi/TeamApi~apiTeamQueryGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TeamListDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 团队信息列表查询接口
     * @param {module:model/SearchTypeEnum} searchType 
     * @param {Object} opts Optional parameters
     * @param {String} opts.keyWord 
     * @param {Number} opts.pageIndex 
     * @param {Number} opts.pageSize 
     * @param {String} opts.orderBy 
     * @param {String} opts.name 
     * @param {String} opts.institution 
     * @param {String} opts.country 
     * @param {String} opts.userId 
     * @param {Number} opts.index 
     * @param {Number} opts.size 
     * @param {module:api/TeamApi~apiTeamQueryGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiTeamQueryGet(searchType, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'searchType' is set
      if (searchType === undefined || searchType === null) {
        throw new Error("Missing the required parameter 'searchType' when calling apiTeamQueryGet");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'KeyWord': opts['keyWord'],'PageIndex': opts['pageIndex'],'PageSize': opts['pageSize'],'OrderBy': opts['orderBy'],'Name': opts['name'],'Institution': opts['institution'],'Country': opts['country'],'UserId': opts['userId'],'SearchType': searchType,'Index': opts['index'],'Size': opts['size']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = TeamListDtoResponse;

      return this.apiClient.callApi(
        '/api/Team/query', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}