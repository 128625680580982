<template>
	<div class="main">
		<el-row>
			<ul>
				<li :style="{marginLeft: (index%4==0?0:20)+'px'}" v-for="(video, index) of videoList" :key="index">
					<div class="video">
					<video :id="'video'+index" @play="play('video'+index)" width="100%" height="100%" controls>
						<source :src="video.videoFile" type="video/mp4">
					</video>
					</div>
					<div style="padding:10px">
						<div class="title">
							{{video.title}}
						</div>
						<div class="info">
							<span style="float:left;text-align: left;">主讲人：{{video.lecturer}}</span><span style="float:right;">{{video.createOn | dateFormat("YYYY.MM.DD")}}</span>
						</div>
					</div>
				</li>
			</ul>
		</el-row>
		<el-row class="pagination">
			<el-pagination
				id="pagination"
				:current-page="pageIndex"
				:page-size="pageSize"
				layout="prev, pager, next, jumper"
				:total="total"
				@current-change="queryChange"
				@prev-click="queryChange"
				@next-click="queryChange"
			>
			</el-pagination>
		</el-row>
	</div>
</template>
<script>
import { ApiClient, VideoManagerApi } from "../../api"
export default {
	data() {
		return {
			videoApi: new VideoManagerApi(ApiClient.instance),
			videoList: [],
			pageSize: 20,
			pageIndex: 1,
			total: 20
		}
	},
	mounted() {
		// var video = {
		// 	title: "课程培训视频 课程培训视频 课程培 训视频 课程培训视频",
		// 	speaker: "主讲人",
		// 	videoFile: "",
		// 	date: "2020.08.13",
		// 	id: ""
		// }
		// this.videoList.push(video)
		// this.videoList.push(video)
		// this.videoList.push(video)
		// this.videoList.push(video)
		// this.videoList.push(video)
		// this.videoList.push(video)
		// this.videoList.push(video)
		// this.videoList.push(video)
		// this.videoList.push(video)
		this.getData()
	},
	methods: {
		getData() {
			this.videoApi.apiVideoManagerVideosGet({ title: "", lecturer: "", index: this.pageIndex, size: this.pageSize }, (err, data, resp) => {
				if (err) {
					console.log(err)
				}
				if (data.success) {
					console.log(data)
					this.videoList = data.result.records.map(item => {
						var res = item
						res.videoFile = process.env.VUE_APP_VIDEO_API + "VideoFile/" + res.videoFile + ".mp4"
						return res
					})
					this.total = data.result.total
				}
			})
		},
		queryChange(val) {
			this.pageIndex = val
			this.getData()
		},
		play(id) {
			var videos = document.getElementsByTagName("video")
			// console.log(videos, videos.length, id)
			if (videos) {
				for (var i = 0; i < videos.length; i++) {
					if (videos[i].id !== id) {
						videos[i].pause()
					}
				}
				// videos = videos.filter(a => a.id !== id)
				// console.log(videos)
				// videos.forEach(element => {
				// 	element.pause()
				// })
			}
		}
	}
}
</script>
<style lang="less" scoped>
* {
	margin: 0;
	padding: 0;
}
ul {
	list-style: none;
}

.pagination {
	text-align: center;
}
.main {
	width: 1170px;
	height: auto;
	margin: 15px auto 0 auto;
	padding-bottom: 20px;
	ul {
		width: 100%;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		// justify-content: space-between;
		li {
			width: 277px;
			min-height: 280px;
			background: #ffffff;
			border: 1px solid #dbdbdb;
			margin-bottom: 20px;
			border-radius: 5px;
			.video {
				width: 277px;
				height: 173px;
				background: #cccccc;
				border: 1px solid #dbdbdb;
				opacity: 1;
				border-radius: 5px 5px 0px 0px;
			}
			.title {
				width: 251px;
				min-height: 44px;
				font-size: 16px;
				font-weight: 500;
				color: #3462e9;
				opacity: 1;
				margin-bottom: 20px;
			}
			.info {
				min-height: 20px;
				font-size: 14px;
				font-weight: 400;
				color: #949494;
				opacity: 1;
				text-align: right;
				padding-right: 15px;
			}
		}
	}
}
</style>
