/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The PatentItemDto model module.
 * @module model/PatentItemDto
 * @version v1
 */
export class PatentItemDto {
  /**
   * Constructs a new <code>PatentItemDto</code>.
   * @alias module:model/PatentItemDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PatentItemDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PatentItemDto} obj Optional instance to populate.
   * @return {module:model/PatentItemDto} The populated <code>PatentItemDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PatentItemDto();
      if (data.hasOwnProperty('patentID'))
        obj.patentID = ApiClient.convertToType(data['patentID'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('applicationNumber'))
        obj.applicationNumber = ApiClient.convertToType(data['applicationNumber'], 'String');
      if (data.hasOwnProperty('inventor'))
        obj.inventor = ApiClient.convertToType(data['inventor'], 'String');
      if (data.hasOwnProperty('statusDescription'))
        obj.statusDescription = ApiClient.convertToType(data['statusDescription'], 'String');
      if (data.hasOwnProperty('cover'))
        obj.cover = ApiClient.convertToType(data['cover'], 'String');
      if (data.hasOwnProperty('publicationDate'))
        obj.publicationDate = ApiClient.convertToType(data['publicationDate'], 'Date');
      if (data.hasOwnProperty('abstract'))
        obj._abstract = ApiClient.convertToType(data['abstract'], 'String');
      if (data.hasOwnProperty('licensor'))
        obj.licensor = ApiClient.convertToType(data['licensor'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} patentID
 */
PatentItemDto.prototype.patentID = undefined;

/**
 * @member {String} title
 */
PatentItemDto.prototype.title = undefined;

/**
 * @member {String} applicationNumber
 */
PatentItemDto.prototype.applicationNumber = undefined;

/**
 * @member {String} inventor
 */
PatentItemDto.prototype.inventor = undefined;

/**
 * @member {String} statusDescription
 */
PatentItemDto.prototype.statusDescription = undefined;

/**
 * @member {String} cover
 */
PatentItemDto.prototype.cover = undefined;

/**
 * @member {Date} publicationDate
 */
PatentItemDto.prototype.publicationDate = undefined;

/**
 * @member {String} _abstract
 */
PatentItemDto.prototype._abstract = undefined;

/**
 * @member {String} licensor
 */
PatentItemDto.prototype.licensor = undefined;

