<template>
	<div class="main-panel">
		<div class="top-bar">
			<div>推荐筛选系统</div>
		</div>
		<div class="recommended">
			<el-table
				:data="recommendInfos"
				border
				style="width: 100%"
				class="dataTable"
				:cell-style="{borderColor:'#dbdbdb'}"
				:header-cell-style="{borderColor:'#dbdbdb'}"
			>
				<el-table-column align="center" prop="targetName" label="靶标名称">
					<template slot="header">
						<img src="../../assets/targetname.png" style="margin-right:10px;vertical-align: middle;" />靶标名称
					</template>
					<template slot-scope="{ row }">
						<span v-html="row.targetName" v-if="row.targetName"></span>
						<span v-else>-</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="aptamerType" label="适体类型">
					<template slot="header">
						<img src="../../assets/aptamertype.png" style="margin-right:10px;vertical-align: middle;" />适体类型
					</template>
					<template slot-scope="{ row }">
						{{ row.aptamerType || "-" }}
					</template></el-table-column
				>
				<el-table-column
					align="center"
					prop="screenMethod"
					label="筛选方法"
				>
					<template slot="header">
						<img src="../../assets/screenmethod.png" style="margin-right:10px;vertical-align: middle;" />筛选方法
					</template>
					<template slot-scope="{ row }">
						{{ row.screenMethod || "-" }}
					</template></el-table-column
				>
				<el-table-column
					align="center"
					prop="score"
					label="推荐打分"
				>
					<template slot="header">
						<img src="../../assets/recommendscore.png" style="margin-right:10px;vertical-align: middle;" />推荐打分
					</template>
					<template slot-scope="{ row }">
						{{ row.score || "-" }}
						<!-- <p v-html="row.score || '-'"></p> -->
					</template
				></el-table-column>
			</el-table>
		</div>
		<div class="top-bar">
			<div>筛选系统统计</div>
		</div>
		<div class="section-whatis">
			<ul>
				<li>
					<div class="chartTitle">适体类别</div>
					<div v-show="aptamerTypeStatisticas.length>0" class="pieChart">
						<ScreenMethodChart
							:dataList="aptamerTypeStatisticas"
							allowedClick="true"
							:dataType="1"
							@click="chartFilter"
							totalDesc=""
						></ScreenMethodChart>
					</div>
					<div v-show="aptamerTypeStatisticas.length<=0" class="noData">
						<img src="../../assets/nodata2.png" />
						<p>暂无数据</p>
					</div>
				</li>
				<li>
					<div class="chartTitle">筛选方法</div>
					<div v-show="ScreenMethodStatisticas.length>0" class="pieChart">
						<ScreenMethodChart
							:dataList="ScreenMethodStatisticas"
							allowedClick="true"
							:dataType="2"
							@click="chartFilter"
							totalDesc=""
						></ScreenMethodChart>
					</div>
					<div v-show="ScreenMethodStatisticas.length<=0" class="noData">
						<img src="../../assets/nodata2.png" />
						<p>暂无数据</p>
					</div>
				</li>
				<li>
					<div class="chartTitle">样本类型</div>
					<div v-show="sampleTypeStatisticas.length>0" class="pieChart">
						<ScreenMethodChart
							:dataList="sampleTypeStatisticas"
							allowedClick="true"
							:dataType="3"
							@click="chartFilter"
							totalDesc=""
						></ScreenMethodChart>
					</div>
					<div v-show="sampleTypeStatisticas.length<=0" class="noData">
						<img src="../../assets/nodata2.png" />
						<p>暂无数据</p>
					</div>
				</li>
				<li>
					<div class="chartTitle">应用</div>
					<div v-show="UsageTypeStatisticas.length>0" class="pieChart">
						<ScreenMethodChart
							:dataList="UsageTypeStatisticas"
							allowedClick="true"
							:dataType="4"
							@click="chartFilter"
							totalDesc=""
						></ScreenMethodChart>
					</div>
					<div v-show="UsageTypeStatisticas.length<=0" class="noData">
						<img src="../../assets/nodata2.png" />
						<p>暂无数据</p>
					</div>
				</li>
			</ul>
		</div>
		<div class="top-bar" style="margin-top: 20px">
			<div>文献引用</div>
		</div>
		<div class="section-method">
			<ul>
				<li v-for="(data, index) of articleList" :key="index">
					<div class="item">
						<div class="entity-title" @click="toDetail(data.articleID)">{{data.articleTitle}}</div>
						<div class="entity">{{data.author}}</div>
						<div class="entity">
							{{data.journal}}. {{data.publishedDate | dateFormat("YYYY-MM-DD")}};{{data.issn}}. doi: {{data.doi}}
						</div>
						<div class="entity1">{{data._abstract}}</div>
					</div>
				</li>
			</ul>
		</div>
		<el-row class="pagination">
			<el-pagination
				id="pagination"
				:current-page="pageIndex"
				:page-size="pageSize"
				layout="prev, pager, next, jumper"
				:total="total"
				@current-change="queryChange"
			>
			</el-pagination>
		</el-row>
	</div>
</template>
<script>
// import AptamerType from "@/components/charts/AptamerType.vue"
import ScreenMethodChart from "../../components/charts/screenMethodChart.vue"
import { ApiClient, AptamerApi } from "@/api"
export default {
	data() {
		return {
			AptamerApi: new AptamerApi(ApiClient.instance),
			recommendInfo: {
				targetName: "",
				screendMethod: "",
				sampleType: "",
				usageType: "",
				score: 0
			},
			recommendInfos: [],
			aptamerTypeStatisticas: [],
			UsageTypeStatisticas: [],
			ScreenMethodStatisticas: [],
			sampleTypeStatisticas: [],
			articleList: [],
			target: "",
			pageIndex: 1,
			pageSize: 10,
			total: 0,
			aptamerType: "",
			screenMethod: "",
			sampleType: "",
			usageType: ""
		}
	},
	mounted() {
		this.target = this.$route.query.keyword || ""
		this.getData()
	},
	components: {
		// AptamerType
		ScreenMethodChart
	},
	methods: {
		toDetail(id) {
			this.$router.push("/paper/" + id)
		},
		queryChange(val) {
			this.pageIndex = val
			this.getRelatedArticle()
		},
		getData() {
			if (this.target.length > 0) {
				this.getRelatedArticle()
				this.AptamerApi.apiAptamerIntelligentfilterGet({ target: this.target }, (error, data, response) => {
					if (error) {
						console.log(error)
					}
					if (data.success) {
						console.log(data)
						const pattern = /(?<type1>\*{1,2})(?<item>[A-Za-z0-9\-\+]+)(\1)(?<type2>\*{1,2})?/g
						this.recommendInfos = data.result.recommendInfo.map(item => {
							var res = item
							var targetmatches = item.targetName.matchAll(pattern)
							for (const match of targetmatches) {
								// console.log(match, match.groups)
								var type = match.groups.type1
								if (type === "**") {
									res.targetName = res.targetName.replace(
										match[0],
										"<sup>" + match.groups.item + "</sup>"
									)
								} else {
									res.targetName = res.targetName.replace(
										match[0],
										"<sub>" + match.groups.item + "</sub>"
									)
								}
							}
							return res
						})
						this.aptamerTypeStatisticas = data.result.aptamerTypeList
						this.sampleTypeStatisticas = data.result.sampleTypeList
						this.ScreenMethodStatisticas = data.result.screenMethodList
						this.UsageTypeStatisticas = data.result.usageList
						// this.total = data.result.relatedArticle.total
						// this.articleList = data.result.relatedArticle.articleList
					}
				})
			}
		},
		getRelatedArticle() {
			this.AptamerApi.apiAptamerIntelligentfilterarticlesGet(
				{
					target: this.target,
					aptamerType: this.aptamerType,
					screenMethod: this.screenMethod,
					sampleType: this.sampleType,
					usageType: this.usageType,
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				},
				(err, data, resp) => {
					if (data?.success && data?.result) {
						this.articleList = data.result.articleList
						this.total = data.result.total
					} else {
						console.log(err)
					}
				}
			)
		},
		chartFilter(val) {
			// console.log(val)
			if (val.dataType === 1) {
				this.aptamerType = val.res.length > 0 ? val.res[0] : ""
			}
			if (val.dataType === 2) {
				this.screenMethod = val.res.length > 0 ? val.res[0] : ""
			}
			if (val.dataType === 3) {
				this.sampleType = val.res.length > 0 ? val.res[0] : ""
			}
			if (val.dataType === 4) {
				this.usageType = val.res.length > 0 ? val.res[0] : ""
			}
			this.getRelatedArticle()
		}
	},
	watch: {
		// $route(to, from) {
		// 	this.target = to.query.keyword || ""
		// 	this.getData()
		// }
	}
}
</script>
<style lang="less" scoped>

.noData {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	p {
		text-align: center;
		margin-top: 30px;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		color: #BFBFBF;
	}
}

* {
	margin: 0;
	padding: 0;
}
ul {
	list-style: none;
}
.pagination {
	text-align: center;
}
.main-body {
	height: auto;
	padding-bottom: 20px;
}
:root {
	--scrollbar: 0px;
}

.top-bar {
	width: 100%;
	background: #ffffff;
	border: 1px solid #dbdbdb;
	opacity: 1;
	border-radius: 0px;
	margin-bottom: 23px;
	div {
		height: 45px;
		font-size: 16px;
		font-weight: 400;
		color: #333333;
		opacity: 1;
		padding: 12px 0 13px 12px;
	}
}
.recommended {
	width: 100%;
	// height: 100px;
	background: #ffffff;
	// border: 1px solid #dbdbdb;
	opacity: 1;
	border-radius: 0px;
	margin-bottom: 23px;

	.dataTable {
		border: 1px solid #dbdbdb;

		/deep/ .el-table__header-wrapper {
			tr {
				min-height: 49px;
			}
			th {
				background-color: #3463E9;
				color: #FFFFFF;
				font-weight: 400;
				font-size: 16px;
			}
		}

		/deep/ .el-table__body-wrapper {
			tr {
				min-height: 49px;
			}
			.el-table__row td {
				color: #333333;
				font-weight: 400;
				font-size: 16px;
				padding-top: 12px;
				padding-bottom: 12px;
			}
		}
	}
}
.section-whatis {
	width: 100%;
	height: 630px;
	ul {
		width: 100%;
		height: 630px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		li {
			width: 570px;
			height: 300px;
			background: #ffffff;
			border: 1px solid #dbdbdb;
			opacity: 1;
			border-radius: 0px;
			margin-bottom: 30px;
			box-sizing: border-box;
			position: relative;

			.pieChart {
				width: 400px;
				height: 272px;
				margin: 0 auto;
			}

			.chartTitle {
				position: absolute;
				top: 20px;
				left: 15px;
				border-left: 3px solid #3462E9;
				padding-left: 10px;
				font-size: 18px;
				font-weight: 400;
				color: #333333;
			}
		}
	}
}

.section-whatis .title {
	font-size: 26px;
	.c-font-family-PF();
	font-weight: 500;
	color: #333333;
	margin: 16px auto;
}

.section-whatis .body {
	width: 890px;
	.c-font-size-18();
	.c-font-family-PF();
	font-weight: 400;
	color: #999999;
	margin: 0 auto;
}

.section-theory {
	width: 1000px;
	display: flex;
	flex-direction: row;
	margin: 20px auto 80px auto;
}

.section-theory .image {
	width: 446px;
	height: 378px;
}

.section-theory .image img {
	width: 446px;
	height: 378px;
}

.section-theory .content {
	width: 480px;
	margin: auto 0 auto auto;
}

.section-theory .content .title {
	font-size: 26px;
	.c-font-family-PF();
	font-weight: 500;
	color: #333333;
	margin-bottom: 20px;
}

.section-theory .content .body {
	.c-font-size-18();
	.c-font-family-PF();
	font-weight: 400;
	color: #999999;
}

.section-method {
	width: 100%;
	ul {
		width: 100%;
		height: 100%;
		li {
			width: 100%;
			height: 188px;
			background: #ffffff;
			margin-bottom: 20px;
			.item {
				width: 100%;
				height: 90%;
				padding: 17px 60px 17px 23px;
				.entity-title {
					text-decoration: underline;
					cursor: pointer;
					height: 44px;
					font-size: 16px;
					font-weight: 500;
					color: #3463ea;
					opacity: 1;
					display: -webkit-box;
					word-wrap: break-word;
					-webkit-line-clamp: 2; // 设置显示行数
					text-overflow: ellipsis;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}
				.entity {
					font-size: 14px;
					font-weight: 400;
					color: #333333;
					opacity: 1;
					margin: 12px 0;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.entity1 {
					height: 40px;
					font-size: 14px;
					font-weight: 400;
					color: #333333;
					opacity: 1;
					display: -webkit-box;
					word-wrap: break-word;
					-webkit-line-clamp: 2; // 设置显示行数
					text-overflow: ellipsis;
					-webkit-box-orient: vertical;
					overflow: hidden;
				}
			}
		}
	}
}

.section-method .image {
	width: 446px;
	height: 378px;
}

.section-method .image img {
	width: 446px;
	height: 378px;
}

.section-method .content {
	width: 480px;
	margin: auto auto auto 0;
}

.section-method .content .title {
	font-size: 26px;
	.c-font-family-PF();
	font-weight: 500;
	color: #333333;
	margin-bottom: 20px;
}

.section-method .content .body {
	.c-font-size-18();
	.c-font-family-PF();
	font-weight: 400;
	color: #999999;
}

.middle-bar {
	width: 100vw;
	width: calc(100vw - var(--scrollbar));
	height: 100%;
}

.middle-bar img {
	width: 100%;
}

.section-discovery {
	width: 100%;
	text-align: center;
	display: inline-block;
	margin: 60px auto;
}

.section-discovery .title {
	font-size: 26px;
	.c-font-family-PF();
	font-weight: 500;
	color: #333333;
	margin: 16px auto;
}

.section-discovery .body {
	width: 890px;
	.c-font-size-18();
	.c-font-family-PF();
	font-weight: 400;
	color: #999999;
	margin: 0 auto;
}

.section-teaching {
	width: 100%;
	text-align: center;
	display: inline-block;
	margin: 20px auto 80px auto;
}

.section-teaching .title {
	font-size: 26px;
	.c-font-family-PF();
	font-weight: 500;
	color: #333333;
	margin: 0 auto 30px auto;
}

.section-teaching .video {
	width: 890px;
	margin: 0 auto;
}
</style>
