<template>
	<div class="main-panel main-list">
		<div class="left-panel">
			<div class="statistic-panel">
				<el-select v-model="cur_sts_type" class="sts_type">
					<el-option label="经费走势" value="0"></el-option>
					<el-option label="项目数量" value="1"></el-option>
					<el-option label="领域经费" value="2"></el-option>
				</el-select>
				<bar-chart
					yName="百万元"
					:dataList="yearBudgetsSts"
					v-show="cur_sts_type === '0'"
				></bar-chart>
				<bar-chart
					:dataList="yearCountSts"
					v-show="cur_sts_type === '1'"
				></bar-chart>
				<doughnut-chart
					:dataList="areaBudgetsSts"
					totalDesc="经费总额(百万)"
					v-show="cur_sts_type === '2'"
					@click="areaSelect"
					isDataFixed="true"
					countFloat="true"
				></doughnut-chart>
			</div>
			<div class="statistic-panel">
				<doughnut-chart
					:dataList="countryBudgetsSts"
					totalDesc="国家"
					@click="countrySelect"
					isDataFixed="true"
				></doughnut-chart>
			</div>
			<div class="filter-panel">
				<projectfilter ref="filter"></projectfilter>
			</div>
		</div>
		<div class="list-panel">
			<projectlist :list="dataList" :total="total" ref="list"></projectlist>
		</div>
	</div>
</template>
<script>
import projectlist from "./project_list.vue"
import projectfilter from "./project_filter.vue"
import barChart from "../../components/charts/BarChart.vue"
import doughnutChart from "../../components/charts/DoughnutChart.vue"
import { ApiClient, ProjectApi } from "../../api"
import "../../assets/css/list.less"
export default {
	data() {
		return {
			api: new ProjectApi(ApiClient.instance),
			total: "",
			orderby: "",
			dataList: [],
			yearCountSts: [],
			yearBudgetsSts: [],
			areaBudgetsSts: [],
			countryBudgetsSts: [],
			cur_sts_type: "0",
			searchType: 0,
			title: "",
			keywords: "",
			leader: "",
			leaderInstitution: "",
			sponsorInstitution: "",
			area: "",
			country: ""
		}
	},
	computed: {},
	mounted() {
		this.getURLParams()
		this.queryList()
	},
	components: {
		projectlist,
		projectfilter,
		barChart,
		doughnutChart
	},
	methods: {
		queryList() {
			this.api.apiProjectQueryGet(
				this.searchType,
				{
					keyWord: this.$route.query.keyword,
					index: this.$refs.list.pager.index,
					size: this.$refs.list.pager.size,
					country: this.country,
					researcherAreas: this.area,
					minBudget: this.$refs.filter.minBudget,
					maxBudget: this.$refs.filter.maxBudget,
					title: this.title,
					keywords: this.keywords,
					leader: this.leader,
					leaderInstitution: this.leaderInstitution,
					sponsorInstitution: this.sponsorInstitution
				},
				(error, data, response) => {
					if (error) {
						console.log(error)
						return
					}
					if (data.success) {
						this.dataList = data.result.records
						this.total = this.$refs.list.pager.total = data.result.total
						this.yearCountSts = data.result.yearCount
						this.yearBudgetsSts = data.result.yearBudgets.map((p) => {
							return {
								name: p.name,
								value: (p.value / 1000000).toFixed(2)
							}
						})
						this.areaBudgetsSts = data.result.areaBudgets.map((p) => {
							return {
								name: p.name,
								value: (p.value / 1000000).toFixed(2)
							}
						})
						this.countryBudgetsSts = data.result.countryCount
					}
				}
			)
		},
		replaceUrl() {
			var opts = {
				searchtype: this.searchType,
				keyWord: this.$route.query.keyword,
				index: this.$refs.list.pager.index,
				size: this.$refs.list.pager.size,
				country: this.country,
				area: this.area,
				budgetRange: this.$refs.filter.budgetRange,
				title: this.title,
				keywords: this.keywords,
				leader: this.leader,
				leaderInstitution: this.leaderInstitution,
				sponsorInstitution: this.sponsorInstitution
			}
			this.$router.replace({ query: opts })
		},
		getURLParams() {
			this.searchType = parseInt(this.$route.query.searchtype) || 0
			this.$refs.list.pager.index = parseInt(this.$route.query.index) || 1
			this.$refs.list.pager.size = parseInt(this.$route.query.size) || 10
			this.$refs.filter.budgetRange = this.$route.query.budgetRange
			this.country = this.$route.query.country
			this.area = this.$route.query.area
			this.title = this.$route.query.title
			this.keywords = this.$route.query.keywords
			this.leader = this.$route.query.leader
			this.leaderInstitution = this.$route.query.leaderInstitution
			this.sponsorInstitution = this.$route.query.sponsorInstitution
		},
		areaSelect(arg) {
			// if (!arg.isOther) {
			// 	this.area = arg.select[0]
			// 	this.searchType = 2
			// 	this.replaceUrl()
			// }
			this.area = arg?.select.length > 0 ? arg.select[0] : null
			this.searchType = 2
			this.replaceUrl()
		},
		countrySelect(val) {
			// if (val.select.length > 0) {
			// 	this.country = val.select[0]
			// } else {
			// 	this.country = ""
			// }
			this.country = val?.select.length > 0 ? val.select[0] : null
			this.searchType = 2
			this.replaceUrl()
		}
	},
	watch: {
		$route(to, from) {
			// console.log(to.query)
			// this.searchType = parseInt(to.query.searchType) || 0
			// this.$refs.list.pager.index = parseInt(to.query.index) || 1
			// this.$refs.list.pager.size = parseInt(to.query.size) || 10
			// this.title = to.query.title
			// this.keywords = to.query.keywords
			// this.leader = to.query.leader
			// this.leaderInstitution = to.query.leaderInstitution
			// this.sponsorInstitution = to.query.sponsorInstitution
			this.getURLParams()
			this.queryList()
		}
	},
	stsTypeChange() {}
}
</script>

<style scoped lang="less">
.statistic-panel {
	height: 315px;
	width: 290px;
	background-color: #ffffff;
	position: relative;
	.sts_type {
		z-index: 90;
		position: absolute;
		right: 0;
		top: 0;
		width: 110px;
		/deep/ .el-input__inner {
			border: none;
			background: none;
		}
	}
}
</style>
