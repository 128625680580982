var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cont" }, [
    _c("div", { staticClass: "chart-header" }, [
      _c("div", { staticClass: "chart-nav" }, [
        _c("div", { staticClass: "numberAptamers-dot dot" }),
        _c("div", [
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.numberAptamers)),
          ]),
          _c("div", { staticClass: "numberText" }, [_vm._v("适体数量")]),
        ]),
      ]),
      _c("div", { staticClass: "chart-nav" }, [
        _c("div", { staticClass: "numberTargets-dot dot" }),
        _c("div", [
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.numberTargets)),
          ]),
          _c("div", { staticClass: "numberText" }, [_vm._v("靶标数量")]),
        ]),
      ]),
      _c("div", { staticClass: "chart-nav" }, [
        _c("div", { staticClass: "sampleType-dot dot" }),
        _c("div", [
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.sampleType)),
          ]),
          _c("div", { staticClass: "numberText" }, [_vm._v("样本类型")]),
        ]),
      ]),
    ]),
    _c("div", { ref: "chartGlobal", attrs: { id: "chart-global" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }