<template>
	<div>
		<div class="issue-release">
			<p class="title">发布问题</p>
			<div class="release-content">
				<div class="row">
					<div class="tip">标题</div>
					<el-input
						class="issue-title"
						v-model="title"
						maxlength="50"
						placeholder="请输入文章标题"
						:disabled="!isLogin"
					>
					</el-input>
				</div>
				<div class="row">
					<div class="tip">正文</div>
					<el-input
						type="textarea"
						:rows="2"
						placeholder="请输入文章正文"
						v-model="textarea"
						resize="none"
						:disabled="!isLogin"
					>
					</el-input>
				</div>
				<div class="row" style="position:relative">
					<div class="tip hide"></div>
					<div class="release-submit">
						<div class="release-user">
							<img :src="avator" />
							<p>{{ userinfo == null ? "" : userinfo.nickname }}</p>
						</div>
						<div class="release" @click="submit">发布</div>
					</div>
					<div v-if="!isLogin" class="toLogin">
						<button @click="toLigin">&gt;&gt; 登录后开启发布问题 &lt;&lt;</button>
					</div>
				</div>
			</div>
		</div>
		<div class="related-topics">
			<p class="title">相关话题</p>
			<div class="related-content">
				<ul>
					<li v-for="(item, i) in listData" :key="i" class="row">
						<div class="related-content-auto">
							<p
								class="related-content-title"
								@click="sendDetail(item.learnTopicID)"
							>
								{{ item.title }}
							</p>
							<div class="related-content-article">
								<p>{{ item.content | ellipsis(150) }}</p>
								<div
									class="related-content-read-more"
									@click="sendDetail(item.learnTopicID)"
								>
									<div class="text">阅读全文</div>
									<img class="icon" src="../../assets/arrow-down.png" />
									<!-- 阅读全文 -->
								</div>
							</div>
							<div class="comment-information">
								<img class="comment-icon" src="../../assets/comment.png" />
								<p>{{ item.commentCount }}条 评论</p>
							</div>
						</div>
						<div class="related-bottom-line"></div>
					</li>
				</ul>
				<div class="pagination">
					<el-pagination
						id="pagination"
						:current-page="pageindex"
						:page-size="pagesize"
						:page-sizes="[10, 25, 50]"
						layout="prev, pager, next, jumper, sizes"
						:total="listTotal"
						@current-change="queryChange"
						@size-change="sizeChange"
						@prev-click="queryChange"
						@next-click="queryChange"
					>
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Cookie from "js-cookie"
import { ApiClient, LearnTopicApi } from "../../api"
import Swal from "sweetalert2"

export default {
	props: ["isLogin"],
	data() {
		return {
			api: new LearnTopicApi(ApiClient.instance),
			title: "",
			textarea: "",
			listData: [],
			listTotal: 0,
			pageindex: 1,
			pagesize: 10,
			allowedSubmit: true
		}
	},
	mounted() {
		this.loadData()
	},
	computed: {
		userinfo() {
			return Cookie.getJSON("user_info")
		},
		avator() {
			if (localStorage.getItem("avator")) {
				return `data:image/png;base64,${JSON.parse(
					localStorage.getItem("avator")
				)}`
			} else {
				return null
			}
		}
	},
	methods: {
		toLigin() {
			document.getElementById("loginSpan").click()
		},
		sendDetail(val) {
			this.$router.push("/CommunicationPlatform/" + val)
		},
		loadData(index, size) {
			this.api.apiLearnTopicLearnTopicesGet(
				{
					size: size || this.pagesize,
					index: index || this.pageindex,
					learnTopicType: this.$parent.num
				},
				(e, data, resp) => {
					if (data.success) {
						this.listData = data.result.records
						this.listTotal = data.result.total
					}
				}
			)
		},
		// 添加收藏
		addFavorite() {
			this.usercenterApi.apiUserCenterFavoritePost(
				{
					collectionID: this.articleId,
					collectionType: 2
				},
				(e, data, resp) => {
					this.getFavoriteStatus()
				}
			)
		},
		// 取消收藏
		removeFavorite() {
			this.usercenterApi.apiUserCenterUnfavoriteDelete(
				this.articleId,
				2,
				null,
				() => {
					this.getFavoriteStatus()
				}
			)
		},
		// 查询文章收藏状态
		getFavoriteStatus() {
			this.usercenterApi.apiUserCenterCollectedGet(
				this.articleId,
				(e, data, resp) => {
					if (data.success) {
						this.favorite = data.result
					}
				}
			)
		},
		favoriteChange(val) {
			if (val === 1) {
				this.addFavorite()
			} else {
				this.removeFavorite()
			}
		},
		submit() {
			if (!this.title) {
				this.$message({ message: "请输入标题！", type: "error" })
				return
			}
			if (!this.textarea) {
				this.$message({ message: "请输入正文！", type: "error" })
				return
			}
			if (!this.allowedSubmit) {
				return
			}
			this.allowedSubmit = false
			this.api.apiLearnTopicSubmitPost(
				{
					body: {
						title: this.title,
						content: this.textarea,
						learnTopicType: this.$parent.num
					}
				},
				(e, data, resp) => {
					if (data?.success && data?.result) {
						Swal.fire("发布成功，请等待管理员审核！").then(() => { window.location.reload() })
					} else {
						this.allowedSubmit = true
						console.log(e, data, resp)
						var err = this.$options.filters.getResponseErrors(resp)
						console.log(err)
						Swal.fire({ icon: "error", html: err })
					}
				}
			)
		},
		// 分页条件改变
		queryChange(val) {
			this.pageindex = val
			this.loadData()
		},
		sizeChange(val) {
			this.pageindex = 1
			this.pagesize = val
			this.loadData()
		}
	}
}
</script>
<style lang="less" scoped>
* {
	margin: 0;
	padding: 0;
}
ul {
	list-style: none;
}
.issue-release {
	width: 1171px;
	height: 336px;
	background: #ffffff;
	border: 1px solid #dbdbdb;
	opacity: 1;
	border-radius: 0px;
	margin-top: 20px;
	.title {
		width: 100px;
		height: 25px;
		font-weight: 500;
		color: #333333;
		opacity: 1;
		margin: 20px 0 10px 24px;
	}
	.release-content {
		width: 1123px;
		height: 201px;
		background: #ffffff;
		opacity: 1;
		border-radius: 0px;
		margin: 0 auto;

		.row {
			display: flex;
			justify-items: center;
			border: 1px solid #dbdbdb;
			margin-top: 10px;
			padding: 5px;

			.tip {
				width: 42px;
				height: 22px;
				font-size: 16px;
				font-family: PingFang SC;
				line-height: 22px;
				color: #333333;
				margin-left: 15px;
			}

			.release-submit {
				width: 100%;
				height: 59px;
				background: #f2f2f3;
				opacity: 1;
				border-radius: 0px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.release-user {
					width: 400px;
					display: flex;
					align-items: center;
					p {
						height: 25px;
						font-size: 18px;
						font-weight: 500;
						color: #333333;
						opacity: 1;
					}
					img {
						width: 40px;
						height: 40px;
						background: #ffffff;
						border: 1px solid #707070;
						border-radius: 50%;
						opacity: 1;
						margin: 0 10px 0 12px;
					}
				}

				.release {
					width: 122px;
					height: 38px;
					background: #3462e9;
					opacity: 1;
					border-radius: 3px;
					font-size: 16px;
					font-weight: 500;
					color: #ffffff;
					opacity: 1;
					text-align: center;
					line-height: 38px;
					margin-right: 20px;
					cursor: pointer;
				}
			}

			/deep/.el-textarea {
				height: 135px;
				vertical-align: inherit;
				font-size: 14px;
				.el-textarea__inner {
					height: 135px;
					border-radius: inherit;
					border: 1px solid #ffffff;
					transition: inherit;
				}
				.el-textarea__inner:focus {
					border: none !important;
				}
			}

			/deep/ .issue-title .el-input__inner {
				padding: 0;
				border: none !important;
				border-radius: 0px !important;
			}

			.hide {
				display: none;
			}
		}

		:first-child {
			align-items: center;
		}

		:last-child {
			margin-top: 0px;
			padding: 0;
		}
	}
}
.related-topics {
	width: 1171px;
	// height: 900px;
	border: 1px solid #dbdbdb;
	opacity: 1;
	border-radius: 0px;
	margin-top: 20px;
	background: #ffffff;
	.title {
		width: 100px;
		height: 25px;
		font-weight: 500;
		color: #333333;
		opacity: 1;
		margin: 20px 0 10px 24px;
	}
	.related-content {
		width: 100%;
		height: 100%;
		margin: 0 auto;
		ul {
			width: 100%;
			height: 100%;
			li {
				width: 100%;
				height: auto;
				margin-bottom: 1px;
				padding-bottom: 20px;
				.related-content-auto {
					width: 96%;
					margin: 0 auto;
					.related-content-title {
						text-decoration: underline;
						width: 1033px;
						max-height: 50px;
						font-size: 18px;
						font-weight: 500;
						color: #3462e9;
						opacity: 1;
						cursor: pointer;
						margin-bottom: 10px;
						text-overflow: -o-ellipsis-lastline;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2; //行数需设置
						line-clamp: 2;
						-webkit-box-orient: vertical;
					}
					.related-content-article {
						position: relative;
						max-height: 40px;
						font-size: 14px;
						font-weight: 400;
						color: #333333;
						opacity: 1;
						text-overflow: -o-ellipsis-lastline;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2; //行数需设置
						line-clamp: 2;
						-webkit-box-orient: vertical;
						display: flex;
						align-items: flex-end;

						p {
							max-width: 994px;
						}

						.related-content-read-more {
							position: absolute;
							left: 1000px;
							bottom: 0;
							display: flex;

							cursor: pointer;

							height: 20px;
							font-size: 14px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 20px;
							color: #3462e9;
							opacity: 1;
						}
					}
					.comment-information {
						width: 200px;
						height: 30px;
						display: flex;
						align-items: center;
						margin: 27px 0;
						img {
							margin-right: 10px;
							width: 20px;
							height: 20px;
						}
						p {
							font-size: 16px;
							font-weight: 500;
							color: #848484;
							opacity: 1;
						}
					}
				}
				.related-bottom-line {
					width: 1171px;
					height: 0px;
					border: 1px solid #dbdbdb;
					opacity: 1;
				}
			}
		}
	}
}

.pagination {
	display: flex;
	justify-content: center;
	margin: 10px auto;
}
</style>
