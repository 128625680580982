var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "whole" }, [
    _c("div", { staticClass: "search" }, [
      _c("div", { staticClass: "Secondary" }, [
        _c("div", { staticClass: "SecondaryTop" }, [
          _c("ul", [
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.toTool(
                      "http://www.unafold.org/mfold/applications/dna-folding-form.php"
                    )
                  },
                },
              },
              [
                _c("img", {
                  attrs: { src: require("../../assets/UNAFold_favicon.png") },
                }),
                _c("p", [_vm._v("UNAFold")]),
              ]
            ),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.toTool("http://www.nupack.org/")
                  },
                },
              },
              [
                _c("img", {
                  attrs: { src: require("../../assets/nupack_favicon.png") },
                }),
                _c("p", [_vm._v("NUPACK")]),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }