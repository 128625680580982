<template>
	<div class="filter-panel-container">
		<form @submit.prevent="submit" novalidate="true">
			<div class="main-filter-panel">
				<div class="filter">
					<div class="label">靶标名称</div>
					<div class="input">
						<el-input v-model="targetName" style="width: 100%;"></el-input>
					</div>
				</div>
			</div>
			<div class="control-panel">
				<div class="submit">
					<button type="submit">筛选</button>
				</div>
				<div class="reset">
					<button type="reset" @click="resetFilter">重置</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
export default {
	props: ["filterinfo"],
	data() {
		return {
			targetName: "",
			targetType: "" // 靶标类型
		}
	},
	methods: {
		submit(event) {
			this.$emit("filterchang", {
				targetName: this.targetName
			})
		},
		resetFilter() {
			this.targetType = ""
			this.$emit("filterchang", {
				targetName: this.targetName
			})
		}
	},
	created: function () {
		this.targetName = this.filterinfo.targetName || ""
	}
}
</script>

<style lang="less" scoped>
.filter-panel-container {
	width: 100%;
	background-color: #ffffff;
	display: inline-block;
}

.main-filter-panel {
	margin: 10px;
	border-bottom: 1px solid #f0f0f0;
}

.filter {
	margin-bottom: 10px;
	text-align: left;
}

.filter .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	line-height: 20px;
	color: #333333;
}

.filter .input input {
	height: 30px;
	width: 98%;
	background-color: #f1f2f7;
	border-radius: 2px;
	border: none;
	text-indent: 2px;
}

.filter .input select {
	height: 30px;
	width: 98%;
	background-color: #f1f2f7;
	border-radius: 2px;
	border: none;
	text-indent: 2px;
}

.funding-panel {
	margin: 10px;
	text-align: left;
}

.funding-panel .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	line-height: 20px;
	color: #333333;
}

.funding-panel .select-panel {
	height: 90px;
	display: flex;
	flex-direction: column;
}

.funding-panel .select-panel .input {
	height: 20px;
	margin-top: 10px;
	margin-left: 6px;
	display: flex;
	flex-direction: row;
}

.funding-panel .select-panel .input input {
	margin: auto 4px;
}

.funding-panel .select-panel .input .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	color: #333333;
	line-height: 20px;
}

.control-panel {
	margin: 10px;
	display: flex;
	flex-direction: row;
}

.submit button {
	width: 160px;
	height: 30px;
	color: #ffffff;
	background-color: #3463ea;
	border-radius: 2px;
	border: none;
}

.reset {
	margin-left: auto;
	margin-right: 0;
}

.reset button {
	width: 100px;
	height: 30px;
	color: #333333;
	background-color: #cbcbcb;
	border-radius: 2px;
	border: none;
	text-align: center;
}
</style>
