<template>
	<div class="main-panel main-list">
		<div class="left-panel">
			<div class="statistic-panel">
				<!--饼图-->
				<doughnut-chart
					:dataList="countryStatistics"
					totalDesc="专利总数"
					@click="countrySelect"
					isDataFixed="true"
				></doughnut-chart>
			</div>
			<div class="statistic-panel" style="margin-top: 20px;">
				<!--柱状图-->
				<BarChart :dataList="candidate"></BarChart>
			</div>
			<div class="filter-panel">
				<patentfilter
					@filterchang="getFilter"
					:filerInfo="filerInfo"
				></patentfilter>
			</div>
		</div>
		<div class="list-panel">
			<patentlist
				ref="list"
				@orderchange="getOrder"
				@inventorchange="getInventor"
				:datalist="dataList"
				:resultAmount="resultAmount"
				:orderby="filerInfo.orderby"
			></patentlist>
			<div class="pagination">
				<el-pagination
					id="pagination"
					:current-page="filerInfo.index"
					:page-size="filerInfo.size"
					:page-sizes="[10, 25, 50]"
					layout="prev, pager, next, jumper, sizes"
					:total="resultAmount"
					@current-change="queryChange"
					@size-change="sizeChange"
					@prev-click="queryChange"
					@next-click="queryChange"
				>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import patentlist from "./list/index.vue"
import patentfilter from "./filter/index.vue"
import DoughnutChart from "../../components/charts/DoughnutChart.vue"
import BarChart from "../../components/charts/BarChart.vue"
import { ApiClient, PatentApi } from "../../api"
import "../../assets/css/list.less"
import "../../assets/css/list-pager@index.less"

export default {
	data() {
		return {
			api: new PatentApi(ApiClient.instance),
			resultAmount: 0,
			dataList: [],
			countryStatistics: [],
			candidate: [],
			filerInfo: {
				aptamerID: "", // 关联适体id
				keyWord: "", // 关键字
				_abstract: "", // 摘要
				patentOffice: "", // 专利局
				language: "", // 语言
				inventionType: "", // 发明分类
				publishedDate: "", // 公布日期
				inventor: "", // 发明人
				orderby: "date", // 排序字段，date-公布日期，times-引用频次
				searchType: 0,
				size: 10,
				index: 1,
				title: "" // 标题
			}
		}
	},
	methods: {
		getData() {
			console.log(this.filerInfo)
			this.api.apiPatentQueryGet(
				this.filerInfo.searchType,
				this.filerInfo,
				(error, data, response) => {
					if (error) {
						console.log(error)
						return
					}
					if (data.success) {
						this.dataList = data.result.records.map((item) => {
							var rec = item
							if (rec.cover) {
								rec.cover = process.env.VUE_APP_IMG_API + rec.cover
							}
							return rec
						})
						this.resultAmount = data.result.total
						this.countryStatistics = data.result.countryStatistics
						this.candidate = data.result.annualCountStatistics
					}
				}
			)
		},
		// 筛选条件改变
		getFilter(data) {
			// console.log("进入条件改变")
			// console.log(data)
			// console.log(this.filerInfo)
			this.filerInfo.patentOffice = data.patentOffice
			// this.filerInfo.language = data.language
			// this.filerInfo.inventionType = data.inventionType
			this.filerInfo.publishedDate = data.publishedDate
			this.filerInfo.inventor = data.inventor
			this.filerInfo.index = 1
			this.filerInfo.searchType = 2
			// console.log(this.filerInfo)
			// this.getData()
			this.replaceUrl(this.filerInfo)
		},
		// 分页条件改变
		queryChange(val) {
			this.filerInfo.index = val
			this.replaceUrl(this.filerInfo)
		},
		sizeChange(val) {
			this.filerInfo.index = 1
			this.filerInfo.size = val
			this.replaceUrl(this.filerInfo)
		},
		// 排序条件改变
		getOrder(data) {
			this.filerInfo.orderby = data
			this.filerInfo.index = 1
			this.replaceUrl(this.filerInfo)
		},
		getInventor(val) {
			this.filerInfo.inventor = val
			this.filerInfo.searchType = 2
			this.filerInfo.index = 1
			this.replaceUrl(this.filerInfo)
		},
		replaceUrl(opts) {
			console.log("进入replaceUrl")
			console.log(this.filerInfo)

			this.$router.replace({ query: this.filerInfo })
		},
		getURLParams() {
			var query = this.$route.query

			console.log("进入url获取参数")

			console.log(query)

			this.filerInfo.aptamerID = query.aptamerID || ""
			this.filerInfo.keyWord = query.keyword || query.keyWord
			this.filerInfo.index = parseInt(query.index) || 1
			this.filerInfo.size = parseInt(query.size) || 10
			this.filerInfo.searchType =
				parseInt(query.searchtype) || parseInt(query.searchType) || 0
			this.filerInfo.inventionType = query.inventionType
			this.filerInfo.inventor = query.inventor
			// this.filerInfo.language = query.language
			this.filerInfo.patentOffice = query.patentOffice
			this.filerInfo.publishedDate = query.publishedDate
			this.filerInfo._abstract = query._abstract
			this.filerInfo.title = query.title
		},
		countrySelect(arg) {
			// if (!arg.isOther) {
			// 	this.filerInfo.searchType = 2
			// 	this.filerInfo.patentOffice = arg.select[0]
			// 	this.replaceUrl(this.filerInfo)
			// }
			this.filerInfo.searchType = 2
			this.filerInfo.patentOffice = arg?.select.length > 0 ? arg.select[0] : null
			// this.filerInfo.patentOffice = arg.select[0]
			this.replaceUrl(this.filerInfo)
		}
	},
	mounted: function () {
		this.getURLParams()
		this.getData()
	},
	watch: {
		$route(to, from) {
			this.getURLParams()
			this.getData()
			// this.updateLevelPaths()
		}
	},
	components: {
		patentlist,
		patentfilter,
		DoughnutChart,
		BarChart
	}
}
</script>
