var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-container" }, [
    _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "basic" }, [
        _c(
          "div",
          { staticClass: "avator" },
          [
            _c("el-avatar", {
              key: _vm.avatorHref,
              attrs: { src: _vm.avatorHref, size: 90 },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c(
        "div",
        { staticClass: "nav-bar" },
        [
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/usercenter/info" } },
            [_vm._v("账号资料")]
          ),
          _c(
            "router-link",
            {
              staticClass: "item",
              attrs: { to: "/usercenter/favorites/aptamer" },
            },
            [_vm._v("我的收藏")]
          ),
          _c(
            "router-link",
            { staticClass: "item", attrs: { to: "/usercenter/myupload/seq" } },
            [_vm._v("我的上传")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "right" }, [_c("router-view")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }