/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Country} from './Country';

/**
 * The ClinicalItemDto model module.
 * @module model/ClinicalItemDto
 * @version v1
 */
export class ClinicalItemDto {
  /**
   * Constructs a new <code>ClinicalItemDto</code>.
   * @alias module:model/ClinicalItemDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ClinicalItemDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ClinicalItemDto} obj Optional instance to populate.
   * @return {module:model/ClinicalItemDto} The populated <code>ClinicalItemDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ClinicalItemDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('recruitmentStatus'))
        obj.recruitmentStatus = ApiClient.convertToType(data['recruitmentStatus'], 'String');
      if (data.hasOwnProperty('conditions'))
        obj.conditions = ApiClient.convertToType(data['conditions'], 'String');
      if (data.hasOwnProperty('studyStartDate'))
        obj.studyStartDate = ApiClient.convertToType(data['studyStartDate'], 'Date');
      if (data.hasOwnProperty('organization'))
        obj.organization = ApiClient.convertToType(data['organization'], 'String');
      if (data.hasOwnProperty('country'))
        obj.country = Country.constructFromObject(data['country']);
      if (data.hasOwnProperty('descriptiveInformation'))
        obj.descriptiveInformation = ApiClient.convertToType(data['descriptiveInformation'], 'String');
      if (data.hasOwnProperty('briefSummary'))
        obj.briefSummary = ApiClient.convertToType(data['briefSummary'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
ClinicalItemDto.prototype.id = undefined;

/**
 * @member {String} title
 */
ClinicalItemDto.prototype.title = undefined;

/**
 * @member {String} recruitmentStatus
 */
ClinicalItemDto.prototype.recruitmentStatus = undefined;

/**
 * @member {String} conditions
 */
ClinicalItemDto.prototype.conditions = undefined;

/**
 * @member {Date} studyStartDate
 */
ClinicalItemDto.prototype.studyStartDate = undefined;

/**
 * @member {String} organization
 */
ClinicalItemDto.prototype.organization = undefined;

/**
 * @member {module:model/Country} country
 */
ClinicalItemDto.prototype.country = undefined;

/**
 * @member {String} descriptiveInformation
 */
ClinicalItemDto.prototype.descriptiveInformation = undefined;

/**
 * @member {String} briefSummary
 */
ClinicalItemDto.prototype.briefSummary = undefined;

