<template>
	<div class="item-list-panel">
		<div class="top-bar-container">
			<div class="top-bar">
				<div class="result">
					<div class="label count-desc">
						<div class="label">当前结果</div>
						<div class="value">
							{{ resultAmount }}
						</div>
						<div class="unit">条</div>
					</div>
				</div>
			</div>
		</div>
		<div class="item-list">
			<div class="item-container" v-for="(team, i) in teams" :key="i">
				<div
					class="item"
					@mouseover="mouseOver(team)"
					@mouseleave="mouseLeave(team)"
				>
					<div class="fav-con" v-if="(team.isOver || team.isFavorite) && isLogin">
						<div
							class="sm-btn"
							@click="addFavorite(team)"
							v-if="!team.isFavorite"
						>
							收 藏
						</div>
						<div
							class="sm-btn"
							@click="removeFavorite(team)"
							v-if="team.isFavorite"
						>
							已收藏
						</div>
					</div>
					<div class="location">
						<div class="entity entity-title">
							{{ team.name }}
						</div>
						<div class="entity-line">
							<div class="entity">
								<div class="label">所在单位：</div>
								<div class="value">
									{{ team.institution }}
								</div>
							</div>
							<div class="entity">
								<div class="label">所属国家：</div>
								<div class="value">
									{{ team.country.name }}
								</div>
							</div>
						</div>
						<div class="entity">
							<div class="label">课题组链接：</div>
							<div class="value">
								<a href="javascript:void(0)" @click="toUrl(team.url)">{{
									team.url
								}}</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="pagination">
				<el-pagination
					id="pagination"
					:current-page="pageIndex"
					:page-size="pageSize"
					:page-sizes="[10, 50, 100]"
					layout="prev, pager, next, jumper,sizes"
					:total="resultAmount"
					@current-change="handlePageChange"
					@size-change="changeSize"
				>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { ApiClient, UserCenterApi } from "../../../api"
import Vue from "vue"
export default {
	props: ["teams", "resultAmount", "pageIndex", "pageSize", "isLogin"],
	data() {
		return {
			userCenterApi: new UserCenterApi(ApiClient.Instance)
		}
	},
	methods: {
		toUrl(url) {
			window.open(url)
		},
		handlePageChange(val) {
			this.$parent.pageIndex = val
			this.$parent.replaceURL()
		},
		changeSize(val) {
			this.$parent.pageIndex = 1
			this.$parent.pageSize = val
			this.$parent.replaceURL()
		},
		mouseOver(team) {
			Vue.set(team, "isOver", true)
		},
		mouseLeave(team) {
			Vue.set(team, "isOver", false)
		},
		addFavorite(team) {
			console.log(team.id)
			this.userCenterApi.apiUserCenterFavoritePost(
				{
					collectionID: team.id,
					collectionType: 5
				},
				(e, data, resp) => {
					this.getFavoriteStatus(team)
				}
			)
		},
		removeFavorite(team) {
			this.userCenterApi.apiUserCenterUnfavoriteDelete(team.id, 5, null, () => {
				this.getFavoriteStatus(team)
			})
		},
		getFavoriteStatus(team) {
			this.userCenterApi.apiUserCenterCollectedGet(team.id, (e, data, resp) => {
				if (data.result) {
					team.isFavorite = true
				} else {
					team.isFavorite = false
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.entity-line {
	display: flex;
	.c-font-size-12();
	justify-content: left;
	flex-direction: row;

	.value {
		width: auto !important;
	}
}

.item {
	position: relative;
}

.item .sm-btn {
	position: absolute;
	.c-font-size-12();
	right: 0px;
	top: 0px;
	width: 45px;
	height: 20px;
	border-radius: 3px;
	border: 1px solid blue;
	color: blue;
	text-align: center;
	cursor: pointer;
	z-index: 999;
}

.entity {
	.c-font-size-14() !important;

	.value a {
		text-decoration: underline;
	}
}
.entity-title {
	.c-font-size-18() !important;
}
</style>
