<template>
	<div class="chart-con" ref="chart"></div>
</template>
<script>
import * as echarts from "echarts"
export default {
	name: "BarChart",
	props: ["dataList", "totalDesc", "yName"],
	data() {
		return {
			option: {
				xAxis: {
					type: "category",
					data: []
				},
				tooltip: {
					trigger: "axis"
					// axisPointer: {
					// 	type: "cross",
					// 	crossStyle: {
					// 		color: "#999"
					// 	}
					// }
				},
				barWidth: 10,
				yAxis: {
					type: "value",
					axisTick: {
						show: false
					}
				},
				series: [
					{
						data: [],
						type: "bar",
						itemStyle: {
							normal: {
								color: "#2b3d9f"
							}
						}
					}
				]
			},
			grid: {
				left: "50px"
			}
		}
	},
	watch: {
		dataList: function (newList, oldList) {
			const chart =
				echarts.getInstanceByDom(this.$refs.chart) ||
				echarts.init(this.$refs.chart, "shine")

			// xAxis year
			var yearList = newList.map((p) => p.name)
			// yAxis count
			var countList = newList.map((p) => p.value)
			if (this.yName) {
				this.option.yAxis.name = this.yName
			}
			this.option.xAxis.data = yearList
			this.option.series[0].data = countList
			chart.setOption(this.option)
		}
	},
	mounted() {},
	methods: {
		loadChart(data) {}
	}
}
</script>

<style scoped lang="less">
.chart-con {
	width: 290px;
	height: 315px;
	padding-left: 10px;
}
</style>
