var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-panel" }, [
    _c("div", { staticClass: "left-panel" }, [
      _c(
        "div",
        { staticClass: "statistic-panel" },
        [
          _c("paperdata", {
            attrs: {
              info: _vm.info,
              favorite: _vm.favorite,
              isLogin: _vm.isLogin,
            },
            on: { favoritechange: _vm.favoriteChange },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "list-panel" },
      [
        _c("papercontent", {
          attrs: {
            content: _vm.content,
            title: _vm.title,
            comments: _vm.comments,
            commentStr: _vm.commentStr,
            figureList: _vm.figureList,
            productList: _vm.productList,
          },
          on: { commentSub: _vm.comment },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }