var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login" }, [
    _c("div", { staticClass: "login-container" }, [
      _c("div", { staticClass: "modalClose", on: { click: _vm.close } }),
      _c("div", { staticClass: "title" }, [_vm._v("修改密码")]),
      _c("div", { staticClass: "input-box" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.oldpassword,
              expression: "oldpassword",
            },
          ],
          attrs: { type: "password", maxlength: "20", placeholder: "旧密码" },
          domProps: { value: _vm.oldpassword },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.oldpassword = $event.target.value
            },
          },
        }),
      ]),
      _c("div", { staticClass: "input-box" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.newpassword,
              expression: "newpassword",
            },
          ],
          attrs: { type: "password", maxlength: "20", placeholder: "新密码" },
          domProps: { value: _vm.newpassword },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.newpassword = $event.target.value
            },
          },
        }),
      ]),
      _c("div", { staticClass: "input-box" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.confirmpassword,
              expression: "confirmpassword",
            },
          ],
          attrs: {
            type: "password",
            maxlength: "20",
            placeholder: "确认新密码",
          },
          domProps: { value: _vm.confirmpassword },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.confirmpassword = $event.target.value
            },
          },
        }),
      ]),
      _c("div", { staticClass: "submit" }, [
        _c("button", { on: { click: _vm.submit } }, [_vm._v("确认修改")]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }