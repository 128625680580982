/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The AptamerItemDto model module.
 * @module model/AptamerItemDto
 * @version v1
 */
export class AptamerItemDto {
  /**
   * Constructs a new <code>AptamerItemDto</code>.
   * @alias module:model/AptamerItemDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AptamerItemDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AptamerItemDto} obj Optional instance to populate.
   * @return {module:model/AptamerItemDto} The populated <code>AptamerItemDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AptamerItemDto();
      if (data.hasOwnProperty('aptamerID'))
        obj.aptamerID = ApiClient.convertToType(data['aptamerID'], 'String');
      if (data.hasOwnProperty('secondaryStructureFigure'))
        obj.secondaryStructureFigure = ApiClient.convertToType(data['secondaryStructureFigure'], 'String');
      if (data.hasOwnProperty('aptamerName'))
        obj.aptamerName = ApiClient.convertToType(data['aptamerName'], 'String');
      if (data.hasOwnProperty('mainName'))
        obj.mainName = ApiClient.convertToType(data['mainName'], 'String');
      if (data.hasOwnProperty('otherName'))
        obj.otherName = ApiClient.convertToType(data['otherName'], 'String');
      if (data.hasOwnProperty('targetName'))
        obj.targetName = ApiClient.convertToType(data['targetName'], 'String');
      if (data.hasOwnProperty('sequenceLength'))
        obj.sequenceLength = ApiClient.convertToType(data['sequenceLength'], 'String');
      if (data.hasOwnProperty('affinity'))
        obj.affinity = ApiClient.convertToType(data['affinity'], 'String');
      if (data.hasOwnProperty('citedCount'))
        obj.citedCount = ApiClient.convertToType(data['citedCount'], 'Number');
      if (data.hasOwnProperty('publishedDate'))
        obj.publishedDate = ApiClient.convertToType(data['publishedDate'], 'Date');
      if (data.hasOwnProperty('journal'))
        obj.journal = ApiClient.convertToType(data['journal'], 'String');
      if (data.hasOwnProperty('aptamerType'))
        obj.aptamerType = ApiClient.convertToType(data['aptamerType'], 'String');
      if (data.hasOwnProperty('finderName'))
        obj.finderName = ApiClient.convertToType(data['finderName'], 'String');
      if (data.hasOwnProperty('articleTitle'))
        obj.articleTitle = ApiClient.convertToType(data['articleTitle'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} aptamerID
 */
AptamerItemDto.prototype.aptamerID = undefined;

/**
 * @member {String} secondaryStructureFigure
 */
AptamerItemDto.prototype.secondaryStructureFigure = undefined;

/**
 * @member {String} aptamerName
 */
AptamerItemDto.prototype.aptamerName = undefined;

/**
 * @member {String} mainName
 */
AptamerItemDto.prototype.mainName = undefined;

/**
 * @member {String} otherName
 */
AptamerItemDto.prototype.otherName = undefined;

/**
 * @member {String} targetName
 */
AptamerItemDto.prototype.targetName = undefined;

/**
 * @member {String} sequenceLength
 */
AptamerItemDto.prototype.sequenceLength = undefined;

/**
 * @member {String} affinity
 */
AptamerItemDto.prototype.affinity = undefined;

/**
 * @member {Number} citedCount
 */
AptamerItemDto.prototype.citedCount = undefined;

/**
 * @member {Date} publishedDate
 */
AptamerItemDto.prototype.publishedDate = undefined;

/**
 * @member {String} journal
 */
AptamerItemDto.prototype.journal = undefined;

/**
 * @member {String} aptamerType
 */
AptamerItemDto.prototype.aptamerType = undefined;

/**
 * @member {String} finderName
 */
AptamerItemDto.prototype.finderName = undefined;

/**
 * @member {String} articleTitle
 */
AptamerItemDto.prototype.articleTitle = undefined;

