/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ClinicalDetailDescriptiveDto model module.
 * @module model/ClinicalDetailDescriptiveDto
 * @version v1
 */
export class ClinicalDetailDescriptiveDto {
  /**
   * Constructs a new <code>ClinicalDetailDescriptiveDto</code>.
   * @alias module:model/ClinicalDetailDescriptiveDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ClinicalDetailDescriptiveDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ClinicalDetailDescriptiveDto} obj Optional instance to populate.
   * @return {module:model/ClinicalDetailDescriptiveDto} The populated <code>ClinicalDetailDescriptiveDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ClinicalDetailDescriptiveDto();
      if (data.hasOwnProperty('briefTitle'))
        obj.briefTitle = ApiClient.convertToType(data['briefTitle'], 'String');
      if (data.hasOwnProperty('officialTitle'))
        obj.officialTitle = ApiClient.convertToType(data['officialTitle'], 'String');
      if (data.hasOwnProperty('briefSummary'))
        obj.briefSummary = ApiClient.convertToType(data['briefSummary'], 'String');
      if (data.hasOwnProperty('detailedDescription'))
        obj.detailedDescription = ApiClient.convertToType(data['detailedDescription'], 'String');
      if (data.hasOwnProperty('studyType'))
        obj.studyType = ApiClient.convertToType(data['studyType'], 'String');
      if (data.hasOwnProperty('studyPhase'))
        obj.studyPhase = ApiClient.convertToType(data['studyPhase'], 'String');
      if (data.hasOwnProperty('conditions'))
        obj.conditions = ApiClient.convertToType(data['conditions'], 'String');
      if (data.hasOwnProperty('intervention'))
        obj.intervention = ApiClient.convertToType(data['intervention'], 'String');
      if (data.hasOwnProperty('publications'))
        obj.publications = ApiClient.convertToType(data['publications'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} briefTitle
 */
ClinicalDetailDescriptiveDto.prototype.briefTitle = undefined;

/**
 * @member {String} officialTitle
 */
ClinicalDetailDescriptiveDto.prototype.officialTitle = undefined;

/**
 * @member {String} briefSummary
 */
ClinicalDetailDescriptiveDto.prototype.briefSummary = undefined;

/**
 * @member {String} detailedDescription
 */
ClinicalDetailDescriptiveDto.prototype.detailedDescription = undefined;

/**
 * @member {String} studyType
 */
ClinicalDetailDescriptiveDto.prototype.studyType = undefined;

/**
 * @member {String} studyPhase
 */
ClinicalDetailDescriptiveDto.prototype.studyPhase = undefined;

/**
 * @member {String} conditions
 */
ClinicalDetailDescriptiveDto.prototype.conditions = undefined;

/**
 * @member {String} intervention
 */
ClinicalDetailDescriptiveDto.prototype.intervention = undefined;

/**
 * @member {String} publications
 */
ClinicalDetailDescriptiveDto.prototype.publications = undefined;

