<template>
	<div class="footer-bar">
		<div class="subbar">
			<div
				class="navigate-container"
				style="height: 100px; display: flex; align-items: center"
			>
				<div class="navigate" @click="navigateTo('introduction')">适体简介</div>
				<div class="navigate" @click="navigateTo('aptamerdb')">
					Aptamer数据库
				</div>
				<div class="navigate" @click="navigateTo('paper')">文献资料</div>
				<div class="navigate" @click="navigateTo('patent')">重点专利</div>
				<div class="navigate" @click="navigateTo('clinical')">临床进展</div>
				<div class="navigate" @click="navigateTo('team')">团队介绍</div>
				<div class="navigate" @click="navigateTo('project')">科研项目</div>
				<div class="navigate" @click="navigateTo('LargeScreen')">数据统计</div>
				<div class="navigate" @click="navigateTo('analysistool')">分析工具</div>
				<!-- <div class="navigate" @click="navigateTo('sequence_submission')">
					序列提交
				</div> -->
				<div class="navigate" @click="openSeqSub">序列提交</div>
				<div class="navigate share">
					<!-- <div @click="share('weixin')">
					<img src="../assets/WeChat.png" alt="" />
				</div> -->
					<div @click="share('sina')">
						<img src="../assets/micro-blog.png" alt="" />
					</div>
				</div>
			</div>
		</div>
		<div class="subbar">
			<div class="authority">
				<div class="copyright">版权所有：中国科学院基础医学与肿瘤研究所</div>
				<div class="gov-permission">
					浙ICP备20028988号-1 浙ICP备20028988号-2
				</div>
			</div>
		</div>
		<div v-if="isSeqSub" class="to-login-panel">
			<SequenceSub @cancelsub="closeSeqSub"></SequenceSub>
		</div>
	</div>
</template>

<script>
import SequenceSub from "./SequenceSub.vue"
import { ApiClient, SysConfigApi } from "../api"
// import Swal from "sweetalert2"

export default {
	props: ["isLogin"],
	data() {
		return {
			SysConfigApi: new SysConfigApi(ApiClient.instance),
			isSeqSub: false, // 是否序列上传
			shareconfig: {
				url: "",
				title: "",
				description: "",
				image: "",
				sites: ["wechat", "weibo"],
				wechatQrcodeTitle: "微信扫一扫：分享"
			},
			weiboShare: {}
		}
	},
	components: {
		SequenceSub
	},
	mounted() {
		this.getShareTemp()
	},
	methods: {
		share(type) {
			if (type === "sina") {
				var url =
					"http://service.weibo.com/share/share.php?url=" +
					this.weiboShare.content +
					"&sharesource=weibo&title=" +
					this.weiboShare.title
				url += this.weiboShare.img ? "&pic=" + this.weiboShare.img : ""
				// window.open(
				// 	"http://service.weibo.com/share/share.php?url=http://test.adb.deepbiogroup.com?sharesource=weibo&title=适配体网站"
				// )
				window.open(url)
			} else if (type === "weixin") {
				console.log(123)
			}
		},
		navigateTo(page) {
			this.$router.push("/" + page)
		},
		closeSeqSub() {
			this.isSeqSub = false
		},
		getShareTemp() {
			this.SysConfigApi.apiSysConfigQueryGet(
				{ request: 1 },
				(error, data, response) => {
					if (error) {
						console.log(error)
					}
					if (data.success) {
						var share = JSON.parse(data.result)
						this.weiboShare = share
						this.shareconfig.url = process.env.VUE_APP_API_ENDPOINT
						this.shareconfig.description = share.content
						this.shareconfig.title = share.title
						this.shareconfig.image = share.img
					}
				}
			)
		},
		openSeqSub() {
			if (this.isLogin) {
				this.isSeqSub = !this.isSeqSub
			} else {
				document.getElementById("loginSpan").click()
				// Swal.fire({ icon: "error", html: "请登录！" }).then(() => { document.getElementById("loginSpan").click() })
			}
		}
	}
}
</script>

<style lang="less" scoped>
.footer-bar {
	width: 100%;
	height: 136px;
	background-color: #333333;
}

.footer-bar .subbar {
	width: 1100px;
	margin: 0 auto;
	border-top: 1px solid #404146;
}

.navigate-container {
	height: 50px;
	display: flex;
	flex-direction: row;
}

.share {
	margin-left: 80px;
}
.navigate {
	min-width: 60px;
	line-height: 50px;
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	color: #ffffff;
	margin-right: 30px;
	cursor: pointer;
}

.authority {
	line-height: 30px;
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	color: #55565b;
	display: flex;
	flex-direction: row;
	margin-left: 200px;
}

.gov-permission {
	margin-left: 20px;
}

.to-login-panel {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(200, 200, 200, 0.5);
	z-index: 100;
	display: flex;
}

.to-login-panel .login {
	margin: auto;
}
</style>
