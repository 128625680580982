var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "team-list-panel" }, [
    _c("div", { staticClass: "top-bar-container" }, [
      _c("div", [
        _c("div", { staticClass: "result" }, [
          _c(
            "ul",
            { staticClass: "tab-tilte", staticStyle: { margin: "0px" } },
            [
              _c(
                "li",
                {
                  class: { active: _vm.showIndex == 1 },
                  on: {
                    click: function ($event) {
                      _vm.showIndex = 1
                    },
                  },
                },
                [_vm._v(" 摘要 ")]
              ),
              _c(
                "li",
                {
                  class: { active: _vm.showIndex == 2 },
                  on: {
                    click: function ($event) {
                      _vm.showIndex = 2
                    },
                  },
                },
                [_vm._v(" 说明书 ")]
              ),
              _c(
                "li",
                {
                  class: { active: _vm.showIndex == 3 },
                  on: {
                    click: function ($event) {
                      _vm.showIndex = 3
                    },
                  },
                },
                [_vm._v(" 权利要求书 ")]
              ),
              _c(
                "li",
                {
                  class: { active: _vm.showIndex == 4 },
                  on: {
                    click: function ($event) {
                      _vm.showIndex = 4
                    },
                  },
                },
                [_vm._v(" 图片 ")]
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showIndex == 1,
            expression: "showIndex == 1",
          },
        ],
        staticClass: "team-list",
      },
      [
        _c("div", { staticClass: "team-container" }, [
          _c("div", {
            staticClass: "team",
            domProps: { innerHTML: _vm._s(_vm.content._abstract) },
          }),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showIndex == 2,
            expression: "showIndex == 2",
          },
        ],
        staticClass: "team-list",
      },
      [
        _c("div", { staticClass: "team-container" }, [
          _c("div", {
            staticClass: "team",
            domProps: { innerHTML: _vm._s(_vm.content.instructions) },
          }),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showIndex == 3,
            expression: "showIndex == 3",
          },
        ],
        staticClass: "team-list",
      },
      [
        _c("div", { staticClass: "team-container" }, [
          _c("div", {
            staticClass: "team",
            domProps: { innerHTML: _vm._s(_vm.content.claims) },
          }),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showIndex == 4,
            expression: "showIndex == 4",
          },
        ],
        staticClass: "team-list",
      },
      [
        _c("div", { staticClass: "team-container" }, [
          _c(
            "div",
            { staticClass: "team" },
            [
              _vm.content.figures.length > 0
                ? _c(
                    "viewer",
                    { attrs: { images: _vm.content.figures } },
                    _vm._l(_vm.content.figures, function (src, index) {
                      return _c("img", {
                        key: index,
                        staticClass: "imgs",
                        attrs: { src: src },
                      })
                    }),
                    0
                  )
                : _c("div", [_vm._v("暂无图片信息！")]),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }