<template>
	<div class="cont">
		<div class="chart-header">
			<div class="chart-nav">
				<div class="numberAptamers-dot dot"></div>
				<div>
					<div class="number">{{ numberAptamers }}</div>
					<div class="numberText">适体数量</div>
				</div>
			</div>
			<div class="chart-nav">
				<div class="numberTargets-dot dot"></div>
				<div>
					<div class="number">{{ numberTargets }}</div>
					<div class="numberText">靶标数量</div>
				</div>
			</div>
			<div class="chart-nav">
				<div class="sampleType-dot dot"></div>
				<div>
					<div class="number">{{ sampleType }}</div>
					<div class="numberText">样本类型</div>
				</div>
			</div>
		</div>
		<div id="chart-global" ref="chartGlobal"></div>
	</div>
</template>
<script>
import GlobalGeoJson from "@/map/globe.geo.json"
import * as echarts from "echarts"
export default {
	name: "BarChart",
	props: ["globalStatisticas"],
	data() {
		return {
			isCN: false, // 是否中文显示
			numberAptamers: "",
			numberTargets: "",
			Statisticas: {},
			sampleType: "",
			china: {},
			globalTop5: [],
			globalTop5List: [],
			option: {
				selectedMode: "multiple",
				tooltip: {
					show: true,
					trigger: "item",
					formatter: function (params) {
						return params.name
					}
				},
				visualMap: {
					show: false,
					min: 0,
					max: 1000,
					left: "left",
					top: "bottom",
					text: ["高", "低"], // 文本，默认为数值文本
					calculable: true,
					inRange: {
						color: ["#0C6EA4", "#FCC302", "#FF6941"]
					}
				},
				series: [
					{
						name: "世界地图",
						type: "map",
						map: "mapName",
						roam: true,
						mapType: "GlobalGeoJson",
						geoIndex: 0,
						itemStyle: {
							// 普通状态下的样式
							normal: {
								areaColor: "#00467F", // 给了一个淡紫色
								borderColor: "#5371c8", // 图形描边的颜色。[ default: #333 ],
								label: {
									show: false
								}
							},
							emphasis: {
								// 高亮状态下的样式
								label: {
									show: false
								},
								areaColor: "#4c82fc" // 给了一个淡紫色
							}
						},
						markPoint: {
							symbol: "image://" + require("@/assets/position.png"), // 自定义图片路径
							symbolSize: [22, 25], // 图片大小
							label: {
								position: "top",
								color: "#ffffff",
								fontWeight: "bold",
								textShadowBlur: 3,
								textShadowColor: "#000000",
								textShadowOffsetX: 1,
								textShadowOffsetY: 1
							},
							// coord数组存放地址坐标
							data: []
						},
						// 设置不允许缩放
						scaleLimit: {
							min: 1.2, //  最小缩放
							max: 1.2 //  最大缩放
						},
						tooltip: {
							alwaysShowContent: true,
							hoverAnimation: true,
							trigger: "item",
							enterable: true,
							transitionDuration: 1,
							triggerOn: "click",
							formatter: function (params) {
								// console.log(params)
								var str
								if (params.data && params.data.value) {
									str = `
								  <div>
								    <div>${params.data.isCN ? params.data.nameCN : params.data.name}<span style="font-size:18px"> ${params.data.value}</span></div>
								  </div>
								  `
								} else if (params.data && !params.data.value) {
									str = `
								  <div>
								    <div>${params.data.isCN ? params.data.nameCN : params.data.name}<span style="font-size:18px"> 0</span></div>
								  </div>
								  `
								} else {
									str = `
								  <div>
								    <div>${params.name}<span style="font-size:18px"> 0</span></div>
								  </div>
								  `
								}
								// console.log(str)
								return str
							},
							borderWidth: "1px",
							borderRadius: "4",
							borderColor: "#00B2AC",
							textStyle: {
								color: "rgba(255,255,255,1)"
							},
							padding: [5, 10]
						},
						regionHeight: 1.3,
						data: []
					}
				]
			}
		}
	},
	watch: {
		globalStatisticas: {
			handler(newValue, oldValue) {
				// console.log(newValue)
				const chart =
					echarts.getInstanceByDom(this.$refs.chartGlobal) ||
					echarts.init(this.$refs.chartGlobal, "macarons")
				window.addEventListener("resize", function () {
					chart.resize()
				})
				chart.showLoading()
				this.sampleType = newValue.sampleTotal
				this.numberAptamers = newValue.aptamerTotal
				this.numberTargets = newValue.targetTotal
				this.option.series[0].data = newValue.aptamerList?.map(item => {
					var res = item
					this.$set(res, "isCN", this.isCN)
					return res
				})
				newValue.aptamerList.forEach((i) => {
					if (i.name) {
						this.globalTop5.push(i)
					}
				})
				this.globalTop5List = []
				this.globalTop5 = this.globalTop5.slice(0, 10)
				this.globalTop5.forEach((y) => {
					this.globalTop5List.push({
						name: y.name,
						value: (this.isCN ? y.nameCN : y.name) + " " + y.value,
						coord: []
					})
				})
				this.globalTop5List.forEach((i) => {
					if (i.name === "China") {
						i.coord = ["85", "38"]
					} else if (i.name === "USA") {
						i.coord = ["-102", "40"]
					} else if (i.name === "Korea") {
						i.coord = ["128", "37"]
					} else if (i.name === "Japan") {
						i.coord = ["142", "44.5"]
					} else if (i.name === "Germany") {
						i.coord = ["10", "51"]
					} else if (i.name === "Canada") {
						i.coord = ["-108", "60"]
					} else if (i.name === "Iran") {
						i.coord = ["53", "32"]
					} else if (i.name === "France") {
						i.coord = ["1.8", "45"]
					} else if (i.name === "UK") {
						i.coord = ["-2.6", "54"]
					} else if (i.name === "India") {
						i.coord = ["78", "21"]
					}
				})
				this.option.series[0].markPoint.data = this.globalTop5List.slice(0, 5)
				this.$echarts.registerMap("mapName", GlobalGeoJson)
				chart.hideLoading()
				chart.setOption(this.option)
				var that = this
				chart.on("click", function (params) {
					that.option.series[0].itemStyle.normal.areaColor = "#00467F"
					chart.setOption(that.option)
				})
			},
			deep: true
		}
	}
}
</script>

<style scoped lang="less">
.cont {
	width: 780px;
	height: 370px;
	background: #ffffff !important;
	margin: 0 auto;
}

.chart-header {
	display: flex;
	width: 70%;
	margin: 60px auto 0 auto;
	height: 50px;
}
#chart-global {
	width: 100%;
	height: 310px;
	margin: 0 auto;
}
.chart-nav {
	width: 33%;
	height: 40px;
	display: flex;
	align-items: center;
}
// .numberAptamers-dot {
// 	background: #333333;
// }
.dot {
	width: 4px;
	height: 35px;
	background: #009FE1;
	border-radius: 1px;
	margin: 0 10px 0 30px;
}
// .numberTargets-dot {
// 	background: #333333;
// }
// .sampleType-dot {
// 	background: #333333;
// }
.number {
	font-size: 22px;
	letter-spacing: 1px;
	width: 100px;
	height: 28px;
	text-align: left;
	color: #333333;
}

.numberText {
	.c-font-size-12();
	height: 25px;
	line-height: 20px;
	width: 100px;
	height: 20px;
	text-align: left;
	padding-left: 3px;
	color: #333333;
}
</style>
