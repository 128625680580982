/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Country} from './Country';
import {Favorite} from './Favorite';

/**
 * The Clinical model module.
 * @module model/Clinical
 * @version v1
 */
export class Clinical {
  /**
   * Constructs a new <code>Clinical</code>.
   * @alias module:model/Clinical
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>Clinical</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Clinical} obj Optional instance to populate.
   * @return {module:model/Clinical} The populated <code>Clinical</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Clinical();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('recruitmentStatus'))
        obj.recruitmentStatus = ApiClient.convertToType(data['recruitmentStatus'], 'String');
      if (data.hasOwnProperty('studyStartDate'))
        obj.studyStartDate = ApiClient.convertToType(data['studyStartDate'], 'Date');
      if (data.hasOwnProperty('studyCompletionDate'))
        obj.studyCompletionDate = ApiClient.convertToType(data['studyCompletionDate'], 'Date');
      if (data.hasOwnProperty('organization'))
        obj.organization = ApiClient.convertToType(data['organization'], 'String');
      if (data.hasOwnProperty('conditions'))
        obj.conditions = ApiClient.convertToType(data['conditions'], 'String');
      if (data.hasOwnProperty('intervention'))
        obj.intervention = ApiClient.convertToType(data['intervention'], 'String');
      if (data.hasOwnProperty('descriptiveInformation'))
        obj.descriptiveInformation = ApiClient.convertToType(data['descriptiveInformation'], 'String');
      if (data.hasOwnProperty('sponsor'))
        obj.sponsor = ApiClient.convertToType(data['sponsor'], 'String');
      if (data.hasOwnProperty('trackingInformation'))
        obj.trackingInformation = ApiClient.convertToType(data['trackingInformation'], 'String');
      if (data.hasOwnProperty('url'))
        obj.url = ApiClient.convertToType(data['url'], 'String');
      if (data.hasOwnProperty('recruitmentInformation'))
        obj.recruitmentInformation = ApiClient.convertToType(data['recruitmentInformation'], 'String');
      if (data.hasOwnProperty('nctid'))
        obj.nctid = ApiClient.convertToType(data['nctid'], 'String');
      if (data.hasOwnProperty('country'))
        obj.country = Country.constructFromObject(data['country']);
      if (data.hasOwnProperty('countryId'))
        obj.countryId = ApiClient.convertToType(data['countryId'], 'String');
      if (data.hasOwnProperty('favorites'))
        obj.favorites = ApiClient.convertToType(data['favorites'], [Favorite]);
      if (data.hasOwnProperty('countries'))
        obj.countries = ApiClient.convertToType(data['countries'], [Country]);
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
Clinical.prototype.id = undefined;

/**
 * @member {String} title
 */
Clinical.prototype.title = undefined;

/**
 * @member {String} recruitmentStatus
 */
Clinical.prototype.recruitmentStatus = undefined;

/**
 * @member {Date} studyStartDate
 */
Clinical.prototype.studyStartDate = undefined;

/**
 * @member {Date} studyCompletionDate
 */
Clinical.prototype.studyCompletionDate = undefined;

/**
 * @member {String} organization
 */
Clinical.prototype.organization = undefined;

/**
 * @member {String} conditions
 */
Clinical.prototype.conditions = undefined;

/**
 * @member {String} intervention
 */
Clinical.prototype.intervention = undefined;

/**
 * @member {String} descriptiveInformation
 */
Clinical.prototype.descriptiveInformation = undefined;

/**
 * @member {String} sponsor
 */
Clinical.prototype.sponsor = undefined;

/**
 * @member {String} trackingInformation
 */
Clinical.prototype.trackingInformation = undefined;

/**
 * @member {String} url
 */
Clinical.prototype.url = undefined;

/**
 * @member {String} recruitmentInformation
 */
Clinical.prototype.recruitmentInformation = undefined;

/**
 * @member {String} nctid
 */
Clinical.prototype.nctid = undefined;

/**
 * @member {module:model/Country} country
 */
Clinical.prototype.country = undefined;

/**
 * @member {String} countryId
 */
Clinical.prototype.countryId = undefined;

/**
 * @member {Array.<module:model/Favorite>} favorites
 */
Clinical.prototype.favorites = undefined;

/**
 * @member {Array.<module:model/Country>} countries
 */
Clinical.prototype.countries = undefined;

