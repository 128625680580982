/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CommentType} from './CommentType';
import {DataStatusEnum} from './DataStatusEnum';

/**
 * The MyComment model module.
 * @module model/MyComment
 * @version v1
 */
export class MyComment {
  /**
   * Constructs a new <code>MyComment</code>.
   * @alias module:model/MyComment
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>MyComment</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MyComment} obj Optional instance to populate.
   * @return {module:model/MyComment} The populated <code>MyComment</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MyComment();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('articleID'))
        obj.articleID = ApiClient.convertToType(data['articleID'], 'String');
      if (data.hasOwnProperty('userID'))
        obj.userID = ApiClient.convertToType(data['userID'], 'String');
      if (data.hasOwnProperty('parentID'))
        obj.parentID = ApiClient.convertToType(data['parentID'], 'String');
      if (data.hasOwnProperty('content'))
        obj.content = ApiClient.convertToType(data['content'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = DataStatusEnum.constructFromObject(data['status']);
      if (data.hasOwnProperty('auditRemark'))
        obj.auditRemark = ApiClient.convertToType(data['auditRemark'], 'String');
      if (data.hasOwnProperty('commentType'))
        obj.commentType = CommentType.constructFromObject(data['commentType']);
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
MyComment.prototype.id = undefined;

/**
 * @member {String} articleID
 */
MyComment.prototype.articleID = undefined;

/**
 * @member {String} userID
 */
MyComment.prototype.userID = undefined;

/**
 * @member {String} parentID
 */
MyComment.prototype.parentID = undefined;

/**
 * @member {String} content
 */
MyComment.prototype.content = undefined;

/**
 * @member {module:model/DataStatusEnum} status
 */
MyComment.prototype.status = undefined;

/**
 * @member {String} auditRemark
 */
MyComment.prototype.auditRemark = undefined;

/**
 * @member {module:model/CommentType} commentType
 */
MyComment.prototype.commentType = undefined;

