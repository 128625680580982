<template>
	<div class="filter-panel-container">
		<form @submit.prevent="submit" novalidate="true">
			<div class="main-filter-panel">
				<div class="filter">
					<div class="title">国家著录项目数据</div>
				</div>
				<div class="filter">
					<div class="label">专利局</div>
					<div class="input">
						{{ info.patentOffice || "N/A" }}
					</div>
				</div>
				<div class="filter">
					<div class="label">申请号</div>
					<div class="input">
						{{ info.applicationNumber || "N/A" }}
					</div>
				</div>
				<div class="filter">
					<div class="label">申请日</div>
					<div class="input">
						{{ info.applicationDate | dateFormat("YYYY-MM-DD", "N/A") }}
					</div>
				</div>
				<div class="filter">
					<div class="label">公布号</div>
					<div class="input">
						{{ info.publicationNumber || "N/A" }}
					</div>
				</div>
				<div class="filter">
					<div class="label">公布日</div>
					<div class="input">
						{{ info.publicationDate | dateFormat("YYYY-MM-DD", "N/A") }}
					</div>
				</div>
				<div class="filter">
					<div class="label">公布类型</div>
					<div class="input">
						{{ info.publicationKind || "N/A" }}
					</div>
				</div>
				<div class="filter">
					<div class="label">国际专利分类</div>
					<div class="input">
						{{ info.patentTypeDescription || "N/A" }}
					</div>
				</div>
				<div class="filter">
					<div class="label">申请人</div>
					<div class="input" v-html="info.applicant || 'N/A'">
						<!-- {{ info.applicant || "N/A" }} -->
					</div>
				</div>
				<div class="filter">
					<div class="label">发明人</div>
					<div class="input" v-html="info.inventor || 'N/A'">
						<!-- {{ info.inventor || "N/A" }} -->
					</div>
				</div>
				<div class="filter">
					<div class="label">代理人</div>
					<div class="input" v-html="info.attorney || 'N/A'">
						<!-- {{ info.attorney || "N/A" }} -->
					</div>
				</div>
				<div class="filter">
					<div class="label">相关适体链接</div>
					<div class="input">
						<!-- {{ info.aptamerLink }} -->
						<ul v-if="info.aptamers && info.aptamers.length > 0">
							<li
								v-for="item in info.aptamers"
								:key="item.aptamerID"
								style="margin-left: 20px;"
							>
								<a
									href="javascript:void(0)"
									@click="toAptamerDetail(item.aptamerID)"
									>{{ item.aptamerName || "暂无名称" }}</a
								>
							</li>
						</ul>
						<div v-else>N/A</div>
					</div>
				</div>
				<div class="filter" v-if="isLogin">
					<div
						class="sm-btn"
						@click="$emit('favoritechange', 1)"
						v-if="!favorite"
					>
						收 藏
					</div>
					<div
						class="sm-btn"
						@click="$emit('favoritechange', 0)"
						v-if="favorite"
					>
						已收藏
					</div>
				</div>
				<!-- <el-button type="primary" plain>收藏</el-button> -->
			</div>
		</form>
	</div>
</template>

<script>
export default {
	props: ["info", "favorite", "isLogin"],
	data() {
		return {}
	},
	methods: {
		toAptamerDetail(id) {
			this.$router.push("/aptamerdb/" + id)
		}
	}
}
</script>

<style lang="less" scoped>
.filter-panel-container {
	width: 100%;
	background-color: #ffffff;
	display: inline-block;
}

.main-filter-panel {
	margin: 20px;
	border-bottom: 1px solid #f0f0f0;
}

.filter {
	margin-bottom: 10px;
	text-align: left;
}

.filter .title {
	.c-font-family-PF();
	.c-font-size-16();
	font-weight: bolder;
	line-height: 20px;
	color: #3463ea;
}

.filter .label {
	.c-font-size-16();
	.c-font-family-PF();
	font-weight: bolder;
	line-height: 20px;
	color: #333333;
}

.filter .input {
	.c-font-family-PF();
	.c-font-size-16();
	word-break: break-all;
	word-wrap: break-word;
	line-height: 27px;
}

.filter .input select {
	height: 30px;
	width: 98%;
	background-color: #f1f2f7;
	border-radius: 2px;
	border: none;
	text-indent: 2px;
}

.funding-panel {
	margin: 10px;
	text-align: left;
}

.funding-panel .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	line-height: 20px;
	color: #333333;
}

.funding-panel .select-panel {
	height: 90px;
	display: flex;
	flex-direction: column;
}

.funding-panel .select-panel .input {
	height: 20px;
	margin-top: 10px;
	margin-left: 6px;
	display: flex;
	flex-direction: row;
}

.funding-panel .select-panel .input input {
	margin: auto 4px;
}

.funding-panel .select-panel .input .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	color: #333333;
	line-height: 20px;
}

.control-panel {
	margin: 10px;
	display: flex;
	flex-direction: row;
}

.submit button {
	width: 160px;
	height: 30px;
	color: #ffffff;
	background-color: #3463ea;
	border-radius: 2px;
	border: none;
}

.reset {
	margin-left: auto;
	margin-right: 0;
}

.reset button {
	width: 100px;
	height: 30px;
	color: #333333;
	background-color: #cbcbcb;
	border-radius: 2px;
	border: none;
	text-align: center;
}
.sm-btn {
	width: 60px;
	height: 30px;
	top: 7px;
	right: 0;
	line-height: 30px;
	.c-font-size-14();
	.c-font-family-PF();
	font-weight: 400;
	color: #3463ea;
	text-align: center;

	cursor: pointer;
	border: 1px solid #3463ea;
	border-radius: 2px;
}
</style>
