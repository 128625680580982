<template>
	<div class="con">
		<div class="left-panel">
			<div class="title-con">
				<h2 class="title">{{ detail.title }}</h2>
				<span class="sm-btn" @click="addFavorite" v-if="!favorite && isLogin">
					收 藏
				</span>
				<span class="sm-btn" @click="removeFavorite" v-if="favorite && isLogin">
					已收藏
				</span>
			</div>
			<table class="fields-con">
				<tr class="gray-row">
					<td class="field-name">项目编号：</td>
					<td class="field-value">{{ detail.projectNumber || "-" }}</td>
					<td class="field-name">资助机构：</td>
					<td class="field-value">{{ detail.sponsorInstitution || "-" }}</td>
				</tr>
				<tr class="normal-row">
					<td class="field-name">项目负责人：</td>
					<td class="field-value">{{ detail.leader || "-" }}</td>
					<td class="field-name">经费来源：</td>
					<td class="field-value">{{ detail.source || "-" }}</td>
				</tr>
				<tr class="gray-row">
					<td class="field-name">项目参与人：</td>
					<td class="field-value">{{ detail.participants || "-" }}</td>
					<td class="field-name">项目类型：</td>
					<td class="field-value">{{ detail.projectType || "-" }}</td>
				</tr>
				<tr class="normal-row">
					<td class="field-name">承担机构：</td>
					<td class="field-value">{{ detail.leaderInstitution || "-" }}</td>
					<td class="field-name">项目等级：</td>
					<td class="field-value">{{ detail.projectLevel || "-" }}</td>
				</tr>
				<tr class="gray-row">
					<td class="field-name">开始日期：</td>
					<td class="field-value">
						{{ detail.beginDate || "-" }}
					</td>
					<td class="field-name">持续时间：</td>
					<td class="field-value">{{ detail.durationTime }}</td>
				</tr>
				<tr class="normal-row">
					<td class="field-name">结束日期：</td>
					<td class="field-value">
						{{ detail.endDate || "-" }}
					</td>
					<td class="field-name">项目状态：</td>
					<td class="field-value">{{ detail.projectStatus || "-" }}</td>
				</tr>
				<tr class="gray-row">
					<td class="field-name">经费金额（元）：</td>
					<td class="field-value">{{ detail.budgetsCNY && detail.budgetsCNY != "0" ? parseFloat(detail.budgetsCNY).toFixed(2) : "-" }}</td>
					<td class="field-name">国家：</td>
					<td class="field-value">{{ countryName || "-" }}</td>
				</tr>
				<tr class="normal-row">
					<td class="field-name">学科领域：</td>
					<td class="field-value">{{ detail.researcherAreas || "-" }}</td>
					<td class="field-name">语言：</td>
					<td class="field-value">{{ detail.language || "-" }}</td>
				</tr>
			</table>
		</div>
		<div class="right-panel">
			<div class="title-con">
				<h2 class="leader-name">{{ detail.leader || "暂无负责人" }}</h2>
			</div>
			<div class="relate-projects-con" v-if="isShowRelated">
				<a
					class="relate-item"
					:href="'/project/' + p.projectId"
					v-for="p in detail.relateProjectItems"
					:key="p.projectId"
					>{{ p.title }}</a
				>
			</div>
			<div v-else class="noRelatedTip">
				暂无相关项目
			</div>
			<el-row style="text-align: right;padding-right: 20px;">
				<a
					v-if="isShowMore"
					:href="
						'/project?searchtype=2&leader=' +
						detail.leader +
						'&leaderInstitution=' +
						detail.leaderInstitution
					"
					>更多></a
				>
			</el-row>
			<!-- <a :href="'/project?searchtype=1&keyword=' + detail.leader" class="more"></a> -->
		</div>
	</div>
</template>
<script>
import { ApiClient, ProjectApi, UserCenterApi } from "../../api"
import Cookie from "js-cookie"
import moment from "moment"
export default {
	data() {
		return {
			api: new ProjectApi(ApiClient.instance),
			userCenterApi: new UserCenterApi(ApiClient.instance),
			detail: {},
			favorite: false,
			isLogin: false
		}
	},
	computed: {
		countryName: function () {
			return this.detail.country === undefined ? "" : this.detail.country.name
		},
		isShowMore: function () {
			return this.detail.totalRelateCount > 6
		},
		isShowRelated() {
			return this.detail.totalRelateCount > 0
		},
		projectId() {
			return this.$route.params.id
		}
	},
	mounted() {
		var id = this.$route.params.id
		this.api.apiProjectIdGet(id, (error, data, response) => {
			if (error) {
				console.log(error)
			}
			if (data.success) {
				this.detail = data.result
				if (this.detail.beginDate && this.detail.endDate) {
					var time1 = moment(this.detail.beginDate)
					var time2 = moment(this.detail.endDate)
					var days = (time2.diff(time1) / (1000 * 60 * 60 * 24 * 365)).toFixed(1) + "年"
					this.$set(this.detail, "durationTime", days)
				} else {
					this.$set(this.detail, "durationTime", "-")
				}
				this.detail.beginDate = this.dateFormat(this.detail.beginDate, "YYYY-MM-DD")
				this.detail.endDate = this.dateFormat(this.detail.endDate, "YYYY-MM-DD")
			}
		})
		var user = Cookie.getJSON("user_info")
		if (user?.account) {
			this.isLogin = true
		}
		if (this.isLogin) {
			this.getFavoriteStatus()
		}
	},
	methods: {
		dateFormat(val, format) {
			if (val) {
				return this.$options.filters.dateFormat(val, format)
			} else {
				return "-"
			}
		},
		addFavorite() {
			this.userCenterApi.apiUserCenterFavoritePost(
				{
					collectionID: this.projectId,
					collectionType: 4
				},
				(e, data, resp) => {
					this.getFavoriteStatus()
				}
			)
		},
		removeFavorite() {
			this.userCenterApi.apiUserCenterUnfavoriteDelete(
				this.projectId,
				4,
				null,
				() => {
					this.getFavoriteStatus()
				}
			)
		},
		getFavoriteStatus() {
			this.userCenterApi.apiUserCenterCollectedGet(
				this.projectId,
				(e, data, resp) => {
					if (data.success) {
						this.favorite = data.result
					}
				}
			)
		}
	}
}
</script>

<style scoped lang="less">
.con {
	display: flex;
	width: 1170px;
	height: 500px;
	margin: 20px auto;

	.left-panel {
		width: 860px;
		height: 100%;
		background: white;
		box-shadow: #bbb7b7 0px 0px 5px;
		display: inline-block;
		.title-con {
			position: relative;
			min-height: 34px;
			padding-top: 1px;
			.title {
				padding-left: 20px;
				border-left: 3px solid blue;
				margin: 30px;
				max-width: 730px;
			}
			.sm-btn {
				position: absolute;
				right: 40px;
				top: 30px;
				width: 58px;
				height: 26px;
				border-radius: 3px;
				border: 1px solid blue;
				color: blue;
				text-align: center;
				cursor: pointer;
			}
		}
		.fields-con {
			border-collapse: collapse;
			margin-left: 30px;
			margin-right: 30px;

			.gray-row {
				background: #f3f3f3;
			}
			.field-name {
				color: #4e75ec;
				padding-left: 20px;
				width: 120px;
			}
			tr {
				height: 43px;
			}
			.field-value {
				color: black;
				padding-left: 20px;
				width: 280px;
			}
		}
	}
	.right-panel {
		display: inline-block;
		margin-left: 20px;
		width: 290px;
		height: 100%;
		box-shadow: #bbb7b7 0px 0px 5px;
		background: white;
		.title-con {
			position: relative;
			.leader-name {
				margin: 20px;
				color: #000000;
			}
		}
		.relate-item {
			margin: 20px;
			// color: #333333;
			text-decoration: underline;
			display: block;
		}
		.noRelatedTip {
			margin-left: 20px;
		}
	}
}
</style>
