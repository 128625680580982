<template>
	<div class="main-panel">
		<div class="top-bar">
			<div>核酸适配体列表</div>
			<p>* 推荐打分公式：sum(IF * 完整度) / 靶标数量</p>
		</div>
		<div class="recommended">
			<el-table
				:data="tableData"
				border
				style="width: 100%"
				class="dataTable"
				@row-click="rowClick"
				v-loading="loading"
				element-loading-text="结果加载中。。。"
				empty-text="-"
			>
				<el-table-column align="center" prop="name" label="名称">
					<template slot="header">
						<img src="../../assets/evaluationname.png" />名称
					</template>
					<template slot-scope="{ row }">
						<p v-html="row.name || '-'"></p>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="target" label="靶标">
					<template slot="header">
						<img src="../../assets/evaluationtarget.png" />靶标
					</template>
					<template slot-scope="{ row }">
						<p v-html="row.target || '-'"></p>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="affinity" label="亲和力(单位：nm)">
					<template slot="header">
						<img src="../../assets/evaluationaffinity.png" />亲和力(单位：nm)
					</template>
					<template slot-scope="{ row }">
						<p v-html="(row.affinity && parseFloat(row.affinity) > 0) ? parseFloat(row.affinity).toFixed(2) : '-'"></p>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="length" label="长度">
					<template slot="header">
						<img src="../../assets/evaluationlength.png" />长度
					</template>
					<template slot-scope="{ row }">
						<p v-html="row.length || '-'"></p>
					</template>
				</el-table-column>
				<!-- <el-table-column align="center" prop="similar" label="相似度">
					<template slot="header">
						<img src="../../assets/evaluationsimilar.png" />相似度
					</template>
					<template slot-scope="{ row }">
						<p>{{row.similar ? row.similar.toFixed(2) : "-"}}</p>
					</template>
				</el-table-column> -->
				<el-table-column align="center" prop="score" label="推荐评分">
					<template slot="header">
						<img src="../../assets/evaluationscore.png" />推荐评分
					</template>
					<template slot-scope="{ row }">
						<p v-html="row.score || '-'"></p>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>
<script>
import { ApiClient, SequenceApi } from "../../api"
export default {
	data() {
		return {
			sequenceApi: new SequenceApi(ApiClient.instance),
			tableData: [],
			target: "",
			loading: false
		}
	},
	mounted() {
		this.target = this.$route.query.keyword || ""
		if (this.target) {
			this.getData()
		}
	},
	methods: {
		rowClick(row, column, event) {
			this.$router.push({ path: "/AptamerEvaluationList/" + row.aptamerID + "?sbjctid=" + this.tableData[0].aptamerID })
		},
		getData() {
			// console.log(this.target)
			if (this.target.length > 0) {
				this.loading = true
				this.sequenceApi.apiSequenceEvaluatecontrastGet({ target: this.target }, (err, data, resp) => {
					if (err) {
						console.log(err)
					}
					if (data.success) {
						// console.log(data)
						const pattern = /(?<type1>\*{1,2})(?<item>[A-Za-z0-9\-\+]+)(\1)(?<type2>\*{1,2})?/g
						this.tableData = data.result.records.map(item => {
							var res = item
							var matches = res.target.matchAll(pattern)
							for (const match of matches) {
								// console.log(match, match.groups)
								var type = match.groups.type1
								if (type === "**") {
									res.target = res.target.replace(
										match[0],
										"<sup>" + match.groups.item + "</sup>"
									)
								} else {
									res.target = res.target.replace(
										match[0],
										"<sub>" + match.groups.item + "</sub>"
									)
								}
							}
							var namematches = item.name.matchAll(pattern)
							for (const match of namematches) {
								// console.log(match, match.groups)
								var nametype = match.groups.type1
								if (nametype === "**") {
									res.name = res.name.replace(
										match[0],
										"<sup>" + match.groups.item + "</sup>"
									)
								} else {
									res.name = res.name.replace(
										match[0],
										"<sub>" + match.groups.item + "</sub>"
									)
								}
							}
							if (item.hsps) {
								this.$set(res, "similar", item.hsps[0].identicalPercent * 100)
							} else {
								this.$set(res, "similar", 0)
							}
							this.$set(res, "hsps", item.hsps)
							return res
						})
						localStorage.setItem("evaluationContrastInfo", JSON.stringify({ target: this.target, data: this.tableData }))
						console.log(this.tableData)
					}
					this.loading = false
				})
				// }
			}
		}
	},
	watch: {
		$route(to, from) {
			this.target = to.query.keyword || ""
			if (this.target) {
				this.getData()
			}
		}
	},
	components: {}
}
</script>
<style lang="less" scoped>
* {
	margin: 0;
	padding: 0;
}
.main-panel {
	height: auto;
	padding-bottom: 20px;
}

.dataTable {
	border: 1px solid #dbdbdb;

	/deep/ .el-table__header-wrapper {
		tr {
			min-height: 49px;
		}
		th {
			background-color: #3463E9;
			color: #FFFFFF;
			font-weight: 400;
			font-size: 16px;
		}
		img {
			margin-right: 10px;
			vertical-align: middle;
		}
	}

	/deep/ .el-table__body-wrapper {
		tr {
			min-height: 49px;
		}
		.el-table__row td {
			color: #333333;
			font-weight: 400;
			font-size: 16px;
			padding-top: 12px;
			padding-bottom: 12px;
		}
	}
}
/deep/.el-table__row > :first-child {
	color: #3462e9 !important;
}
// /deep/.el-table__row td {
// 	font-size: 14px;
// 	font-weight: 400;
// 	color: #333333;
// }
/deep/.el-table__row {
	cursor: pointer;
}
// /deep/.has-gutter th {
// 	font-size: 14px;
// 	font-weight: 400;
// 	color: #333333;
// }
// ul {
// 	list-style: none;
// }
// /deep/.el-table .cell {
// 	text-align: center;
// }
.top-bar {
	width: 100%;
	background: #ffffff;
	border: 1px solid #dbdbdb;
	opacity: 1;
	border-radius: 0px;
	margin-bottom: 23px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	p {
		font-size: 12px;
		color: #666666;
		padding-right: 20px;
	}
	div {
		height: 45px;
		font-size: 16px;
		font-weight: 400;
		color: #333333;
		opacity: 1;
		padding: 12px 0 13px 12px;
	}
}
.recommended {
	width: 100%;
	height: auto;
	background: #ffffff;
	border: 1px solid #dbdbdb;
	opacity: 1;
	border-radius: 0px;
	margin-bottom: 23px;
	ul {
		width: 100%;
		height: 100%;
		display: flex;
		li {
			width: 18%;
			height: 100%;
			div {
				height: 50%;
				font-size: 16px;
				font-weight: 400;
				text-align: center;
				color: #333333;
				opacity: 1;
				line-height: 49px;
			}
		}
	}
}
</style>
