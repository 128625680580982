<template>
    <div class="mainPanel">
        <el-row class="timeRow">{{ data.time }}</el-row>
        <el-row class="titleRow">{{ data.title }}</el-row>
        <el-row class="imgRow" v-if="data.relativeImgPath">
            <img :src="imgPath + data.relativeImgPath" />
        </el-row>
        <el-row class="authorRow">{{ data.authorsStr }}</el-row>
        <el-row class="contentRow" v-html="data._abstract"></el-row>
        <el-row class="journalRow">Journal: {{ data.journal }}</el-row>
        <el-row class="urlRow">Publisher URL: <span @click="openLink(data.publisherUrl)">{{ data.publisherUrl }}</span></el-row>
        <el-row class="doiRow">DOI: {{ data.doi }}</el-row>
    </div>
</template>

<script>
import { ApiClient, FeedsArticleApi } from "../../api"
export default {
	data() {
		return {
			feedsArticleApi: new FeedsArticleApi(ApiClient.instance),
			id: null,
			imgPath: "",
			data: {
				authors: "",
				batchId: "",
				createOn: "",
				createdDate: "",
				dataId: "",
				doi: "",
				id: "",
				imageUrl: "",
				journal: "",
				matchedFilter: "",
				relativeImgPath: "",
				state: 0,
				title: "",
				_abstract: "",
				time: "",
				authorsStr: ""
			}
		}
	},
	created() {
		this.imgPath = process.env.VUE_APP_IMG_API + "feeds_article_img/"
		this.id = this.$route.params.id
		this.getInfo()
	},
	methods: {
		openLink(path) {
			window.open(path)
		},
		getInfo() {
			var nowTime = new Date().getTime()
			this.feedsArticleApi.apiFeedsArticleIdGet(this.id, (err, data, resp) => {
				if (err) {
					console.log(err)
				}
				if (data.success) {
					this.data = data.result
					var aus = ""
					if (this.data.authors) {
						JSON.parse(this.data.authors).forEach((str, index) => {
							aus += (index > 0 ? "，" : "") + str
						})
					}
					this.data.authorsStr = aus
					var hours = Math.floor((nowTime - this.data.createdDate * 1000) / (1000 * 60 * 60))
					if (hours === 0) {
						hours = 1
					}
					if (hours < 24) {
						this.data.time = hours + "小时前"
					} else if (hours >= 24 && hours < 48) {
						this.data.time = "一天前"
					} else if (hours >= 48 && hours < 72) {
						this.data.time = "二天前"
					} else {
						this.data.time = this.$options.filters.dateFormat(new Date(this.data.createdDate * 1000), "YYYY-MM-DD")
					}
				}
			})
		}
	}
}
</script>

<style lang="less" scoped   >
.mainPanel {
    width: 1170px;
    margin: 0 auto;
    background-color: #fff;
    margin-bottom: 20px;
    padding: 20px 25px;

    .timeRow {
        font-size: 16px;
        color: #999;
    }

    .titleRow {
        font-size: 22px;
        font-weight: bold;
        color: #333;
        margin-top: 20px;
    }

    .imgRow {
        margin: 0 auto;
        margin-top: 30px;
        text-align: center;
        width: 100%;
        height: 460px;
        position: relative;
        text-align: center;
        img{
            height: 100%;
            max-width: 100%;
            background: #E2E2E2;
            border-radius: 5px 5px 5px 5px;
            opacity: 1;
            border: 1px solid #DBDBDB;
        }
    }

    .authorRow {
        font-size: 18px;
        color: #999;
        margin-top: 40px;
    }

    .contentRow {
        font-size: 18px;
        color: #333;
        margin-top: 30px;
    }

    .journalRow {
        font-size: 18px;
        color: #333;
        margin-top: 10px;
    }

    .urlRow {
        font-size: 18px;
        margin-top: 10px;
        color: #333;

        span {
            color: #3463ea;
            word-break: break-all;
            cursor: pointer;
        }
    }

    .doiRow {
        font-size: 18px;
        color: #333;
        margin-top: 10px;
    }
}
</style>
