var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    ref: "myChart2",
    staticStyle: { height: "350px", width: "92%", margin: "15px auto" },
    attrs: { id: "myChart2" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }