<template>
	<div class="main-panel">
		<div class="left-panel">
			<div class="statistic-panel">
				<projectdata
					:info="info"
					:favorite="favorite"
					@favoritechange="favoriteChange"
					:isLogin="isLogin"
				></projectdata>
			</div>
		</div>
		<div class="list-panel">
			<contentdata :content="content"></contentdata>
		</div>
	</div>
</template>

<script>
import projectdata from "./detail/projectdata.vue"
import contentdata from "./detail/content.vue"
import { ApiClient, PatentApi, UserCenterApi } from "../../api"
import Cookie from "js-cookie"

export default {
	data() {
		return {
			id: "",
			patentApi: new PatentApi(ApiClient.instance),
			usercenterApi: new UserCenterApi(ApiClient.instance),
			favorite: false, // 是否已收藏
			info: {
				patentOffice: "", // 专利局
				applicationNo: "", // 申请号
				applicationUser: "", // 申请人
				applicaitonDate: "", // 申请日
				publishNo: "", // 公布号
				publishDate: "", // 公布日
				publishType: "", // 公布类型
				patentClassify: "", // 国际专利分类
				inventor: "", // 发明人
				agent: "", // 代理人
				link: "" // 相关适体链接
			},
			content: {
				_abstract: "", // 摘要
				figures: [], // 图片
				instructions: "", // 说明书
				claims: "" // 权利要求书
			},
			isLogin: false
		}
	},
	methods: {
		getData() {
			this.patentApi.apiPatentIdGet(this.id, (error, data, response) => {
				// console.log("专利信息查询请求")
				// console.log(response)
				if (error) {
					console.log(error)
				} else {
					var abstract = ""
					if (data.result._abstract) {
						abstract = data.result._abstract.replace(
							new RegExp("src=\"patent/", "gm"),
							"src=\"" + process.env.VUE_APP_IMG_API + "patent/"
						)
						abstract = abstract.replace(new RegExp("font", "gm"), "span")
					} else {
						abstract = "暂无摘要信息！"
					}
					var instructions = ""
					if (data.result.instructions) {
						instructions = data.result.instructions.replace(
							new RegExp("src=\"patent/", "gm"),
							"src=\"" + process.env.VUE_APP_IMG_API + "patent/"
						)
						instructions = instructions.replace(new RegExp("font", "gm"), "span")

						instructions = instructions.replace(new RegExp("width=\"520\"", "gm"), "style=\"width:800px;font-size:28px;\"")
					} else {
						instructions = "暂无说明书信息！"
					}
					// console.log(instructions)
					// console.log(data.result.instructions)
					var claims = ""
					if (data.result.claims) {
						claims = data.result.claims.replace(
							new RegExp("src=\"patent/", "gm"),
							"src=\"" + process.env.VUE_APP_IMG_API + "patent/"
						)
						claims = claims.replace(new RegExp("font", "gm"), "span")
					} else {
						claims = "暂无权利要求书信息！"
					}
					this.content = {
						_abstract: abstract,
						figures: data.result.figures.map((item) => {
							return process.env.VUE_APP_IMG_API + item
						}),
						instructions: instructions,
						claims: claims
					}
					// this.content = {
					// 	_abstract: data.result._abstract ? data.result._abstract.replace(
					// 		new RegExp("src=\"patent/", "gm"),
					// 		"src=\"" + process.env.VUE_APP_IMG_API + "patent/"
					// 	) : "暂无摘要信息！",
					// 	figures: data.result.figures.map((item) => {
					// 		return process.env.VUE_APP_IMG_API + item
					// 	}),
					// 	instructions: data.result.instructions ? data.result.instructions.replace(
					// 		new RegExp("src=\"patent/", "gm"),
					// 		"src=\"" + process.env.VUE_APP_IMG_API + "patent/"
					// 	) : "暂无说明书信息！",
					// 	claims: data.result.claims ? data.result.claims.replace(
					// 		new RegExp("src=\"patent/", "gm"),
					// 		"src=\"" + process.env.VUE_APP_IMG_API + "patent/"
					// 	) : "暂无权利要求书信息！"
					// }
					// console.log(this.content._abstract, process.env.VUE_APP_IMG_API)

					var result = data.result
					this.$delete(result, "_abstract")
					this.$delete(result, "figures")
					this.$delete(result, "instructions")
					this.$delete(result, "claims")
					console.log(result)
					result.applicant = result.applicant ? result.applicant.replace(new RegExp("\\n", "gm"), "<br>") : ""
					result.inventor = result.inventor ? result.inventor.replace(new RegExp("\\n", "gm"), "<br>") : ""
					result.attorney = result.attorney ? result.attorney.replace(new RegExp("\\n", "gm"), "<br>") : ""
					// console.log(result)
					this.info = result
				}
			})
		},
		addFavorite() {
			this.usercenterApi.apiUserCenterFavoritePost(
				{
					collectionID: this.id,
					collectionType: 1
				},
				(e, data, resp) => {
					this.getFavoriteStatus()
				}
			)
		},
		removeFavorite() {
			this.usercenterApi.apiUserCenterUnfavoriteDelete(this.id, 1, null, () => {
				this.getFavoriteStatus()
			})
		},
		getFavoriteStatus() {
			this.usercenterApi.apiUserCenterCollectedGet(this.id, (e, data, resp) => {
				if (data.success) {
					this.favorite = data.result
				}
			})
		},
		favoriteChange(val) {
			if (val === 1) {
				this.addFavorite()
			} else {
				this.removeFavorite()
			}
		}
	},
	created: function () {
		if (this.$route.params.id) {
			this.id = this.$route.params.id
			this.getData()
			var user = Cookie.getJSON("user_info")
			if (user?.account) {
				this.isLogin = true
			}
			if (this.isLogin) {
				this.getFavoriteStatus()
			}
		} else {
			alert("专利信息获取失败！")
			this.$router.go(-1)
		}
	},
	components: {
		projectdata,
		contentdata
	}
}
</script>

<style lang="less" scoped>
.main-panel {
	width: 1170px;
	height: 100%;
	min-height: 100vh;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
}

.left-panel {
	width: 290px;
	// height: 236px;
}

.statistic-panel {
	height: 100%;
	width: 100%;
	background-color: #ffffff;
}

.filter-panel {
	width: 100%;
	height: 100%;
	margin-top: 20px;
	background-color: #ffffff;
}

.list-panel {
	width: 860px;
	height: 100%;
	margin-left: 20px;
}
</style>
