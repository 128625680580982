<template>
	<div class="team-list-panel">
		<!--内容-->
		<div class="top-bar-container">
			<div class="top-bar-container-title">{{ topicInfo.title }}</div>
			<div class="result">
				{{ topicInfo.content }}
			</div>
			<el-row style="text-align: right">
				发布人：{{ topicInfo.createUserName }}，发布时间：{{
					topicInfo.createOn | dateFormat("YYYY-MM-DD")
				}}
			</el-row>
		</div>
		<!--评论-->
		<div class="team-list">
			<div class="team-container">
				<div class="team" style="position: relative">
					<div class="commentLabel">参与评论</div>
					<div style="border: 1px solid #dbdbdb;position: relative">
						<el-input
							maxlength="1000"
							class="el-input_inner"
							type="textarea"
							placeholder="发表评论..."
							rows="3"
							show-word-limit
							v-model="commentStr"
						>
						</el-input>
						<div class="commentSubmit">
							<div style="width: 50px">
								<img
									style="
										width: 40px;
										height: 40px;
										border-radius: 50%;
										vertical-align: middle;
									"
									:src="avator"
								/>
							</div>
							<div
								class="c-font-size-18"
								style="width: 426px; font-weight: 500; color: #333333"
							>
								<span>{{ nickname }}</span>
							</div>
							<div style="width: 630px; text-align: right">
								<button @click="commentSubmit">发布</button>
							</div>
						</div>
						<div v-if="!isLogin" class="toLogin">
							<button @click="toLigin">&gt;&gt; 登录后开启发布评论 &lt;&lt;</button>
						</div>
					</div>

					<div class="commentLabel">评论区</div>
					<commentPanel
						v-for="(commentInfo, i) in comments"
						@commentSub="commentSub"
						:key="i"
						:commentInfo="commentInfo"
						:isFirst="true"
						:index="i"
						:commentStr="commentStr"
						:isLogin="isLogin"
					></commentPanel>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import commentPanel from "../../paper/detail/commentpanel.vue"
import {
	ApiClient,
	ArticleApi,
	LearnTopicApi,
	UserCenterApi
} from "../../../api"
import Cookie from "js-cookie"
import Swal from "sweetalert2"
export default {
	props: ["title"],
	data() {
		return {
			articleApi: new ArticleApi(ApiClient.instance),
			learnTopicApi: new LearnTopicApi(ApiClient.instance),
			usercenterApi: new UserCenterApi(ApiClient.instance),
			avator: "",
			nickname: "",
			topicId: "",
			topicInfo: {},
			comments: [],
			commentStr: "",
			isLogin: false
		}
	},
	mounted() {
		this.avator =
			"data:image/png;base64," + JSON.parse(localStorage.getItem("avator"))
		// this.nickname = Cookie.getJSON("user_info").nickname
		var user = Cookie.getJSON("user_info")
		if (user?.account) {
			this.isLogin = true
			this.nickname = user.nickname
		}
		this.topicId = this.$route.params.id
		this.getLearnTopic(this.topicId)
		this.getComments()
	},
	methods: {
		commentSub(opts) {
			this.commentSubmit(opts)
		},
		getLearnTopic(id) {
			this.learnTopicApi.apiLearnTopicDetailGet(id, (e, data, resp) => {
				if (data.success) {
					this.topicInfo = data.result
				}
			})
		},
		// 提交评论
		commentSubmit(opts) {
			// console.log(opts)
			opts.articleID = this.topicId
			opts.content = opts.commentStr || this.commentStr
			opts.commentType = 1
			this.usercenterApi.apiUserCenterCommentPost(
				opts,
				(error, data, response) => {
					// console.log("评论提交成功")
					// console.log(response)
					if (error) {
						console.log(error)
					} else {
						Swal.fire("发布成功，请等待管理员审核！").then(() => {
							this.commentStr = ""
							this.getComments()
						})
					}
				}
			)
		},
		comment(data) {
			console.log("detail页面接收到评论信息")
			var opts = {
				articleID: this.articleId,
				parentID: data.parentID,
				userID: null,
				content: data.commentStr
			}
			console.log(opts)
			this.commentSubmit(opts)
		},
		getComments() {
			this.articleApi.apiArticleCommentsGet(
				{ articleid: this.topicId },
				(error, data, response) => {
					console.log("评论查询成功")
					console.log(response)
					if (error) {
						console.log(error)
					} else {
						console.log(data)
						this.comments = data.result.comments
					}
				}
			)
		},
		toLigin() {
			document.getElementById("loginSpan").click()
		}
	},
	components: {
		commentPanel
	}
}
</script>

<style lang="less" scoped>
.team-list-panel {
	width: 1170px;
	margin: 0 auto;
	color: #333333;
}

.top-bar-container {
	width: 100%;
	background-color: #ffffff;
	display: inline-block;
	padding: 30px;
}

.top-bar-container-title {
	width: 1032px;
	margin: 0 auto;
	margin-bottom: 20px;
	text-align: center;
	.c-font-size-18();
	font-weight: 500;
}

.result {
	.c-font-size-14();
	font-weight: 400;
	margin-bottom: 20px;
}

.imgs {
	margin: 10px;
	width: 256px;
	height: 256px;
}

.comFlexDiv {
	display: flex;
	align-items: center;
}

.commentLabel {
	.c-font-size-18();
	font-weight: 500;
	margin-top: 10px;
	margin-bottom: 10px;
}

.commentSubmit {
	width: 100%;
	background-color: rgb(239, 239, 239);
	display: flex;
	align-items: center;
	padding: 10px;
}
.commentSubmit button {
	color: #fff;
	.c-font-size-16();
	font-weight: 500;
	width: 122px;
	height: 38px;
	background: #3462e9;
	opacity: 1;
	border-radius: 3px;
	border: none;
}

.comBorder {
	border-top: 2px solid rgb(239, 239, 239);
}

.tab-tilte {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	list-style: none;
	white-space: nowrap;
	transition: width 0.5s ease;
}

.tab-tilte li {
	padding: 10px 20px;
	.c-font-size-14();
	.c-font-family-PF();
	font-weight: bolder;
	line-height: 20px;
	color: #333333;
}

.active {
	border-bottom: 2px solid #3463ea;
}

.imgpanel {
	width: 80px;
	height: 80px;
	margin: 16px 0 0 16px;
}

.team-list {
	width: 100%;
	min-height: 500px;
}

.team-container {
	width: 100%;
	display: inline-flex;
	background-color: #ffffff;
	margin-top: 20px;
}

.team {
	width: 100%;
	margin: 16px;
	font-family: PingFang-SC-Medium;
	.c-font-size-14();
}

.team .title {
	.c-font-size-12();
	color: #3463ea;
	line-height: 20px;
	text-align: left;
}

.team .title a {
	text-decoration: none;
	.c-font-size-16();
}
.team .title a:visited {
	color: #3463ea;
}

.team .entry a:visited {
	color: #3463ea;
}

.team .location {
	.c-font-size-12();
	line-height: 20px;
	display: flex;
	flex-direction: row;
	text-align: left;
}

.team .location .entry {
	display: flex;
	flex-direction: row;
	margin-right: 20px;
}

.team .location .entry .value {
	margin-left: 6px;
}

.team .link {
	display: flex;
	flex-direction: row;
	.c-font-size-12();
	line-height: 20px;
}

.team .link .url {
	margin-left: 6px;
}

.pagination {
	display: flex;
	justify-content: center;
	margin: 10px auto;
}
</style>
