var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "favorite-container" },
    [
      _c(
        "div",
        { staticClass: "nav-bar" },
        [
          _c(
            "router-link",
            {
              staticClass: "item",
              class: { active: _vm.current === "aptamer" },
              attrs: { to: "/usercenter/favorites/aptamer" },
            },
            [_vm._v("适配体")]
          ),
          _c(
            "router-link",
            {
              staticClass: "item",
              class: { active: _vm.current === "article" },
              attrs: { to: "/usercenter/favorites/article" },
            },
            [_vm._v("论文")]
          ),
          _c(
            "router-link",
            {
              staticClass: "item",
              class: { active: _vm.current === "patent" },
              attrs: { to: "/usercenter/favorites/patent" },
            },
            [_vm._v("专利")]
          ),
          _c(
            "router-link",
            {
              staticClass: "item",
              class: { active: _vm.current === "clinical" },
              attrs: { to: "/usercenter/favorites/clinical" },
            },
            [_vm._v("临床进展")]
          ),
          _c(
            "router-link",
            {
              staticClass: "item",
              class: { active: _vm.current === "team" },
              attrs: { to: "/usercenter/favorites/team" },
            },
            [_vm._v("重要团队")]
          ),
          _c(
            "router-link",
            {
              staticClass: "item",
              class: { active: _vm.current === "project" },
              attrs: { to: "/usercenter/favorites/project" },
            },
            [_vm._v("科研项目")]
          ),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }