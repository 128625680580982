var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-panel main-list" }, [
    _c(
      "div",
      { staticClass: "list-panel" },
      [
        _vm._l(_vm.dataList, function (i, index) {
          return _c(
            "el-row",
            {
              key: index,
              staticClass: "dynamicItem",
              style: { marginTop: (index > 0 ? 20 : 0) + "px" },
            },
            [
              _c("el-row", { staticClass: "timeRow" }, [
                _vm._v(" " + _vm._s(i.time) + " "),
              ]),
              _c(
                "div",
                {
                  staticClass: "titleRow ellipsisContent",
                  on: {
                    click: function ($event) {
                      return _vm.toDetail(i.id)
                    },
                  },
                },
                [_vm._v(_vm._s(i.title))]
              ),
              _c("el-row", { staticClass: "contentRow" }, [
                i.relativeImgPath
                  ? _c("div", { staticClass: "img" }, [
                      _c("img", {
                        attrs: { src: _vm.imgPath + i.relativeImgPath },
                      }),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "authorcontent" },
                  [
                    _c("el-row", { staticClass: "author ellipsisContent" }, [
                      _vm._v(" " + _vm._s(i.authorsStr) + " "),
                    ]),
                    _c("el-row", {
                      staticClass: "content ellipsisContent",
                      domProps: { innerHTML: _vm._s(i._abstract) },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        }),
        _c(
          "el-row",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              attrs: {
                id: "pagination",
                "current-page": _vm.pageIndex,
                "page-size": _vm.pageSize,
                "page-sizes": [10, 25, 50],
                layout: "prev, pager, next, jumper, sizes",
                total: _vm.totalCount,
              },
              on: {
                "current-change": _vm.queryChange,
                "size-change": _vm.sizeChange,
                "prev-click": _vm.queryChange,
                "next-click": _vm.queryChange,
              },
            }),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }