<template>
	<div class="container">
		<div class="main-filter-panel">
			<div class="main-filter-panel1">
				<div class="main-filter-panel-logo">
					<router-link :to="'/'">
						<img src="@/assets/logo.png" class="logo" />
					</router-link>
				</div>
				<div class="main-filter-panel-Journalism">
					<template>
						<el-carousel
							direction="vertical"
							height="50px"
							:interval="5000"
							autoplay
						>
							<el-carousel-item v-for="(item, index) in newsList" :key="index">
								<div class="vertical">
									<div class="vertial-time">{{ item._date }}</div>
									<div class="vertial-news">
										<a href="javascript:void(0)" @click="openNew(item.link)">{{ item.title }}</a>
									</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</template>
				</div>
			</div>
		</div>
		<div class="main-filter">
			<div class="Map" style="height: 430px;">
				<div class="title">
					<span></span>
					<p>全球适体数据统计</p>
				</div>
				<Aptamerdata :globalStatisticas="globalStatisticas"></Aptamerdata>
			</div>
			<div class="echarts" style="height: 430px;">
				<div class="title">
					<span></span>
					<p>适体类型</p>
				</div>
				<AptamerType
					:dataList="aptamerTypeStatisticas"
					totalDesc="总数"
				></AptamerType>
			</div>
			<div class="echarts" style="height: 430px;">
				<div class="title">
					<span></span>
					<p>样本类型</p>
				</div>
				<SampleType :sampleTypeStatisticas="sampleTypeStatisticas"></SampleType>
			</div>
			<div class="Map" style="height: 400px;">
				<div class="title">
					<span></span>
					<p>适体发现数量统计</p>
				</div>
				<NumberAptamers
					:findCountStatisticas="findCountStatisticas"
				></NumberAptamers>
			</div>
			<div class="echarts" style="height: 400px;">
				<div class="title">
					<span></span>
					<p>应用类型</p>
				</div>
				<ApplicationType
					:dataList="applicationTypeStatisticas"
					totalDesc="总数"
				></ApplicationType>
			</div>
			<div class="echarts" style="height: 400px;">
				<div class="title">
					<span></span>
					<p>数据来源</p>
				</div>
				<AptamerType
					:dataList="dataSourceStatisticas"
					totalDesc="总数"
				></AptamerType>
			</div>
		</div>
	</div>
</template>

<script>
import AptamerType from "@/components/charts/AptamerType.vue"
import ApplicationType from "@/components/charts/ApplicationType.vue"
import { ApiClient, AptamerApi, SysConfigApi } from "@/api"
import NumberAptamers from "@/components/charts/NumberAptamers.vue"
import SampleType from "@/components/charts/SampleType.vue"
import Aptamerdata from "@/components/charts/Aptamerdata.vue"
export default {
	components: {
		AptamerType,
		NumberAptamers,
		SampleType,
		Aptamerdata,
		ApplicationType
	},
	data() {
		return {
			keyWord: "",
			countryFilter: "",
			positionFilter: "",
			iresearchAreaFilter: "",
			fundingFilter: "",
			pageIndex: 0,
			pageSize: 10,
			AptamerApi: new AptamerApi(ApiClient.instance),
			SysConfigApi: new SysConfigApi(ApiClient.instance),
			teams: [],
			resultAmount: 0,
			statistic: [],
			aptamerTypeStatisticas: [],
			globalStatisticas: {},
			findCountStatisticas: {},
			sampleTypeStatisticas: {},
			newsList: [],
			applicationTypeStatisticas: [],
			dataSourceStatisticas: []
		}
	},
	mounted() {
		this.AptamerApi.apiAptamerDatastatisticsGet((error, data, response) => {
			if (error) {
				console.log(error)
			}
			if (data.success) {
				// console.log(data, response)
				this.globalStatisticas = response.body.result.globalStatisticas
				// console.log(this.globalStatisticas)
				this.aptamerTypeStatisticas = response.body.result.aptamerTypeStatisticas
				this.findCountStatisticas = response.body.result.findCountStatisticas
				this.sampleTypeStatisticas = response.body.result.sampleTypeStatisticas
				this.dataSourceStatisticas = response.body.result.dataSourceStatisticas
				this.applicationTypeStatisticas = response.body.result.applicationTypeStatisticas
			}
		})
		this.SysConfigApi.apiSysConfigGetNewsGet((error, data, response) => {
			if (error) {
				console.log(error)
			}
			if (data.success) {
				this.newsList = data.result.newsList
			}
		})
	},
	methods: {
		openNew(link) {
			window.open(link)
		}
	}
}
</script>

<style lang="less" scoped>
.el-checkbox {
	display: block;
	margin: 10px;
}
</style>

<style scoped lang="less">
.container {
	width: 100%;
	background-color: #f2f2f3;
	display: inline-block;
	height: 960px;
	.main-filter {
		width: 96%;
		height: 850px;
		margin: 0 auto;
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.Map {
			width: 46%;
			height: 420px;
			background: #ffffff;
			position: relative;
			.title {
				position: absolute;
				top: 20px;
				left: 20px;
				color: #004098;
				display: flex;
				align-items: center;
				span {
					display: block;
					width: 3px;
					height: 16px;
					background: #2272e1;
					margin-right: 10px;
				}
			}
		}
		.echarts {
			width: 26.2%;
			height: 400px;
			background: #ffffff;
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
			.title {
				position: absolute;
				top: 20px;
				left: 20px;
				color: #004098;
				display: flex;
				align-items: center;
				span {
					display: block;
					width: 3px;
					height: 16px;
					background: #2272e1;
					margin-right: 10px;
				}
			}
		}
	}
	.main-filter-panel {
		width: 100%;
		margin: 0 auto;
		height: 80px;
		background: #ffffff;
		.main-filter-panel1 {
			width: 96%;
			margin: 0 auto;
			display: flex;
			height: 100%;
			justify-content: space-between;
			align-items: center;
			.main-filter-panel-Journalism {
				width: 650px;
				height: 50px;
				background: #f5f5f5;
				.vertical {
					width: 100%;
					height: 50px;
					display: flex;
					justify-content: space-around;
					line-height: 50px;
					padding: 0 20px;
					color: #2c3fa0;
					cursor: pointer;
					.vertial-time {
						width: 16%;
						height: 50px;
						text-align: left;
					}
					.vertial-news {
						width: 84%;
						height: 50px;
						a {
							width: 100%;
							display: block;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
				}
			}
			.main-filter-panel-logo {
				width: 50px;
				height: 50px;
				.logo {
					margin: 0 auto;
					margin-left: 50px;
				}
			}
		}
	}
}
/deep/.el-carousel__indicators--vertical {
	display: none;
}
a {
	text-decoration: none;
	color: #2c3fa0;
}
.carousel-control-prev {
	display: none;
}
.carousel-control-next {
	display: none;
}
.carousel-indicators {
	display: none;
}
</style>
