var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    ref: "myChart3",
    staticStyle: { height: "400px", width: "94%", margin: "15px auto" },
    attrs: { id: "myChart3" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }