<template>
	<div v-if="isFrontPage == false" class="breadcrumb-container">
		<div class="set" v-for="(lp, i) in levelPaths" :key="i">
			<!-- <router-link class="breadcrumb" :to="lp.path">
				{{ lp.name }}
			</router-link> -->
			<span class="breadcrumb">{{ lp.name }}</span>
			<div class="separator" v-if="i + 1 < levelPaths.length">></div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			levelPaths: [],
			isFrontPage: true
		}
	},
	beforeMount() {
		this.updateLevelPaths()
	},
	watch: {
		$route(to, from) {
			this.updateLevelPaths()
		}
	},
	methods: {
		updateLevelPaths() {
			const path = this.$route.path
			const levels = []
			if (path === "/") {
				this.isFrontPage = true
			} else {
				this.isFrontPage = false
				this.getPathLevels(path, levels)
			}
			levels.unshift("/")
			const levelPaths = []
			const routes = this.$router.options.routes
			for (const level of levels) {
				for (const route of routes) {
					if (route.path !== level) {
						continue
					}
					if (route.name === "首页") {
						levelPaths.push(route)
						// 添加无导航面包屑
						if (this.$route.meta.root) {
							levelPaths.push({
								name: this.$route.meta.root,
								path: ""
							})
						}
					} else {
						levelPaths.push(route)
					}
				}
			}

			this.levelPaths = levelPaths
			this.isDetail()
		},
		getPathLevels(path, levels) {
			levels.unshift(path)
			const parentPath = path.substr(0, path.lastIndexOf("/"))
			if (parentPath.length === 0) {
				return
			}

			this.getPathLevels(parentPath, levels)
		},
		isDetail() {
			var detail = this.$route.meta.detail
			if (detail) {
				this.levelPaths.push(this.$route)
			}
			console.log(this.levelPaths)
		}
	}
}
</script>

<style lang="less" scoped>
.breadcrumb-container {
	width: 1170px;
	margin: 10px auto;
	display: flex;
	flex-direction: row;
}

.breadcrumb-container .set {
	display: flex;
	flex-direction: row;
	margin-left: 6px;
}

.breadcrumb {
	.c-font-size-14();
	line-height: 20px;
	.c-font-family-PF();
	font-weight: 400;
	color: #666666;
	// cursor: pointer;
}

.separator {
	.c-font-size-14();
	line-height: 20px;
	.c-font-family-PF();
	font-weight: 400;
	color: #666666;
	margin-left: 6px;
}
</style>
