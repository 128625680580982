<template>
	<div class="chart-con" ref="chart"></div>
</template>
<script>
import * as echarts from "echarts"
// import { containStroke } from "echarts-gl"
// import { createLogger } from "vuex"
export default {
	name: "BarChart",
	props: ["dataList", "totalDesc", "isDataFixed", "countFloat"],
	data() {
		return {
			selectedIndex: -1,
			isFilledData: false,
			option: {
				tooltip: {
					trigger: "item",
					formatter: "{b} : {c}"
				},
				color: [
					"#2C3FA0",
					"#CB332A",
					"#F4B200",
					"#6D4BB2",
					"#10BADF",
					"#FF9000",
					"#08A216"
				],
				title: {
					text: "",
					left: "center",
					top: "32%",
					textStyle: {
						color: "black",
						fontSize: 25,
						align: "center"
					}
				},
				graphic: {
					type: "text",
					left: "center",
					top: "45%",
					style: {
						text: "",
						textAlign: "center",
						fill: "#333",
						fontSize: 12
					}
				},
				legend: {
					bottom: "1%",
					left: "center"
				},
				series: [
					{
						type: "pie",
						radius: ["40%", "52%"],
						center: ["50%", "40%"],
						labelLine: {
							normal: {
								show: true
							}
						},
						itemStyle: {
							borderRadius: 0,
							borderColor: "#fff",
							borderWidth: 2
						},
						label: {
							normal: {
								formatter: function (data) {
									return data.percent.toFixed(0) + "%"
								}
							}
						},
						data: []
					}
				]
			}
		}
	},
	beforeRouteUpdate(to, from, next) {},
	watch: {
		dataList: function (dataList, oldList) {
			const chart =
				echarts.getInstanceByDom(this.$refs.chart) ||
				echarts.init(this.$refs.chart, "shine")

			var total = dataList.reduce(
				(sum, e) => sum + Number(e.value || 0),
				0
			)
			this.option.title.text = this.countFloat ? total.toFixed(2) : total

			this.option.graphic.style.text = this.totalDesc

			// 深拷贝副本 防止排序时影响原数组
			var showData = dataList.concat([])
			/*
			空白，UnKnown和5个之外显示为other:
			other = foo.where(p.name is empty)
			foo = foo.except(other)
			foo.orderby(count).desc
			if(foo.length > 5){
				bigger5 = foo.take(index > 5)
				other.merge(foo.take(index > 5))
				foo.except(bigger5)
			}
			foo.insert(foo.end,other)
			*/
			// debugger
			var other = showData.filter(
				(p) =>
					p.name === "" ||
					p.name === null ||
					p.name === "UnKnown" ||
					p.name === "unknown" ||
					p.name === "Other" ||
					p.name === "other" ||
					p.name === "Others" ||
					p.name === "others"
			)
			showData = showData.filter(
				(p) =>
					p.name !== "" &&
					p.name !== null &&
					p.name !== "UnKnown" &&
					p.name !== "unknown" &&
					p.name !== "Other" &&
					p.name !== "other" &&
					p.name !== "Others" &&
					p.name !== "others"
			)
			showData.sort((a, b) => b.value - a.value)
			if (showData.length > 5) {
				var bigger5 = showData.slice(5)
				bigger5.forEach(element => {
					other.push(element)
				})
				// other.push(bigger5)
				showData = showData.slice(0, 5)
			}
			// console.log(other)
			var otherItem = {
				name: "Others",
				value: other.reduce((sum, e) => sum + Number(e.value || 0), 0)
			}
			showData.push(otherItem)
			// console.log(this.totalDesc, showData, otherItem)

			this.option.series[0].data = showData

			if (!this.isDataFixed || !this.isFilledData) {
				chart.setOption(this.option)
				this.isFilledData = true

				chart.on("legendselectchanged", (params) => {
					chart.dispatchAction({
						type: "legendSelect",
						name: params.name
					})

					this.pieSelect(dataList, chart, params.name)
				})

				chart.on("pieselectchanged", (selected) => {
					debugger
					console.log(selected)
					console.log("1a")
				})
				chart.on("click", (params) => {
					console.log(params)
					this.pieSelect(dataList, chart, params.name)
				})
			}
		},
		totalDesc: function (old, newDesc) {
			const chart =
				echarts.getInstanceByDom(this.$refs.chart) ||
				echarts.init(this.$refs.chart, "shine")

			this.option.graphic.style.text = this.totalDesc
			chart.setOption(this.option)
		}
	},
	mounted() {},
	methods: {
		pieSelect(dataList, chart, selectName) {
			var res = { isOther: false, select: [] }

			// 计算选中项
			// 如果选中的是“other”，则已选中数组中的项应为排除top5以后的其他全部项
			// 修改others筛选直接返回others，前五截取由接口后台做
			res.isOther = selectName.toLowerCase() === "others"
			// if (res.isOther) {
			// 	var select = []
			// 	var shown = dataList.slice(0, 5)
			// 	select = dataList.filter(
			// 		(p) => shown.map((x) => x.name).indexOf(p.name) < 0
			// 	)
			// 	res.select = select
			// } else {
			// 	res.select.push(selectName)
			// }

			res.select.push(selectName)

			// 如果选中的是 other， 则不触发事件
			// if (res.isOther) {
			// 	return
			// }
			this.curPiePartIndex = toggleSingleHighlight(
				chart,
				selectName,
				this.curPiePartIndex
			)
			if (this.curPiePartIndex === -1) {
				this.$emit("click", { isOther: false, select: [] })
			} else {
				this.$emit("click", res)
			}

			function toggleSingleHighlight(chart, name, curPiePartIndex) {
				// First at all, clear current highlight
				chart.dispatchAction({
					type: "downplay",
					seriesIndex: 0
				})

				// Get the dataIndex with the given name
				var nameIndex = chart
					.getOption()
					.series[0].data.map((p) => p.name)
					.indexOf(name)

				if (nameIndex === curPiePartIndex) {
					return -1
				} else {
					// Set highlight
					chart.dispatchAction({
						type: "highlight",
						seriesIndex: 0,
						dataIndex: nameIndex
					})
					return nameIndex
				}
			}
		}
	}
}
</script>

<style scoped lang="less">
.chart-con {
	width: 290px;
	height: 315px;
}
</style>
