var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "systemPage evaluationBgimg" }, [
    _c("div", { staticClass: "contentPanel" }, [
      _c("div", { staticClass: "sysTitle" }, [_vm._v("核酸适体评价系统")]),
      _c(
        "div",
        { staticClass: "inputPanel" },
        [
          _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom",
                width: "618",
                trigger: "click",
                disabled: _vm.showList.length <= 0,
              },
              model: {
                value: _vm.selectExpress,
                callback: function ($$v) {
                  _vm.selectExpress = $$v
                },
                expression: "selectExpress",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    "max-height": "200px",
                    "overflow-y": "auto",
                  },
                },
                _vm._l(_vm.showList, function (item, index) {
                  return _c("div", { key: index }, [
                    index > 0
                      ? _c("div", {
                          staticStyle: {
                            width: "100%",
                            "border-top": "1px #DFDFDF solid",
                          },
                        })
                      : _vm._e(),
                    _c("div", {
                      staticClass: "targetSelectOptions",
                      domProps: { innerHTML: _vm._s(item.value) },
                      on: {
                        click: function ($event) {
                          return _vm.selectTarget(item)
                        },
                      },
                    }),
                  ])
                }),
                0
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.keyword,
                    expression: "keyword",
                  },
                ],
                staticClass: "targetSearchInputBox",
                attrs: {
                  slot: "reference",
                  type: "text",
                  placeholder: "请输入靶标名称",
                },
                domProps: { value: _vm.keyword },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.search.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.keyword = $event.target.value
                  },
                },
                slot: "reference",
              }),
            ]
          ),
          _c("button", { on: { click: _vm.toList } }, [
            _c("img", { attrs: { src: require("../../assets/search2.png") } }),
            _vm._v("搜索"),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }