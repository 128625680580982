<template>
	<div class="login" @click.stop="isolateClick()" @touchmove.prevent>
		<div class="login-container" v-if="containerShow">
			<div class="modalClose" @click="close"></div>
			<div class="title">{{ showDetail ? "序列详情" : "序列上传" }}</div>
			<form @submit.prevent="submit">
				<el-row>
					<el-col :span="12">
						<span class="label">适体名称</span>
						<el-input
							:disabled="canEdit"
							v-model="info.name"
							placeholder="请输入适体名称"
							maxlength="500"
						>
						</el-input>
					</el-col>
					<el-col :span="12">
						<span class="label">适体类型</span>
						<el-select
							:disabled="canEdit"
							style="width: 100%"
							v-model="info.type"
							placeholder="请选择适体类型"
						>
							<el-option value="DNA">DNA</el-option>
							<el-option value="RNA">RNA</el-option>
							<el-option value="DNA/RNA">DNA/RNA</el-option>
							<el-option value="Peptide">Peptide</el-option>
						</el-select>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<span class="label">靶标名称</span>
						<el-input
							:disabled="canEdit"
							v-model="info.target"
							placeholder="请输入靶标名称"
							maxlength="500"
						>
						</el-input>
					</el-col>
					<el-col :span="12">
						<span class="label">靶标种类</span>
						<el-select
							:disabled="canEdit"
							style="width: 100%"
							v-model="info.targetType"
							placeholder="请选择靶标种类"
						>
							<el-option value="Protein">Protein</el-option>
							<el-option value="Compound">Compound</el-option>
							<el-option value="Virus">Virus</el-option>
							<el-option value="Cells">Cells</el-option>
							<el-option value="Ion">Ion</el-option>
							<el-option value="Other">Other</el-option>
						</el-select>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<span class="label">亲和力(Kd)</span>
						<el-form
							:model="info"
							status-icon
							:rules="rules"
							label-width="100px"
							class="demo-ruleForm"
						>
							<el-form-item label="" prop="pass">
								<el-input
									:disabled="canEdit"
									v-model="info.affinity"
									placeholder="请输入亲和力"
									type="number"
									@keypress.native="checkInput"
								>
									<el-select
										style="width: 70px"
										v-model="selectUnit"
										slot="append"
										:disabled="canEdit"
										placeholder="请选择单位"
									>
										<el-option label="mm" :value="1"></el-option>
										<el-option label="μm" :value="2"></el-option>
										<el-option label="nm" :value="3"></el-option>
										<el-option label="pm" :value="4"></el-option>
										<el-option label="fm" :value="5"></el-option>
									</el-select>
								</el-input>
							</el-form-item>
						</el-form>
					</el-col>
					<el-col :span="12">
						<span class="label">筛选方法</span>
						<el-input
							:disabled="canEdit"
							v-model="info.screenMethod"
							placeholder="请输入筛选方法"
							maxlength="500"
						></el-input>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<span class="label">样本</span>
						<el-input
							:disabled="canEdit"
							v-model="info.sampleType"
							placeholder="请输入样本"
							maxlength="500"
						>
						</el-input>
					</el-col>
					<el-col :span="12">
						<span class="label">应用</span>
						<el-input
							:disabled="canEdit"
							v-model="info.appliedteChnology"
							placeholder="请输入应用"
							maxlength="500"
						></el-input>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<span class="label">引用类型</span>
						<el-select
							:disabled="canEdit"
							style="width: 100%"
							v-model="info.referenceType"
							placeholder="请选择引用类型"
						>
							<el-option value="DOI">DOI</el-option>
							<el-option value="URL">URL</el-option>
						</el-select>
					</el-col>
					<el-col :span="12">
						<span class="label">引用信息</span>
						<el-input
							:disabled="canEdit"
							v-model="info.referenceInfo"
							placeholder="请输入引用信息"
							maxlength="500"
						></el-input>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<span class="label">结合条件</span>
						<!-- <el-input v-model="info.name" placeholder="请输入结合条件">
						</el-input> -->
						<el-input
							:disabled="canEdit"
							class="el-input_inner"
							type="textarea"
							placeholder="请输入结合条件"
							resize="none"
							rows="3"
							v-model="info.combiningCondition"
							maxlength="2000"
						>
						</el-input>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<span class="label">适体序列</span>
						<!-- <el-input v-model="info.name" placeholder="请输入适体序列">
						</el-input> -->
						<el-input
							:disabled="canEdit"
							class="el-input_inner"
							type="textarea"
							resize="none"
							placeholder="请输入适体序列"
							rows="3"
							v-model="info.sequence"
						>
						</el-input>
					</el-col>
				</el-row>
				<el-row v-if="!canEdit">
					<el-col
						:span="24"
						style="display: flex; justify-content: space-between"
					>
						<el-button @click="batchSubmit()" class="batch">批量上传</el-button>
						<el-button type="primary" @click="submit()">确认上传</el-button>
						<el-button @click="close()">取消上传</el-button>
					</el-col>
				</el-row>
			</form>
		</div>
		<div class="batchUpload" v-if="batchUploadShow">
			<div class="batchUpload-title">批量上传</div>
			<div class="batchUpload-upload">
				<el-upload
					:disabled="disabled"
					ref="upload"
					:on-change="handleAvatarChange"
					accept="xls,xlsx"
					class="upload-demo"
					drag
					:auto-upload="false"
					action="#"
				>
					<img src="../assets/batchUpload.png" />
					<div class="el-upload__text">请导入Excel文件</div>
					<p v-if="upload.name">{{ upload.name }}</p>
				</el-upload>
			</div>
			<div class="download">
				<a
					href="/uploadTemplate.xlsx"
					download="序列上传模板.xlsx"
					v-if="!disabled"
					>模板下载</a
				>
			</div>
			<div class="batchUpload-bottom">
				<el-button @click="batchUpload()" class="batch" :disabled="disabled"
					>序列上传</el-button
				>
				<el-button
					type="primary"
					@click="batchUploadSubmit()"
					:disabled="disabled"
					>确认上传</el-button
				>
				<el-button @click="close()" :disabled="disabled">取消上传</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import { ApiClient, UserCenterApi, SubmittedSequenceApi } from "../api"
import Swal from "sweetalert2"

export default {
	props: ["showDetail", "canEdit", "showinfo"],
	data() {
		var validatePass = (rule, value, callback) => {
			if (!/^(-1|-2|([0-9]+(\.[0-9]{1,2})?))$/.test(this.info.affinity)) {
				callback(new Error("亲和力不能为空且最多可输入两位小数!"))
			} else {
				callback()
			}
		}
		return {
			rules: {
				pass: [{ validator: validatePass, trigger: "blur" }]
			},
			info: {
				name: "", // 名称
				type: "", // 类型
				target: "", // 靶标
				targetType: "", // 靶标类型
				affinity: "", // 亲和力
				screenMethod: "", // 筛选方法
				sampleType: "", // 样板类型
				appliedteChnology: "", // 应用技术
				combiningCondition: "", // 结合条件
				sequence: "", // 序列
				referenceType: "", // 引用类型
				referenceInfo: "" // 引用信息
			},
			id: 0,
			disabled: false,
			containerShow: true,
			batchUploadShow: false,
			submitDisable: false,
			api: new UserCenterApi(ApiClient.instance),
			seqApi: new SubmittedSequenceApi(ApiClient.instance),
			affinity: "",
			selectUnit: 3,
			value: 0,
			uploadTemplate: "../assets/uploadTemplate.xlsx",
			upload: {
				fileList: [],
				name: ""
			},
			base64Excel: {}
		}
	},
	methods: {
		checkInput(event) {
			if (event.key.toLowerCase() === "e") {
				event.returnValue = false
				return false
			}
			return true
		},
		submit(event) {
			if (!this.info.name) {
				this.$message({ message: "适体名称不能为空！", type: "error" })
				return
			}
			if (!this.info.type) {
				this.$message({ message: "适体类型不能为空！", type: "error" })
				return
			}
			if (!this.info.target) {
				this.$message({ message: "靶标名称不能为空！", type: "error" })
				return
			}
			if (!this.info.targetType) {
				this.$message({ message: "靶标种类不能为空！", type: "error" })
				return
			}
			if (!this.info.affinity) {
				this.$message({ message: "亲和力不能为空！", type: "error" })
				return
			}
			if (!/^(-1|-2|([0-9]+(\.[0-9]{1,2})?))$/.test(this.info.affinity)) {
				this.$message({ message: "亲和力最多输入两位小数！", type: "error" })
				return
			}
			this.info.affinity = this.formatAffnity()
			if (!this.info.screenMethod) {
				this.$message({ message: "筛选方法不能为空！", type: "error" })
				return
			}
			if (!this.info.sampleType) {
				this.$message({ message: "样本类型不能为空！", type: "error" })
				return
			}
			if (!this.info.appliedteChnology) {
				this.$message({ message: "应用技术不能为空！", type: "error" })
				return
			}
			if (this.info.referenceType) {
				if (!this.info.referenceInfo) {
					this.$message({ message: "引用信息不能为空！", type: "error" })
					return
				}
			}
			if (!this.info.combiningCondition) {
				this.$message({ message: "结合条件不能为空！", type: "error" })
				return
			}
			if (!this.info.sequence) {
				this.$message({ message: "适体序列不能为空！", type: "error" })
				return
			} else if (!/^[ATCGUatcgu]{1,}$/.test(this.info.sequence)) {
				this.$message({
					message: "请输入只包含ATCGU大小写字母的序列！",
					type: "error"
				})
				return
			}
			if (!this.id) {
				this.api.apiUserCenterSubmitsequencePost(
					this.info,
					(error, data, resp) => {
						if (error) {
							Swal.fire({ icon: "error", html: resp.body.errorMsg })
						} else {
							Swal.fire("序列上传成功，请等待管理员审核！").then(() =>
								this.close()
							)
						}
					}
				)
			} else {
				var opts = {
					ID: this.id,
					name: this.name,
					type: this.type,
					target: this.target,
					targetType: this.targetType,
					affinity: this.affinity,
					screenMethod: this.screenMethod,
					sampleType: this.sampleType,
					appliedteChnology: this.appliedteChnology,
					combiningCondition: this.combiningCondition,
					sequence: this.sequence
				}

				this.seqApi.apiSubmittedSequenceUpdatePost(opts, (err, data, resp) => {
					console.log(err, data, resp)
					if (data?.success && data?.result) {
						// this.$message({ message: "提交成功", type: "success" })
						Swal.fire("提交成功").then(() => this.close())
					} else {
						var errMsg = this.$options.filters.getResponseErrors(resp)
						Swal.fire({ icon: "error", html: errMsg })
					}
				})

				// this.seqApi.apiSubmittedSequenceUpdatePost(opts, (err, data, resp) => {
				// 	if (data?.success) {
				// 		this.$message({ message: "提交成功", type: "success" })
				// 		this.close()
				// 	} else {
				// 		var errMsg = this.$options.filters.getResponseErrors(resp)
				// 		Swal.fire({ icon: "error", html: errMsg })
				// 	}
				// })
			}
		},
		isolateClick() {
			// do nothing
		},
		close() {
			this.$emit("cancelsub")
			this.upload.name = ""
		},
		batchSubmit() {
			this.containerShow = false
			this.batchUploadShow = true
		},
		handleAvatarChange(file, fileList) {
			this.upload.fileList = []
			if (file) {
				var fileType = file.name.substring(file.name.lastIndexOf(".") + 1)
				if (fileType !== "xlsx" && fileType !== "xls") {
					this.$refs.upload.clearFiles()
					this.$message({
						message: "上传文件只能是.xlsx或.xls格式!",
						type: "warning"
					})
				} else {
					this.upload.name = file.name
					this.upload.fileList.push(file.raw)
				}
			}
		},
		batchUploadSubmit() {
			if (this.upload.fileList.length !== 0) {
				this.disabled = true
				this.seqApi.apiSubmittedSequenceBatchuploadPost(
					{ file: this.upload.fileList[0] },
					(error, data, response) => {
						if (error) {
							this.$message({
								message: "文件内容发生改变，请重新选择文件上传！",
								type: "success"
							})
							this.disabled = false
						} else if (response.body.success === true) {
							this.disabled = false
							this.$message({
								message: "上传成功!",
								type: "success"
							})
							window.location.reload()
							this.$emit("cancelsub")
						} else {
							this.disabled = false
						}
					}
				)
			} else {
				this.$message({
					message: "请上传文件!",
					type: "warning"
				})
			}
		},
		batchUpload() {
			this.containerShow = true
			this.batchUploadShow = false
			this.upload.name = ""
		},
		// 格式化亲和力，转换为nm单位
		formatAffnity() {
			var res = 0
			switch (this.selectUnit) {
				case 1:
					res = parseFloat(this.affinity) * 1000 * 1000
					break
				case 2:
					res = parseFloat(this.affinity) * 1000
					break
				case 3:
					res = parseFloat(this.affinity)
					break
				case 4:
					res = parseFloat(this.affinity) / 1000
					break
				case 5:
					res = parseFloat(this.affinity) / 1000 / 1000
					break
				default:
					res = parseFloat(this.affinity)
					break
			}
			return res
		}
	},
	created() {
		if (this.showDetail) {
			this.info = this.showinfo
			this.info.affinity = parseInt(this.info.affinity)
			this.affinity = this.info.affinity
			if (this.info.referenceType === 1) {
				this.info.referenceType = "DOI"
			} else if (this.info.referenceType === 2) {
				this.info.referenceType = "URL"
			} else {
				this.info.referenceType = ""
			}
			console.log(this.info)
			this.id = this.showinfo.id
		}
	},
	mounted() {}
}
</script>

<style lang="less" scoped>
/deep/ .el-button {
	background: #fff !important;
	font-weight: 500 !important;
	padding: 12px 20px !important;
	font-size: 18px !important;
	border-radius: 4px !important;
	width: 200px;
}
/deep/.el-input .el-input__suffix {
	display: none;
}
/deep/ .el-button:hover {
	border-color: #3463e9;
	color: #3463e9;
}
/deep/.el-form .el-form-item__content {
	margin-left: inherit !important;
}
.submit {
	background-color: #3463e9 !important;
}
.batch {
	color: #3463e9;
	border: 1px solid #3463e9;
}
.download {
	cursor: pointer;
	margin: 10px auto;
	font-size: 16px;
	height: 22px;
}
/deep/ .el-button--primary {
	color: #fff !important;
	background-color: #3463e9 !important;
	border-color: #409eff !important;
}
/deep/.el-upload-list {
	display: none;
}
.el-row {
	padding: 10px;
}
.el-col {
	padding: 0px 10px;
}
.el-row .el-col {
	text-align: left;
}
.el-row .el-col .label {
	.c-font-size-14();
	color: #333333;
	.c-font-family-PF();
}
</style>

<style lang="less" scoped>
.login-container {
	width: 800px;
	height: 100%;
	display: inline-block;
	text-align: center;
	border-radius: 6px;
	background-color: #ffffff;
	position: relative;
}
.batchUpload {
	width: 741px;
	height: 350px;
	background: #ffffff;
	opacity: 1;
	display: inline-block;
	text-align: center;
	border-radius: 10px;
	position: relative;
	.batchUpload-title {
		margin: 30px auto 20px auto;
		width: 80px;
		height: 28px;
		font-size: 20px;
		font-weight: bold;
		color: #333333;
		opacity: 1;
	}
	.batchUpload-upload {
		width: 659px;
		height: 150px;
		background: #f6f6f6;
		opacity: 1;
		border-radius: 10px;
		margin: 0 auto;
	}
	.batchUpload-bottom {
		margin: 25px auto;
		width: 659px;
		display: flex;
		justify-content: space-between;
	}
}
/deep/.el-upload-dragger {
	background-color: inherit;
	border: inherit;
	height: 140px !important;
	img {
		margin: 40px auto 15px auto;
	}
}
.title {
	margin: 20px auto;
	line-height: 20px;
	font-size: 20px;
	.c-font-family-PF();
	font-weight: 500;
	color: #333333;
}

.input-box input {
	width: 256px;
	height: 30px;
	margin: 10px auto;
	background-color: #f4f4f4;
	border: 1px solid #e0e0e0;
	border-radius: 2px;
	text-indent: 2px;
}

.submit {
	margin: 10px 16px;
}
</style>
