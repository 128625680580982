var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filter-panel-container" }, [
    _c(
      "form",
      {
        attrs: { novalidate: "true" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "main-filter-panel" }, [
          _vm._m(0),
          _c("div", { staticClass: "filter" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 研究开始日期 ")]),
            _c("div", { staticClass: "input" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("dateFormat")(
                      _vm.info.studyStartDate,
                      "YYYY-MM-DD",
                      "N/A"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "filter" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 招募状态 ")]),
            _c("div", { staticClass: "input" }, [
              _vm._v(" " + _vm._s(_vm.info.recruitmentStatus || "N/A") + " "),
            ]),
          ]),
          _c("div", { staticClass: "filter" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 研究类型 ")]),
            _c("div", { staticClass: "input" }, [
              _vm._v(" " + _vm._s(_vm.info.studyType || "N/A") + " "),
            ]),
          ]),
          _c("div", { staticClass: "filter" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 适应症 ")]),
            _c("div", { staticClass: "input" }, [
              _vm.info.conditions
                ? _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.info.conditions) },
                  })
                : _c("span", [_vm._v("N/A")]),
            ]),
          ]),
          _c("div", { staticClass: "filter" }, [
            _c("div", { staticClass: "label" }, [_vm._v(" 原文链接 ")]),
            _c("div", { staticClass: "input" }, [
              !_vm.info.url
                ? _c("span", [_vm._v("N/A")])
                : _c(
                    "a",
                    {
                      staticStyle: {
                        display: "block",
                        overflow: "hidden",
                        "word-break": "keep-all",
                        "white-space": "nowrap",
                        "text-overflow": "ellipsis",
                        "text-decoration": "underline",
                      },
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.openURL(_vm.info.url)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.info.url))]
                  ),
            ]),
          ]),
          _vm.isLogin
            ? _c("div", { staticClass: "filter" }, [
                !_vm.favorite
                  ? _c(
                      "div",
                      {
                        staticClass: "sm-btn",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("favoritechange", 1)
                          },
                        },
                      },
                      [_vm._v(" 收 藏 ")]
                    )
                  : _vm._e(),
                _vm.favorite
                  ? _c(
                      "div",
                      {
                        staticClass: "sm-btn",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("favoritechange", 0)
                          },
                        },
                      },
                      [_vm._v(" 已收藏 ")]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter" }, [
      _c("div", { staticClass: "title" }, [_vm._v(" 基础信息 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }