var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item-list-panel" }, [
    _c("div", { staticClass: "top-bar-container" }, [
      _c("div", { staticClass: "top-bar" }, [
        _c("div", { staticClass: "result" }, [
          _c("div", { staticClass: "label count-desc" }, [
            _c("div", { staticClass: "label" }, [_vm._v("当前结果")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(" " + _vm._s(_vm.total) + " "),
            ]),
            _c("div", { staticClass: "unit" }, [_vm._v("条")]),
          ]),
          _c("div", { staticClass: "textLabel" }, [
            _vm._v("按开始日期倒序排列"),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "item-list" },
      [
        _vm._l(_vm.list, function (d, i) {
          return _c("div", { key: i, staticClass: "item-container" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "location" }, [
                _c("div", { staticClass: "entity-line" }, [
                  _c(
                    "div",
                    { staticClass: "entity entity-title title" },
                    [
                      _c("router-link", { attrs: { to: "/project/" + d.id } }, [
                        _c("div", [_vm._v(" " + _vm._s(d.title) + " ")]),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "entity" }, [
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("dateFormat")(d.beginDate, "YYYY-MM-DD")
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "other c-font-size-12" },
                  [
                    _c("el-row", [
                      _c(
                        "div",
                        {
                          staticClass: "entity",
                          staticStyle: { "margin-right": "0px", width: "84px" },
                        },
                        [
                          _c("p", { staticClass: "label" }, [
                            _vm._v("项目所在地："),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "entity",
                          staticStyle: { width: "176px" },
                        },
                        [
                          _c("p", { staticClass: "value" }, [
                            _vm._v(_vm._s(d.country.name)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "entity",
                          staticStyle: { "margin-right": "0px", width: "84px" },
                        },
                        [
                          _c("p", { staticClass: "label" }, [
                            _vm._v("项目负责人："),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "entity",
                          staticStyle: { width: "436px" },
                        },
                        [
                          _c("p", { staticClass: "value" }, [
                            _vm._v(_vm._s(d.leader)),
                          ]),
                        ]
                      ),
                    ]),
                    _c("el-row", [
                      _c(
                        "div",
                        {
                          staticClass: "entity",
                          staticStyle: { "margin-right": "0px", width: "70px" },
                        },
                        [
                          _c("p", { staticClass: "label" }, [
                            _vm._v("经费金额："),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "entity",
                          staticStyle: { width: "190px" },
                        },
                        [
                          _c("p", { staticClass: "value" }, [
                            _vm._v(
                              _vm._s(
                                d.budgetsCNY > 0 ? d.budgetsCNY.toFixed(2) : "-"
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "entity",
                          staticStyle: { "margin-right": "0px", width: "70px" },
                        },
                        [
                          _c("p", { staticClass: "label" }, [
                            _vm._v("承担机构："),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "entity",
                          staticStyle: { width: "436px" },
                        },
                        [
                          _c("p", { staticClass: "value" }, [
                            _vm._v(_vm._s(d.leaderInstitution)),
                          ]),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        }),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              attrs: {
                id: "pagination",
                "current-page": _vm.pager.index,
                "page-sizes": [10, 50, 100],
                "page-size": _vm.pager.size,
                layout: "prev, pager, next, jumper,sizes",
                total: _vm.pager.total,
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.pager, "index", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.pager, "index", $event)
                },
                "update:pageSize": function ($event) {
                  return _vm.$set(_vm.pager, "size", $event)
                },
                "update:page-size": function ($event) {
                  return _vm.$set(_vm.pager, "size", $event)
                },
                "current-change": _vm.query,
                "size-change": _vm.sizeChange,
              },
            }),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }