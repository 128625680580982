<template>
	<div class="filter-panel-container">
		<form @submit.prevent="submit" novalidate="true">
			<div class="main-filter-panel">
				<div class="filter">
					<div class="title">基础信息</div>
				</div>
				<div class="filter">
					<div class="label">发表时间</div>
					<div class="input">
						{{ info.publishDate | dateFormat("YYYY-MM", "N/A") }}
					</div>
				</div>
				<div class="filter">
					<div class="label">杂志名称</div>
					<div class="input">
						{{ info.journal || "N/A" }}
					</div>
				</div>
				<div class="filter">
					<div class="label">期刊号</div>
					<div class="input">
						{{ info.issn || "N/A" }}
					</div>
				</div>
				<div class="filter">
					<div class="label">作者</div>
					<div class="input">
						{{ info.author || "N/A" }}
					</div>
				</div>
				<!-- <div class="filter">
					<div class="label">相关专利</div>
					<div class="input">
						<span
							v-if="!info.relationPatentNo || info.relationPatentNo.length <= 0"
							>暂无</span
						>
						<span v-else v-for="(pat, i) in info.relationPatentNo" :key="i">
							<a href="#">{{ pat }}</a>
							<span v-if="i < info.relationPatentNo.length - 1">、</span>
						</span>
					</div>
				</div>
				<div class="filter">
					<div class="label">全文链接</div>
					<div class="input">
						<a v-if="info.fulltextLink" :href="info.fulltextLink">{{
							info.fulltextLink
						}}</a>
						<span v-else>暂无</span>
					</div>
				</div> -->

				<div class="filter" v-if="isLogin">
					<div
						class="sm-btn"
						@click="$emit('favoritechange', 1)"
						v-if="!favorite"
					>
						收 藏
					</div>
					<div
						class="sm-btn"
						@click="$emit('favoritechange', 0)"
						v-if="favorite"
					>
						已收藏
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
export default {
	props: ["info", "favorite", "isLogin"],
	data() {
		return {}
	},
	methods: {}
}
</script>

<style lang="less" scoped>
.filter-panel-container {
	width: 100%;
	background-color: #ffffff;
	display: inline-block;
}

.main-filter-panel {
	margin: 20px;
	border-bottom: 1px solid #f0f0f0;
}

.filter {
	margin-bottom: 10px;
	text-align: left;
}

.filter .title {
	.c-font-family-PF();
	.c-font-size-16();
	font-weight: bolder;
	line-height: 20px;
	color: #3463ea;
}

.filter .label {
	.c-font-size-16();
	.c-font-family-PF();
	font-weight: bold;
	line-height: 20px;
	color: #333333;
}

.filter .input {
	.c-font-family-PF();
	.c-font-size-16();
	line-height: 27px;
}

.filter .input select {
	height: 30px;
	width: 98%;
	background-color: #f1f2f7;
	border-radius: 2px;
	border: none;
	text-indent: 2px;
}

.funding-panel {
	margin: 10px;
	text-align: left;
}

.funding-panel .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	line-height: 20px;
	color: #333333;
}

.funding-panel .select-panel {
	height: 90px;
	display: flex;
	flex-direction: column;
}

.funding-panel .select-panel .input {
	height: 20px;
	margin-top: 10px;
	margin-left: 6px;
	display: flex;
	flex-direction: row;
}

.funding-panel .select-panel .input input {
	margin: auto 4px;
}

.funding-panel .select-panel .input .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	color: #333333;
	line-height: 20px;
}

.control-panel {
	margin: 10px;
	display: flex;
	flex-direction: row;
}

.submit button {
	width: 160px;
	height: 30px;
	color: #ffffff;
	background-color: #3463ea;
	border-radius: 2px;
	border: none;
}

.reset {
	margin-left: auto;
	margin-right: 0;
}

.reset button {
	width: 100px;
	height: 30px;
	color: #333333;
	background-color: #cbcbcb;
	border-radius: 2px;
	border: none;
	text-align: center;
}
.sm-btn {
	width: 60px;
	height: 30px;
	top: 7px;
	right: 0;
	line-height: 30px;
	.c-font-size-14();
	.c-font-family-PF();
	font-weight: 400;
	color: #3463ea;
	text-align: center;

	cursor: pointer;
	border: 1px solid #3463ea;
	border-radius: 2px;
}
</style>
