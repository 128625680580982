var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "team-list-panel" }, [
    _c(
      "div",
      { staticClass: "top-bar-container" },
      [
        _c("div", { staticClass: "top-bar-container-title" }, [
          _vm._v(_vm._s(_vm.topicInfo.title)),
        ]),
        _c("div", { staticClass: "result" }, [
          _vm._v(" " + _vm._s(_vm.topicInfo.content) + " "),
        ]),
        _c("el-row", { staticStyle: { "text-align": "right" } }, [
          _vm._v(
            " 发布人：" +
              _vm._s(_vm.topicInfo.createUserName) +
              "，发布时间：" +
              _vm._s(
                _vm._f("dateFormat")(_vm.topicInfo.createOn, "YYYY-MM-DD")
              ) +
              " "
          ),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "team-list" }, [
      _c("div", { staticClass: "team-container" }, [
        _c(
          "div",
          { staticClass: "team", staticStyle: { position: "relative" } },
          [
            _c("div", { staticClass: "commentLabel" }, [_vm._v("参与评论")]),
            _c(
              "div",
              {
                staticStyle: {
                  border: "1px solid #dbdbdb",
                  position: "relative",
                },
              },
              [
                _c("el-input", {
                  staticClass: "el-input_inner",
                  attrs: {
                    maxlength: "1000",
                    type: "textarea",
                    placeholder: "发表评论...",
                    rows: "3",
                    "show-word-limit": "",
                  },
                  model: {
                    value: _vm.commentStr,
                    callback: function ($$v) {
                      _vm.commentStr = $$v
                    },
                    expression: "commentStr",
                  },
                }),
                _c("div", { staticClass: "commentSubmit" }, [
                  _c("div", { staticStyle: { width: "50px" } }, [
                    _c("img", {
                      staticStyle: {
                        width: "40px",
                        height: "40px",
                        "border-radius": "50%",
                        "vertical-align": "middle",
                      },
                      attrs: { src: _vm.avator },
                    }),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "c-font-size-18",
                      staticStyle: {
                        width: "426px",
                        "font-weight": "500",
                        color: "#333333",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.nickname))])]
                  ),
                  _c(
                    "div",
                    { staticStyle: { width: "630px", "text-align": "right" } },
                    [
                      _c("button", { on: { click: _vm.commentSubmit } }, [
                        _vm._v("发布"),
                      ]),
                    ]
                  ),
                ]),
                !_vm.isLogin
                  ? _c("div", { staticClass: "toLogin" }, [
                      _c("button", { on: { click: _vm.toLigin } }, [
                        _vm._v(">> 登录后开启发布评论 <<"),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "commentLabel" }, [_vm._v("评论区")]),
            _vm._l(_vm.comments, function (commentInfo, i) {
              return _c("commentPanel", {
                key: i,
                attrs: {
                  commentInfo: commentInfo,
                  isFirst: true,
                  index: i,
                  commentStr: _vm.commentStr,
                  isLogin: _vm.isLogin,
                },
                on: { commentSub: _vm.commentSub },
              })
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }