var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "footer-bar" }, [
    _c("div", { staticClass: "subbar" }, [
      _c(
        "div",
        {
          staticClass: "navigate-container",
          staticStyle: {
            height: "100px",
            display: "flex",
            "align-items": "center",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "navigate",
              on: {
                click: function ($event) {
                  return _vm.navigateTo("introduction")
                },
              },
            },
            [_vm._v("适体简介")]
          ),
          _c(
            "div",
            {
              staticClass: "navigate",
              on: {
                click: function ($event) {
                  return _vm.navigateTo("aptamerdb")
                },
              },
            },
            [_vm._v(" Aptamer数据库 ")]
          ),
          _c(
            "div",
            {
              staticClass: "navigate",
              on: {
                click: function ($event) {
                  return _vm.navigateTo("paper")
                },
              },
            },
            [_vm._v("文献资料")]
          ),
          _c(
            "div",
            {
              staticClass: "navigate",
              on: {
                click: function ($event) {
                  return _vm.navigateTo("patent")
                },
              },
            },
            [_vm._v("重点专利")]
          ),
          _c(
            "div",
            {
              staticClass: "navigate",
              on: {
                click: function ($event) {
                  return _vm.navigateTo("clinical")
                },
              },
            },
            [_vm._v("临床进展")]
          ),
          _c(
            "div",
            {
              staticClass: "navigate",
              on: {
                click: function ($event) {
                  return _vm.navigateTo("team")
                },
              },
            },
            [_vm._v("团队介绍")]
          ),
          _c(
            "div",
            {
              staticClass: "navigate",
              on: {
                click: function ($event) {
                  return _vm.navigateTo("project")
                },
              },
            },
            [_vm._v("科研项目")]
          ),
          _c(
            "div",
            {
              staticClass: "navigate",
              on: {
                click: function ($event) {
                  return _vm.navigateTo("LargeScreen")
                },
              },
            },
            [_vm._v("数据统计")]
          ),
          _c(
            "div",
            {
              staticClass: "navigate",
              on: {
                click: function ($event) {
                  return _vm.navigateTo("analysistool")
                },
              },
            },
            [_vm._v("分析工具")]
          ),
          _c(
            "div",
            { staticClass: "navigate", on: { click: _vm.openSeqSub } },
            [_vm._v("序列提交")]
          ),
          _c("div", { staticClass: "navigate share" }, [
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    return _vm.share("sina")
                  },
                },
              },
              [
                _c("img", {
                  attrs: { src: require("../assets/micro-blog.png"), alt: "" },
                }),
              ]
            ),
          ]),
        ]
      ),
    ]),
    _vm._m(0),
    _vm.isSeqSub
      ? _c(
          "div",
          { staticClass: "to-login-panel" },
          [_c("SequenceSub", { on: { cancelsub: _vm.closeSeqSub } })],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "subbar" }, [
      _c("div", { staticClass: "authority" }, [
        _c("div", { staticClass: "copyright" }, [
          _vm._v("版权所有：中国科学院基础医学与肿瘤研究所"),
        ]),
        _c("div", { staticClass: "gov-permission" }, [
          _vm._v(" 浙ICP备20028988号-1 浙ICP备20028988号-2 "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }