var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-panel" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "recommended" },
        [
          _c(
            "el-table",
            {
              staticClass: "dataTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.recommendInfos,
                border: "",
                "cell-style": { borderColor: "#dbdbdb" },
                "header-cell-style": { borderColor: "#dbdbdb" },
              },
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: {
                    align: "center",
                    prop: "targetName",
                    label: "靶标名称",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.targetName
                            ? _c("span", {
                                domProps: { innerHTML: _vm._s(row.targetName) },
                              })
                            : _c("span", [_vm._v("-")]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("img", {
                      staticStyle: {
                        "margin-right": "10px",
                        "vertical-align": "middle",
                      },
                      attrs: { src: require("../../assets/targetname.png") },
                    }),
                    _vm._v("靶标名称 "),
                  ]),
                ],
                2
              ),
              _c(
                "el-table-column",
                {
                  attrs: {
                    align: "center",
                    prop: "aptamerType",
                    label: "适体类型",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(" " + _vm._s(row.aptamerType || "-") + " "),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("img", {
                      staticStyle: {
                        "margin-right": "10px",
                        "vertical-align": "middle",
                      },
                      attrs: { src: require("../../assets/aptamertype.png") },
                    }),
                    _vm._v("适体类型 "),
                  ]),
                ],
                2
              ),
              _c(
                "el-table-column",
                {
                  attrs: {
                    align: "center",
                    prop: "screenMethod",
                    label: "筛选方法",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _vm._v(" " + _vm._s(row.screenMethod || "-") + " "),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("img", {
                      staticStyle: {
                        "margin-right": "10px",
                        "vertical-align": "middle",
                      },
                      attrs: { src: require("../../assets/screenmethod.png") },
                    }),
                    _vm._v("筛选方法 "),
                  ]),
                ],
                2
              ),
              _c(
                "el-table-column",
                {
                  attrs: { align: "center", prop: "score", label: "推荐打分" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [_vm._v(" " + _vm._s(row.score || "-") + " ")]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("img", {
                      staticStyle: {
                        "margin-right": "10px",
                        "vertical-align": "middle",
                      },
                      attrs: {
                        src: require("../../assets/recommendscore.png"),
                      },
                    }),
                    _vm._v("推荐打分 "),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(1),
      _c("div", { staticClass: "section-whatis" }, [
        _c("ul", [
          _c("li", [
            _c("div", { staticClass: "chartTitle" }, [_vm._v("适体类别")]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.aptamerTypeStatisticas.length > 0,
                    expression: "aptamerTypeStatisticas.length>0",
                  },
                ],
                staticClass: "pieChart",
              },
              [
                _c("ScreenMethodChart", {
                  attrs: {
                    dataList: _vm.aptamerTypeStatisticas,
                    allowedClick: "true",
                    dataType: 1,
                    totalDesc: "",
                  },
                  on: { click: _vm.chartFilter },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.aptamerTypeStatisticas.length <= 0,
                    expression: "aptamerTypeStatisticas.length<=0",
                  },
                ],
                staticClass: "noData",
              },
              [
                _c("img", {
                  attrs: { src: require("../../assets/nodata2.png") },
                }),
                _c("p", [_vm._v("暂无数据")]),
              ]
            ),
          ]),
          _c("li", [
            _c("div", { staticClass: "chartTitle" }, [_vm._v("筛选方法")]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.ScreenMethodStatisticas.length > 0,
                    expression: "ScreenMethodStatisticas.length>0",
                  },
                ],
                staticClass: "pieChart",
              },
              [
                _c("ScreenMethodChart", {
                  attrs: {
                    dataList: _vm.ScreenMethodStatisticas,
                    allowedClick: "true",
                    dataType: 2,
                    totalDesc: "",
                  },
                  on: { click: _vm.chartFilter },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.ScreenMethodStatisticas.length <= 0,
                    expression: "ScreenMethodStatisticas.length<=0",
                  },
                ],
                staticClass: "noData",
              },
              [
                _c("img", {
                  attrs: { src: require("../../assets/nodata2.png") },
                }),
                _c("p", [_vm._v("暂无数据")]),
              ]
            ),
          ]),
          _c("li", [
            _c("div", { staticClass: "chartTitle" }, [_vm._v("样本类型")]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.sampleTypeStatisticas.length > 0,
                    expression: "sampleTypeStatisticas.length>0",
                  },
                ],
                staticClass: "pieChart",
              },
              [
                _c("ScreenMethodChart", {
                  attrs: {
                    dataList: _vm.sampleTypeStatisticas,
                    allowedClick: "true",
                    dataType: 3,
                    totalDesc: "",
                  },
                  on: { click: _vm.chartFilter },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.sampleTypeStatisticas.length <= 0,
                    expression: "sampleTypeStatisticas.length<=0",
                  },
                ],
                staticClass: "noData",
              },
              [
                _c("img", {
                  attrs: { src: require("../../assets/nodata2.png") },
                }),
                _c("p", [_vm._v("暂无数据")]),
              ]
            ),
          ]),
          _c("li", [
            _c("div", { staticClass: "chartTitle" }, [_vm._v("应用")]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.UsageTypeStatisticas.length > 0,
                    expression: "UsageTypeStatisticas.length>0",
                  },
                ],
                staticClass: "pieChart",
              },
              [
                _c("ScreenMethodChart", {
                  attrs: {
                    dataList: _vm.UsageTypeStatisticas,
                    allowedClick: "true",
                    dataType: 4,
                    totalDesc: "",
                  },
                  on: { click: _vm.chartFilter },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.UsageTypeStatisticas.length <= 0,
                    expression: "UsageTypeStatisticas.length<=0",
                  },
                ],
                staticClass: "noData",
              },
              [
                _c("img", {
                  attrs: { src: require("../../assets/nodata2.png") },
                }),
                _c("p", [_vm._v("暂无数据")]),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._m(2),
      _c("div", { staticClass: "section-method" }, [
        _c(
          "ul",
          _vm._l(_vm.articleList, function (data, index) {
            return _c("li", { key: index }, [
              _c("div", { staticClass: "item" }, [
                _c(
                  "div",
                  {
                    staticClass: "entity-title",
                    on: {
                      click: function ($event) {
                        return _vm.toDetail(data.articleID)
                      },
                    },
                  },
                  [_vm._v(_vm._s(data.articleTitle))]
                ),
                _c("div", { staticClass: "entity" }, [
                  _vm._v(_vm._s(data.author)),
                ]),
                _c("div", { staticClass: "entity" }, [
                  _vm._v(
                    " " +
                      _vm._s(data.journal) +
                      ". " +
                      _vm._s(
                        _vm._f("dateFormat")(data.publishedDate, "YYYY-MM-DD")
                      ) +
                      ";" +
                      _vm._s(data.issn) +
                      ". doi: " +
                      _vm._s(data.doi) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "entity1" }, [
                  _vm._v(_vm._s(data._abstract)),
                ]),
              ]),
            ])
          }),
          0
        ),
      ]),
      _c(
        "el-row",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              id: "pagination",
              "current-page": _vm.pageIndex,
              "page-size": _vm.pageSize,
              layout: "prev, pager, next, jumper",
              total: _vm.total,
            },
            on: { "current-change": _vm.queryChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-bar" }, [
      _c("div", [_vm._v("推荐筛选系统")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-bar" }, [
      _c("div", [_vm._v("筛选系统统计")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "top-bar", staticStyle: { "margin-top": "20px" } },
      [_c("div", [_vm._v("文献引用")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }