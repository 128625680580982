/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The AptamerDetailTargetDto model module.
 * @module model/AptamerDetailTargetDto
 * @version v1
 */
export class AptamerDetailTargetDto {
  /**
   * Constructs a new <code>AptamerDetailTargetDto</code>.
   * @alias module:model/AptamerDetailTargetDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AptamerDetailTargetDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AptamerDetailTargetDto} obj Optional instance to populate.
   * @return {module:model/AptamerDetailTargetDto} The populated <code>AptamerDetailTargetDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AptamerDetailTargetDto();
      if (data.hasOwnProperty('aptamerID'))
        obj.aptamerID = ApiClient.convertToType(data['aptamerID'], 'String');
      if (data.hasOwnProperty('target'))
        obj.target = ApiClient.convertToType(data['target'], 'String');
      if (data.hasOwnProperty('targetType'))
        obj.targetType = ApiClient.convertToType(data['targetType'], 'String');
      if (data.hasOwnProperty('sample'))
        obj.sample = ApiClient.convertToType(data['sample'], 'String');
      if (data.hasOwnProperty('affinity'))
        obj.affinity = ApiClient.convertToType(data['affinity'], 'String');
      if (data.hasOwnProperty('bindingConditions'))
        obj.bindingConditions = ApiClient.convertToType(data['bindingConditions'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} aptamerID
 */
AptamerDetailTargetDto.prototype.aptamerID = undefined;

/**
 * @member {String} target
 */
AptamerDetailTargetDto.prototype.target = undefined;

/**
 * @member {String} targetType
 */
AptamerDetailTargetDto.prototype.targetType = undefined;

/**
 * @member {String} sample
 */
AptamerDetailTargetDto.prototype.sample = undefined;

/**
 * @member {String} affinity
 */
AptamerDetailTargetDto.prototype.affinity = undefined;

/**
 * @member {String} bindingConditions
 */
AptamerDetailTargetDto.prototype.bindingConditions = undefined;

