<template>
	<div class="user-container">
		<div class="left">
			<div class="basic">
				<div class="avator">
					<el-avatar :key="avatorHref" :src="avatorHref" :size="90"></el-avatar>
				</div>
				<div class="name">{{ name }}</div>
				<div class="title">{{ title }}</div>
				<!-- <div class="sequence-upload-btn" @click="submitSequence">序列上传</div> -->
			</div>
			<div class="nav-bar">
				<router-link :to="'/usercenter/info'" class="item"
					>账号资料</router-link
				>
				<router-link :to="'/usercenter/favorites/aptamer'" class="item"
					>我的收藏</router-link
				>
				<router-link :to="'/usercenter/myupload/seq'" class="item"
					>我的上传</router-link
				>
			</div>
		</div>
		<div class="right">
			<router-view />
		</div>
	</div>
</template>

<script>
import { UserCenterApi, ApiClient } from "../../api"
export default {
	data() {
		return {
			avator: "",
			name: "1",
			title: "1",
			api: new UserCenterApi(ApiClient.instance)
		}
	},
	mounted() {
		this.getinfo()
	},
	computed: {
		avatorHref() {
			return "data:image/png;base64," + this.avator
		}
	},
	methods: {
		getinfo() {
			this.api.apiUserCenterInfoGet((e, data, resp) => {
				if (data.success) {
					this.avator = data.result.avator
					this.name = data.result.name
					// this.password = data.result.password
					this.title = data.result.title
				}
			})
		},
		submitSequence() {
			var isSeqSub = this.$parent.$children[0].isSeqSub
			this.$parent.$children[0].isSeqSub = !isSeqSub
		}
	}
}
</script>

<style lang="less" scoped>
.user-container {
	display: flex;
	width: 1170px;
	margin: 30px auto;
	.c-font-family-PF();
	font-weight: 500;

	.left {
		width: 290px;
		margin-right: 20px;

		.basic {
			background: #ffffff;
			height: 310px;
			box-shadow: 0px 0px 6px 0px rgba(4, 0, 0, 0.07);
			margin-bottom: 20px;

			display: grid;
			grid-template-columns: repeat(1);
			grid-row-gap: 10px;
			justify-content: center;
			align-content: center;
			justify-items: center;

			.avator {
				width: 90px;
				height: 90px;
				border-radius: 50%;
				img {
					height: 100%;
				}
			}
			.name {
				height: 17px;
				line-height: 17px;
				.c-font-size-18();
				font-weight: 500;
				color: #333333;
			}
			.title {
				height: 14px;
				line-height: 14px;
				.c-font-size-14();
				font-weight: 500;
				color: #cacaca;
			}
			.sequence-upload-btn {
				height: 46px;
				width: 241px;

				text-align: center;
				line-height: 46px;
				.c-font-size-16();
				.c-font-family-PF();
				font-weight: 500;
				color: #3463ea;

				border: 1px solid #416deb;
				border-radius: 4px;
				margin-top: 30px;
			}
		}

		.nav-bar {
			background: #ffffff;
			height: 210px;
			box-shadow: 0px 0px 6px 0px rgba(4, 0, 0, 0.07);
			display: grid;
			grid-template-columns: repeat(1);
			grid-row-gap: 5px;

			.item {
				text-decoration: none;
				padding-left: 35px;
				line-height: 66px;
				.c-font-size-16();
				font-weight: 500;
				color: #666666;
				border-bottom: 1px solid rgba(4, 0, 0, 0.07);
			}

			.active {
				background: #416deb;
				color: #ffffff;
			}
		}
	}

	.right {
		width: 860px;
	}
}
</style>
