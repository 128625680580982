/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {AptamerItemDto} from './AptamerItemDto';
import {PatentTypeEnum} from './PatentTypeEnum';

/**
 * The PatentDetailDto model module.
 * @module model/PatentDetailDto
 * @version v1
 */
export class PatentDetailDto {
  /**
   * Constructs a new <code>PatentDetailDto</code>.
   * @alias module:model/PatentDetailDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>PatentDetailDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/PatentDetailDto} obj Optional instance to populate.
   * @return {module:model/PatentDetailDto} The populated <code>PatentDetailDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PatentDetailDto();
      if (data.hasOwnProperty('patentOffice'))
        obj.patentOffice = ApiClient.convertToType(data['patentOffice'], 'String');
      if (data.hasOwnProperty('applicationNumber'))
        obj.applicationNumber = ApiClient.convertToType(data['applicationNumber'], 'String');
      if (data.hasOwnProperty('applicationDate'))
        obj.applicationDate = ApiClient.convertToType(data['applicationDate'], 'Date');
      if (data.hasOwnProperty('publicationNumber'))
        obj.publicationNumber = ApiClient.convertToType(data['publicationNumber'], 'String');
      if (data.hasOwnProperty('publicationDate'))
        obj.publicationDate = ApiClient.convertToType(data['publicationDate'], 'Date');
      if (data.hasOwnProperty('publicationKind'))
        obj.publicationKind = ApiClient.convertToType(data['publicationKind'], 'String');
      if (data.hasOwnProperty('patentType'))
        obj.patentType = PatentTypeEnum.constructFromObject(data['patentType']);
      if (data.hasOwnProperty('patentTypeDescription'))
        obj.patentTypeDescription = ApiClient.convertToType(data['patentTypeDescription'], 'String');
      if (data.hasOwnProperty('applicant'))
        obj.applicant = ApiClient.convertToType(data['applicant'], 'String');
      if (data.hasOwnProperty('inventor'))
        obj.inventor = ApiClient.convertToType(data['inventor'], 'String');
      if (data.hasOwnProperty('attorney'))
        obj.attorney = ApiClient.convertToType(data['attorney'], 'String');
      if (data.hasOwnProperty('aptamerLink'))
        obj.aptamerLink = ApiClient.convertToType(data['aptamerLink'], 'String');
      if (data.hasOwnProperty('abstract'))
        obj._abstract = ApiClient.convertToType(data['abstract'], 'String');
      if (data.hasOwnProperty('instructions'))
        obj.instructions = ApiClient.convertToType(data['instructions'], 'String');
      if (data.hasOwnProperty('claims'))
        obj.claims = ApiClient.convertToType(data['claims'], 'String');
      if (data.hasOwnProperty('figures'))
        obj.figures = ApiClient.convertToType(data['figures'], ['String']);
      if (data.hasOwnProperty('aptamers'))
        obj.aptamers = ApiClient.convertToType(data['aptamers'], [AptamerItemDto]);
    }
    return obj;
  }
}

/**
 * @member {String} patentOffice
 */
PatentDetailDto.prototype.patentOffice = undefined;

/**
 * @member {String} applicationNumber
 */
PatentDetailDto.prototype.applicationNumber = undefined;

/**
 * @member {Date} applicationDate
 */
PatentDetailDto.prototype.applicationDate = undefined;

/**
 * @member {String} publicationNumber
 */
PatentDetailDto.prototype.publicationNumber = undefined;

/**
 * @member {Date} publicationDate
 */
PatentDetailDto.prototype.publicationDate = undefined;

/**
 * @member {String} publicationKind
 */
PatentDetailDto.prototype.publicationKind = undefined;

/**
 * @member {module:model/PatentTypeEnum} patentType
 */
PatentDetailDto.prototype.patentType = undefined;

/**
 * @member {String} patentTypeDescription
 */
PatentDetailDto.prototype.patentTypeDescription = undefined;

/**
 * @member {String} applicant
 */
PatentDetailDto.prototype.applicant = undefined;

/**
 * @member {String} inventor
 */
PatentDetailDto.prototype.inventor = undefined;

/**
 * @member {String} attorney
 */
PatentDetailDto.prototype.attorney = undefined;

/**
 * @member {String} aptamerLink
 */
PatentDetailDto.prototype.aptamerLink = undefined;

/**
 * @member {String} _abstract
 */
PatentDetailDto.prototype._abstract = undefined;

/**
 * @member {String} instructions
 */
PatentDetailDto.prototype.instructions = undefined;

/**
 * @member {String} claims
 */
PatentDetailDto.prototype.claims = undefined;

/**
 * @member {Array.<String>} figures
 */
PatentDetailDto.prototype.figures = undefined;

/**
 * @member {Array.<module:model/AptamerItemDto>} aptamers
 */
PatentDetailDto.prototype.aptamers = undefined;

