var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-panel" }, [
    _c("div", { staticClass: "tab-panel" }, [
      _c("ul", { staticClass: "tab-tilte", staticStyle: { margin: "0px" } }, [
        _c(
          "li",
          {
            class: { active: _vm.showType == 1 },
            on: {
              click: function ($event) {
                return _vm.changeShow(1)
              },
            },
          },
          [_vm._v(" Descriptions ")]
        ),
        _c(
          "li",
          {
            class: { active: _vm.showType == 2 },
            on: {
              click: function ($event) {
                return _vm.changeShow(2)
              },
            },
          },
          [_vm._v(" Alignments ")]
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "list-panel" },
      [
        _vm.showType == 1
          ? _c("Descriptions", {
              attrs: { dataList: _vm.aptamerInfo, loading: _vm.loading },
              on: { optionselected: _vm.optionSelected },
            })
          : _vm._e(),
        _vm.showType == 2
          ? _c("Alignments", { attrs: { info: _vm.selectSequence } })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }