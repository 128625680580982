/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {BooleanResponse} from '../model/BooleanResponse';
import {CollectionTypeEnum} from '../model/CollectionTypeEnum';
import {CommentListReponseResponse} from '../model/CommentListReponseResponse';
import {CommentType} from '../model/CommentType';
import {DataStatusEnum} from '../model/DataStatusEnum';
import {ForgetPasswordRequest} from '../model/ForgetPasswordRequest';
import {LearnTopicListDtoResponse} from '../model/LearnTopicListDtoResponse';
import {OrderByDescEnum} from '../model/OrderByDescEnum';
import {OrderByEnum} from '../model/OrderByEnum';
import {ReferenceTypeEnum} from '../model/ReferenceTypeEnum';
import {SearchTypeEnum} from '../model/SearchTypeEnum';
import {SignOnUserDtoResponse} from '../model/SignOnUserDtoResponse';
import {StringResponse} from '../model/StringResponse';
import {UserChangePasswordRequest} from '../model/UserChangePasswordRequest';
import {UserInfoDtoResponse} from '../model/UserInfoDtoResponse';
import {UserSexEnum} from '../model/UserSexEnum';

/**
* UserCenter service.
* @module api/UserCenterApi
* @version v1
*/
export class UserCenterApi {

    /**
    * Constructs a new UserCenterApi. 
    * @alias module:api/UserCenterApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiUserCenterCaptchaGet operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterCaptchaGetCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 图形验证码
     * @param {module:api/UserCenterApi~apiUserCenterCaptchaGetCallback} callback The callback function, accepting three arguments: error, data, response
     */
    apiUserCenterCaptchaGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/UserCenter/captcha', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterChangepasswordPost operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterChangepasswordPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BooleanResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/UserChangePasswordRequest} opts.body 
     * @param {module:api/UserCenterApi~apiUserCenterChangepasswordPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiUserCenterChangepasswordPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;

      return this.apiClient.callApi(
        '/api/UserCenter/changepassword', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterCollectedGet operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterCollectedGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BooleanResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 检查是否收藏
     * @param {String} collectionId 
     * @param {module:api/UserCenterApi~apiUserCenterCollectedGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiUserCenterCollectedGet(collectionId, callback) {
      
      let postBody = null;
      // verify the required parameter 'collectionId' is set
      if (collectionId === undefined || collectionId === null) {
        throw new Error("Missing the required parameter 'collectionId' when calling apiUserCenterCollectedGet");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'collectionId': collectionId
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;

      return this.apiClient.callApi(
        '/api/UserCenter/collected', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterCommentPost operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterCommentPostCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 提交评论
     * @param {Object} opts Optional parameters
     * @param {String} opts.articleID 
     * @param {String} opts.userID 
     * @param {String} opts.parentID 
     * @param {String} opts.content 
     * @param {module:model/CommentType} opts.commentType 
     * @param {module:api/UserCenterApi~apiUserCenterCommentPostCallback} callback The callback function, accepting three arguments: error, data, response
     */
    apiUserCenterCommentPost(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        'ArticleID': opts['articleID'],'UserID': opts['userID'],'ParentID': opts['parentID'],'Content': opts['content'],'CommentType': opts['commentType']
      };

      let authNames = ['Bearer'];
      let contentTypes = ['multipart/form-data'];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/UserCenter/comment', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterCommentsetstatusPost operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterCommentsetstatusPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BooleanResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 我的评论状态设置
     * @param {String} id 
     * @param {module:model/DataStatusEnum} dataStatus 
     * @param {module:api/UserCenterApi~apiUserCenterCommentsetstatusPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiUserCenterCommentsetstatusPost(id, dataStatus, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiUserCenterCommentsetstatusPost");
      }
      // verify the required parameter 'dataStatus' is set
      if (dataStatus === undefined || dataStatus === null) {
        throw new Error("Missing the required parameter 'dataStatus' when calling apiUserCenterCommentsetstatusPost");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'id': id,'dataStatus': dataStatus
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;

      return this.apiClient.callApi(
        '/api/UserCenter/commentsetstatus', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterFavoritePost operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterFavoritePostCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 添加收藏
     * @param {Object} opts Optional parameters
     * @param {String} opts.collectionID 
     * @param {String} opts.userID 
     * @param {module:model/CollectionTypeEnum} opts.collectionType 
     * @param {module:api/UserCenterApi~apiUserCenterFavoritePostCallback} callback The callback function, accepting three arguments: error, data, response
     */
    apiUserCenterFavoritePost(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        'CollectionID': opts['collectionID'],'UserID': opts['userID'],'CollectionType': opts['collectionType']
      };

      let authNames = ['Bearer'];
      let contentTypes = ['multipart/form-data'];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/UserCenter/favorite', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterFavoritesGet operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterFavoritesGetCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 查询收藏
     * @param {module:model/CollectionTypeEnum} collectionType 
     * @param {Object} opts Optional parameters
     * @param {String} opts.userID 
     * @param {Number} opts.index 
     * @param {Number} opts.size 
     * @param {module:api/UserCenterApi~apiUserCenterFavoritesGetCallback} callback The callback function, accepting three arguments: error, data, response
     */
    apiUserCenterFavoritesGet(collectionType, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'collectionType' is set
      if (collectionType === undefined || collectionType === null) {
        throw new Error("Missing the required parameter 'collectionType' when calling apiUserCenterFavoritesGet");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'UserID': opts['userID'],'CollectionType': collectionType,'Index': opts['index'],'Size': opts['size']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/UserCenter/favorites', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterFeedbackPost operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterFeedbackPostCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 提交反馈
     * @param {Object} opts Optional parameters
     * @param {String} opts.feedBackUserID 
     * @param {String} opts.submittedURL 
     * @param {String} opts.content 
     * @param {module:api/UserCenterApi~apiUserCenterFeedbackPostCallback} callback The callback function, accepting three arguments: error, data, response
     */
    apiUserCenterFeedbackPost(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        'FeedBackUserID': opts['feedBackUserID'],'SubmittedURL': opts['submittedURL'],'Content': opts['content']
      };

      let authNames = ['Bearer'];
      let contentTypes = ['multipart/form-data'];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/UserCenter/feedback', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterForgetpasswordPost operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterForgetpasswordPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BooleanResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 忘记密码
     * @param {Object} opts Optional parameters
     * @param {module:model/ForgetPasswordRequest} opts.body 
     * @param {module:api/UserCenterApi~apiUserCenterForgetpasswordPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiUserCenterForgetpasswordPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;

      return this.apiClient.callApi(
        '/api/UserCenter/forgetpassword', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterInfoGet operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterInfoGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UserInfoDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 获取个人信息
     * @param {module:api/UserCenterApi~apiUserCenterInfoGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiUserCenterInfoGet(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserInfoDtoResponse;

      return this.apiClient.callApi(
        '/api/UserCenter/info', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterInfoPost operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterInfoPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BooleanResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 更新个人信息
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {module:model/UserSexEnum} opts.sex 
     * @param {String} opts.password 
     * @param {String} opts.phone 
     * @param {String} opts.title 
     * @param {String} opts.university 
     * @param {String} opts.institution 
     * @param {String} opts.avator 
     * @param {module:api/UserCenterApi~apiUserCenterInfoPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiUserCenterInfoPost(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        'Name': opts['name'],'Sex': opts['sex'],'Password': opts['password'],'Phone': opts['phone'],'Title': opts['title'],'University': opts['university'],'Institution': opts['institution'],'Avator': opts['avator']
      };

      let authNames = ['Bearer'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;

      return this.apiClient.callApi(
        '/api/UserCenter/info', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterMycommentsGet operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterMycommentsGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CommentListReponseResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 个人中心-我的上传-评论查询
     * @param {module:model/SearchTypeEnum} searchType 
     * @param {Object} opts Optional parameters
     * @param {module:model/OrderByDescEnum} opts.orderByDesc 
     * @param {module:model/OrderByEnum} opts.orderBy 
     * @param {Number} opts.index 
     * @param {Number} opts.size 
     * @param {module:api/UserCenterApi~apiUserCenterMycommentsGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiUserCenterMycommentsGet(searchType, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'searchType' is set
      if (searchType === undefined || searchType === null) {
        throw new Error("Missing the required parameter 'searchType' when calling apiUserCenterMycommentsGet");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'OrderByDesc': opts['orderByDesc'],'OrderBy': opts['orderBy'],'SearchType': searchType,'Index': opts['index'],'Size': opts['size']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CommentListReponseResponse;

      return this.apiClient.callApi(
        '/api/UserCenter/mycomments', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterMylearntopicGet operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterMylearntopicGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LearnTopicListDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 个人中心-我的上传-交流话题查询
     * @param {module:model/SearchTypeEnum} searchType 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.index 
     * @param {Number} opts.size 
     * @param {module:api/UserCenterApi~apiUserCenterMylearntopicGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiUserCenterMylearntopicGet(searchType, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'searchType' is set
      if (searchType === undefined || searchType === null) {
        throw new Error("Missing the required parameter 'searchType' when calling apiUserCenterMylearntopicGet");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'SearchType': searchType,'Index': opts['index'],'Size': opts['size']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LearnTopicListDtoResponse;

      return this.apiClient.callApi(
        '/api/UserCenter/mylearntopic', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterSigninPost operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterSigninPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BooleanResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 注册
     * @param {Object} opts Optional parameters
     * @param {String} opts.account 
     * @param {String} opts.password 
     * @param {String} opts.nickName 
     * @param {module:model/UserSexEnum} opts.sex 
     * @param {String} opts.phone 
     * @param {String} opts.title 
     * @param {String} opts.university 
     * @param {String} opts.institution 
     * @param {String} opts.smsCode 
     * @param {String} opts.avator 
     * @param {module:api/UserCenterApi~apiUserCenterSigninPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiUserCenterSigninPost(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        'Account': opts['account'],'Password': opts['password'],'NickName': opts['nickName'],'Sex': opts['sex'],'Phone': opts['phone'],'Title': opts['title'],'University': opts['university'],'Institution': opts['institution'],'SmsCode': opts['smsCode'],'Avator': opts['avator']
      };

      let authNames = ['Bearer'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;

      return this.apiClient.callApi(
        '/api/UserCenter/signin', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterSignonPost operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterSignonPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SignOnUserDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 登录
     * @param {Object} opts Optional parameters
     * @param {String} opts.account 
     * @param {String} opts.password 
     * @param {String} opts.lastLoginIP 
     * @param {Boolean} opts.remember 
     * @param {module:api/UserCenterApi~apiUserCenterSignonPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiUserCenterSignonPost(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        'Account': opts['account'],'Password': opts['password'],'LastLoginIP': opts['lastLoginIP'],'Remember': opts['remember']
      };

      let authNames = ['Bearer'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SignOnUserDtoResponse;

      return this.apiClient.callApi(
        '/api/UserCenter/signon', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterSignoutPost operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterSignoutPostCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 登出
     * @param {module:api/UserCenterApi~apiUserCenterSignoutPostCallback} callback The callback function, accepting three arguments: error, data, response
     */
    apiUserCenterSignoutPost(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/UserCenter/signout', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterSmscaptchaGet operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterSmscaptchaGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/StringResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 短信验证码
     * @param {String} phone 
     * @param {Object} opts Optional parameters
     * @param {String} opts.captcha 
     * @param {String} opts.captchaSha1 
     * @param {module:api/UserCenterApi~apiUserCenterSmscaptchaGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiUserCenterSmscaptchaGet(phone, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'phone' is set
      if (phone === undefined || phone === null) {
        throw new Error("Missing the required parameter 'phone' when calling apiUserCenterSmscaptchaGet");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'phone': phone,'captcha': opts['captcha'],'captchaSha1': opts['captchaSha1']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = StringResponse;

      return this.apiClient.callApi(
        '/api/UserCenter/smscaptcha', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterSubmitsequencePost operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterSubmitsequencePostCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 提交序列
     * @param {Object} opts Optional parameters
     * @param {String} opts.name 
     * @param {String} opts.type 
     * @param {String} opts.target 
     * @param {String} opts.targetType 
     * @param {String} opts.affinity 
     * @param {String} opts.screenMethod 
     * @param {String} opts.sampleType 
     * @param {String} opts.appliedteChnology 
     * @param {String} opts.combiningCondition 
     * @param {String} opts.sequence 
     * @param {module:model/ReferenceTypeEnum} opts.referenceType 
     * @param {String} opts.referenceInfo 
     * @param {module:api/UserCenterApi~apiUserCenterSubmitsequencePostCallback} callback The callback function, accepting three arguments: error, data, response
     */
    apiUserCenterSubmitsequencePost(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        'Name': opts['name'],'Type': opts['type'],'Target': opts['target'],'TargetType': opts['targetType'],'Affinity': opts['affinity'],'ScreenMethod': opts['screenMethod'],'SampleType': opts['sampleType'],'AppliedteChnology': opts['appliedteChnology'],'CombiningCondition': opts['combiningCondition'],'Sequence': opts['sequence'],'ReferenceType': opts['referenceType'],'ReferenceInfo': opts['referenceInfo']
      };

      let authNames = ['Bearer'];
      let contentTypes = ['multipart/form-data'];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/UserCenter/submitsequence', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterTopicsetstatusPost operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterTopicsetstatusPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BooleanResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 交流内容状态设置
     * @param {String} id 
     * @param {module:model/DataStatusEnum} dataStatus 
     * @param {module:api/UserCenterApi~apiUserCenterTopicsetstatusPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiUserCenterTopicsetstatusPost(id, dataStatus, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiUserCenterTopicsetstatusPost");
      }
      // verify the required parameter 'dataStatus' is set
      if (dataStatus === undefined || dataStatus === null) {
        throw new Error("Missing the required parameter 'dataStatus' when calling apiUserCenterTopicsetstatusPost");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'id': id,'dataStatus': dataStatus
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;

      return this.apiClient.callApi(
        '/api/UserCenter/topicsetstatus', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiUserCenterUnfavoriteDelete operation.
     * @callback moduleapi/UserCenterApi~apiUserCenterUnfavoriteDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 取消收藏
     * @param {String} collectionID 
     * @param {module:model/CollectionTypeEnum} collectionType 
     * @param {Object} opts Optional parameters
     * @param {String} opts.userID 
     * @param {module:api/UserCenterApi~apiUserCenterUnfavoriteDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    apiUserCenterUnfavoriteDelete(collectionID, collectionType, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'collectionID' is set
      if (collectionID === undefined || collectionID === null) {
        throw new Error("Missing the required parameter 'collectionID' when calling apiUserCenterUnfavoriteDelete");
      }
      // verify the required parameter 'collectionType' is set
      if (collectionType === undefined || collectionType === null) {
        throw new Error("Missing the required parameter 'collectionType' when calling apiUserCenterUnfavoriteDelete");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'CollectionID': collectionID,'UserID': opts['userID'],'CollectionType': collectionType
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/UserCenter/unfavorite', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}