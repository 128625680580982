/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The UserChangePasswordRequest model module.
 * @module model/UserChangePasswordRequest
 * @version v1
 */
export class UserChangePasswordRequest {
  /**
   * Constructs a new <code>UserChangePasswordRequest</code>.
   * @alias module:model/UserChangePasswordRequest
   * @class
   * @param oldPassword {String} 
   * @param newPassword {String} 
   * @param confirmPassword {String} 
   */
  constructor(oldPassword, newPassword, confirmPassword) {
    this.oldPassword = oldPassword;
    this.newPassword = newPassword;
    this.confirmPassword = confirmPassword;
  }

  /**
   * Constructs a <code>UserChangePasswordRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserChangePasswordRequest} obj Optional instance to populate.
   * @return {module:model/UserChangePasswordRequest} The populated <code>UserChangePasswordRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserChangePasswordRequest();
      if (data.hasOwnProperty('oldPassword'))
        obj.oldPassword = ApiClient.convertToType(data['oldPassword'], 'String');
      if (data.hasOwnProperty('newPassword'))
        obj.newPassword = ApiClient.convertToType(data['newPassword'], 'String');
      if (data.hasOwnProperty('confirmPassword'))
        obj.confirmPassword = ApiClient.convertToType(data['confirmPassword'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} oldPassword
 */
UserChangePasswordRequest.prototype.oldPassword = undefined;

/**
 * @member {String} newPassword
 */
UserChangePasswordRequest.prototype.newPassword = undefined;

/**
 * @member {String} confirmPassword
 */
UserChangePasswordRequest.prototype.confirmPassword = undefined;

