/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {DataStatusEnum} from './DataStatusEnum';
import {LearnTopicTypeEnum} from './LearnTopicTypeEnum';

/**
 * The LearnTopicItemDto model module.
 * @module model/LearnTopicItemDto
 * @version v1
 */
export class LearnTopicItemDto {
  /**
   * Constructs a new <code>LearnTopicItemDto</code>.
   * @alias module:model/LearnTopicItemDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>LearnTopicItemDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/LearnTopicItemDto} obj Optional instance to populate.
   * @return {module:model/LearnTopicItemDto} The populated <code>LearnTopicItemDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new LearnTopicItemDto();
      if (data.hasOwnProperty('learnTopicID'))
        obj.learnTopicID = ApiClient.convertToType(data['learnTopicID'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('content'))
        obj.content = ApiClient.convertToType(data['content'], 'String');
      if (data.hasOwnProperty('status'))
        obj.status = DataStatusEnum.constructFromObject(data['status']);
      if (data.hasOwnProperty('type'))
        obj.type = LearnTopicTypeEnum.constructFromObject(data['type']);
      if (data.hasOwnProperty('commentCount'))
        obj.commentCount = ApiClient.convertToType(data['commentCount'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {String} learnTopicID
 */
LearnTopicItemDto.prototype.learnTopicID = undefined;

/**
 * @member {String} title
 */
LearnTopicItemDto.prototype.title = undefined;

/**
 * @member {String} content
 */
LearnTopicItemDto.prototype.content = undefined;

/**
 * @member {module:model/DataStatusEnum} status
 */
LearnTopicItemDto.prototype.status = undefined;

/**
 * @member {module:model/LearnTopicTypeEnum} type
 */
LearnTopicItemDto.prototype.type = undefined;

/**
 * @member {Number} commentCount
 */
LearnTopicItemDto.prototype.commentCount = undefined;

