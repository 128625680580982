/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.34
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from './ApiClient';
import {AptamerDetailDto} from './model/AptamerDetailDto';
import {AptamerDetailDtoResponse} from './model/AptamerDetailDtoResponse';
import {AptamerDetailTargetDto} from './model/AptamerDetailTargetDto';
import {AptamerExportItemDto} from './model/AptamerExportItemDto';
import {AptamerExportResponse} from './model/AptamerExportResponse';
import {AptamerExportResponseResponse} from './model/AptamerExportResponseResponse';
import {AptamerForContrast} from './model/AptamerForContrast';
import {AptamerItemDto} from './model/AptamerItemDto';
import {AptamerListDto} from './model/AptamerListDto';
import {AptamerListDtoResponse} from './model/AptamerListDtoResponse';
import {AptamerRelatedArticleDto} from './model/AptamerRelatedArticleDto';
import {AptamerRelatedArticleList} from './model/AptamerRelatedArticleList';
import {AptamerRelatedPatentDto} from './model/AptamerRelatedPatentDto';
import {AptamerRelatedPatentList} from './model/AptamerRelatedPatentList';
import {AptamerSequenceDetailDto} from './model/AptamerSequenceDetailDto';
import {ArticleCommentDto} from './model/ArticleCommentDto';
import {ArticleCommentsDto} from './model/ArticleCommentsDto';
import {ArticleCommentsDtoResponse} from './model/ArticleCommentsDtoResponse';
import {ArticleDetailDto} from './model/ArticleDetailDto';
import {ArticleDetailDtoResponse} from './model/ArticleDetailDtoResponse';
import {ArticleExportItemDto} from './model/ArticleExportItemDto';
import {ArticleExportResponseDto} from './model/ArticleExportResponseDto';
import {ArticleExportResponseDtoResponse} from './model/ArticleExportResponseDtoResponse';
import {ArticleFigure} from './model/ArticleFigure';
import {ArticleFigureResponse} from './model/ArticleFigureResponse';
import {ArticleFigureResponseResponse} from './model/ArticleFigureResponseResponse';
import {ArticleItemDto} from './model/ArticleItemDto';
import {ArticleListDto} from './model/ArticleListDto';
import {ArticleListDtoResponse} from './model/ArticleListDtoResponse';
import {ArticleProduct} from './model/ArticleProduct';
import {ArticleProductResult} from './model/ArticleProductResult';
import {ArticleProductResultResponse} from './model/ArticleProductResultResponse';
import {BarChartDto} from './model/BarChartDto';
import {BooleanResponse} from './model/BooleanResponse';
import {Clinical} from './model/Clinical';
import {ClinicalDetailDescriptiveDto} from './model/ClinicalDetailDescriptiveDto';
import {ClinicalDetailDto} from './model/ClinicalDetailDto';
import {ClinicalDetailDtoResponse} from './model/ClinicalDetailDtoResponse';
import {ClinicalDetailRecruitmentDto} from './model/ClinicalDetailRecruitmentDto';
import {ClinicalDetailTrackingDto} from './model/ClinicalDetailTrackingDto';
import {ClinicalItemDto} from './model/ClinicalItemDto';
import {ClinicalListDto} from './model/ClinicalListDto';
import {ClinicalListDtoResponse} from './model/ClinicalListDtoResponse';
import {CollectionTypeEnum} from './model/CollectionTypeEnum';
import {CommentListReponse} from './model/CommentListReponse';
import {CommentListReponseResponse} from './model/CommentListReponseResponse';
import {CommentType} from './model/CommentType';
import {ContrastInfo} from './model/ContrastInfo';
import {Country} from './model/Country';
import {DataStatusEnum} from './model/DataStatusEnum';
import {DynamicItemDto} from './model/DynamicItemDto';
import {DynamicListDto} from './model/DynamicListDto';
import {DynamicListDtoResponse} from './model/DynamicListDtoResponse';
import {EnumCandidateDto} from './model/EnumCandidateDto';
import {EvaluateContrastDto} from './model/EvaluateContrastDto';
import {EvaluateContrastInfo} from './model/EvaluateContrastInfo';
import {EvaluateContrastInfoResponse} from './model/EvaluateContrastInfoResponse';
import {EvaluateContrastResult} from './model/EvaluateContrastResult';
import {EvaluateContrastResultResponse} from './model/EvaluateContrastResultResponse';
import {EvaluationOtherInfo} from './model/EvaluationOtherInfo';
import {EvaluationOtherInfoReturn} from './model/EvaluationOtherInfoReturn';
import {EvaluationOtherInfoReturnResponse} from './model/EvaluationOtherInfoReturnResponse';
import {Favorite} from './model/Favorite';
import {FeedsArticleItemDto} from './model/FeedsArticleItemDto';
import {FeedsArticleItemDtoResponse} from './model/FeedsArticleItemDtoResponse';
import {FeedsArticleListDto} from './model/FeedsArticleListDto';
import {FeedsArticleListDtoResponse} from './model/FeedsArticleListDtoResponse';
import {ForgetPasswordRequest} from './model/ForgetPasswordRequest';
import {HSPSDto} from './model/HSPSDto';
import {HttpCodeEnum} from './model/HttpCodeEnum';
import {IntelligentFilterDto} from './model/IntelligentFilterDto';
import {IntelligentFilterDtoResponse} from './model/IntelligentFilterDtoResponse';
import {LearnTopicDetailDto} from './model/LearnTopicDetailDto';
import {LearnTopicDetailDtoResponse} from './model/LearnTopicDetailDtoResponse';
import {LearnTopicItemDto} from './model/LearnTopicItemDto';
import {LearnTopicListDto} from './model/LearnTopicListDto';
import {LearnTopicListDtoResponse} from './model/LearnTopicListDtoResponse';
import {LearnTopicTypeEnum} from './model/LearnTopicTypeEnum';
import {MyComment} from './model/MyComment';
import {News} from './model/News';
import {NewsListDto} from './model/NewsListDto';
import {NewsListDtoResponse} from './model/NewsListDtoResponse';
import {OrderByDescEnum} from './model/OrderByDescEnum';
import {OrderByEnum} from './model/OrderByEnum';
import {PatentDetailDto} from './model/PatentDetailDto';
import {PatentDetailDtoResponse} from './model/PatentDetailDtoResponse';
import {PatentItemDto} from './model/PatentItemDto';
import {PatentListDto} from './model/PatentListDto';
import {PatentListDtoResponse} from './model/PatentListDtoResponse';
import {PatentTypeEnum} from './model/PatentTypeEnum';
import {PieDoughnutDto} from './model/PieDoughnutDto';
import {PieDoughnutDtoListResponse} from './model/PieDoughnutDtoListResponse';
import {ProjectDetailDto} from './model/ProjectDetailDto';
import {ProjectDetailDtoResponse} from './model/ProjectDetailDtoResponse';
import {ProjectItemDto} from './model/ProjectItemDto';
import {ProjectListDto} from './model/ProjectListDto';
import {ProjectListDtoResponse} from './model/ProjectListDtoResponse';
import {RecommendInfo} from './model/RecommendInfo';
import {ReferenceTypeEnum} from './model/ReferenceTypeEnum';
import {RelateProjectItem} from './model/RelateProjectItem';
import {RelatedArticle} from './model/RelatedArticle';
import {RelatedArticleResponse} from './model/RelatedArticleResponse';
import {ReturnHSPSDto} from './model/ReturnHSPSDto';
import {SearchTypeEnum} from './model/SearchTypeEnum';
import {SequenceInfo} from './model/SequenceInfo';
import {SequenceListDto} from './model/SequenceListDto';
import {SequenceListDtoResponse} from './model/SequenceListDtoResponse';
import {SignOnUserDto} from './model/SignOnUserDto';
import {SignOnUserDtoResponse} from './model/SignOnUserDtoResponse';
import {StringListResponse} from './model/StringListResponse';
import {StringResponse} from './model/StringResponse';
import {SubmitLearnTopicRequest} from './model/SubmitLearnTopicRequest';
import {SubmittedSequence} from './model/SubmittedSequence';
import {SubmittedSequenceBatchuploadBody} from './model/SubmittedSequenceBatchuploadBody';
import {SubmittedSequenceDetailDto} from './model/SubmittedSequenceDetailDto';
import {SubmittedSequenceDetailDtoResponse} from './model/SubmittedSequenceDetailDtoResponse';
import {SubmittedSequenceItemDto} from './model/SubmittedSequenceItemDto';
import {SubmittedSequenceListDto} from './model/SubmittedSequenceListDto';
import {SubmittedSequenceListDtoResponse} from './model/SubmittedSequenceListDtoResponse';
import {SysConfigKeyEnum} from './model/SysConfigKeyEnum';
import {TargetTypeEnum} from './model/TargetTypeEnum';
import {TeamItemDto} from './model/TeamItemDto';
import {TeamListDto} from './model/TeamListDto';
import {TeamListDtoResponse} from './model/TeamListDtoResponse';
import {UserCenterCommentBody} from './model/UserCenterCommentBody';
import {UserCenterFavoriteBody} from './model/UserCenterFavoriteBody';
import {UserCenterFeedbackBody} from './model/UserCenterFeedbackBody';
import {UserCenterInfoBody} from './model/UserCenterInfoBody';
import {UserCenterSigninBody} from './model/UserCenterSigninBody';
import {UserCenterSignonBody} from './model/UserCenterSignonBody';
import {UserCenterSubmitsequenceBody} from './model/UserCenterSubmitsequenceBody';
import {UserChangePasswordRequest} from './model/UserChangePasswordRequest';
import {UserInfoDto} from './model/UserInfoDto';
import {UserInfoDtoResponse} from './model/UserInfoDtoResponse';
import {UserSexEnum} from './model/UserSexEnum';
import {VideoManagerItemDto} from './model/VideoManagerItemDto';
import {VideoManagerListDto} from './model/VideoManagerListDto';
import {VideoManagerListDtoResponse} from './model/VideoManagerListDtoResponse';
import {AptamerApi} from './api/AptamerApi';
import {ArticleApi} from './api/ArticleApi';
import {ClinicalApi} from './api/ClinicalApi';
import {DynamicManagerApi} from './api/DynamicManagerApi';
import {FeedsArticleApi} from './api/FeedsArticleApi';
import {LearnTopicApi} from './api/LearnTopicApi';
import {PatentApi} from './api/PatentApi';
import {ProjectApi} from './api/ProjectApi';
import {SequenceApi} from './api/SequenceApi';
import {SubmittedSequenceApi} from './api/SubmittedSequenceApi';
import {SysConfigApi} from './api/SysConfigApi';
import {TeamApi} from './api/TeamApi';
import {UserCenterApi} from './api/UserCenterApi';
import {VideoManagerApi} from './api/VideoManagerApi';
import {WeatherForecastApi} from './api/WeatherForecastApi';

/**
* Object.<br>
* The <code>index</code> module provides access to constructors for all the classes which comprise the public API.
* <p>
* An AMD (recommended!) or CommonJS application will generally do something equivalent to the following:
* <pre>
* var AptamerDataBaseweb = require('index'); // See note below*.
* var xxxSvc = new AptamerDataBaseweb.XxxApi(); // Allocate the API class we're going to use.
* var yyyModel = new AptamerDataBaseweb.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* <em>*NOTE: For a top-level AMD script, use require(['index'], function(){...})
* and put the application logic within the callback function.</em>
* </p>
* <p>
* A non-AMD browser application (discouraged) might do something like this:
* <pre>
* var xxxSvc = new AptamerDataBaseweb.XxxApi(); // Allocate the API class we're going to use.
* var yyy = new AptamerDataBaseweb.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* </p>
* @module index
* @version v1
*/
export {
    /**
     * The ApiClient constructor.
     * @property {module:ApiClient}
     */
    ApiClient,

    /**
     * The AptamerDetailDto model constructor.
     * @property {module:model/AptamerDetailDto}
     */
    AptamerDetailDto,

    /**
     * The AptamerDetailDtoResponse model constructor.
     * @property {module:model/AptamerDetailDtoResponse}
     */
    AptamerDetailDtoResponse,

    /**
     * The AptamerDetailTargetDto model constructor.
     * @property {module:model/AptamerDetailTargetDto}
     */
    AptamerDetailTargetDto,

    /**
     * The AptamerExportItemDto model constructor.
     * @property {module:model/AptamerExportItemDto}
     */
    AptamerExportItemDto,

    /**
     * The AptamerExportResponse model constructor.
     * @property {module:model/AptamerExportResponse}
     */
    AptamerExportResponse,

    /**
     * The AptamerExportResponseResponse model constructor.
     * @property {module:model/AptamerExportResponseResponse}
     */
    AptamerExportResponseResponse,

    /**
     * The AptamerForContrast model constructor.
     * @property {module:model/AptamerForContrast}
     */
    AptamerForContrast,

    /**
     * The AptamerItemDto model constructor.
     * @property {module:model/AptamerItemDto}
     */
    AptamerItemDto,

    /**
     * The AptamerListDto model constructor.
     * @property {module:model/AptamerListDto}
     */
    AptamerListDto,

    /**
     * The AptamerListDtoResponse model constructor.
     * @property {module:model/AptamerListDtoResponse}
     */
    AptamerListDtoResponse,

    /**
     * The AptamerRelatedArticleDto model constructor.
     * @property {module:model/AptamerRelatedArticleDto}
     */
    AptamerRelatedArticleDto,

    /**
     * The AptamerRelatedArticleList model constructor.
     * @property {module:model/AptamerRelatedArticleList}
     */
    AptamerRelatedArticleList,

    /**
     * The AptamerRelatedPatentDto model constructor.
     * @property {module:model/AptamerRelatedPatentDto}
     */
    AptamerRelatedPatentDto,

    /**
     * The AptamerRelatedPatentList model constructor.
     * @property {module:model/AptamerRelatedPatentList}
     */
    AptamerRelatedPatentList,

    /**
     * The AptamerSequenceDetailDto model constructor.
     * @property {module:model/AptamerSequenceDetailDto}
     */
    AptamerSequenceDetailDto,

    /**
     * The ArticleCommentDto model constructor.
     * @property {module:model/ArticleCommentDto}
     */
    ArticleCommentDto,

    /**
     * The ArticleCommentsDto model constructor.
     * @property {module:model/ArticleCommentsDto}
     */
    ArticleCommentsDto,

    /**
     * The ArticleCommentsDtoResponse model constructor.
     * @property {module:model/ArticleCommentsDtoResponse}
     */
    ArticleCommentsDtoResponse,

    /**
     * The ArticleDetailDto model constructor.
     * @property {module:model/ArticleDetailDto}
     */
    ArticleDetailDto,

    /**
     * The ArticleDetailDtoResponse model constructor.
     * @property {module:model/ArticleDetailDtoResponse}
     */
    ArticleDetailDtoResponse,

    /**
     * The ArticleExportItemDto model constructor.
     * @property {module:model/ArticleExportItemDto}
     */
    ArticleExportItemDto,

    /**
     * The ArticleExportResponseDto model constructor.
     * @property {module:model/ArticleExportResponseDto}
     */
    ArticleExportResponseDto,

    /**
     * The ArticleExportResponseDtoResponse model constructor.
     * @property {module:model/ArticleExportResponseDtoResponse}
     */
    ArticleExportResponseDtoResponse,

    /**
     * The ArticleFigure model constructor.
     * @property {module:model/ArticleFigure}
     */
    ArticleFigure,

    /**
     * The ArticleFigureResponse model constructor.
     * @property {module:model/ArticleFigureResponse}
     */
    ArticleFigureResponse,

    /**
     * The ArticleFigureResponseResponse model constructor.
     * @property {module:model/ArticleFigureResponseResponse}
     */
    ArticleFigureResponseResponse,

    /**
     * The ArticleItemDto model constructor.
     * @property {module:model/ArticleItemDto}
     */
    ArticleItemDto,

    /**
     * The ArticleListDto model constructor.
     * @property {module:model/ArticleListDto}
     */
    ArticleListDto,

    /**
     * The ArticleListDtoResponse model constructor.
     * @property {module:model/ArticleListDtoResponse}
     */
    ArticleListDtoResponse,

    /**
     * The ArticleProduct model constructor.
     * @property {module:model/ArticleProduct}
     */
    ArticleProduct,

    /**
     * The ArticleProductResult model constructor.
     * @property {module:model/ArticleProductResult}
     */
    ArticleProductResult,

    /**
     * The ArticleProductResultResponse model constructor.
     * @property {module:model/ArticleProductResultResponse}
     */
    ArticleProductResultResponse,

    /**
     * The BarChartDto model constructor.
     * @property {module:model/BarChartDto}
     */
    BarChartDto,

    /**
     * The BooleanResponse model constructor.
     * @property {module:model/BooleanResponse}
     */
    BooleanResponse,

    /**
     * The Clinical model constructor.
     * @property {module:model/Clinical}
     */
    Clinical,

    /**
     * The ClinicalDetailDescriptiveDto model constructor.
     * @property {module:model/ClinicalDetailDescriptiveDto}
     */
    ClinicalDetailDescriptiveDto,

    /**
     * The ClinicalDetailDto model constructor.
     * @property {module:model/ClinicalDetailDto}
     */
    ClinicalDetailDto,

    /**
     * The ClinicalDetailDtoResponse model constructor.
     * @property {module:model/ClinicalDetailDtoResponse}
     */
    ClinicalDetailDtoResponse,

    /**
     * The ClinicalDetailRecruitmentDto model constructor.
     * @property {module:model/ClinicalDetailRecruitmentDto}
     */
    ClinicalDetailRecruitmentDto,

    /**
     * The ClinicalDetailTrackingDto model constructor.
     * @property {module:model/ClinicalDetailTrackingDto}
     */
    ClinicalDetailTrackingDto,

    /**
     * The ClinicalItemDto model constructor.
     * @property {module:model/ClinicalItemDto}
     */
    ClinicalItemDto,

    /**
     * The ClinicalListDto model constructor.
     * @property {module:model/ClinicalListDto}
     */
    ClinicalListDto,

    /**
     * The ClinicalListDtoResponse model constructor.
     * @property {module:model/ClinicalListDtoResponse}
     */
    ClinicalListDtoResponse,

    /**
     * The CollectionTypeEnum model constructor.
     * @property {module:model/CollectionTypeEnum}
     */
    CollectionTypeEnum,

    /**
     * The CommentListReponse model constructor.
     * @property {module:model/CommentListReponse}
     */
    CommentListReponse,

    /**
     * The CommentListReponseResponse model constructor.
     * @property {module:model/CommentListReponseResponse}
     */
    CommentListReponseResponse,

    /**
     * The CommentType model constructor.
     * @property {module:model/CommentType}
     */
    CommentType,

    /**
     * The ContrastInfo model constructor.
     * @property {module:model/ContrastInfo}
     */
    ContrastInfo,

    /**
     * The Country model constructor.
     * @property {module:model/Country}
     */
    Country,

    /**
     * The DataStatusEnum model constructor.
     * @property {module:model/DataStatusEnum}
     */
    DataStatusEnum,

    /**
     * The DynamicItemDto model constructor.
     * @property {module:model/DynamicItemDto}
     */
    DynamicItemDto,

    /**
     * The DynamicListDto model constructor.
     * @property {module:model/DynamicListDto}
     */
    DynamicListDto,

    /**
     * The DynamicListDtoResponse model constructor.
     * @property {module:model/DynamicListDtoResponse}
     */
    DynamicListDtoResponse,

    /**
     * The EnumCandidateDto model constructor.
     * @property {module:model/EnumCandidateDto}
     */
    EnumCandidateDto,

    /**
     * The EvaluateContrastDto model constructor.
     * @property {module:model/EvaluateContrastDto}
     */
    EvaluateContrastDto,

    /**
     * The EvaluateContrastInfo model constructor.
     * @property {module:model/EvaluateContrastInfo}
     */
    EvaluateContrastInfo,

    /**
     * The EvaluateContrastInfoResponse model constructor.
     * @property {module:model/EvaluateContrastInfoResponse}
     */
    EvaluateContrastInfoResponse,

    /**
     * The EvaluateContrastResult model constructor.
     * @property {module:model/EvaluateContrastResult}
     */
    EvaluateContrastResult,

    /**
     * The EvaluateContrastResultResponse model constructor.
     * @property {module:model/EvaluateContrastResultResponse}
     */
    EvaluateContrastResultResponse,

    /**
     * The EvaluationOtherInfo model constructor.
     * @property {module:model/EvaluationOtherInfo}
     */
    EvaluationOtherInfo,

    /**
     * The EvaluationOtherInfoReturn model constructor.
     * @property {module:model/EvaluationOtherInfoReturn}
     */
    EvaluationOtherInfoReturn,

    /**
     * The EvaluationOtherInfoReturnResponse model constructor.
     * @property {module:model/EvaluationOtherInfoReturnResponse}
     */
    EvaluationOtherInfoReturnResponse,

    /**
     * The Favorite model constructor.
     * @property {module:model/Favorite}
     */
    Favorite,

    /**
     * The FeedsArticleItemDto model constructor.
     * @property {module:model/FeedsArticleItemDto}
     */
    FeedsArticleItemDto,

    /**
     * The FeedsArticleItemDtoResponse model constructor.
     * @property {module:model/FeedsArticleItemDtoResponse}
     */
    FeedsArticleItemDtoResponse,

    /**
     * The FeedsArticleListDto model constructor.
     * @property {module:model/FeedsArticleListDto}
     */
    FeedsArticleListDto,

    /**
     * The FeedsArticleListDtoResponse model constructor.
     * @property {module:model/FeedsArticleListDtoResponse}
     */
    FeedsArticleListDtoResponse,

    /**
     * The ForgetPasswordRequest model constructor.
     * @property {module:model/ForgetPasswordRequest}
     */
    ForgetPasswordRequest,

    /**
     * The HSPSDto model constructor.
     * @property {module:model/HSPSDto}
     */
    HSPSDto,

    /**
     * The HttpCodeEnum model constructor.
     * @property {module:model/HttpCodeEnum}
     */
    HttpCodeEnum,

    /**
     * The IntelligentFilterDto model constructor.
     * @property {module:model/IntelligentFilterDto}
     */
    IntelligentFilterDto,

    /**
     * The IntelligentFilterDtoResponse model constructor.
     * @property {module:model/IntelligentFilterDtoResponse}
     */
    IntelligentFilterDtoResponse,

    /**
     * The LearnTopicDetailDto model constructor.
     * @property {module:model/LearnTopicDetailDto}
     */
    LearnTopicDetailDto,

    /**
     * The LearnTopicDetailDtoResponse model constructor.
     * @property {module:model/LearnTopicDetailDtoResponse}
     */
    LearnTopicDetailDtoResponse,

    /**
     * The LearnTopicItemDto model constructor.
     * @property {module:model/LearnTopicItemDto}
     */
    LearnTopicItemDto,

    /**
     * The LearnTopicListDto model constructor.
     * @property {module:model/LearnTopicListDto}
     */
    LearnTopicListDto,

    /**
     * The LearnTopicListDtoResponse model constructor.
     * @property {module:model/LearnTopicListDtoResponse}
     */
    LearnTopicListDtoResponse,

    /**
     * The LearnTopicTypeEnum model constructor.
     * @property {module:model/LearnTopicTypeEnum}
     */
    LearnTopicTypeEnum,

    /**
     * The MyComment model constructor.
     * @property {module:model/MyComment}
     */
    MyComment,

    /**
     * The News model constructor.
     * @property {module:model/News}
     */
    News,

    /**
     * The NewsListDto model constructor.
     * @property {module:model/NewsListDto}
     */
    NewsListDto,

    /**
     * The NewsListDtoResponse model constructor.
     * @property {module:model/NewsListDtoResponse}
     */
    NewsListDtoResponse,

    /**
     * The OrderByDescEnum model constructor.
     * @property {module:model/OrderByDescEnum}
     */
    OrderByDescEnum,

    /**
     * The OrderByEnum model constructor.
     * @property {module:model/OrderByEnum}
     */
    OrderByEnum,

    /**
     * The PatentDetailDto model constructor.
     * @property {module:model/PatentDetailDto}
     */
    PatentDetailDto,

    /**
     * The PatentDetailDtoResponse model constructor.
     * @property {module:model/PatentDetailDtoResponse}
     */
    PatentDetailDtoResponse,

    /**
     * The PatentItemDto model constructor.
     * @property {module:model/PatentItemDto}
     */
    PatentItemDto,

    /**
     * The PatentListDto model constructor.
     * @property {module:model/PatentListDto}
     */
    PatentListDto,

    /**
     * The PatentListDtoResponse model constructor.
     * @property {module:model/PatentListDtoResponse}
     */
    PatentListDtoResponse,

    /**
     * The PatentTypeEnum model constructor.
     * @property {module:model/PatentTypeEnum}
     */
    PatentTypeEnum,

    /**
     * The PieDoughnutDto model constructor.
     * @property {module:model/PieDoughnutDto}
     */
    PieDoughnutDto,

    /**
     * The PieDoughnutDtoListResponse model constructor.
     * @property {module:model/PieDoughnutDtoListResponse}
     */
    PieDoughnutDtoListResponse,

    /**
     * The ProjectDetailDto model constructor.
     * @property {module:model/ProjectDetailDto}
     */
    ProjectDetailDto,

    /**
     * The ProjectDetailDtoResponse model constructor.
     * @property {module:model/ProjectDetailDtoResponse}
     */
    ProjectDetailDtoResponse,

    /**
     * The ProjectItemDto model constructor.
     * @property {module:model/ProjectItemDto}
     */
    ProjectItemDto,

    /**
     * The ProjectListDto model constructor.
     * @property {module:model/ProjectListDto}
     */
    ProjectListDto,

    /**
     * The ProjectListDtoResponse model constructor.
     * @property {module:model/ProjectListDtoResponse}
     */
    ProjectListDtoResponse,

    /**
     * The RecommendInfo model constructor.
     * @property {module:model/RecommendInfo}
     */
    RecommendInfo,

    /**
     * The ReferenceTypeEnum model constructor.
     * @property {module:model/ReferenceTypeEnum}
     */
    ReferenceTypeEnum,

    /**
     * The RelateProjectItem model constructor.
     * @property {module:model/RelateProjectItem}
     */
    RelateProjectItem,

    /**
     * The RelatedArticle model constructor.
     * @property {module:model/RelatedArticle}
     */
    RelatedArticle,

    /**
     * The RelatedArticleResponse model constructor.
     * @property {module:model/RelatedArticleResponse}
     */
    RelatedArticleResponse,

    /**
     * The ReturnHSPSDto model constructor.
     * @property {module:model/ReturnHSPSDto}
     */
    ReturnHSPSDto,

    /**
     * The SearchTypeEnum model constructor.
     * @property {module:model/SearchTypeEnum}
     */
    SearchTypeEnum,

    /**
     * The SequenceInfo model constructor.
     * @property {module:model/SequenceInfo}
     */
    SequenceInfo,

    /**
     * The SequenceListDto model constructor.
     * @property {module:model/SequenceListDto}
     */
    SequenceListDto,

    /**
     * The SequenceListDtoResponse model constructor.
     * @property {module:model/SequenceListDtoResponse}
     */
    SequenceListDtoResponse,

    /**
     * The SignOnUserDto model constructor.
     * @property {module:model/SignOnUserDto}
     */
    SignOnUserDto,

    /**
     * The SignOnUserDtoResponse model constructor.
     * @property {module:model/SignOnUserDtoResponse}
     */
    SignOnUserDtoResponse,

    /**
     * The StringListResponse model constructor.
     * @property {module:model/StringListResponse}
     */
    StringListResponse,

    /**
     * The StringResponse model constructor.
     * @property {module:model/StringResponse}
     */
    StringResponse,

    /**
     * The SubmitLearnTopicRequest model constructor.
     * @property {module:model/SubmitLearnTopicRequest}
     */
    SubmitLearnTopicRequest,

    /**
     * The SubmittedSequence model constructor.
     * @property {module:model/SubmittedSequence}
     */
    SubmittedSequence,

    /**
     * The SubmittedSequenceBatchuploadBody model constructor.
     * @property {module:model/SubmittedSequenceBatchuploadBody}
     */
    SubmittedSequenceBatchuploadBody,

    /**
     * The SubmittedSequenceDetailDto model constructor.
     * @property {module:model/SubmittedSequenceDetailDto}
     */
    SubmittedSequenceDetailDto,

    /**
     * The SubmittedSequenceDetailDtoResponse model constructor.
     * @property {module:model/SubmittedSequenceDetailDtoResponse}
     */
    SubmittedSequenceDetailDtoResponse,

    /**
     * The SubmittedSequenceItemDto model constructor.
     * @property {module:model/SubmittedSequenceItemDto}
     */
    SubmittedSequenceItemDto,

    /**
     * The SubmittedSequenceListDto model constructor.
     * @property {module:model/SubmittedSequenceListDto}
     */
    SubmittedSequenceListDto,

    /**
     * The SubmittedSequenceListDtoResponse model constructor.
     * @property {module:model/SubmittedSequenceListDtoResponse}
     */
    SubmittedSequenceListDtoResponse,

    /**
     * The SysConfigKeyEnum model constructor.
     * @property {module:model/SysConfigKeyEnum}
     */
    SysConfigKeyEnum,

    /**
     * The TargetTypeEnum model constructor.
     * @property {module:model/TargetTypeEnum}
     */
    TargetTypeEnum,

    /**
     * The TeamItemDto model constructor.
     * @property {module:model/TeamItemDto}
     */
    TeamItemDto,

    /**
     * The TeamListDto model constructor.
     * @property {module:model/TeamListDto}
     */
    TeamListDto,

    /**
     * The TeamListDtoResponse model constructor.
     * @property {module:model/TeamListDtoResponse}
     */
    TeamListDtoResponse,

    /**
     * The UserCenterCommentBody model constructor.
     * @property {module:model/UserCenterCommentBody}
     */
    UserCenterCommentBody,

    /**
     * The UserCenterFavoriteBody model constructor.
     * @property {module:model/UserCenterFavoriteBody}
     */
    UserCenterFavoriteBody,

    /**
     * The UserCenterFeedbackBody model constructor.
     * @property {module:model/UserCenterFeedbackBody}
     */
    UserCenterFeedbackBody,

    /**
     * The UserCenterInfoBody model constructor.
     * @property {module:model/UserCenterInfoBody}
     */
    UserCenterInfoBody,

    /**
     * The UserCenterSigninBody model constructor.
     * @property {module:model/UserCenterSigninBody}
     */
    UserCenterSigninBody,

    /**
     * The UserCenterSignonBody model constructor.
     * @property {module:model/UserCenterSignonBody}
     */
    UserCenterSignonBody,

    /**
     * The UserCenterSubmitsequenceBody model constructor.
     * @property {module:model/UserCenterSubmitsequenceBody}
     */
    UserCenterSubmitsequenceBody,

    /**
     * The UserChangePasswordRequest model constructor.
     * @property {module:model/UserChangePasswordRequest}
     */
    UserChangePasswordRequest,

    /**
     * The UserInfoDto model constructor.
     * @property {module:model/UserInfoDto}
     */
    UserInfoDto,

    /**
     * The UserInfoDtoResponse model constructor.
     * @property {module:model/UserInfoDtoResponse}
     */
    UserInfoDtoResponse,

    /**
     * The UserSexEnum model constructor.
     * @property {module:model/UserSexEnum}
     */
    UserSexEnum,

    /**
     * The VideoManagerItemDto model constructor.
     * @property {module:model/VideoManagerItemDto}
     */
    VideoManagerItemDto,

    /**
     * The VideoManagerListDto model constructor.
     * @property {module:model/VideoManagerListDto}
     */
    VideoManagerListDto,

    /**
     * The VideoManagerListDtoResponse model constructor.
     * @property {module:model/VideoManagerListDtoResponse}
     */
    VideoManagerListDtoResponse,

    /**
    * The AptamerApi service constructor.
    * @property {module:api/AptamerApi}
    */
    AptamerApi,

    /**
    * The ArticleApi service constructor.
    * @property {module:api/ArticleApi}
    */
    ArticleApi,

    /**
    * The ClinicalApi service constructor.
    * @property {module:api/ClinicalApi}
    */
    ClinicalApi,

    /**
    * The DynamicManagerApi service constructor.
    * @property {module:api/DynamicManagerApi}
    */
    DynamicManagerApi,

    /**
    * The FeedsArticleApi service constructor.
    * @property {module:api/FeedsArticleApi}
    */
    FeedsArticleApi,

    /**
    * The LearnTopicApi service constructor.
    * @property {module:api/LearnTopicApi}
    */
    LearnTopicApi,

    /**
    * The PatentApi service constructor.
    * @property {module:api/PatentApi}
    */
    PatentApi,

    /**
    * The ProjectApi service constructor.
    * @property {module:api/ProjectApi}
    */
    ProjectApi,

    /**
    * The SequenceApi service constructor.
    * @property {module:api/SequenceApi}
    */
    SequenceApi,

    /**
    * The SubmittedSequenceApi service constructor.
    * @property {module:api/SubmittedSequenceApi}
    */
    SubmittedSequenceApi,

    /**
    * The SysConfigApi service constructor.
    * @property {module:api/SysConfigApi}
    */
    SysConfigApi,

    /**
    * The TeamApi service constructor.
    * @property {module:api/TeamApi}
    */
    TeamApi,

    /**
    * The UserCenterApi service constructor.
    * @property {module:api/UserCenterApi}
    */
    UserCenterApi,

    /**
    * The VideoManagerApi service constructor.
    * @property {module:api/VideoManagerApi}
    */
    VideoManagerApi,

    /**
    * The WeatherForecastApi service constructor.
    * @property {module:api/WeatherForecastApi}
    */
    WeatherForecastApi
};
