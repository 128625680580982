/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The AptamerRelatedArticleDto model module.
 * @module model/AptamerRelatedArticleDto
 * @version v1
 */
export class AptamerRelatedArticleDto {
  /**
   * Constructs a new <code>AptamerRelatedArticleDto</code>.
   * @alias module:model/AptamerRelatedArticleDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AptamerRelatedArticleDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AptamerRelatedArticleDto} obj Optional instance to populate.
   * @return {module:model/AptamerRelatedArticleDto} The populated <code>AptamerRelatedArticleDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AptamerRelatedArticleDto();
      if (data.hasOwnProperty('articleID'))
        obj.articleID = ApiClient.convertToType(data['articleID'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('author'))
        obj.author = ApiClient.convertToType(data['author'], 'String');
      if (data.hasOwnProperty('issn'))
        obj.issn = ApiClient.convertToType(data['issn'], 'String');
      if (data.hasOwnProperty('journal'))
        obj.journal = ApiClient.convertToType(data['journal'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} articleID
 */
AptamerRelatedArticleDto.prototype.articleID = undefined;

/**
 * @member {String} title
 */
AptamerRelatedArticleDto.prototype.title = undefined;

/**
 * @member {String} author
 */
AptamerRelatedArticleDto.prototype.author = undefined;

/**
 * @member {String} issn
 */
AptamerRelatedArticleDto.prototype.issn = undefined;

/**
 * @member {String} journal
 */
AptamerRelatedArticleDto.prototype.journal = undefined;

