/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Country} from './Country';

/**
 * The TeamItemDto model module.
 * @module model/TeamItemDto
 * @version v1
 */
export class TeamItemDto {
  /**
   * Constructs a new <code>TeamItemDto</code>.
   * @alias module:model/TeamItemDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>TeamItemDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TeamItemDto} obj Optional instance to populate.
   * @return {module:model/TeamItemDto} The populated <code>TeamItemDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TeamItemDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('institution'))
        obj.institution = ApiClient.convertToType(data['institution'], 'String');
      if (data.hasOwnProperty('country'))
        obj.country = Country.constructFromObject(data['country']);
      if (data.hasOwnProperty('url'))
        obj.url = ApiClient.convertToType(data['url'], 'String');
      if (data.hasOwnProperty('isFavorite'))
        obj.isFavorite = ApiClient.convertToType(data['isFavorite'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
TeamItemDto.prototype.id = undefined;

/**
 * @member {String} name
 */
TeamItemDto.prototype.name = undefined;

/**
 * @member {String} institution
 */
TeamItemDto.prototype.institution = undefined;

/**
 * @member {module:model/Country} country
 */
TeamItemDto.prototype.country = undefined;

/**
 * @member {String} url
 */
TeamItemDto.prototype.url = undefined;

/**
 * @member {Boolean} isFavorite
 */
TeamItemDto.prototype.isFavorite = undefined;

