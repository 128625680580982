/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {ArticleItemDto} from './ArticleItemDto';
import {BarChartDto} from './BarChartDto';
import {EnumCandidateDto} from './EnumCandidateDto';
import {PieDoughnutDto} from './PieDoughnutDto';

/**
 * The ArticleListDto model module.
 * @module model/ArticleListDto
 * @version v1
 */
export class ArticleListDto {
  /**
   * Constructs a new <code>ArticleListDto</code>.
   * @alias module:model/ArticleListDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ArticleListDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ArticleListDto} obj Optional instance to populate.
   * @return {module:model/ArticleListDto} The populated <code>ArticleListDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ArticleListDto();
      if (data.hasOwnProperty('records'))
        obj.records = ApiClient.convertToType(data['records'], [ArticleItemDto]);
      if (data.hasOwnProperty('total'))
        obj.total = ApiClient.convertToType(data['total'], 'Number');
      if (data.hasOwnProperty('countryStatistics'))
        obj.countryStatistics = ApiClient.convertToType(data['countryStatistics'], [PieDoughnutDto]);
      if (data.hasOwnProperty('annualCountStatistics'))
        obj.annualCountStatistics = ApiClient.convertToType(data['annualCountStatistics'], [BarChartDto]);
      if (data.hasOwnProperty('candidate'))
        obj.candidate = ApiClient.convertToType(data['candidate'], [EnumCandidateDto]);
    }
    return obj;
  }
}

/**
 * @member {Array.<module:model/ArticleItemDto>} records
 */
ArticleListDto.prototype.records = undefined;

/**
 * @member {Number} total
 */
ArticleListDto.prototype.total = undefined;

/**
 * @member {Array.<module:model/PieDoughnutDto>} countryStatistics
 */
ArticleListDto.prototype.countryStatistics = undefined;

/**
 * @member {Array.<module:model/BarChartDto>} annualCountStatistics
 */
ArticleListDto.prototype.annualCountStatistics = undefined;

/**
 * @member {Array.<module:model/EnumCandidateDto>} candidate
 */
ArticleListDto.prototype.candidate = undefined;

