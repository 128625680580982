<template>
	<div class="filter-panel-container">
		<form @submit.prevent="submit" novalidate="true">
			<div class="main-filter-panel">
				<!-- <div class="filter">
					<div class="label">专利局</div>
					<div class="input">
						<el-input
							v-model="filter.patentOffice"
							style="width: 100%;"
						></el-input> -->
						<!-- <el-select v-model="filerInfo.patentOffice" style="width:100%;" :popper-append-to-body="false">
							<el-option
								v-for="x in patentOfficeList"
								:key="x.value"
								:value="x.value"
							>
								{{ x.name }}
							</el-option>
						</el-select> -->
					<!-- </div>
				</div> -->
				<!-- <div class="filter">
					<div class="label">语言</div>
					<div class="input">
						<el-input v-model="filter.language" style="width: 100%"></el-input> -->
				<!-- <el-select v-model="filerInfo.language" style="width:100%">
							<el-option
								v-for="x in languagelist"
								:key="x.value"
								:value="x.value"
							>
								{{ x.name }}
							</el-option>
						</el-select> -->
				<!-- </div>
				</div> -->
				<!-- <div class="filter">
					<div class="label">发明分类</div>
					<div class="input">
						<el-select v-model="filter.inventionType" style="width: 100%">
							<el-option
								v-for="(x, i) in classifylist"
								:key="i"
								:label="x.name"
								:value="x.value"
							>
								{{ x.name }}
							</el-option>
						</el-select>
					</div>
				</div> -->
				<!-- <div class="filter">
					<div class="label">是否授权</div>
					<div class="input">
						<el-select v-model="filter.country" style="width: 100%">
							<el-option value="-1" label="全部"> 全部 </el-option>
							<el-option value="1" label="是"> 是 </el-option>
							<el-option value="0" label="否"> 否 </el-option>
						</el-select>
					</div>
				</div> -->
			</div>
			<div class="funding-panel">
				<div class="label">发布日期</div>
				<div class="select-panel">
					<!-- <div class="input">
						<input
							type="radio"
							name="ffst10"
							value="1"
							id="user"
							v-model="date1"
							@click="selectFunding('1')"
						/>
						<label class="label" for="user">1年内</label>
					</div>
					<div class="input">
						<input
							type="radio"
							name="ff10to100"
							value="5"
							id="user1"
							v-model="date5"
							@click="selectFunding('5')"
						/>
						<label class="label" for="user1">5年内</label>
					</div>
					<div class="input">
						<input
							type="radio"
							name="fflt100"
							value="10"
							id="user2"
							v-model="date10"
							@click="selectFunding('10')"
						/>
						<label class="label" for="user2">10年内</label>
					</div> -->
					<el-radio v-model="filter.publishedDate" label="1"> 1年内 </el-radio>
					<el-radio v-model="filter.publishedDate" label="5"> 5年内 </el-radio>
					<el-radio v-model="filter.publishedDate" label="10">
						10年内
					</el-radio>
				</div>
			</div>
			<div class="control-panel">
				<div class="submit">
					<button type="submit">筛选</button>
				</div>
				<div class="reset">
					<button type="reset" @click="resetFilter">重置</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
export default {
	props: ["filerInfo"],
	data() {
		return {
			filter: {
				patentOffice: "", // 专利局
				language: "", // 语言
				inventionType: "", // 发明分类
				publishedDate: ""
			},
			// 发明分类数据
			// classifylist: [
			// 	{ value: "", name: "全部" },
			// 	{ value: "0", name: "产品发明" },
			// 	{ value: "1", name: "方法发明" }
			// ],
			// 发布日期按钮
			date1: false,
			date5: false,
			date10: false
		}
	},
	methods: {
		// selectFunding(selected) {
		// 	if (selected === "1") {
		// 		this.date10 = this.date5 = ""
		// 		this.date1 = this.date1 ? "" : true
		// 	} else if (selected === "5") {
		// 		this.date10 = this.date1 = ""
		// 		this.date5 = this.date5 ? "" : true
		// 	} else if (selected === "10") {
		// 		this.date5 = this.date1 = ""
		// 		this.date10 = this.date10 ? "" : true
		// 	}
		// },
		submit(event) {
			// this.filter.publishedDate = this.date1
			// 	? 1
			// 	: this.date5
			// 	? 5
			// 	: this.date10
			// 	? 10
			// 	: 0
			this.$emit("filterchang", this.filter)
		},
		resetFilter() {
			this.filter.patentOffice = ""
			// this.filter.language = ""
			// this.filter.inventionType = ""
			this.filter.publishedDate = ""
			this.$emit("filterchang", this.filter)
		}
	},
	created() {
		this.filter = this.filerInfo
	}
}
</script>

<style scoped lang="less">
/deep/.el-radio {
	width: 100%;
	height: 20px;
	margin-top: 10px;
	padding-left: 10px;
}
.filter-panel-container {
	width: 100%;
	background-color: #ffffff;
	display: inline-block;
}

.main-filter-panel {
	margin: 10px;
	border-bottom: 1px solid #f0f0f0;
}

.filter {
	margin-bottom: 10px;
	text-align: left;
}

.filter .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	line-height: 20px;
	color: #333333;
}

.filter .input input {
	height: 30px;
	width: 98%;
	background-color: #f1f2f7;
	border-radius: 2px;
	border: none;
	text-indent: 2px;
}

.filter .input select {
	height: 30px;
	width: 98%;
	background-color: #f1f2f7;
	border-radius: 2px;
	border: none;
	text-indent: 2px;
}

.funding-panel {
	margin: 10px;
	text-align: left;
}

.funding-panel .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	line-height: 20px;
	color: #333333;
}

.funding-panel .select-panel {
	height: 90px;
	display: flex;
	flex-direction: column;
}

.funding-panel .select-panel .input {
	height: 20px;
	margin-top: 10px;
	margin-left: 6px;
	display: flex;
	flex-direction: row;
}

.funding-panel .select-panel .input input {
	margin: auto 4px;
}

.funding-panel .select-panel .input .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	color: #333333;
	line-height: 20px;
}

.control-panel {
	margin: 10px;
	display: flex;
	flex-direction: row;
}

.submit button {
	width: 160px;
	height: 30px;
	color: #ffffff;
	background-color: #3463ea;
	border-radius: 2px;
	border: none;
}

.reset {
	margin-left: auto;
	margin-right: 0;
}

.reset button {
	width: 100px;
	height: 30px;
	color: #333333;
	background-color: #cbcbcb;
	border-radius: 2px;
	border: none;
	text-align: center;
}
</style>
