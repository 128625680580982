/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Country} from './Country';
import {RelateProjectItem} from './RelateProjectItem';

/**
 * The ProjectDetailDto model module.
 * @module model/ProjectDetailDto
 * @version v1
 */
export class ProjectDetailDto {
  /**
   * Constructs a new <code>ProjectDetailDto</code>.
   * @alias module:model/ProjectDetailDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ProjectDetailDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ProjectDetailDto} obj Optional instance to populate.
   * @return {module:model/ProjectDetailDto} The populated <code>ProjectDetailDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ProjectDetailDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('leader'))
        obj.leader = ApiClient.convertToType(data['leader'], 'String');
      if (data.hasOwnProperty('beginDate'))
        obj.beginDate = ApiClient.convertToType(data['beginDate'], 'Date');
      if (data.hasOwnProperty('endDate'))
        obj.endDate = ApiClient.convertToType(data['endDate'], 'Date');
      if (data.hasOwnProperty('sponsorInstitution'))
        obj.sponsorInstitution = ApiClient.convertToType(data['sponsorInstitution'], 'String');
      if (data.hasOwnProperty('source'))
        obj.source = ApiClient.convertToType(data['source'], 'String');
      if (data.hasOwnProperty('leaderInstitution'))
        obj.leaderInstitution = ApiClient.convertToType(data['leaderInstitution'], 'String');
      if (data.hasOwnProperty('researcherAreas'))
        obj.researcherAreas = ApiClient.convertToType(data['researcherAreas'], 'String');
      if (data.hasOwnProperty('abstract'))
        obj._abstract = ApiClient.convertToType(data['abstract'], 'String');
      if (data.hasOwnProperty('projectStatus'))
        obj.projectStatus = ApiClient.convertToType(data['projectStatus'], 'String');
      if (data.hasOwnProperty('keywords'))
        obj.keywords = ApiClient.convertToType(data['keywords'], 'String');
      if (data.hasOwnProperty('budgets'))
        obj.budgets = ApiClient.convertToType(data['budgets'], 'Number');
      if (data.hasOwnProperty('budgetsCNY'))
        obj.budgetsCNY = ApiClient.convertToType(data['budgetsCNY'], 'String');
      if (data.hasOwnProperty('projectType'))
        obj.projectType = ApiClient.convertToType(data['projectType'], 'String');
      if (data.hasOwnProperty('projectNumber'))
        obj.projectNumber = ApiClient.convertToType(data['projectNumber'], 'String');
      if (data.hasOwnProperty('projectLevel'))
        obj.projectLevel = ApiClient.convertToType(data['projectLevel'], 'String');
      if (data.hasOwnProperty('participants'))
        obj.participants = ApiClient.convertToType(data['participants'], 'String');
      if (data.hasOwnProperty('country'))
        obj.country = Country.constructFromObject(data['country']);
      if (data.hasOwnProperty('countryId'))
        obj.countryId = ApiClient.convertToType(data['countryId'], 'String');
      if (data.hasOwnProperty('language'))
        obj.language = ApiClient.convertToType(data['language'], 'String');
      if (data.hasOwnProperty('url'))
        obj.url = ApiClient.convertToType(data['url'], 'String');
      if (data.hasOwnProperty('relateProjectItems'))
        obj.relateProjectItems = ApiClient.convertToType(data['relateProjectItems'], [RelateProjectItem]);
      if (data.hasOwnProperty('totalRelateCount'))
        obj.totalRelateCount = ApiClient.convertToType(data['totalRelateCount'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
ProjectDetailDto.prototype.id = undefined;

/**
 * @member {String} title
 */
ProjectDetailDto.prototype.title = undefined;

/**
 * @member {String} leader
 */
ProjectDetailDto.prototype.leader = undefined;

/**
 * @member {Date} beginDate
 */
ProjectDetailDto.prototype.beginDate = undefined;

/**
 * @member {Date} endDate
 */
ProjectDetailDto.prototype.endDate = undefined;

/**
 * @member {String} sponsorInstitution
 */
ProjectDetailDto.prototype.sponsorInstitution = undefined;

/**
 * @member {String} source
 */
ProjectDetailDto.prototype.source = undefined;

/**
 * @member {String} leaderInstitution
 */
ProjectDetailDto.prototype.leaderInstitution = undefined;

/**
 * @member {String} researcherAreas
 */
ProjectDetailDto.prototype.researcherAreas = undefined;

/**
 * @member {String} _abstract
 */
ProjectDetailDto.prototype._abstract = undefined;

/**
 * @member {String} projectStatus
 */
ProjectDetailDto.prototype.projectStatus = undefined;

/**
 * @member {String} keywords
 */
ProjectDetailDto.prototype.keywords = undefined;

/**
 * @member {Number} budgets
 */
ProjectDetailDto.prototype.budgets = undefined;

/**
 * @member {String} budgetsCNY
 */
ProjectDetailDto.prototype.budgetsCNY = undefined;

/**
 * @member {String} projectType
 */
ProjectDetailDto.prototype.projectType = undefined;

/**
 * @member {String} projectNumber
 */
ProjectDetailDto.prototype.projectNumber = undefined;

/**
 * @member {String} projectLevel
 */
ProjectDetailDto.prototype.projectLevel = undefined;

/**
 * @member {String} participants
 */
ProjectDetailDto.prototype.participants = undefined;

/**
 * @member {module:model/Country} country
 */
ProjectDetailDto.prototype.country = undefined;

/**
 * @member {String} countryId
 */
ProjectDetailDto.prototype.countryId = undefined;

/**
 * @member {String} language
 */
ProjectDetailDto.prototype.language = undefined;

/**
 * @member {String} url
 */
ProjectDetailDto.prototype.url = undefined;

/**
 * @member {Array.<module:model/RelateProjectItem>} relateProjectItems
 */
ProjectDetailDto.prototype.relateProjectItems = undefined;

/**
 * @member {Number} totalRelateCount
 */
ProjectDetailDto.prototype.totalRelateCount = undefined;

