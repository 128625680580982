<template>
	<div class="login">
		<div class="login-container" @click.stop="isolateClick()">
			<div class="modalClose" @click="close"></div>
			<div class="title">密码登录</div>
			<form @submit.prevent="submit" novalidate="true">
				<div class="input-box">
					<input
						type="text"
						v-model="username"
						maxlength="20" autocomplete="off"
						placeholder="账号"
					/>
				</div>
				<div class="input-box">
					<input
						type="password"
						v-model="password"
						maxlength="20" autocomplete="new-password"
						placeholder="密码"
					/>
				</div>
				<div class="submit">
					<button @click="submit()" :disabled="submitDisable">登录</button>
				</div>
				<div class="bottom-bar">
					<div class="auto-login">
						<el-checkbox id="autoLogin" v-model="autoLogin" >下次自动登录</el-checkbox>
						<!-- <div class="label">下次自动登录</div> -->
					</div>
					<div class="other-options">
						<div class="forget-password btn-bar" @click="forgetpwd">
							忘记密码
						</div>
						<div class="seperator">|</div>
						<div class="register btn-bar" @click="register">注册</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
import { ApiClient, UserCenterApi } from "../api"
import Cookies from "js-cookie"
import Swal from "sweetalert2"

export default {
	data() {
		return {
			username: "",
			password: "",
			autoLogin: "",
			submitDisable: false,
			api: new UserCenterApi(ApiClient.instance)
		}
	},
	methods: {
		close() {
			this.$parent.closeLogin()
		},
		submit(event) {
			const _this = this
			_this.submitDisable = true
			if (!this.username) {
				this.$message({ message: "账号不能为空！", type: "error" })
				return
			}
			if (!this.password) {
				this.$message({ message: "密码不能为空！", type: "error" })
				return
			}
			this.api.apiUserCenterSignonPost(
				{
					account: this.username,
					password: this.password,
					remember: this.autoLogin || false
				},
				(e, data, resp) => {
					if (data?.success) {
						const list = {
							account: data.result.account,
							nickname: data.result.nickname,
							token: data.result.token,
							userID: data.result.userID,
							version: data.result.version
						}
						Cookies.remove("user_info")
						localStorage.removeItem("avator")
						Cookies.set("user_info", JSON.stringify(list), {
							expires: data.result.remember ? 30 : 7
						})
						localStorage.setItem("avator", JSON.stringify(data.result.avator))
						Swal.fire({
							title: "登录成功",
							text: "2秒后自动关闭。",
							timer: 2000
						}).then(() => window.location.reload())
					} else {
						var err = this.$options.filters.getResponseErrors(resp)
						Swal.fire({ icon: "error", html: err })
					}
				}
			)
			setTimeout(() => {
				_this.submitDisable = false
			}, 1000)
		},
		isolateClick() {
			// do nothing
		},
		register() {
			this.$parent.register()
		},
		forgetpwd() {
			this.$parent.forgetpwd()
		}
	}
}
</script>

<style lang="less" scoped>
/deep/ .el-checkbox__inner {
	vertical-align: text-bottom;
}
/deep/ .el-checkbox__label {
	font-size: 12px;
    font-weight: 400;
    color: #333333;
	line-height: 26px;
}
.login-container {
	width: 300px;
	height: 280px;
	display: inline-block;
	text-align: center;
	border-radius: 6px;
	background-color: #ffffff;
	position: relative;
}

.title {
	margin: 30px auto 20px auto;
	line-height: 20px;
	font-size: 20px;
	.c-font-family-PF();
	font-weight: 500;
	color: #333333;
}

.input-box input {
	width: 256px;
	height: 30px;
	margin: 10px auto;
	background-color: #f4f4f4;
	border: 1px solid #e0e0e0;
	border-radius: 2px;
	text-indent: 2px;
	border: none;
	outline: none;
	padding-left: 5px;
}
input:focus {
	border-color: #3463ea;
	box-shadow: 0 0 3px #3463ea;
}
.submit button {
	width: 262px;
	line-height: 30px;
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	border: none;
	border-radius: 2px;
	color: #ffffff;
	background-color: #3462e9;
	margin: 16px auto 0 auto;
}

.bottom-bar {
	width: 262px;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
}

.bottom-bar .auto-login {
	display: flex;
	flex-direction: row;
	height: 26px;
}

.bottom-bar .auto-login input {
	margin: auto 2px;
}

.bottom-bar .auto-login .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	color: #333333;
	line-height: 26px;
}

.bottom-bar .other-options {
	display: flex;
	flex-direction: row;
	margin-left: auto;
	margin-right: 0;
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	color: #999999;
	line-height: 26px;
}

.bottom-bar .seperator {
	width: 10px;
	text-align: center;
}

.bottom-bar .btn-bar {
	cursor: pointer;
}
</style>
