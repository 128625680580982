<template>
	<div class="main-panel">
		<div class="left-panel">
			<div class="statistic-panel">
				<infodata
					:info="info"
					:favorite="favorite"
					@favoritechange="favoriteChange"
					:isLogin="isLogin"
				></infodata>
			</div>
		</div>
		<div class="list-panel">
			<contentdata :content="content"></contentdata>
		</div>
	</div>
</template>

<script>
import infodata from "./detail/info.vue"
import contentdata from "./detail/content.vue"
import { ApiClient, ClinicalApi, UserCenterApi } from "../../api"
import Cookie from "js-cookie"

export default {
	data() {
		return {
			id: "",
			clinicalApi: new ClinicalApi(ApiClient.instance),
			usercenterApi: new UserCenterApi(ApiClient.instance),
			favorite: false, // 是否已收藏
			info: {
				id: "", // id
				studyStartDate: "", // 研究开始日期
				recruitmentStatus: "", // 招募状态
				studyType: "", // 研究类型
				conditions: "", // 适应症
				url: "" // 原文链接
			},
			content: {
				descriptiveInformation: "", // 描述性信息
				trackingInformation: "", // 追踪信息
				recruitmentInformation: "" // 招募信息
			},
			isLogin: false
		}
	},
	methods: {
		getInfo() {
			// console.log("getInfo")
			// console.log(this.id)
			this.clinicalApi.apiClinicalIdGet(this.id, (error, data, response) => {
				// console.log(response)
				if (error) {
					alert(error)
					this.$router.go(-1)
					// console.log(error)
				} else {
					console.log(data)
					var result = data.result
					this.content = {
						descriptiveInformation: result.descriptiveInformation, // 描述性信息
						trackingInformation: result.trackingInformation, // 追踪信息
						recruitmentInformation: result.recruitmentInformation // 招募信息
					}
					this.$delete(result, "descriptiveInformation")
					this.$delete(result, "trackingInformation")
					this.$delete(result, "recruitmentInformation")
					this.info = result
				}
			})
		},
		// 添加收藏
		addFavorite() {
			this.usercenterApi.apiUserCenterFavoritePost(
				{
					collectionID: this.id,
					collectionType: 3
				},
				(e, data, resp) => {
					this.getFavoriteStatus()
				}
			)
		},
		// 取消收藏
		removeFavorite() {
			this.usercenterApi.apiUserCenterUnfavoriteDelete(this.id, 3, null, () => {
				this.getFavoriteStatus()
			})
		},
		// 查询文章收藏状态
		getFavoriteStatus() {
			this.usercenterApi.apiUserCenterCollectedGet(this.id, (e, data, resp) => {
				if (data.success) {
					this.favorite = data.result
				}
			})
		},
		favoriteChange(val) {
			if (val === 1) {
				this.addFavorite()
			} else {
				this.removeFavorite()
			}
		}
	},
	created: function () {
		if (this.$route.params.id) {
			this.id = this.$route.params.id
			this.getInfo()
			var user = Cookie.getJSON("user_info")
			if (user?.account) {
				this.isLogin = true
			}
			if (this.isLogin) {
				this.getFavoriteStatus()
			}
		} else {
			alert("临床信息获取失败！")
			this.$router.go(-1)
		}
	},
	components: {
		infodata,
		contentdata
	}
}
</script>

<style lang="less" scoped>
.main-panel {
	width: 1170px;
	height: 100%;
	min-height: 100vh;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
}

.left-panel {
	width: 290px;
	height: 236px;
}

.statistic-panel {
	height: 100%;
	width: 100%;
	background-color: #ffffff;
}

.filter-panel {
	width: 100%;
	height: 100%;
	margin-top: 20px;
	background-color: #ffffff;
}

.list-panel {
	width: 860px;
	height: 100%;
	margin-left: 20px;
}
</style>
