<template>
	<div class="team-list-panel">
		<div class="top-bar-container">
			<div style="color: black; .c-font-size-16();">
				<router-link style="text-decoration: underline;" :to="'/aptamerdb/' + info.id" title="点击查看Aptamer详情">
					{{ info.name }}
				</router-link>
			</div>
			<div style="color: #c0c0c0; .c-font-size-14(); margin-top: 16px">
				Sequence ID: {{ info.id }}&nbsp;&nbsp;&nbsp;Length:
				{{ info.length }} &nbsp;Number of Matches: {{ info.count }}
			</div>
			<div v-for="(dto, k) in info.hsps" :key="k">
				<div
					style="
						color: #c0c0c0;
						.c-font-size-14();
						margin-top: 16px;
						width: 841px;
					"
				>
					<!-- Range {{ k + 1 }}: {{ dto.rangeTwo }} to {{ dto.rangeEnd }} -->
					Query：{{ dto.rangeOne }} to
					{{ dto.rangeOneEnd }}&nbsp;&nbsp;&nbsp;&nbsp;Sbjct：{{
						dto.rangeTwo
					}}
					to {{ dto.rangeEnd }}
				</div>
				<div class="valuePanel">
					<div>
						<span class="valueTitle">Score</span><br /><span
							class="valueLabel"
							>{{ dto.score }}</span
						>
					</div>
					<div>
						<span class="valueTitle">Expect</span><br /><span
							class="valueLabel"
							>{{ dto.expect }}</span
						>
					</div>
					<div>
						<span class="valueTitle">Identities</span><br /><span
							class="valueLabel"
							>{{ dto.identicalCount }} / {{ dto.alignCount }} ({{
								(dto.identicalPercent * 100).toFixed(2)
							}}%)</span
						>
					</div>
					<div>
						<span class="valueTitle">Gaps</span><br /><span class="valueLabel">
							{{ dto.gapCount }} / {{ dto.alignCount }} ({{
								(dto.gapPercent * 100).toFixed(2)
							}}%)</span
						>
					</div>
					<div>
						<span class="valueTitle">Strand</span><br /><span
							class="valueLabel"
							>{{ dto.strand }}</span
						>
					</div>
				</div>
				<div class="thanPanel">
					<!--循环开始div-->
					<div
						v-for="(item, j) in dto.contractResult"
						:key="j"
						style="margin-top: 10px"
					>
						<!--第一行Query div-->
						<div class="thanDiv">
							<div class="label">Query</div>
							<!-- <div class="startCode">{{ item.queryStratCode }}</div> -->
							<div v-for="(val, i) in item.align1" :key="i" class="valueDiv">
								{{ val }}
							</div>
							<!-- <div class="endCode">{{ item.queryEndCode }}</div> -->
						</div>
						<!--第二行比对结果 div-->
						<div class="thanDiv">
							<div class="label"></div>
							<!-- <div class="startCode"></div> -->
							<div v-for="(val, i) in item.middle" :key="i" class="valueDiv">
								{{ val }}
							</div>
							<!-- <div class="endCode"></div> -->
						</div>
						<!--第一行Sbjct div-->
						<div class="thanDiv">
							<div class="label">Sbjct</div>
							<!-- <div class="startCode">{{ item.sbjctStartCode }}</div> -->
							<div v-for="(val, i) in item.align2" :key="i" class="valueDiv">
								{{ val }}
							</div>
							<!-- <div class="endCode">{{ item.sbjctEndCode }}</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["info"],
	data() {
		return {}
	},
	methods: {}
}
</script>

<style lang="less" scoped>
.valuePanel {
	margin-top: 16px;
	padding: 10px 16px;
	border-top: 2px solid black;
	border-bottom: 2px solid black;
	display: flex;
	width: 841px;
}
.valuePanel div {
	width: 184px;
}
.valuePanel .valueTitle {
	color: #333;
	.c-font-size-14();
}
.valuePanel .valueLabel {
	color: #666;
	.c-font-size-14();
}

.thanPanel {
	margin-top: 16px;
}
.thanPanel .thanDiv {
	display: flex;
	.c-font-size-14();
	height: 20px;
	line-height: 20px;
}

.thanPanel .thanDiv .label {
	width: 50px;
}
.thanPanel .thanDiv .startCode {
	width: 50px;
	margin-right: 10px;
	text-align: center;
}
.thanPanel .thanDiv .valueDiv {
	width: 11px;
	text-align: center;
}
.thanPanel .thanDiv .endCode {
	width: 50px;
	margin-left: 10px;
	text-align: center;
}

.top-bar-container {
	width: 100%;
	background-color: #ffffff;
	display: inline-block;
	padding: 16px;
}
</style>
