/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ClinicalDetailTrackingDto model module.
 * @module model/ClinicalDetailTrackingDto
 * @version v1
 */
export class ClinicalDetailTrackingDto {
  /**
   * Constructs a new <code>ClinicalDetailTrackingDto</code>.
   * @alias module:model/ClinicalDetailTrackingDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ClinicalDetailTrackingDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ClinicalDetailTrackingDto} obj Optional instance to populate.
   * @return {module:model/ClinicalDetailTrackingDto} The populated <code>ClinicalDetailTrackingDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ClinicalDetailTrackingDto();
      if (data.hasOwnProperty('currentPrimaryOutcomeMeasures'))
        obj.currentPrimaryOutcomeMeasures = ApiClient.convertToType(data['currentPrimaryOutcomeMeasures'], 'String');
      if (data.hasOwnProperty('originalPrimaryOutcomeMeasures'))
        obj.originalPrimaryOutcomeMeasures = ApiClient.convertToType(data['originalPrimaryOutcomeMeasures'], 'String');
      if (data.hasOwnProperty('currentSecondaryOutcomeMeasures'))
        obj.currentSecondaryOutcomeMeasures = ApiClient.convertToType(data['currentSecondaryOutcomeMeasures'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} currentPrimaryOutcomeMeasures
 */
ClinicalDetailTrackingDto.prototype.currentPrimaryOutcomeMeasures = undefined;

/**
 * @member {String} originalPrimaryOutcomeMeasures
 */
ClinicalDetailTrackingDto.prototype.originalPrimaryOutcomeMeasures = undefined;

/**
 * @member {String} currentSecondaryOutcomeMeasures
 */
ClinicalDetailTrackingDto.prototype.currentSecondaryOutcomeMeasures = undefined;

