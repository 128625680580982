var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filter-panel-container" }, [
    _c(
      "form",
      {
        attrs: { novalidate: "true" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "main-filter-panel" }, [
          _vm._m(0),
          _c("div", { staticClass: "filter" }, [
            _c("div", { staticClass: "label" }, [_vm._v("发表时间")]),
            _c("div", { staticClass: "input" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("dateFormat")(_vm.info.publishDate, "YYYY-MM", "N/A")
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "filter" }, [
            _c("div", { staticClass: "label" }, [_vm._v("杂志名称")]),
            _c("div", { staticClass: "input" }, [
              _vm._v(" " + _vm._s(_vm.info.journal || "N/A") + " "),
            ]),
          ]),
          _c("div", { staticClass: "filter" }, [
            _c("div", { staticClass: "label" }, [_vm._v("期刊号")]),
            _c("div", { staticClass: "input" }, [
              _vm._v(" " + _vm._s(_vm.info.issn || "N/A") + " "),
            ]),
          ]),
          _c("div", { staticClass: "filter" }, [
            _c("div", { staticClass: "label" }, [_vm._v("作者")]),
            _c("div", { staticClass: "input" }, [
              _vm._v(" " + _vm._s(_vm.info.author || "N/A") + " "),
            ]),
          ]),
          _vm.isLogin
            ? _c("div", { staticClass: "filter" }, [
                !_vm.favorite
                  ? _c(
                      "div",
                      {
                        staticClass: "sm-btn",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("favoritechange", 1)
                          },
                        },
                      },
                      [_vm._v(" 收 藏 ")]
                    )
                  : _vm._e(),
                _vm.favorite
                  ? _c(
                      "div",
                      {
                        staticClass: "sm-btn",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("favoritechange", 0)
                          },
                        },
                      },
                      [_vm._v(" 已收藏 ")]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter" }, [
      _c("div", { staticClass: "title" }, [_vm._v("基础信息")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }