<template>
	<div class="item-list-panel">
		<div class="top-bar-container">
			<div class="top-bar">
				<div class="result">
					<div class="label count-desc">
						<div class="label">当前结果</div>
						<div class="value">
							{{ total }}
						</div>
						<div class="unit">条</div>
					</div>
					<div class="textLabel">按开始日期倒序排列</div>
				</div>
			</div>
		</div>
		<div class="item-list">
			<div class="item-container" v-for="(d, i) in list" :key="i">
				<div class="item">
					<div class="location">
						<div class="entity-line">
							<div class="entity entity-title title">
								<router-link :to="'/project/' + d.id">
									<div>
										{{ d.title }}
									</div>
								</router-link>
							</div>
							<div class="entity">
								<div class="value">
									{{ d.beginDate | dateFormat("YYYY-MM-DD") }}
								</div>
							</div>
						</div>
						<div class="other c-font-size-12">
							<el-row>
								<div class="entity" style="margin-right:0px;width:84px;">
									<p class="label">项目所在地：</p>
								</div>
								<div class="entity" style="width:176px;">
									<p class="value">{{ d.country.name }}</p>
								</div>
								<div class="entity" style="margin-right:0px;width:84px;">
									<p class="label">项目负责人：</p>
								</div>
								<div class="entity" style="width:436px;">
									<p class="value">{{ d.leader }}</p>
								</div>
							</el-row>
							<el-row>
								<div class="entity" style="margin-right:0px;width:70px;">
									<p class="label">经费金额：</p>
								</div>
								<div class="entity" style="width:190px;">
									<p class="value">{{ d.budgetsCNY > 0 ? d.budgetsCNY.toFixed(2) : "-" }}</p>
								</div>
								<div class="entity" style="margin-right:0px;width:70px;">
									<p class="label">承担机构：</p>
								</div>
								<div class="entity" style="width:436px;">
									<p class="value">{{ d.leaderInstitution }}</p>
								</div>
							</el-row>
						</div>
					</div>
				</div>
			</div>
			<div class="pagination">
				<el-pagination
					id="pagination"
					:current-page.sync="pager.index"
					:page-sizes="[10, 50, 100]"
					:page-size.sync="pager.size"
					layout="prev, pager, next, jumper,sizes"
					:total="pager.total"
					@current-change="query"
					@size-change="sizeChange"
				>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["list", "total"],
	data() {
		return {
			pager: {
				index: 1,
				size: 10,
				total: 100
			}
		}
	},
	methods: {
		query() {
			this.$parent.queryList()
		},
		sizeChange(size) {
			this.pager.size = size
			this.pager.index = 1
			this.query()
		}
	}
}
</script>

<style scoped lang="less">
.main-panel.main-list
	.list-panel
	.item-list-panel
	.item-list
	.item-container
	.item {
	margin: 30px;
}

.other {
	justify-content: left;
}
.title {
	justify-content: space-between;

	.label {
	}
}

.entity {
	.c-font-size-14();
	display: inline-table !important;
	margin-right: 0 !important;
}
.entity-line {
	display: flex;
	.c-font-size-12();
	flex-direction: row;

	.value {
		width: auto !important;
	}
}
.entity-title {
	.c-font-size-18() !important;
}
</style>
