var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item-list-panel" }, [
    _c("div", { staticClass: "top-bar-container" }, [
      _c("div", { staticClass: "top-bar" }, [
        _c("div", { staticClass: "result" }, [
          _c("div", { staticClass: "label count-desc" }, [
            _vm.isSelect
              ? _c(
                  "div",
                  { staticClass: "chk-select" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "checkbox",
                        on: { change: _vm.checkAllChange },
                        model: {
                          value: _vm.isCheckAll,
                          callback: function ($$v) {
                            _vm.isCheckAll = $$v
                          },
                          expression: "isCheckAll",
                        },
                      },
                      [_vm._v("全选")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "choosepanel" }, [
              _c(
                "a",
                {
                  staticClass: "select-switch",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      _vm.isSelect = !_vm.isSelect
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.isSelect ? "取消选择" : "选择"))]
              ),
            ]),
            _c("div", { staticClass: "label" }, [_vm._v("当前结果")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(" " + _vm._s(_vm.resultAmount) + " "),
            ]),
            _c("div", { staticClass: "unit" }, [_vm._v("条")]),
          ]),
          _c("div", { staticClass: "textLabel" }, [
            _c(
              "a",
              {
                staticClass: "btn-export",
                attrs: { href: "#" },
                on: { click: _vm.openDialog },
              },
              [
                _c("img", {
                  attrs: { src: require("../../../assets/导出.png") },
                }),
                _c("i", { staticClass: "lbl-export" }),
                _vm._v("导出结果 "),
              ]
            ),
            _c("div", { staticClass: "orders" }, [
              _c(
                "div",
                { staticClass: "orders-container" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "order-type",
                      attrs: { placeholder: "排序方式" },
                      on: { change: _vm.orderChange },
                      model: {
                        value: _vm.orderBy,
                        callback: function ($$v) {
                          _vm.orderBy = $$v
                        },
                        expression: "orderBy",
                      },
                    },
                    _vm._l(_vm.orderOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "item-list" },
      [
        _c(
          "el-checkbox-group",
          {
            model: {
              value: _vm.selectData,
              callback: function ($$v) {
                _vm.selectData = $$v
              },
              expression: "selectData",
            },
          },
          [
            _vm._l(_vm.aptamers, function (item, i) {
              return _c("div", { key: i, staticClass: "item-container" }, [
                _c("div", { staticClass: "item" }, [
                  _vm.isSelect
                    ? _c(
                        "div",
                        { staticClass: "checkbox" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { label: item.aptamerID, name: "record" },
                              on: { change: _vm.selectChange },
                            },
                            [_vm._v(" ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "figure" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/aptamerdb/" + item.aptamerID } },
                        [
                          !item.secondaryStructureFigure
                            ? _c("img", {
                                staticClass: "defaultImg",
                                attrs: {
                                  src: require("@/assets/sequence-default2.png"),
                                },
                              })
                            : _vm._e(),
                          item.secondaryStructureFigure
                            ? _c("img", {
                                attrs: {
                                  src: `${_vm.imgHost}/${item.secondaryStructureFigure}`,
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "location" }, [
                    _c(
                      "div",
                      { staticClass: "entity-line" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "entity entity-title",
                            attrs: { to: "/aptamerdb/" + item.aptamerID },
                          },
                          [
                            _c("p", {
                              staticStyle: { width: "550px !important" },
                              domProps: { innerHTML: _vm._s(item.mainName) },
                            }),
                          ]
                        ),
                        _c("div", { staticClass: "entity" }, [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("发表时间："),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    item.publishedDate,
                                    "YYYY-MM-DD"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "entity" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("其他名称："),
                      ]),
                      _c("div", {
                        staticClass: "value",
                        domProps: { innerHTML: _vm._s(item.otherName) },
                      }),
                    ]),
                    _c("div", { staticClass: "entity" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("适体类型："),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _vm._v(" " + _vm._s(item.aptamerType) + " "),
                      ]),
                    ]),
                    _c("div", { staticClass: "entity" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("靶标名称："),
                      ]),
                      _c("div", {
                        staticClass: "value",
                        domProps: { innerHTML: _vm._s(item.targetName) },
                      }),
                    ]),
                    _c("div", { staticClass: "entity" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("长      度："),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _vm._v(" " + _vm._s(item.sequenceLength) + " "),
                      ]),
                    ]),
                    _c("div", { staticClass: "item-comment" }, [
                      _c("div", { staticClass: "comment" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("被引文章："),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(" " + _vm._s(item.citedCount || 0) + " "),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            }),
            _c(
              "div",
              { staticClass: "pagination" },
              [
                _c("el-pagination", {
                  attrs: {
                    id: "pagination",
                    "current-page": _vm.pager.index,
                    "page-sizes": [10, 50, 100],
                    "page-size": _vm.pager.size,
                    layout: "prev, pager, next, jumper,sizes",
                    total: _vm.resultAmount,
                  },
                  on: {
                    "update:currentPage": function ($event) {
                      return _vm.$set(_vm.pager, "index", $event)
                    },
                    "update:current-page": function ($event) {
                      return _vm.$set(_vm.pager, "index", $event)
                    },
                    "update:pageSize": function ($event) {
                      return _vm.$set(_vm.pager, "size", $event)
                    },
                    "update:page-size": function ($event) {
                      return _vm.$set(_vm.pager, "size", $event)
                    },
                    "current-change": _vm.query,
                    "size-change": _vm.sizeChange,
                  },
                }),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { attrs: { id: "exportDia" } },
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: "导出结果",
              visible: _vm.openDialogShow,
              width: "650px",
            },
            on: { close: _vm.closeDialog },
          },
          [
            _c(
              "div",
              { staticClass: "radioDiv" },
              [
                _vm._v(" 选择导出方法： "),
                _c(
                  "el-radio",
                  {
                    attrs: { label: 0 },
                    model: {
                      value: _vm.exportFileType,
                      callback: function ($$v) {
                        _vm.exportFileType = $$v
                      },
                      expression: "exportFileType",
                    },
                  },
                  [_vm._v("RIS格式")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: 2 },
                    model: {
                      value: _vm.exportFileType,
                      callback: function ($$v) {
                        _vm.exportFileType = $$v
                      },
                      expression: "exportFileType",
                    },
                  },
                  [_vm._v("CSV")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "radioDiv" }, [_vm._v("选择导出记录：")]),
            _c(
              "div",
              { staticClass: "radioDiv" },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { disabled: _vm.selectData.length == 0, label: 1 },
                    model: {
                      value: _vm.exportType,
                      callback: function ($$v) {
                        _vm.exportType = $$v
                      },
                      expression: "exportType",
                    },
                  },
                  [_vm._v("当前选择的")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "radioDiv" },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: 2 },
                    model: {
                      value: _vm.exportType,
                      callback: function ($$v) {
                        _vm.exportType = $$v
                      },
                      expression: "exportType",
                    },
                  },
                  [_vm._v("当前页面所有记录")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "radioDiv" },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: 3 },
                    model: {
                      value: _vm.exportType,
                      callback: function ($$v) {
                        _vm.exportType = $$v
                      },
                      expression: "exportType",
                    },
                  },
                  [
                    _vm._v(" 选择记录 "),
                    _c("el-input-number", {
                      staticStyle: { width: "200px" },
                      attrs: {
                        disabled: _vm.exportType != 3,
                        "step-strictly": "",
                        step: 1,
                        "controls-position": "right",
                        min: 1,
                      },
                      model: {
                        value: _vm.startNo,
                        callback: function ($$v) {
                          _vm.startNo = $$v
                        },
                        expression: "startNo",
                      },
                    }),
                    _vm._v(" 至 "),
                    _c("el-input-number", {
                      staticStyle: { width: "200px" },
                      attrs: {
                        disabled: _vm.exportType != 3,
                        "step-strictly": "",
                        step: 1,
                        "controls-position": "right",
                        min: _vm.startNo,
                        max: _vm.startNo + 499,
                      },
                      model: {
                        value: _vm.endNo,
                        callback: function ($$v) {
                          _vm.endNo = $$v
                        },
                        expression: "endNo",
                      },
                    }),
                    _c("br"),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "26px",
                          color: "#c0c0c0",
                        },
                      },
                      [_vm._v("单次导出上限500条")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.exportData } },
                  [_vm._v("确定")]
                ),
                _c("el-button", { on: { click: _vm.closeDialog } }, [
                  _vm._v("取消"),
                ]),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }