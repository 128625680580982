var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "useragreement" }, [
    _c(
      "div",
      {
        staticClass: "useragreement-container",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.isolateClick()
          },
        },
      },
      [
        _c("div", { staticClass: "title" }, [_vm._v("用户协议")]),
        _vm._m(0),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "20px" } },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.$parent.UserAgreement(false)
                  },
                },
              },
              [_vm._v("不同意")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.$parent.UserAgreement(true)
                  },
                },
              },
              [_vm._v("同意并继续")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { "text-align": "left", padding: "0 20px 20px 20px" } },
      [
        _c("p", [
          _vm._v(
            "     The person who associated a work with this deed has dedicated the work to the public domain by waiving all of his or her rights to the work worldwide under copyright law, including all related and neighboring rights, to the extent allowed by law. You can copy, modify, distribute and perform the work, even for commercial purposes, all without asking permission. "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }