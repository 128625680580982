<template>
	<div class="favorite-container">
		<div class="nav-bar">
			<router-link
				to="/usercenter/favorites/aptamer"
				class="item"
				:class="{ active: current === 'aptamer' }"
				>适配体</router-link
			>
			<router-link
				to="/usercenter/favorites/article"
				class="item"
				:class="{ active: current === 'article' }"
				>论文</router-link
			>
			<router-link
				to="/usercenter/favorites/patent"
				class="item"
				:class="{ active: current === 'patent' }"
				>专利</router-link
			>
			<router-link
				to="/usercenter/favorites/clinical"
				class="item"
				:class="{ active: current === 'clinical' }"
				>临床进展</router-link
			>
			<router-link
				to="/usercenter/favorites/team"
				class="item"
				:class="{ active: current === 'team' }"
				>重要团队</router-link
			>
			<router-link
				to="/usercenter/favorites/project"
				class="item"
				:class="{ active: current === 'project' }"
				>科研项目</router-link
			>
		</div>
		<router-view />
	</div>
</template>

<script>
export default {
	computed: {
		current() {
			var path = this.$route.path
			var parts = path.split("/")
			return parts[parts.length - 1]
		}
	}
}
</script>

<style lang="less" scoped>
.favorite-container {
	.nav-bar {
		display: grid;
		height: 45px;
		grid-template-columns: repeat(7, 100px);
		column-gap: 15px;
		justify-content: center;
		background: #ffffff;

		.item {
			display: grid;
			align-items: center;
			text-align: center;

			.c-font-size-16();
			font-weight: 400;
			color: #666666;
			text-decoration: none;
		}

		.active {
			color: #3463ea;
			border-bottom: 2px solid #3463ea;
		}
	}
}
</style>
