<template>
	<div class="main-panel main-list">
		<div class="left-panel">
			<div class="statistic-panel">
				<doughnut-chart
					:dataList="statistic"
					totalDesc="团队总数"
					@click="countrySelect"
					isDataFixed="true"
				></doughnut-chart>
			</div>
			<div class="filter-panel">
				<teamfilter @filterchang="getFilter"></teamfilter>
			</div>
		</div>
		<div class="list-panel">
			<teamlist :teams="teams" :resultAmount="resultAmount" :isLogin="isLogin"></teamlist>
		</div>
	</div>
</template>

<script>
import teamlist from "./teamlist/index.vue"
import teamfilter from "./teamfilter/index.vue"
import { ApiClient, TeamApi } from "../../api"
import doughnutChart from "../../components/charts/DoughnutChart.vue"
import "../../assets/css/list.less"
import Cookie from "js-cookie"

export default {
	data() {
		return {
			name: "",
			institution: "",
			keyWord: "",
			countryFilter: "",
			positionFilter: "",
			iresearchAreaFilter: "",
			fundingFilter: "",
			pageIndex: 0,
			pageSize: 10,
			teamApi: new TeamApi(ApiClient.instance),
			teams: [],
			resultAmount: 0,
			searchType: 0,
			statistic: [],
			isLogin: false
		}
	},
	mounted() {
		// this.keyWord = this.$route.query.keyword
		// this.searchType = parseInt(this.$route.query.searchtype) || 0
		// this.name = this.$route.query.name
		// this.institution = this.$route.query.institution
		// this.countryFilter = this.$route.query.country
		// this.pageIndex = parseInt(this.$route.query.index) || 1
		// this.pageSize = parseInt(this.$route.query.size) || 10
		this.getURLParams()
		this.queryTeams()
		var user = Cookie.getJSON("user_info")
		if (user?.account) {
			this.isLogin = true
		}
	},
	watch: {
		$route(to, from) {
			if (to.path === "/team") {
				this.getURLParams()
				// this.keyWord = this.$route.query.keyword || ""
				// this.searchType = parseInt(this.$route.query.searchtype) || 0
				// this.name = this.$route.query.name
				// this.institution = this.$route.query.institution
				// this.countryFilter = this.$route.query.country
				// this.pageIndex = parseInt(this.$route.query.index) || 1
				// this.pageSize = parseInt(this.$route.query.size) || 10
				this.queryTeams()
			}
		}
	},
	methods: {
		replaceURL() {
			var opts = {
				keyword: this.keyWord,
				searchtype: this.searchType,
				name: this.name,
				institution: this.institution,
				country: this.countryFilter,
				index: this.pageIndex,
				size: this.pageSize
			}
			this.$router.replace({ query: opts })
		},
		getURLParams() {
			this.keyWord = this.$route.query.keyword || ""
			this.searchType = parseInt(this.$route.query.searchtype) || 0
			this.name = this.$route.query.name
			this.institution = this.$route.query.institution
			this.countryFilter = this.$route.query.country
			this.$children[1].filter.countryFilter = this.countryFilter
			this.pageIndex = parseInt(this.$route.query.index) || 1
			this.pageSize = parseInt(this.$route.query.size) || 10
		},
		getFilter(data) {
			this.countryFilter = data.countryFilter
			this.searchType = data.searchType
			this.replaceURL()
		},
		queryTeams() {
			const opts = {
				name: this.name,
				institution: this.institution,
				keyWord: this.keyWord,
				index: this.pageIndex,
				size: this.pageSize,
				country: this.countryFilter,
				position: this.positionFilter,
				researchArea: this.researchAreaFilter,
				funding: this.fundingFilter
			}
			// console.log(opts)
			this.teamApi.apiTeamQueryGet(
				this.searchType,
				opts,
				(error, data, response) => {
					if (error) {
						console.log(error)
					}
					if (data.success) {
						this.resultAmount = data.result.total
						this.teams = data.result.records
						this.statistic = data.result.countryCount
					}
				}
			)
		},
		countrySelect(arg) {
			// if (!arg.isOther) {
			// 	this.searchType = 2
			// 	this.countryFilter = arg.select[0]
			// 	// this.queryTeams()
			// 	this.replaceURL()
			// }
			this.searchType = 2
			this.countryFilter = arg?.select.length > 0 ? arg.select[0] : null
			this.replaceURL()
		}
	},
	components: {
		teamlist,
		teamfilter,
		doughnutChart
	}
}
</script>

<style lang="less" scoped></style>
