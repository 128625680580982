<template>
	<div id="app">
		<Header v-show="!(path === '/largescreen')"></Header>
		<SearchBox
			:db_type="db_type"
			ref="searchbox"
			v-if="
				path.indexOf('/intelligentfiltering') < 0 && path.indexOf('/aptamerevaluationlist') < 0 && path != '/largescreen'
				&& path != '/aptamerevaluationpage' && path != '/aptamerfilterpage'
			"
		></SearchBox>
		<TargetSearchBox
			v-if="
				(path.indexOf('/intelligentfiltering') >= 0 || path.indexOf('/aptamerevaluationlist') >= 0) && path != '/largescreen'
			"
		></TargetSearchBox>
		<Breadcrumb v-show="!(path === '/largescreen') && !(path === '/aptamerevaluationpage') && !(path === '/aptamerfilterpage')"></Breadcrumb>
		<router-view class="main-body" />
		<Footer v-show="!(path === '/largescreen') && !(path === '/aptamerevaluationpage') && !(path === '/aptamerfilterpage')" :isLogin="isLogin"></Footer>
		<div class="side-float" v-show="!(path === '/largescreen') && !(path === '/aptamerevaluationpage') && !(path === '/aptamerfilterpage')">
			<SideFloat :isLogin="isLogin" />
		</div>
	</div>
</template>

<script>
import Header from "./components/Header.vue"
import SearchBox from "./components/SearchBox.vue"
import TargetSearchBox from "./components/TargetSearchBox.vue"
import Footer from "./components/Footer.vue"
import Breadcrumb from "./components/Breadcrumb.vue"
import SideFloat from "./components/SideFloat.vue"
import Cookie from "js-cookie"

export default {
	components: {
		Header,
		SearchBox,
		Footer,
		Breadcrumb,
		SideFloat,
		TargetSearchBox
	},
	data() {
		return {
			db_type: "",
			path: "",
			isLogin: false
		}
	},
	computed: {},
	mounted() {
		this.path = this.$route.path.toLowerCase()
		if (Cookie.getJSON("user_info")) {
			this.isLogin = true
		}
	},
	watch: {
		$route(to, from) {
			this.path = to.path.toLowerCase()
		}
	}
}
</script>

<style lang="less">

.noAvator {
	background: #ffffff;
	border: 1px solid #707070;
	border-radius: 50%;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

#app {
	background-color: #f2f2f3;
}
.el-popover {
	padding: 0 !important;
	min-width: inherit !important;
}
.main-body {
	min-height: 620px;
}

.side-float {
	position: fixed;
	bottom: 150px;
	left: calc(1170px + 30px + (100% - 1170px) / 2);
}
@font-face {
	font-family: "myFont";
	src: url("./fontFamily/PingFangSC-Light.ttf"); //你的资源目录
	font-weight: normal;
	font-style: normal;
}
#app {
	background: #f2f2f4;
	font-family: myFont, sans-serif !important;
}

.inputHint {
	font-size: 12px;
	color: #afafaf;
	font-weight: 400;
	margin: 5px 0;
	text-align: left;
	width: max-content;
}

.modalClose {
    width: 20px;
	height: 20px;
    background-image: url("./assets/close.png");
    background-size: 100% 100%;
    background-repeat:no-repeat;
	cursor: pointer;
	position: absolute;
	top: 20px;
	right: 20px;
}

.searchBox {
	max-width: 525px !important;

	/deep/ .el-select-dropdown__item {
		max-width: 525px !important;
		word-break: break-all !important;
		white-space: none !important;
		height: auto !important;
	}
}

.pageSearchBox {
	width: 618px;
}

.toLogin {
	position: absolute;
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #F9F9F9 100%);
	opacity: 1;
	top: 0;
	left: 0;

	button {
		width: 304px;
		height: 42px;
		border: none;
		background: #3462E9;
		border-radius: 3px;
		color: #FFFFFF;
		.c-font-size-18();
		cursor: pointer;
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.targetSelectOptions {
	min-height: 34px;
	font-size: 14px;
	color: #606266;
	cursor: pointer;
	padding: 10px 20px;
}

.targetSelectOptions:hover {
	background-color: #F5F7FA;
}

.hearderTargetSearchBox {
	height: 46px;
	border: none;
	width: 525px;
	font-size: 16px;
	color: #666666;
	padding: 10px;
}
.hearderTargetSearchBox:focus{
	border: none;
}
.dynamicTip {
	font-size: 16px !important;
	color: #333;
	max-width: 345px;
	max-height: 150px;
	overflow-x: hidden;
	overflow-y: auto;
}
</style>
