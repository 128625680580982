var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "head-bar", on: { mouseleave: _vm.selectLeave } },
    [
      _c("div", { staticClass: "navigate-container" }, [
        _c(
          "div",
          {
            staticClass: "navigate",
            class: { active: _vm.currentPath === "/" },
            staticStyle: { width: "150px" },
            on: {
              click: function ($event) {
                return _vm.navigateTo("")
              },
            },
          },
          [_vm._v(" 首页 ")]
        ),
        _c(
          "div",
          {
            staticClass: "navigate",
            class: { active: _vm.currentPath.indexOf("introduction") > -1 },
            staticStyle: { width: "122px" },
            on: {
              click: function ($event) {
                return _vm.navigateTo("introduction")
              },
            },
          },
          [
            _c(
              "el-popover",
              {
                attrs: {
                  placement: "bottom",
                  "popper-class": "popper",
                  width: "122",
                  trigger: "hover",
                },
              },
              [
                _c("div", { staticClass: "navigate-select" }, [
                  _c("ul", [
                    _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.navigateTo("introduction")
                          },
                        },
                      },
                      [_vm._v("适体简介")]
                    ),
                    _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.navigateTo("aptamerdb")
                          },
                        },
                      },
                      [_vm._v("Aptamer数据库")]
                    ),
                    _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.navigateTo("paper")
                          },
                        },
                      },
                      [_vm._v("文献资料")]
                    ),
                    _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.navigateTo("patent")
                          },
                        },
                      },
                      [_vm._v("重点专利")]
                    ),
                    _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.navigateTo("project")
                          },
                        },
                      },
                      [_vm._v("科研项目")]
                    ),
                    _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.navigateTo("LargeScreen")
                          },
                        },
                      },
                      [_vm._v("数据统计")]
                    ),
                    _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.navigateTo("analysistool")
                          },
                        },
                      },
                      [_vm._v("分析工具")]
                    ),
                    _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.navigateTo("clinical")
                          },
                        },
                      },
                      [_vm._v("临床进展")]
                    ),
                  ]),
                ]),
                _c(
                  "el-button",
                  { attrs: { slot: "reference" }, slot: "reference" },
                  [_vm._v(" 核酸适体数据系统 ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "navigate",
            class: {
              active: _vm.currentPath.indexOf("AptamerEvaluationPage") > -1,
            },
            staticStyle: { width: "122px" },
            on: {
              click: function ($event) {
                return _vm.navigateTo("AptamerEvaluationPage")
              },
            },
          },
          [_vm._v(" 核酸适体评价系统 ")]
        ),
        _c(
          "div",
          {
            staticClass: "navigate",
            class: {
              active: _vm.currentPath.indexOf("aptamerfilterpage") > -1,
            },
            staticStyle: { width: "150px" },
            on: {
              click: function ($event) {
                return _vm.navigateTo("aptamerfilterpage")
              },
            },
          },
          [_vm._v(" 核酸适体智能筛选系统 ")]
        ),
        _c(
          "div",
          {
            staticClass: "navigate",
            class: { active: _vm.currentPath.indexOf("team") > -1 },
            staticStyle: { width: "192px" },
            on: {
              click: function ($event) {
                return _vm.navigateTo("team")
              },
            },
          },
          [
            _c(
              "el-popover",
              {
                staticStyle: { "min-width": "0" },
                attrs: {
                  placement: "bottom",
                  "popper-class": "education",
                  width: "190",
                  trigger: "hover",
                },
              },
              [
                _c("div", { staticClass: "fitness-Education" }, [
                  _c("ul", [
                    _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.navigateTo("team")
                          },
                        },
                      },
                      [_vm._v("团队介绍")]
                    ),
                    _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.navigateTo("LiteratureLearning")
                          },
                        },
                      },
                      [_vm._v("文献学习")]
                    ),
                    _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.navigateTo("CommunicationPlatform")
                          },
                        },
                      },
                      [_vm._v("交流平台")]
                    ),
                    _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.navigateTo("TrainingCourse")
                          },
                        },
                      },
                      [_vm._v("培训及课程")]
                    ),
                  ]),
                ]),
                _c(
                  "el-button",
                  { attrs: { slot: "reference" }, slot: "reference" },
                  [_vm._v(" 核酸适体教育与科研服务系统 ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.loggedIn
          ? _c("div", { staticClass: "logged-in-panel" }, [
              _c(
                "div",
                {
                  staticClass: "image-label cursor",
                  on: { click: _vm.advancedSearch },
                },
                [
                  _c("img", {
                    attrs: { src: require("../assets/search.png") },
                  }),
                  _c("div", { staticClass: "label" }, [_vm._v("高级搜索")]),
                ]
              ),
              _vm._m(0),
              _c(
                "div",
                {
                  staticClass: "user",
                  on: {
                    click: function ($event) {
                      return _vm.navigateTo("usercenter")
                    },
                  },
                },
                [_c("img", { attrs: { src: require("../assets/user.png") } })]
              ),
            ])
          : _vm._e(),
        _vm.loggedIn == false
          ? _c("div", { staticClass: "not-logged-in-panel" }, [
              _vm._m(1),
              _c("div", { staticClass: "to-login" }, [
                _vm.userinfo == null
                  ? _c("section", [
                      _c(
                        "span",
                        {
                          staticClass: "popup",
                          attrs: { id: "loginSpan" },
                          on: { click: _vm.login },
                        },
                        [_vm._v("登录")]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _vm.avator
          ? _c(
              "div",
              { staticClass: "imgUrl", on: { mouseover: _vm.selectOver } },
              [
                _c("img", {
                  attrs: { slot: "reference", src: _vm.avator, alt: "" },
                  slot: "reference",
                }),
              ]
            )
          : _vm._e(),
        _vm.selectShow
          ? _c(
              "div",
              {
                staticClass: "selectList",
                on: { mouseleave: _vm.selectLeave },
              },
              [
                _c("ul", [
                  _c("li", { on: { click: _vm.toUserCenter } }, [
                    _vm._v("个人中心"),
                  ]),
                  _c(
                    "li",
                    {
                      on: {
                        click: function ($event) {
                          _vm.ischangePassword = true
                        },
                      },
                    },
                    [_vm._v("修改密码")]
                  ),
                  _c("li", { on: { click: _vm.logout } }, [_vm._v("退出登录")]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _vm.toLogin
        ? _c("div", { staticClass: "to-login-panel" }, [_c("Login")], 1)
        : _vm._e(),
      _vm.toRegister
        ? _c("div", { staticClass: "to-register-panel" }, [_c("Register")], 1)
        : _vm._e(),
      _vm.toForgetPwd
        ? _c("div", { staticClass: "to-forgetpwd-panel" }, [_c("ForgetPwd")], 1)
        : _vm._e(),
      _vm.isSeqSub
        ? _c(
            "div",
            { staticClass: "to-login-panel" },
            [_c("SequenceSub", { on: { cancelsub: _vm.closeSeqSub } })],
            1
          )
        : _vm._e(),
      _vm.showUserAgreement
        ? _c(
            "div",
            {
              staticClass: "to-useragreement-panel",
              on: {
                click: function ($event) {
                  _vm.showUserAgreement = false
                },
              },
            },
            [_c("UserAgreement")],
            1
          )
        : _vm._e(),
      _vm.ischangePassword
        ? _c(
            "div",
            { staticClass: "to-login-panel", attrs: { id: "mation" } },
            [
              _c("ChangePassword", {
                on: { ischange: _vm.showchangePassword },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showMessageBox
        ? _c("div", { staticClass: "modal" }, [
            _c(
              "div",
              {
                staticClass: "message-box-container",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.isolateClick()
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "modalClose",
                  on: {
                    click: function ($event) {
                      _vm.showMessageBox = false
                    },
                  },
                }),
                _c("div", { staticClass: "title" }, [_vm._v("问题反馈")]),
                _c("div", { staticClass: "textarea-container" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.feedback,
                        expression: "feedback",
                      },
                    ],
                    attrs: {
                      cols: "30",
                      rows: "10",
                      placeholder: "请输入反馈信息",
                    },
                    domProps: { value: _vm.feedback },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.feedback = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "submit" }, [
                  _c(
                    "button",
                    {
                      attrs: { disabled: _vm.submitDisable },
                      on: {
                        click: function ($event) {
                          return _vm.submitFeedback()
                        },
                      },
                    },
                    [_vm._v(" 提交信息 ")]
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image-label" }, [
      _c("img", { attrs: { src: require("../assets/language.png") } }),
      _c("div", { staticClass: "label" }, [_vm._v("中文")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image-label" }, [
      _c("img", { attrs: { src: require("../assets/language.png") } }),
      _c("div", { staticClass: "label" }, [_vm._v("中文")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }