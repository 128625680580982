/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {PieDoughnutDto} from './PieDoughnutDto';
import {TeamItemDto} from './TeamItemDto';

/**
 * The TeamListDto model module.
 * @module model/TeamListDto
 * @version v1
 */
export class TeamListDto {
  /**
   * Constructs a new <code>TeamListDto</code>.
   * @alias module:model/TeamListDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>TeamListDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TeamListDto} obj Optional instance to populate.
   * @return {module:model/TeamListDto} The populated <code>TeamListDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TeamListDto();
      if (data.hasOwnProperty('records'))
        obj.records = ApiClient.convertToType(data['records'], [TeamItemDto]);
      if (data.hasOwnProperty('total'))
        obj.total = ApiClient.convertToType(data['total'], 'Number');
      if (data.hasOwnProperty('countryCount'))
        obj.countryCount = ApiClient.convertToType(data['countryCount'], [PieDoughnutDto]);
    }
    return obj;
  }
}

/**
 * @member {Array.<module:model/TeamItemDto>} records
 */
TeamListDto.prototype.records = undefined;

/**
 * @member {Number} total
 */
TeamListDto.prototype.total = undefined;

/**
 * @member {Array.<module:model/PieDoughnutDto>} countryCount
 */
TeamListDto.prototype.countryCount = undefined;

