/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The EvaluateContrastDto model module.
 * @module model/EvaluateContrastDto
 * @version v1
 */
export class EvaluateContrastDto {
  /**
   * Constructs a new <code>EvaluateContrastDto</code>.
   * @alias module:model/EvaluateContrastDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EvaluateContrastDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EvaluateContrastDto} obj Optional instance to populate.
   * @return {module:model/EvaluateContrastDto} The populated <code>EvaluateContrastDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EvaluateContrastDto();
      if (data.hasOwnProperty('aptamerID'))
        obj.aptamerID = ApiClient.convertToType(data['aptamerID'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('target'))
        obj.target = ApiClient.convertToType(data['target'], 'String');
      if (data.hasOwnProperty('length'))
        obj.length = ApiClient.convertToType(data['length'], 'Number');
      if (data.hasOwnProperty('affinity'))
        obj.affinity = ApiClient.convertToType(data['affinity'], 'String');
      if (data.hasOwnProperty('score'))
        obj.score = ApiClient.convertToType(data['score'], 'Number');
      if (data.hasOwnProperty('sequence'))
        obj.sequence = ApiClient.convertToType(data['sequence'], 'String');
      if (data.hasOwnProperty('sbjSequence'))
        obj.sbjSequence = ApiClient.convertToType(data['sbjSequence'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} aptamerID
 */
EvaluateContrastDto.prototype.aptamerID = undefined;

/**
 * @member {String} name
 */
EvaluateContrastDto.prototype.name = undefined;

/**
 * @member {String} target
 */
EvaluateContrastDto.prototype.target = undefined;

/**
 * @member {Number} length
 */
EvaluateContrastDto.prototype.length = undefined;

/**
 * @member {String} affinity
 */
EvaluateContrastDto.prototype.affinity = undefined;

/**
 * @member {Number} score
 */
EvaluateContrastDto.prototype.score = undefined;

/**
 * @member {String} sequence
 */
EvaluateContrastDto.prototype.sequence = undefined;

/**
 * @member {String} sbjSequence
 */
EvaluateContrastDto.prototype.sbjSequence = undefined;

