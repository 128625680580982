var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("el-row", [
        _c(
          "ul",
          _vm._l(_vm.videoList, function (video, index) {
            return _c(
              "li",
              {
                key: index,
                style: { marginLeft: (index % 4 == 0 ? 0 : 20) + "px" },
              },
              [
                _c("div", { staticClass: "video" }, [
                  _c(
                    "video",
                    {
                      attrs: {
                        id: "video" + index,
                        width: "100%",
                        height: "100%",
                        controls: "",
                      },
                      on: {
                        play: function ($event) {
                          return _vm.play("video" + index)
                        },
                      },
                    },
                    [
                      _c("source", {
                        attrs: { src: video.videoFile, type: "video/mp4" },
                      }),
                    ]
                  ),
                ]),
                _c("div", { staticStyle: { padding: "10px" } }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(" " + _vm._s(video.title) + " "),
                  ]),
                  _c("div", { staticClass: "info" }, [
                    _c(
                      "span",
                      { staticStyle: { float: "left", "text-align": "left" } },
                      [_vm._v("主讲人：" + _vm._s(video.lecturer))]
                    ),
                    _c("span", { staticStyle: { float: "right" } }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("dateFormat")(video.createOn, "YYYY.MM.DD")
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "el-row",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              id: "pagination",
              "current-page": _vm.pageIndex,
              "page-size": _vm.pageSize,
              layout: "prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "current-change": _vm.queryChange,
              "prev-click": _vm.queryChange,
              "next-click": _vm.queryChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }