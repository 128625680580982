/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The SignOnUserDto model module.
 * @module model/SignOnUserDto
 * @version v1
 */
export class SignOnUserDto {
  /**
   * Constructs a new <code>SignOnUserDto</code>.
   * @alias module:model/SignOnUserDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>SignOnUserDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SignOnUserDto} obj Optional instance to populate.
   * @return {module:model/SignOnUserDto} The populated <code>SignOnUserDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SignOnUserDto();
      if (data.hasOwnProperty('userID'))
        obj.userID = ApiClient.convertToType(data['userID'], 'String');
      if (data.hasOwnProperty('avator'))
        obj.avator = ApiClient.convertToType(data['avator'], 'String');
      if (data.hasOwnProperty('nickname'))
        obj.nickname = ApiClient.convertToType(data['nickname'], 'String');
      if (data.hasOwnProperty('version'))
        obj.version = ApiClient.convertToType(data['version'], 'String');
      if (data.hasOwnProperty('token'))
        obj.token = ApiClient.convertToType(data['token'], 'String');
      if (data.hasOwnProperty('account'))
        obj.account = ApiClient.convertToType(data['account'], 'String');
      if (data.hasOwnProperty('remember'))
        obj.remember = ApiClient.convertToType(data['remember'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {String} userID
 */
SignOnUserDto.prototype.userID = undefined;

/**
 * @member {String} avator
 */
SignOnUserDto.prototype.avator = undefined;

/**
 * @member {String} nickname
 */
SignOnUserDto.prototype.nickname = undefined;

/**
 * @member {String} version
 */
SignOnUserDto.prototype.version = undefined;

/**
 * @member {String} token
 */
SignOnUserDto.prototype.token = undefined;

/**
 * @member {String} account
 */
SignOnUserDto.prototype.account = undefined;

/**
 * @member {Boolean} remember
 */
SignOnUserDto.prototype.remember = undefined;

