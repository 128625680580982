<template>
	<div class="item-list-panel">
		<div class="top-bar-container">
			<div class="top-bar">
				<div class="result">
					<div class="label count-desc">
						<div class="label">当前结果</div>
						<div class="value">
							{{ resultAmount }}
						</div>
						<div class="unit">条</div>
					</div>
					<div class="textLabel">按公布日期倒序排列</div>
				</div>
			</div>
		</div>
		<div class="item-list">
			<div class="item-container" v-for="(item, i) in datalist" :key="i">
				<div class="item">
					<div class="location">
						<router-link
							class="entity entity-title"
							:to="'/clinical/' + item.id"
							style=".c-font-size-16(); text-decoration: none;"
							:title="item.title"
						>
							{{ item.title | ellipsis(90) }}
						</router-link>
						<div class="entity-line">
							<div class="entity">
								<div class="label">招募状态：</div>
								<div
									class="value"
									:class="
										item.recruitmentStatus === 'Terminated'
											? 'statusColor'
											: item.recruitmentStatus === 'Recruiting'
											? 'RecruitingColor'
											: ''
									"
								>
									{{ item.recruitmentStatus }}
								</div>
							</div>
							<div class="entity">
								<div class="label">适应症：</div>
								<div class="value conditions" v-html="item.conditions"></div>
							</div>
						</div>
						<div class="entity-line">
							<div class="entity">
								<div class="value">研究单位：{{ item.organization }}</div>
							</div>
							<div class="entity">
								<div class="label">开始日期：</div>
								<div class="value">
									{{ item.studyStartDate | dateFormat("YYYY-MM-DD") }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["datalist", "resultAmount"],
	data() {
		return {}
	},
	methods: {},
	watch: {}
}
</script>

<style lang="less" scoped>
.entity {
	.c-font-size-14();
}
.entity-title {
	.c-font-size-18() !important;
}
.entity-line {
	display: flex;
	.c-font-size-12();
	justify-content: left;
	flex-direction: row;

	.value.short {
		width: auto !important;
	}
}

.statusColor {
	color: red;
}
.RecruitingColor {
	color: blue;
}
</style>

<style lang="less">
.conditions ul {
	display: flex;
	list-style-type: decimal;
	margin: 0 !important;
}
.conditions ul li {
	margin: 0 15px !important;
}
.conditions ul {
	flex-wrap: wrap;
}
</style>
