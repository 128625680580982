var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "main-filter-panel" }, [
      _c("div", { staticClass: "main-filter-panel1" }, [
        _c(
          "div",
          { staticClass: "main-filter-panel-logo" },
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _c("img", {
                staticClass: "logo",
                attrs: { src: require("@/assets/logo.png") },
              }),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "main-filter-panel-Journalism" },
          [
            [
              _c(
                "el-carousel",
                {
                  attrs: {
                    direction: "vertical",
                    height: "50px",
                    interval: 5000,
                    autoplay: "",
                  },
                },
                _vm._l(_vm.newsList, function (item, index) {
                  return _c("el-carousel-item", { key: index }, [
                    _c("div", { staticClass: "vertical" }, [
                      _c("div", { staticClass: "vertial-time" }, [
                        _vm._v(_vm._s(item._date)),
                      ]),
                      _c("div", { staticClass: "vertial-news" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function ($event) {
                                return _vm.openNew(item.link)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.title))]
                        ),
                      ]),
                    ]),
                  ])
                }),
                1
              ),
            ],
          ],
          2
        ),
      ]),
    ]),
    _c("div", { staticClass: "main-filter" }, [
      _c(
        "div",
        { staticClass: "Map", staticStyle: { height: "430px" } },
        [
          _vm._m(0),
          _c("Aptamerdata", {
            attrs: { globalStatisticas: _vm.globalStatisticas },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "echarts", staticStyle: { height: "430px" } },
        [
          _vm._m(1),
          _c("AptamerType", {
            attrs: { dataList: _vm.aptamerTypeStatisticas, totalDesc: "总数" },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "echarts", staticStyle: { height: "430px" } },
        [
          _vm._m(2),
          _c("SampleType", {
            attrs: { sampleTypeStatisticas: _vm.sampleTypeStatisticas },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "Map", staticStyle: { height: "400px" } },
        [
          _vm._m(3),
          _c("NumberAptamers", {
            attrs: { findCountStatisticas: _vm.findCountStatisticas },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "echarts", staticStyle: { height: "400px" } },
        [
          _vm._m(4),
          _c("ApplicationType", {
            attrs: {
              dataList: _vm.applicationTypeStatisticas,
              totalDesc: "总数",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "echarts", staticStyle: { height: "400px" } },
        [
          _vm._m(5),
          _c("AptamerType", {
            attrs: { dataList: _vm.dataSourceStatisticas, totalDesc: "总数" },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span"),
      _c("p", [_vm._v("全球适体数据统计")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span"),
      _c("p", [_vm._v("适体类型")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span"),
      _c("p", [_vm._v("样本类型")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span"),
      _c("p", [_vm._v("适体发现数量统计")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span"),
      _c("p", [_vm._v("应用类型")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span"),
      _c("p", [_vm._v("数据来源")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }