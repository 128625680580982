/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {LearnTopicTypeEnum} from './LearnTopicTypeEnum';

/**
 * The SubmitLearnTopicRequest model module.
 * @module model/SubmitLearnTopicRequest
 * @version v1
 */
export class SubmitLearnTopicRequest {
  /**
   * Constructs a new <code>SubmitLearnTopicRequest</code>.
   * @alias module:model/SubmitLearnTopicRequest
   * @class
   * @param title {String} 
   * @param content {String} 
   * @param learnTopicType {module:model/LearnTopicTypeEnum} 
   */
  constructor(title, content, learnTopicType) {
    this.title = title;
    this.content = content;
    this.learnTopicType = learnTopicType;
  }

  /**
   * Constructs a <code>SubmitLearnTopicRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SubmitLearnTopicRequest} obj Optional instance to populate.
   * @return {module:model/SubmitLearnTopicRequest} The populated <code>SubmitLearnTopicRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SubmitLearnTopicRequest();
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('content'))
        obj.content = ApiClient.convertToType(data['content'], 'String');
      if (data.hasOwnProperty('learnTopicType'))
        obj.learnTopicType = LearnTopicTypeEnum.constructFromObject(data['learnTopicType']);
    }
    return obj;
  }
}

/**
 * @member {String} title
 */
SubmitLearnTopicRequest.prototype.title = undefined;

/**
 * @member {String} content
 */
SubmitLearnTopicRequest.prototype.content = undefined;

/**
 * @member {module:model/LearnTopicTypeEnum} learnTopicType
 */
SubmitLearnTopicRequest.prototype.learnTopicType = undefined;

