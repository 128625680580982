var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "whole" }, [
    _c("div", { staticClass: "search" }, [
      _vm._v(" 高级搜索 "),
      _c("img", {
        attrs: { src: require("@/assets/fork.png") },
        on: { click: _vm.cancel },
      }),
    ]),
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "header1" }, [
        _c(
          "div",
          {
            staticClass: "field",
            class: { show: _vm.int == 0 },
            on: {
              click: function ($event) {
                return _vm.active(0)
              },
            },
          },
          [_vm._v(" 字段搜索 ")]
        ),
        _c(
          "div",
          {
            staticClass: "field",
            class: { show: _vm.int == 1 },
            on: {
              click: function ($event) {
                return _vm.active(1)
              },
            },
          },
          [_vm._v(" 序列对比 ")]
        ),
      ]),
    ]),
    _vm.int == 0
      ? _c("div", { staticClass: "nav" }, [
          _c("div", { staticClass: "nav1" }, [
            _c("p", [_vm._v("选择数据库 :")]),
            _c(
              "div",
              { staticClass: "nav-one" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "typeSelect",
                    on: { change: _vm.db_type_change },
                    model: {
                      value: _vm.cur_type,
                      callback: function ($$v) {
                        _vm.cur_type = $$v
                      },
                      expression: "cur_type",
                    },
                  },
                  _vm._l(_vm.types, function (t) {
                    return _c("el-option", {
                      key: t.db_type,
                      attrs: { label: t.db_name, value: t.db_type },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "query" },
            [
              _c("p", [_vm._v("查询字段 :")]),
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "query1" },
                  [
                    _c("div", [
                      _c("img", {
                        staticClass: "query-img",
                        attrs: { src: require("@/assets/remove.png") },
                        on: {
                          click: function ($event) {
                            return _vm.del(item, index)
                          },
                        },
                      }),
                    ]),
                    _vm.cur_type == 0
                      ? _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "290px",
                              "margin-right": "10px",
                            },
                            on: { change: _vm.changeOpion },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          },
                          _vm._l(_vm.aptamerCols, function (t) {
                            return _c("el-option", {
                              key: t.db_type,
                              attrs: {
                                label: t.db_name,
                                value: t.db_type,
                                disabled: t.db_bled,
                              },
                            })
                          }),
                          1
                        )
                      : _vm.cur_type == 1
                      ? _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "290px",
                              "margin-right": "10px",
                            },
                            on: { change: _vm.changeOpion },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          },
                          _vm._l(_vm.articleCols, function (t) {
                            return _c("el-option", {
                              key: t.db_type,
                              attrs: {
                                label: t.db_name,
                                value: t.db_type,
                                disabled: t.db_bled,
                              },
                            })
                          }),
                          1
                        )
                      : _vm.cur_type == 2
                      ? _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "290px",
                              "margin-right": "10px",
                            },
                            on: { change: _vm.changeOpion },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          },
                          _vm._l(_vm.clinicalCols, function (t) {
                            return _c("el-option", {
                              key: t.db_type,
                              attrs: {
                                label: t.db_name,
                                value: t.db_type,
                                disabled: t.db_bled,
                              },
                            })
                          }),
                          1
                        )
                      : _vm.cur_type == 3
                      ? _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "290px",
                              "margin-right": "10px",
                            },
                            on: { change: _vm.changeOpion },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          },
                          _vm._l(_vm.teamCols, function (t) {
                            return _c("el-option", {
                              key: t.db_type,
                              attrs: {
                                label: t.db_name,
                                value: t.db_type,
                                disabled: t.db_bled,
                              },
                            })
                          }),
                          1
                        )
                      : _vm.cur_type == 4
                      ? _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "290px",
                              "margin-right": "10px",
                            },
                            on: { change: _vm.changeOpion },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          },
                          _vm._l(_vm.projectCols, function (t) {
                            return _c("el-option", {
                              key: t.db_type,
                              attrs: {
                                label: t.db_name,
                                value: t.db_type,
                                disabled: t.db_bled,
                              },
                            })
                          }),
                          1
                        )
                      : _vm.cur_type == 5
                      ? _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "290px",
                              "margin-right": "10px",
                            },
                            on: { change: _vm.changeOpion },
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          },
                          _vm._l(_vm.patentCols, function (t) {
                            return _c("el-option", {
                              key: t.db_type,
                              attrs: {
                                label: t.db_name,
                                value: t.db_type,
                                disabled: t.db_bled,
                              },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _c("el-input", {
                      staticStyle: { width: "600px" },
                      attrs: { placeholder: "请输入内容" },
                      model: {
                        value: item.value,
                        callback: function ($$v) {
                          _vm.$set(item, "value", $$v)
                        },
                        expression: "item.value",
                      },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c("div", { staticClass: "footer" }, [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      color: "#416deb",
                      border: "1px solid #416deb",
                    },
                    attrs: {
                      icon: "el-icon-circle-plus-outline",
                      disabled: _vm.addBled,
                    },
                    on: { click: _vm.addList },
                  },
                  [_vm._v("添加行")]
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { background: "#edeff4" },
                    attrs: { disabled: _vm.bled },
                    on: { click: _vm.remove },
                  },
                  [_vm._v("清除")]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "180px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.query },
                  },
                  [_vm._v("查询")]
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm.int == 1
      ? _c("div", { staticClass: "nav2" }, [
          _c(
            "div",
            [
              _c("p", [_vm._v("输入序列 :")]),
              _c("el-input", {
                attrs: { type: "textarea", placeholder: "请输入序列" },
                model: {
                  value: _vm.seqstr,
                  callback: function ($$v) {
                    _vm.seqstr = $$v
                  },
                  expression: "seqstr",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "nav-bottom" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "180px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.toseq },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }