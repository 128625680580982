import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import Viewer from "v-viewer"
import moment from "moment"
import echarts from "echarts"
import "echarts-gl"

Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Viewer)

// 日期格式转换
Vue.filter("dateFormat", function (data, formatStr = "YYYY-MM-DD HH:mm:ss", nullStr) {
	if (data) {
		return moment(data).format(formatStr)
	} else {
		return (nullStr || "")
	}
})
router.beforeEach((to, from, next) => {
	document.body.scrollTop = 0
	document.documentElement.scrollTop = 0
	window.pageYOffset = 0
	next()
})
// 过长字符串裁为...
Vue.filter("ellipsis", function (value, length) {
	if (!value) return ""
	if (value.length > length) {
		return value.slice(0, length) + "..."
	}
	return value
})
// get api response errors message
Vue.filter("getResponseErrors", function (resp) {
	if (!resp) {
		return ""
	}
	if (resp.body.errorMsg) {
		return resp.body.errorMsg
	}
	if (resp.body.errors) {
		var errStr = ""
		var keys = Object.keys(resp.body.errors)
		keys.forEach(item => {
			var err = resp.body.errors[item]
			err.forEach(info => {
				errStr += info
			})
		})
		return errStr
	}
})

// replace string for export CSV
Vue.filter("replaceStringForExportCSV", function (str) {
	if (!str) {
		return ""
	} else {
		str = str + ""
		var keys = ["\"", ",", "，", "\\", "-", "+", " ", "_"]
		var hasKey = false

		keys.forEach(item => {
			if (str.indexOf(item) !== -1) {
				hasKey = true
			}
		})

		if (hasKey) {
			if (str.indexOf("\"") !== -1) {
				str = str.replace(new RegExp(",", "gm"), "\"\"")
			}

			str = "\"" + str + "\""
		}
		return str
	}
})

// Format Target List
Vue.filter("formatTargetList", function (targets) {
	var list = []
	const pattern = /(?<type1>\*{1,2})(?<item>[A-Za-z0-9\-\+]+)(\1)(?<type2>\*{1,2})?/g
	targets.forEach((target, index) => {
		var matches = target.matchAll(pattern)
		var name = JSON.parse(JSON.stringify(target))
		for (const match of matches) {
			// console.log(match, match.groups)
			var type = match.groups.type1
			if (type === "**") {
				target = target.replace(
					match[0],
					"<sup>" + match.groups.item + "</sup>"
				)
			} else {
				target = target.replace(
					match[0],
					"<sub>" + match.groups.item + "</sub>"
				)
			}
		}
		// name: 数据库值，用于数据查询提交
		// formatCol： name去掉*号，用于筛选
		// target: name中的*和**处理成上下标标签，用于展示
		list.push({ name: name, formatCol: name.replace(new RegExp("\\*", "gm"), "").toLowerCase(), value: target })
	})
	return list
})

Vue.filter("relatedArticleFormat", function (val) {
	var str = ""
	if (val) {
		if (val.author) {
			str += val.author
		}
		if (val.title) {
			str += " \"" + val.title + "\" "
		}
		if (val.journal) {
			str += val.journal + ". "
		}
		if (val.issn) {
			str += val.issn + "."
		}
	}
	return str
})

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount("#app")
