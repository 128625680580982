<template>
	<div class="favorite-container">
		<div class="nav-bar">
			<router-link v-for="(link, index) of linkList" :key="index"
				:to="'/usercenter/myupload/' + link.url"
				class="item"
				:class="{ active: current === link.url }"
				>{{link.name}}</router-link
			>
		</div>
		<router-view />
	</div>
</template>

<script>
export default {
	data() {
		return {
			linkList: [
				{
					name: "提交序列",
					url: "seq"
				},
				{
					name: "发布话题",
					url: "topic"
				},
				{
					name: "我的评论",
					url: "comments"
				}
			]
		}
	},
	computed: {
		current() {
			var path = this.$route.path
			var parts = path.split("/")
			return parts[parts.length - 1]
		}
	}
}
</script>

<style lang="less" scoped>
.favorite-container {
	.nav-bar {
		display: grid;
		height: 45px;
		grid-template-columns: repeat(7, 100px);
		column-gap: 15px;
		justify-content: center;
		background: #ffffff;

		.item {
			display: grid;
			align-items: center;
			text-align: center;

			.c-font-size-16();
			font-weight: 400;
			color: #666666;
			text-decoration: none;
		}

		.active {
			color: #3463ea;
			border-bottom: 2px solid #3463ea;
		}
	}
}
</style>
