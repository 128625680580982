var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "detail-container" }, [
    _c(
      "div",
      {
        staticClass: "sequence-detail detail-block",
        attrs: { id: "sequence-detail" },
      },
      [
        _c("div", { staticClass: "bar" }, [_vm._v("序列详情")]),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "left" }, [
            _vm.sequnce.secondaryStructureFigure
              ? _c("img", {
                  staticClass: "figure",
                  attrs: {
                    src: `${_vm.imgHost}/${_vm.sequnce.secondaryStructureFigure}`,
                  },
                })
              : _vm._e(),
            !_vm.sequnce.secondaryStructureFigure
              ? _c("img", {
                  staticClass: "figure defaultImg",
                  attrs: { src: require("@/assets/sequence-default2.png") },
                })
              : _vm._e(),
          ]),
          _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "top" }, [
              _c("div", {
                staticClass: "title",
                domProps: { innerHTML: _vm._s(_vm.sequnce.mainName) },
              }),
              !_vm.favorite && _vm.isLogin
                ? _c(
                    "div",
                    { staticClass: "sm-btn", on: { click: _vm.addFavorite } },
                    [_vm._v(" 收 藏 ")]
                  )
                : _vm._e(),
              _vm.favorite && _vm.isLogin
                ? _c(
                    "div",
                    {
                      staticClass: "sm-btn",
                      on: { click: _vm.removeFavorite },
                    },
                    [_vm._v(" 已收藏 ")]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "bottom" }, [
              _c("div", { staticClass: "entity" }, [
                _c("div", { staticClass: "name" }, [_vm._v("其他名称：")]),
                _c("div", { staticClass: "value otherName" }, [
                  _vm._v(_vm._s(_vm.sequnce.otherName)),
                ]),
              ]),
              _c("div", { staticClass: "entity" }, [
                _c("div", { staticClass: "name" }, [_vm._v("适体类型：")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.sequnce.aptamerType)),
                ]),
              ]),
              _c("div", { staticClass: "entity" }, [
                _c("div", { staticClass: "name" }, [_vm._v("折叠程序：")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(" " + _vm._s(_vm.sequnce.foldingProgram) + " "),
                ]),
              ]),
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.sequnce.targetInfos,
                        height: "350px",
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "target",
                          label: "靶标名称",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(row.target || "-"),
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "targetType",
                          label: "靶标种类",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(
                                  " " + _vm._s(row.targetType || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "sample",
                          label: "样本名称",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(" " + _vm._s(row.sample || "-") + " "),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "affinity",
                          label: "亲和力(nM)",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.affinity === "0" || row.affinity === 0
                                        ? "-"
                                        : row.affinity
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "bindingConditions",
                          label: "结合条件",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      row.bindingConditions || "-"
                                    ),
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "score" }, [
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.sequnce.score || 0)),
                ]),
                _c("div", { staticClass: "name" }, [_vm._v("评分")]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "sequence-info detail-block",
        attrs: { id: "sequence-info" },
      },
      [
        _c("div", { staticClass: "bar" }, [_vm._v("序列信息")]),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "entity" }, [
            _c("div", { staticClass: "name" }, [_vm._v("Sequence：")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.sequnce.sequence)),
            ]),
          ]),
          _c("div", { staticClass: "entity" }, [
            _c("div", { staticClass: "name" }, [_vm._v("长度：")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.sequnce.aptamerLength)),
            ]),
          ]),
          _c("div", { staticClass: "entity" }, [
            _c("div", { staticClass: "name" }, [_vm._v("GC含量：")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(
                _vm._s(
                  _vm.sequnce.gcContent
                    ? (parseFloat(_vm.sequnce.gcContent) * 100).toFixed(1)
                    : "-"
                ) + "%"
              ),
            ]),
          ]),
          _c("div", { staticClass: "entity" }, [
            _c("div", { staticClass: "name" }, [_vm._v("分子量：")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(
                _vm._s(
                  _vm.sequnce.molecularWeight
                    ? parseFloat(_vm.sequnce.molecularWeight).toFixed(1)
                    : "-"
                )
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "discoverer detail-block", attrs: { id: "discoverer" } },
      [
        _c("div", { staticClass: "bar" }, [_vm._v("发现者")]),
        _c("div", { staticClass: "content" }, [
          _vm.sequnce.articleID
            ? _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.toDetail("paper", _vm.sequnce.articleID)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.discoverInfo))]
              )
            : _c("div", { staticStyle: { "text-align": "center" } }, [
                _vm._v("暂无数据"),
              ]),
        ]),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "relevant-article detail-block",
        attrs: { id: "relevant-article" },
      },
      [
        _c("div", { staticClass: "bar" }, [_vm._v("相关文献")]),
        _c("div", { staticClass: "statisticalChart" }, [
          _c("ul", [
            _c(
              "li",
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.countryStatis.length <= 0,
                        expression: "countryStatis.length<=0",
                      },
                    ],
                    staticClass: "noData",
                  },
                  [
                    _c("img", {
                      attrs: { src: require("../../assets/nodata.png") },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ]
                ),
                _c("ScreenMethodChart", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.countryStatis.length > 0,
                      expression: "countryStatis.length>0",
                    },
                  ],
                  attrs: { dataList: _vm.countryStatis, totalDesc: "国别" },
                }),
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.sampleStatis.length <= 0,
                        expression: "sampleStatis.length<=0",
                      },
                    ],
                    staticClass: "noData",
                  },
                  [
                    _c("img", {
                      attrs: { src: require("../../assets/nodata.png") },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ]
                ),
                _c("ScreenMethodChart", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.sampleStatis.length > 0,
                      expression: "sampleStatis.length>0",
                    },
                  ],
                  attrs: { dataList: _vm.sampleStatis, totalDesc: "样本" },
                }),
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.usageStatis.length <= 0,
                        expression: "usageStatis.length<=0",
                      },
                    ],
                    staticClass: "noData",
                  },
                  [
                    _c("img", {
                      attrs: { src: require("../../assets/nodata.png") },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ]
                ),
                _c("ScreenMethodChart", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.usageStatis.length > 0,
                      expression: "usageStatis.length>0",
                    },
                  ],
                  attrs: { dataList: _vm.usageStatis, totalDesc: "应用" },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "content" },
          [
            _vm._l(_vm.articles.records, function (item) {
              return _c("div", { key: item.articleID, staticClass: "item" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.toDetail("paper", item.articleID)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm._f("relatedArticleFormat")(item)))]
                ),
              ])
            }),
            _vm.articles.total == 0
              ? _c("div", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("暂无数据"),
                ])
              : _vm._e(),
            _vm.articles.total > 5
              ? _c(
                  "div",
                  {
                    staticClass: "more-btn",
                    on: {
                      click: function ($event) {
                        return _vm.toList("paper")
                      },
                    },
                  },
                  [_vm._v("查看更多")]
                )
              : _vm._e(),
          ],
          2
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "relevant-patent detail-block",
        attrs: { id: "relevant-patent" },
      },
      [
        _c("div", { staticClass: "bar" }, [_vm._v("相关专利")]),
        _c(
          "div",
          { staticClass: "content" },
          [
            _vm._l(_vm.patents.records, function (item) {
              return _c("div", { key: item.patentID, staticClass: "item" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.toDetail("patent", item.patentID)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.title))]
                ),
              ])
            }),
            _vm.patents.total == 0
              ? _c("div", { staticStyle: { "text-align": "center" } }, [
                  _vm._v("暂无数据"),
                ])
              : _vm._e(),
            _vm.patents.total > 5
              ? _c(
                  "div",
                  {
                    staticClass: "more-btn",
                    on: {
                      click: function ($event) {
                        return _vm.toList("patent")
                      },
                    },
                  },
                  [_vm._v("查看更多")]
                )
              : _vm._e(),
          ],
          2
        ),
      ]
    ),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flxed-box" }, [
      _c(
        "a",
        {
          staticClass: "nav",
          attrs: { id: "sequence-detail-nav", href: "#sequence-detail" },
        },
        [_vm._v("序列详情")]
      ),
      _c(
        "a",
        {
          staticClass: "nav",
          attrs: { id: "sequence-info-nav", href: "#sequence-info" },
        },
        [_vm._v("序列信息")]
      ),
      _c(
        "a",
        {
          staticClass: "nav",
          attrs: { id: "discoverer-nav", href: "#discoverer" },
        },
        [_vm._v("发现者")]
      ),
      _c(
        "a",
        {
          staticClass: "nav",
          attrs: { id: "relevant-article-nav", href: "#relevant-article" },
        },
        [_vm._v("相关文献")]
      ),
      _c(
        "a",
        {
          staticClass: "nav",
          attrs: { id: "relevant-patent-nav", href: "#relevant-patent" },
        },
        [_vm._v("相关专利")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }