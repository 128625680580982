<template>
	<div class="team-list-panel">
		<div class="top-bar-container">
			<div>
				<div class="result">
					<ul class="tab-tilte" style="margin: 0px;">
						<li :class="{ active: showIndex == 1 }" @click="showIndex = 1">
							描述性信息
						</li>
						<li :class="{ active: showIndex == 2 }" @click="showIndex = 2">
							追踪信息
						</li>
						<li :class="{ active: showIndex == 3 }" @click="showIndex = 3">
							招募信息
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!--描述性信息-->
		<div class="team-list" v-show="showIndex == 1">
			<div class="team-container">
				<!-- <div class="team">{{content.descriptiveInformation}}</div> -->
				<div class="title">{{ content.descriptiveInformation.briefTitle }}</div>
				<div class="lable">正式名称</div>
				<div
					class="text"
					v-html="content.descriptiveInformation.officialTitle"
				></div>
				<div class="lable">简要总结</div>
				<div
					class="text"
					v-html="content.descriptiveInformation.briefSummary"
				></div>
				<div class="lable">详细说明</div>
				<div
					class="text"
					v-html="content.descriptiveInformation.detailedDescription"
				></div>
				<div class="lable">研究类型</div>
				<div
					class="text"
					v-html="content.descriptiveInformation.studyType"
				></div>
				<div class="lable">条件</div>
				<div
					class="text"
					v-html="content.descriptiveInformation.conditions"
				></div>
				<div class="lable">干预</div>
				<div
					class="text"
					v-html="content.descriptiveInformation.intervention"
				></div>
				<div class="lable">出版物</div>
				<div
					class="text"
					v-html="content.descriptiveInformation.publications"
				></div>
			</div>
		</div>
		<!--追踪信息-->
		<div class="team-list" v-show="showIndex == 2">
			<div class="team-container">
				<!-- <div class="team">{{content.trackingInformation}}</div> -->
				<div class="lable">当前的主要成果指标</div>
				<div
					class="text"
					v-html="content.trackingInformation.currentPrimaryOutcomeMeasures"
				></div>
				<div class="lable">原始主要结局指标</div>
				<div
					class="text"
					v-html="content.trackingInformation.originalPrimaryOutcomeMeasures"
				></div>
				<div class="lable">当前的次要成果指标</div>
				<div
					class="text"
					v-html="content.trackingInformation.currentSecondaryOutcomeMeasures"
				></div>
			</div>
		</div>
		<!--招募信息-->
		<div class="team-list" v-show="showIndex == 3">
			<div class="team-container">
				<!-- <div class="team">{{content.recruitmentInformation}}</div> -->
				<div class="lable">招募状态</div>
				<div
					class="text"
					v-html="content.recruitmentInformation.recruitmentStatus"
				></div>
				<div class="lable">预计招募人数</div>
				<div
					class="text"
					v-html="content.recruitmentInformation.estimatedEnrollment"
				></div>
				<div class="lable">预计完成研究日期</div>
				<div
					class="text"
					v-html="content.recruitmentInformation.estimatedStudyCompletionDate"
				></div>
				<div class="lable">资格标准</div>
				<div
					class="text"
					v-html="content.recruitmentInformation.eligibilityCriteria"
				></div>
				<div class="lable">年龄</div>
				<div class="text" v-html="content.recruitmentInformation.ages"></div>
				<div class="lable">联系方式</div>
				<div
					class="text"
					v-html="content.recruitmentInformation.contacts"
				></div>
				<div class="lable">国家/地区</div>
				<div
					class="text"
					v-html="content.recruitmentInformation.listedLocationCountries"
				></div>
			</div>
		</div>
	</div>
</template>

<script>
import "viewerjs/dist/viewer.css"
export default {
	props: ["content"],
	data() {
		return {
			showIndex: 1
		}
	},
	methods: {}
}
</script>

<style lang="less" scoped>
.title {
	width: 100%;
	font-size: 24px;
	text-align: center;
	padding: 0px 30px;
	font-weight: bolder;
}

.lable {
	width: 100%;
	.c-font-size-18();
	text-align: left;
	font-weight: bolder;
	margin-top: 10px;
}
.text {
	width: 100%;
	text-align: left;

	/deep/ ul {
		margin-left: 20px;

		li {
			a {
				pointer-events: none;
			}
		}
	}
}

.tab-tilte {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	list-style: none;
	white-space: nowrap;
	transition: width 0.5s ease;
}

.tab-tilte li {
	padding: 10px 20px;
	.c-font-size-14();
	.c-font-family-PF();
	font-weight: bolder;
	line-height: 20px;
	color: #333333;
}

.active {
	border-bottom: 2px solid #3463ea;
}

.imgpanel {
	width: 80px;
	height: 80px;
	margin: 16px 0 0 16px;
}

.top-bar-container {
	width: 100%;
	background-color: #ffffff;
	display: inline-block;
}

.result {
	.c-font-size-12();
	.c-font-family-PF();
	line-height: 16px;
	color: #666666;
	display: flex;
	flex-direction: row;
}

.result .value {
	color: #3463ea;
	margin: 0 6px;
}

.team-list {
	width: 100%;
}

.team-container {
	width: 100%;
	background-color: #ffffff;
	margin-top: 16px;
	padding: 30px;
}

.team {
	width: 100%;
	margin: 16px;
	font-family: PingFang-SC-Medium;
	.c-font-size-14();
}

.team .title {
	.c-font-size-12();
	color: #3463ea;
	line-height: 20px;
	text-align: left;
}

.team .title a {
	text-decoration: none;
	.c-font-size-16();
}
.team .title a:visited {
	color: #3463ea;
}

.team .entry a:visited {
	color: #3463ea;
}

.team .location {
	.c-font-size-12();
	line-height: 20px;
	display: flex;
	flex-direction: row;
	text-align: left;
}

.team .location .entry {
	display: flex;
	flex-direction: row;
	margin-right: 20px;
}

.team .location .entry .value {
	margin-left: 6px;
}

.team .link {
	display: flex;
	flex-direction: row;
	.c-font-size-12();
	line-height: 20px;
}

.team .link .url {
	margin-left: 6px;
}

.pagination {
	display: flex;
	justify-content: center;
	margin: 10px auto;
}
</style>
