/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CommentType} from './CommentType';

/**
 * The UserCenterCommentBody model module.
 * @module model/UserCenterCommentBody
 * @version v1
 */
export class UserCenterCommentBody {
  /**
   * Constructs a new <code>UserCenterCommentBody</code>.
   * @alias module:model/UserCenterCommentBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>UserCenterCommentBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserCenterCommentBody} obj Optional instance to populate.
   * @return {module:model/UserCenterCommentBody} The populated <code>UserCenterCommentBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserCenterCommentBody();
      if (data.hasOwnProperty('ArticleID'))
        obj.articleID = ApiClient.convertToType(data['ArticleID'], 'String');
      if (data.hasOwnProperty('UserID'))
        obj.userID = ApiClient.convertToType(data['UserID'], 'String');
      if (data.hasOwnProperty('ParentID'))
        obj.parentID = ApiClient.convertToType(data['ParentID'], 'String');
      if (data.hasOwnProperty('Content'))
        obj.content = ApiClient.convertToType(data['Content'], 'String');
      if (data.hasOwnProperty('CommentType'))
        obj.commentType = CommentType.constructFromObject(data['CommentType']);
    }
    return obj;
  }
}

/**
 * @member {String} articleID
 */
UserCenterCommentBody.prototype.articleID = undefined;

/**
 * @member {String} userID
 */
UserCenterCommentBody.prototype.userID = undefined;

/**
 * @member {String} parentID
 */
UserCenterCommentBody.prototype.parentID = undefined;

/**
 * @member {String} content
 */
UserCenterCommentBody.prototype.content = undefined;

/**
 * @member {module:model/CommentType} commentType
 */
UserCenterCommentBody.prototype.commentType = undefined;

