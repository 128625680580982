/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {ReferenceTypeEnum} from './ReferenceTypeEnum';

/**
 * The UserCenterSubmitsequenceBody model module.
 * @module model/UserCenterSubmitsequenceBody
 * @version v1
 */
export class UserCenterSubmitsequenceBody {
  /**
   * Constructs a new <code>UserCenterSubmitsequenceBody</code>.
   * @alias module:model/UserCenterSubmitsequenceBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>UserCenterSubmitsequenceBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserCenterSubmitsequenceBody} obj Optional instance to populate.
   * @return {module:model/UserCenterSubmitsequenceBody} The populated <code>UserCenterSubmitsequenceBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserCenterSubmitsequenceBody();
      if (data.hasOwnProperty('Name'))
        obj.name = ApiClient.convertToType(data['Name'], 'String');
      if (data.hasOwnProperty('Type'))
        obj.type = ApiClient.convertToType(data['Type'], 'String');
      if (data.hasOwnProperty('Target'))
        obj.target = ApiClient.convertToType(data['Target'], 'String');
      if (data.hasOwnProperty('TargetType'))
        obj.targetType = ApiClient.convertToType(data['TargetType'], 'String');
      if (data.hasOwnProperty('Affinity'))
        obj.affinity = ApiClient.convertToType(data['Affinity'], 'String');
      if (data.hasOwnProperty('ScreenMethod'))
        obj.screenMethod = ApiClient.convertToType(data['ScreenMethod'], 'String');
      if (data.hasOwnProperty('SampleType'))
        obj.sampleType = ApiClient.convertToType(data['SampleType'], 'String');
      if (data.hasOwnProperty('AppliedteChnology'))
        obj.appliedteChnology = ApiClient.convertToType(data['AppliedteChnology'], 'String');
      if (data.hasOwnProperty('CombiningCondition'))
        obj.combiningCondition = ApiClient.convertToType(data['CombiningCondition'], 'String');
      if (data.hasOwnProperty('Sequence'))
        obj.sequence = ApiClient.convertToType(data['Sequence'], 'String');
      if (data.hasOwnProperty('ReferenceType'))
        obj.referenceType = ReferenceTypeEnum.constructFromObject(data['ReferenceType']);
      if (data.hasOwnProperty('ReferenceInfo'))
        obj.referenceInfo = ApiClient.convertToType(data['ReferenceInfo'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} name
 */
UserCenterSubmitsequenceBody.prototype.name = undefined;

/**
 * @member {String} type
 */
UserCenterSubmitsequenceBody.prototype.type = undefined;

/**
 * @member {String} target
 */
UserCenterSubmitsequenceBody.prototype.target = undefined;

/**
 * @member {String} targetType
 */
UserCenterSubmitsequenceBody.prototype.targetType = undefined;

/**
 * @member {String} affinity
 */
UserCenterSubmitsequenceBody.prototype.affinity = undefined;

/**
 * @member {String} screenMethod
 */
UserCenterSubmitsequenceBody.prototype.screenMethod = undefined;

/**
 * @member {String} sampleType
 */
UserCenterSubmitsequenceBody.prototype.sampleType = undefined;

/**
 * @member {String} appliedteChnology
 */
UserCenterSubmitsequenceBody.prototype.appliedteChnology = undefined;

/**
 * @member {String} combiningCondition
 */
UserCenterSubmitsequenceBody.prototype.combiningCondition = undefined;

/**
 * @member {String} sequence
 */
UserCenterSubmitsequenceBody.prototype.sequence = undefined;

/**
 * @member {module:model/ReferenceTypeEnum} referenceType
 */
UserCenterSubmitsequenceBody.prototype.referenceType = undefined;

/**
 * @member {String} referenceInfo
 */
UserCenterSubmitsequenceBody.prototype.referenceInfo = undefined;

