/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {BooleanResponse} from '../model/BooleanResponse';
import {LearnTopicDetailDtoResponse} from '../model/LearnTopicDetailDtoResponse';
import {LearnTopicListDtoResponse} from '../model/LearnTopicListDtoResponse';
import {LearnTopicTypeEnum} from '../model/LearnTopicTypeEnum';
import {SubmitLearnTopicRequest} from '../model/SubmitLearnTopicRequest';

/**
* LearnTopic service.
* @module api/LearnTopicApi
* @version v1
*/
export class LearnTopicApi {

    /**
    * Constructs a new LearnTopicApi. 
    * @alias module:api/LearnTopicApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiLearnTopicDetailGet operation.
     * @callback moduleapi/LearnTopicApi~apiLearnTopicDetailGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LearnTopicDetailDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 交流内容详情
     * @param {String} id 
     * @param {module:api/LearnTopicApi~apiLearnTopicDetailGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiLearnTopicDetailGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiLearnTopicDetailGet");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'id': id
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LearnTopicDetailDtoResponse;

      return this.apiClient.callApi(
        '/api/LearnTopic/Detail', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiLearnTopicLearnTopicesGet operation.
     * @callback moduleapi/LearnTopicApi~apiLearnTopicLearnTopicesGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LearnTopicListDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 交流内容列表
     * @param {Object} opts Optional parameters
     * @param {module:model/LearnTopicTypeEnum} opts.learnTopicType 
     * @param {Number} opts.index 
     * @param {Number} opts.size 
     * @param {module:api/LearnTopicApi~apiLearnTopicLearnTopicesGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiLearnTopicLearnTopicesGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'LearnTopicType': opts['learnTopicType'],'Index': opts['index'],'Size': opts['size']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LearnTopicListDtoResponse;

      return this.apiClient.callApi(
        '/api/LearnTopic/LearnTopices', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiLearnTopicSubmitPost operation.
     * @callback moduleapi/LearnTopicApi~apiLearnTopicSubmitPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BooleanResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 提交交流内容
     * @param {Object} opts Optional parameters
     * @param {module:model/SubmitLearnTopicRequest} opts.body 
     * @param {module:api/LearnTopicApi~apiLearnTopicSubmitPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiLearnTopicSubmitPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;

      return this.apiClient.callApi(
        '/api/LearnTopic/Submit', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}