<template>
	<div class="whole">
		<div class="search">
			<!-- <div class="header">
				<div class="tab">
					<ul>
						<li :class="{ show: num == 0 }" @click="active(0)">二级结构</li>
						<li :class="{ show: num == 1 }" @click="active(1)">三级结构</li>
						<li :class="{ show: num == 2 }" @click="active(2)">模拟预测</li>
					</ul>
				</div>
				<div class="orders">
					<div class="orders-container">
						<div class="show">Show</div>
						<el-select
							v-model="orderBy"
							class="order-type"
							placeholder="请选择"
							@change="orderChange"
						>
							<el-option
								v-for="item in orderOptions"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
					</div>
				</div>
			</div> -->
			<div class="Secondary">
				<div class="SecondaryTop">
					<ul>
						<li
							@click="
								toTool(
									'http://www.unafold.org/mfold/applications/dna-folding-form.php'
								)
							"
						>
							<img src="../../assets/UNAFold_favicon.png" />
							<p>UNAFold</p>
						</li>
						<li @click="toTool('http://www.nupack.org/')">
							<img src="../../assets/nupack_favicon.png" />
							<p>NUPACK</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "search",
	data() {
		return {}
	},
	mounted() {},
	methods: {
		toTool(url) {
			window.open(url)
		}
	}
}
</script>
<style scoped lang="less">
ul {
	list-style: none;
}
.whole {
	width: 1170px;
	margin: 10px auto 20px auto;
	height: 1010px;
	.search {
		width: 1170px;
		height: 45px;
		background: #ffffff;
		box-shadow: 0px 0px 6px 0px rgba(4, 0, 0, 0.07);
		.Secondary {
			width: 1170px;
			height: 934px;
			background: #ffffff;
			padding-top: 30px;
			display: flex;
			align-items: center;
			.SecondaryTop {
				width: 100%;
				height: 100%;
				margin: 0 auto;
				ul {
					width: 100%;
					height: 100%;
					display: flex;
					flex-wrap: wrap;
					li {
						width: 180px;
						height: 180px;
						margin-right: 18px;
						p {
							width: 100%;
							padding: 0 10px;
							height: 30px;
							line-height: 30px;
							text-align: center;
							color: #3463ea;
							text-decoration: underline;
							// border-bottom: 1px solid #3463ea;
							margin: 20px auto;
							cursor: pointer;
							word-break: break-all;
							word-wrap: break-word;
						}
						img {
							width: 120px;
							height: 120px;
							transform: translateX(25%);
						}
					}
				}
			}
		}
		.header {
			width: 96%;
			margin: 0 auto;
			height: 100%;
			display: flex;
			justify-content: space-between;
			.tab {
				height: 100%;
				display: flex;
				align-items: center;
				ul {
					display: flex;
					height: 100%;
					.show {
						border-bottom: 2px solid #3463ea;
						color: #3463ea;
					}
					li {
						width: 63px;
						height: 45px;
						.c-font-size-14();
						.c-font-family-PF();
						font-weight: 400;
						color: #666666;
						line-height: 45px;
						margin-right: 10px;
						cursor: pointer;
						text-align: center;
					}
				}
			}
		}
	}
}
.orders {
	width: 100.5px;
	position: inherit !important;
	.orders-container {
		position: relative;
		display: flex;
		align-items: center;
		.show {
			height: 45px;
			line-height: 45px;
			.c-font-size-14();
			.c-font-family-PF();
			font-weight: 400;
			color: #666666;
		}
	}
	.orders-container::before {
		position: absolute;
		top: 20px;
		right: 0;
		content: "";
		border-style: solid;
		border-width: 5px;
		border-color: rgb(0, 0, 0) transparent transparent transparent;
		z-index: 999;
	}

	.order-type {
		line-height: 45px;
		input {
			border: none;
			height: 22px;
			width: 100%;
			border-radius: 5px;
			text-indent: 2px;
			padding: 0;
			text-align: center;
		}

		.el-icon-arrow-up:before {
			content: "" !important;
		}
	}
}
</style>
