<template>
	<div class="filter-panel-container">
		<form @submit.prevent="submit" novalidate="true">
			<div class="main-filter-panel">
				<div class="filter">
					<div class="label">靶标名称</div>
					<div class="input">
						<input
							type="text"
							name="targetNameFilter"
							v-model="targetNameFilter"
							maxlength="20"
							@keyup.enter="submit"
						/>
					</div>
				</div>
				<div class="filter">
					<div class="label">适体类别</div>
					<el-select
						v-model="aptamerTypeFilter"
						class="aptamer-type"
						clearable
						placeholder="请选择"
					>
						<el-option
							v-for="item in aptamerTypeFilters"
							:key="item.value"
							:label="item.description"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</div>
				<div class="filter">
					<div class="label">应用</div>
					<div class="input">
						<input
							type="text"
							name="applyFilter"
							v-model="applyFilter"
							maxlength="20"
							@keyup.enter="submit"
						/>
					</div>
				</div>
				<div class="filter">
					<div class="label">样本类型</div>
					<div class="input">
						<input
							type="text"
							name="sampleTypeFilter"
							v-model="sampleTypeFilter"
							maxlength="20"
							@keyup.enter="submit"
						/>
					</div>
				</div>
				<div class="filter" v-if="false">
					<div class="label">筛选方法</div>
					<div class="input">
						<input
							type="text"
							name="methodFilter"
							v-model="methodFilter"
							maxlength="20"
							@keyup.enter="submit"
						/>
					</div>
				</div>
				<!-- <div class="filter" v-if="false">
					<div class="label">年份范围</div>
					<div class="input">
						<el-slider
							v-model="yearFilter"
							range
							:min="yearFilters[0]"
							:max="yearFilters[1]"
							@change="change"
						>
						</el-slider>
					</div>
				</div> -->
			</div>
			<div class="control-panel">
				<div class="submit">
					<button type="submit">筛选</button>
				</div>
				<div class="reset">
					<button @click="reset" type="reset">重置</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
export default {
	props: ["aptamerTypeFilters", "yearFilterRange"],
	data() {
		return {
			targetNameFilter: "",
			aptamerTypeFilter: "",
			applyFilter: "",
			sampleTypeFilter: "",
			methodFilter: "",
			yearFilter: []
		}
	},
	mounted() {
		this.yearFilter = this.yearFilters
	},
	computed: {
		// yearFilters() {
		// 	if (this.yearFilterRange && this.yearFilterRange.length === 2) {
		// 		return this.yearFilterRange
		// 	} else {
		// 		throw new Error("年份范围必须有两个参数值")
		// 	}
		// },
		// yearMarks() {
		// 	return JSON.parse(
		// 		`{${this.yearFilterRange.map((s) => `"${s}":"${s}"`).join()}}`
		// 	)
		// }
	},
	methods: {
		submit(event) {
			event.preventDefault()
			this.query()
		},
		query() {
			console.log(this.$parent.$children[3])
			this.$parent.$children[3].pager.index = 1
			this.$parent.searchType = 2
			this.$parent.replaceUrl()
		},
		// change(value) {
		// 	this.yearFilter = value
		// },
		reset() {
			this.targetNameFilter = ""
			this.aptamerTypeFilter = ""
			this.applyFilter = ""
			this.sampleTypeFilter = ""
			this.methodFilter = ""
			// this.yearFilter = this.yearFilters
			this.$parent.replaceUrl()
			this.query()
		}
	}
}
</script>

<style lang="less">
.aptamer-type {
	width: 100%;
	input {
		display: block;
		background-color: #f1f2f7;
		height: 40px;
		width: 98%;
		background-color: #f1f2f7;
		border-radius: 2px;
		border: none;
		text-indent: 2px;
	}
}
</style>
