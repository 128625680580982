/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The UserCenterSignonBody model module.
 * @module model/UserCenterSignonBody
 * @version v1
 */
export class UserCenterSignonBody {
  /**
   * Constructs a new <code>UserCenterSignonBody</code>.
   * @alias module:model/UserCenterSignonBody
   * @class
   * @param account {String} 
   * @param password {String} 
   */
  constructor(account, password) {
    this.account = account;
    this.password = password;
  }

  /**
   * Constructs a <code>UserCenterSignonBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserCenterSignonBody} obj Optional instance to populate.
   * @return {module:model/UserCenterSignonBody} The populated <code>UserCenterSignonBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserCenterSignonBody();
      if (data.hasOwnProperty('Account'))
        obj.account = ApiClient.convertToType(data['Account'], 'String');
      if (data.hasOwnProperty('Password'))
        obj.password = ApiClient.convertToType(data['Password'], 'String');
      if (data.hasOwnProperty('LastLoginIP'))
        obj.lastLoginIP = ApiClient.convertToType(data['LastLoginIP'], 'String');
      if (data.hasOwnProperty('Remember'))
        obj.remember = ApiClient.convertToType(data['Remember'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {String} account
 */
UserCenterSignonBody.prototype.account = undefined;

/**
 * @member {String} password
 */
UserCenterSignonBody.prototype.password = undefined;

/**
 * @member {String} lastLoginIP
 */
UserCenterSignonBody.prototype.lastLoginIP = undefined;

/**
 * @member {Boolean} remember
 */
UserCenterSignonBody.prototype.remember = undefined;

