/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {ClinicalDetailDescriptiveDto} from './ClinicalDetailDescriptiveDto';
import {ClinicalDetailRecruitmentDto} from './ClinicalDetailRecruitmentDto';
import {ClinicalDetailTrackingDto} from './ClinicalDetailTrackingDto';
import {Country} from './Country';

/**
 * The ClinicalDetailDto model module.
 * @module model/ClinicalDetailDto
 * @version v1
 */
export class ClinicalDetailDto {
  /**
   * Constructs a new <code>ClinicalDetailDto</code>.
   * @alias module:model/ClinicalDetailDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ClinicalDetailDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ClinicalDetailDto} obj Optional instance to populate.
   * @return {module:model/ClinicalDetailDto} The populated <code>ClinicalDetailDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ClinicalDetailDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('organization'))
        obj.organization = ApiClient.convertToType(data['organization'], 'String');
      if (data.hasOwnProperty('country'))
        obj.country = Country.constructFromObject(data['country']);
      if (data.hasOwnProperty('descriptiveInformation'))
        obj.descriptiveInformation = ClinicalDetailDescriptiveDto.constructFromObject(data['descriptiveInformation']);
      if (data.hasOwnProperty('briefSummary'))
        obj.briefSummary = ApiClient.convertToType(data['briefSummary'], 'String');
      if (data.hasOwnProperty('studyType'))
        obj.studyType = ApiClient.convertToType(data['studyType'], 'String');
      if (data.hasOwnProperty('url'))
        obj.url = ApiClient.convertToType(data['url'], 'String');
      if (data.hasOwnProperty('studyResult'))
        obj.studyResult = ApiClient.convertToType(data['studyResult'], 'String');
      if (data.hasOwnProperty('studyStartDate'))
        obj.studyStartDate = ApiClient.convertToType(data['studyStartDate'], 'Date');
      if (data.hasOwnProperty('recruitmentStatus'))
        obj.recruitmentStatus = ApiClient.convertToType(data['recruitmentStatus'], 'String');
      if (data.hasOwnProperty('conditions'))
        obj.conditions = ApiClient.convertToType(data['conditions'], 'String');
      if (data.hasOwnProperty('collected'))
        obj.collected = ApiClient.convertToType(data['collected'], 'Boolean');
      if (data.hasOwnProperty('trackingInformation'))
        obj.trackingInformation = ClinicalDetailTrackingDto.constructFromObject(data['trackingInformation']);
      if (data.hasOwnProperty('recruitmentInformation'))
        obj.recruitmentInformation = ClinicalDetailRecruitmentDto.constructFromObject(data['recruitmentInformation']);
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
ClinicalDetailDto.prototype.id = undefined;

/**
 * @member {String} title
 */
ClinicalDetailDto.prototype.title = undefined;

/**
 * @member {String} organization
 */
ClinicalDetailDto.prototype.organization = undefined;

/**
 * @member {module:model/Country} country
 */
ClinicalDetailDto.prototype.country = undefined;

/**
 * @member {module:model/ClinicalDetailDescriptiveDto} descriptiveInformation
 */
ClinicalDetailDto.prototype.descriptiveInformation = undefined;

/**
 * @member {String} briefSummary
 */
ClinicalDetailDto.prototype.briefSummary = undefined;

/**
 * @member {String} studyType
 */
ClinicalDetailDto.prototype.studyType = undefined;

/**
 * @member {String} url
 */
ClinicalDetailDto.prototype.url = undefined;

/**
 * @member {String} studyResult
 */
ClinicalDetailDto.prototype.studyResult = undefined;

/**
 * @member {Date} studyStartDate
 */
ClinicalDetailDto.prototype.studyStartDate = undefined;

/**
 * @member {String} recruitmentStatus
 */
ClinicalDetailDto.prototype.recruitmentStatus = undefined;

/**
 * @member {String} conditions
 */
ClinicalDetailDto.prototype.conditions = undefined;

/**
 * @member {Boolean} collected
 */
ClinicalDetailDto.prototype.collected = undefined;

/**
 * @member {module:model/ClinicalDetailTrackingDto} trackingInformation
 */
ClinicalDetailDto.prototype.trackingInformation = undefined;

/**
 * @member {module:model/ClinicalDetailRecruitmentDto} recruitmentInformation
 */
ClinicalDetailDto.prototype.recruitmentInformation = undefined;

