/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {ProjectDetailDtoResponse} from '../model/ProjectDetailDtoResponse';
import {ProjectListDtoResponse} from '../model/ProjectListDtoResponse';
import {SearchTypeEnum} from '../model/SearchTypeEnum';

/**
* Project service.
* @module api/ProjectApi
* @version v1
*/
export class ProjectApi {

    /**
    * Constructs a new ProjectApi. 
    * @alias module:api/ProjectApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiProjectIdGet operation.
     * @callback moduleapi/ProjectApi~apiProjectIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProjectDetailDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 项目详情查询接口
     * @param {String} id 项目主键ID
     * @param {module:api/ProjectApi~apiProjectIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiProjectIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiProjectIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProjectDetailDtoResponse;

      return this.apiClient.callApi(
        '/api/Project/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiProjectQueryGet operation.
     * @callback moduleapi/ProjectApi~apiProjectQueryGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProjectListDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 项目列表查询接口
     * @param {module:model/SearchTypeEnum} searchType 
     * @param {Object} opts Optional parameters
     * @param {String} opts.keyWord 
     * @param {String} opts.title 
     * @param {String} opts.keywords 
     * @param {Number} opts.startYear 
     * @param {String} opts.country 
     * @param {String} opts.leaderInstitution 
     * @param {String} opts.leader 
     * @param {String} opts.researcherAreas 
     * @param {String} opts.sponsorInstitution 
     * @param {String} opts._abstract 
     * @param {Number} opts.minBudget 
     * @param {Number} opts.maxBudget 
     * @param {Number} opts.index 
     * @param {Number} opts.size 
     * @param {module:api/ProjectApi~apiProjectQueryGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiProjectQueryGet(searchType, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'searchType' is set
      if (searchType === undefined || searchType === null) {
        throw new Error("Missing the required parameter 'searchType' when calling apiProjectQueryGet");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'KeyWord': opts['keyWord'],'Title': opts['title'],'Keywords': opts['keywords'],'StartYear': opts['startYear'],'Country': opts['country'],'LeaderInstitution': opts['leaderInstitution'],'Leader': opts['leader'],'ResearcherAreas': opts['researcherAreas'],'SponsorInstitution': opts['sponsorInstitution'],'Abstract': opts['_abstract'],'MinBudget': opts['minBudget'],'MaxBudget': opts['maxBudget'],'SearchType': searchType,'Index': opts['index'],'Size': opts['size']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProjectListDtoResponse;

      return this.apiClient.callApi(
        '/api/Project/query', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}