<template>
  <div class="LoadingBody">
      <img src="../assets/loading.png" />
      <p>{{promptText || "加载中，请耐心等待"}}</p>
  </div>
</template>

<script>
export default {
	name: "Loading",
	props: ["promptText"]

}
</script>

<style lang="less" scoped>
.LoadingBody {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    text-align: center;

    p{
        // width: 126px;
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
        color: #B8B7B7;
    }

    img {
        width: 107px;
        height: 107px;

        -webkit-transform: rotate(360deg);
        animation: rotation 3s linear infinite;
        -moz-animation: rotation 3s linear infinite;
        -webkit-animation: rotation 3s linear infinite;
        -o-animation: rotation 3s linear infinite;
    }
    @-webkit-keyframes rotation{
        from {-webkit-transform: rotate(0deg);}
        to {-webkit-transform: rotate(360deg);}
    }
}
</style>
