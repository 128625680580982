/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ArticleCommentDto model module.
 * @module model/ArticleCommentDto
 * @version v1
 */
export class ArticleCommentDto {
  /**
   * Constructs a new <code>ArticleCommentDto</code>.
   * @alias module:model/ArticleCommentDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ArticleCommentDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ArticleCommentDto} obj Optional instance to populate.
   * @return {module:model/ArticleCommentDto} The populated <code>ArticleCommentDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ArticleCommentDto();
      if (data.hasOwnProperty('userID'))
        obj.userID = ApiClient.convertToType(data['userID'], 'String');
      if (data.hasOwnProperty('commentID'))
        obj.commentID = ApiClient.convertToType(data['commentID'], 'String');
      if (data.hasOwnProperty('avator'))
        obj.avator = ApiClient.convertToType(data['avator'], 'String');
      if (data.hasOwnProperty('userName'))
        obj.userName = ApiClient.convertToType(data['userName'], 'String');
      if (data.hasOwnProperty('content'))
        obj.content = ApiClient.convertToType(data['content'], 'String');
      if (data.hasOwnProperty('childs'))
        obj.childs = ApiClient.convertToType(data['childs'], [ArticleCommentDto]);
      if (data.hasOwnProperty('createOn'))
        obj.createOn = ApiClient.convertToType(data['createOn'], 'Date');
    }
    return obj;
  }
}

/**
 * @member {String} userID
 */
ArticleCommentDto.prototype.userID = undefined;

/**
 * @member {String} commentID
 */
ArticleCommentDto.prototype.commentID = undefined;

/**
 * @member {String} avator
 */
ArticleCommentDto.prototype.avator = undefined;

/**
 * @member {String} userName
 */
ArticleCommentDto.prototype.userName = undefined;

/**
 * @member {String} content
 */
ArticleCommentDto.prototype.content = undefined;

/**
 * @member {Array.<module:model/ArticleCommentDto>} childs
 */
ArticleCommentDto.prototype.childs = undefined;

/**
 * @member {Date} createOn
 */
ArticleCommentDto.prototype.createOn = undefined;

