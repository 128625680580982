/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CollectionTypeEnum} from './CollectionTypeEnum';

/**
 * The Favorite model module.
 * @module model/Favorite
 * @version v1
 */
export class Favorite {
  /**
   * Constructs a new <code>Favorite</code>.
   * @alias module:model/Favorite
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>Favorite</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Favorite} obj Optional instance to populate.
   * @return {module:model/Favorite} The populated <code>Favorite</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Favorite();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('userID'))
        obj.userID = ApiClient.convertToType(data['userID'], 'String');
      if (data.hasOwnProperty('collectionID'))
        obj.collectionID = ApiClient.convertToType(data['collectionID'], 'String');
      if (data.hasOwnProperty('collectionType'))
        obj.collectionType = CollectionTypeEnum.constructFromObject(data['collectionType']);
      if (data.hasOwnProperty('publishedDate'))
        obj.publishedDate = ApiClient.convertToType(data['publishedDate'], 'Date');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
Favorite.prototype.id = undefined;

/**
 * @member {String} userID
 */
Favorite.prototype.userID = undefined;

/**
 * @member {String} collectionID
 */
Favorite.prototype.collectionID = undefined;

/**
 * @member {module:model/CollectionTypeEnum} collectionType
 */
Favorite.prototype.collectionType = undefined;

/**
 * @member {Date} publishedDate
 */
Favorite.prototype.publishedDate = undefined;

