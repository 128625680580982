/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {AptamerDetailTargetDto} from './AptamerDetailTargetDto';
import {SubmittedSequence} from './SubmittedSequence';
import {TargetTypeEnum} from './TargetTypeEnum';

/**
 * The AptamerSequenceDetailDto model module.
 * @module model/AptamerSequenceDetailDto
 * @version v1
 */
export class AptamerSequenceDetailDto {
  /**
   * Constructs a new <code>AptamerSequenceDetailDto</code>.
   * @alias module:model/AptamerSequenceDetailDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AptamerSequenceDetailDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AptamerSequenceDetailDto} obj Optional instance to populate.
   * @return {module:model/AptamerSequenceDetailDto} The populated <code>AptamerSequenceDetailDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AptamerSequenceDetailDto();
      if (data.hasOwnProperty('aptamerId'))
        obj.aptamerId = ApiClient.convertToType(data['aptamerId'], 'String');
      if (data.hasOwnProperty('secondaryStructureFigure'))
        obj.secondaryStructureFigure = ApiClient.convertToType(data['secondaryStructureFigure'], 'String');
      if (data.hasOwnProperty('aptamerName'))
        obj.aptamerName = ApiClient.convertToType(data['aptamerName'], 'String');
      if (data.hasOwnProperty('mainName'))
        obj.mainName = ApiClient.convertToType(data['mainName'], 'String');
      if (data.hasOwnProperty('otherName'))
        obj.otherName = ApiClient.convertToType(data['otherName'], 'String');
      if (data.hasOwnProperty('aptamerNames'))
        obj.aptamerNames = ApiClient.convertToType(data['aptamerNames'], ['String']);
      if (data.hasOwnProperty('targetName'))
        obj.targetName = ApiClient.convertToType(data['targetName'], 'String');
      if (data.hasOwnProperty('targetType'))
        obj.targetType = TargetTypeEnum.constructFromObject(data['targetType']);
      if (data.hasOwnProperty('targetTypeDescription'))
        obj.targetTypeDescription = ApiClient.convertToType(data['targetTypeDescription'], 'String');
      if (data.hasOwnProperty('aptamerType'))
        obj.aptamerType = ApiClient.convertToType(data['aptamerType'], 'String');
      if (data.hasOwnProperty('affinity'))
        obj.affinity = ApiClient.convertToType(data['affinity'], 'String');
      if (data.hasOwnProperty('bindingConditions'))
        obj.bindingConditions = ApiClient.convertToType(data['bindingConditions'], 'String');
      if (data.hasOwnProperty('foldingProgram'))
        obj.foldingProgram = ApiClient.convertToType(data['foldingProgram'], 'String');
      if (data.hasOwnProperty('aptamerLength'))
        obj.aptamerLength = ApiClient.convertToType(data['aptamerLength'], 'String');
      if (data.hasOwnProperty('gcContent'))
        obj.gcContent = ApiClient.convertToType(data['gcContent'], 'String');
      if (data.hasOwnProperty('molecularWeight'))
        obj.molecularWeight = ApiClient.convertToType(data['molecularWeight'], 'String');
      if (data.hasOwnProperty('oD260'))
        obj.oD260 = ApiClient.convertToType(data['oD260'], 'String');
      if (data.hasOwnProperty('discoverer'))
        obj.discoverer = ApiClient.convertToType(data['discoverer'], 'String');
      if (data.hasOwnProperty('score'))
        obj.score = ApiClient.convertToType(data['score'], 'Number');
      if (data.hasOwnProperty('targetInfos'))
        obj.targetInfos = ApiClient.convertToType(data['targetInfos'], [AptamerDetailTargetDto]);
      if (data.hasOwnProperty('articleID'))
        obj.articleID = ApiClient.convertToType(data['articleID'], 'String');
      if (data.hasOwnProperty('articleTitle'))
        obj.articleTitle = ApiClient.convertToType(data['articleTitle'], 'String');
      if (data.hasOwnProperty('sequence'))
        obj.sequence = ApiClient.convertToType(data['sequence'], 'String');
      if (data.hasOwnProperty('issn'))
        obj.issn = ApiClient.convertToType(data['issn'], 'String');
      if (data.hasOwnProperty('doi'))
        obj.doi = ApiClient.convertToType(data['doi'], 'String');
      if (data.hasOwnProperty('journal'))
        obj.journal = ApiClient.convertToType(data['journal'], 'String');
      if (data.hasOwnProperty('submittedSequence'))
        obj.submittedSequence = SubmittedSequence.constructFromObject(data['submittedSequence']);
    }
    return obj;
  }
}

/**
 * @member {String} aptamerId
 */
AptamerSequenceDetailDto.prototype.aptamerId = undefined;

/**
 * @member {String} secondaryStructureFigure
 */
AptamerSequenceDetailDto.prototype.secondaryStructureFigure = undefined;

/**
 * @member {String} aptamerName
 */
AptamerSequenceDetailDto.prototype.aptamerName = undefined;

/**
 * @member {String} mainName
 */
AptamerSequenceDetailDto.prototype.mainName = undefined;

/**
 * @member {String} otherName
 */
AptamerSequenceDetailDto.prototype.otherName = undefined;

/**
 * @member {Array.<String>} aptamerNames
 */
AptamerSequenceDetailDto.prototype.aptamerNames = undefined;

/**
 * @member {String} targetName
 */
AptamerSequenceDetailDto.prototype.targetName = undefined;

/**
 * @member {module:model/TargetTypeEnum} targetType
 */
AptamerSequenceDetailDto.prototype.targetType = undefined;

/**
 * @member {String} targetTypeDescription
 */
AptamerSequenceDetailDto.prototype.targetTypeDescription = undefined;

/**
 * @member {String} aptamerType
 */
AptamerSequenceDetailDto.prototype.aptamerType = undefined;

/**
 * @member {String} affinity
 */
AptamerSequenceDetailDto.prototype.affinity = undefined;

/**
 * @member {String} bindingConditions
 */
AptamerSequenceDetailDto.prototype.bindingConditions = undefined;

/**
 * @member {String} foldingProgram
 */
AptamerSequenceDetailDto.prototype.foldingProgram = undefined;

/**
 * @member {String} aptamerLength
 */
AptamerSequenceDetailDto.prototype.aptamerLength = undefined;

/**
 * @member {String} gcContent
 */
AptamerSequenceDetailDto.prototype.gcContent = undefined;

/**
 * @member {String} molecularWeight
 */
AptamerSequenceDetailDto.prototype.molecularWeight = undefined;

/**
 * @member {String} oD260
 */
AptamerSequenceDetailDto.prototype.oD260 = undefined;

/**
 * @member {String} discoverer
 */
AptamerSequenceDetailDto.prototype.discoverer = undefined;

/**
 * @member {Number} score
 */
AptamerSequenceDetailDto.prototype.score = undefined;

/**
 * @member {Array.<module:model/AptamerDetailTargetDto>} targetInfos
 */
AptamerSequenceDetailDto.prototype.targetInfos = undefined;

/**
 * @member {String} articleID
 */
AptamerSequenceDetailDto.prototype.articleID = undefined;

/**
 * @member {String} articleTitle
 */
AptamerSequenceDetailDto.prototype.articleTitle = undefined;

/**
 * @member {String} sequence
 */
AptamerSequenceDetailDto.prototype.sequence = undefined;

/**
 * @member {String} issn
 */
AptamerSequenceDetailDto.prototype.issn = undefined;

/**
 * @member {String} doi
 */
AptamerSequenceDetailDto.prototype.doi = undefined;

/**
 * @member {String} journal
 */
AptamerSequenceDetailDto.prototype.journal = undefined;

/**
 * @member {module:model/SubmittedSequence} submittedSequence
 */
AptamerSequenceDetailDto.prototype.submittedSequence = undefined;

