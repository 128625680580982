<template>
	<div class="item-list-panel">
		<div class="top-bar-container">
			<div class="top-bar">
				<div class="result">
					<div class="label count-desc">
						<div class="label">当前结果</div>
						<div class="value">
							{{ resultAmount }}
						</div>
						<div class="unit">条</div>
					</div>
					<div class="textLabel">按公布日期倒序排列</div>
				</div>
			</div>
		</div>
		<div class="item-list">
			<div class="item-container" v-for="(pat, i) in datalist" :key="i">
				<div class="item contentItem">
					<div class="figure">
						<img
							class="defaultImg"
							v-if="!pat.cover"
							src="@/assets/sequence-default2.png"
						/>
						<img v-else :src="pat.cover" />
					</div>
					<div class="location">
						<router-link
							:to="'/patent/' + pat.patentID"
							class="entity entity-title"
							>{{ pat.title || "" }}</router-link
						>
						<div class="entity-line">
							<div class="entity">
								<div class="label">申请号：</div>
								<div class="value">
									{{ pat.applicationNumber }}
								</div>
							</div>
							<div class="entity">
								<div class="label">公布日期：</div>
								<div class="value">
									{{ pat.publicationDate | dateFormat("YYYY-MM-DD") }}
								</div>
							</div>
						</div>
						<div class="entity">
							<div class="label">发明人：</div>
							<div
								class="value"
								style="word-break: break-all; word-wrap: break-word;"
							>
								{{pat.inventor}}
								<!-- <a href="#" @click="changeInventor(pat.inventor)">
									{{ pat.inventor }}
								</a> -->
							</div>
						</div>
						<div class="entity-line">
							<div class="entity">
								<div class="label">专利权人：</div>
								<div class="licensor">
									{{ pat.licensor }}
								</div>
							</div>
							<div class="entity">
								<div class="label">专利状态：</div>
								<div class="status">
									{{ pat.statusDescription }}
								</div>
							</div>
						</div>
						<div class="entity abstract">
							{{ pat._abstract }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["datalist", "resultAmount", "inventor", "orderby"],
	data() {
		return {
			orderStr: "date"
		}
	},
	methods: {
		changeInventor(val) {
			// this.inventor = val
			this.$emit("inventorchange", val)
		}
	},
	created: function () {
		this.orderStr = this.orderby
	},
	watch: {
		orderStr: function (newValue, oldValue) {
			// console.log("进入监听orderStr")
			this.$emit("orderchange", newValue)
		},
		inventor: function (newValue, oldValue) {
			this.$emit("inventorchange", newValue)
		}
	}
}
</script>

<style lang="less" scoped>
.contentItem {
	display: -webkit-box !important;
	.licensor {
		max-width: 460px !important;
	}

	.abstract {
		max-width: 633px;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box !important;
		-webkit-line-clamp: 3; //行数需设置
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.status {
		width: 30px;
	}
}
.entity {
	.c-font-size-14();
}
.entity-title {
	.c-font-size-18() !important;
}
.entity-line {
	display: flex;
	.c-font-size-12();
	justify-content: left;
	flex-direction: row;

	.value {
		width: auto !important;
	}
}
.textLabel::after {
	top: 0.5 rem;
	right: 0;
}
</style>
