<template>
	<div
		id="myChart3"
		style="height: 400px; width: 94%; margin: 15px auto"
		ref="myChart3"
	></div>
</template>
<script>
import * as echarts from "echarts"
export default {
	props: ["sampleTypeStatisticas"],
	data() {
		return {
			option: {
				color: ["#2C3FA0"],
				tooltip: {
					trigger: "axis",
					axisPointer: {
						type: "shadow"
					}
				},
				grid: {
					left: "0",
					right: "5%",
					bottom: "0",
					containLabel: true
				},
				xAxis: {
					type: "value",
					boundaryGap: [0, 0.01],
					axisLabel: {
						interval: 0,
						rotate: 40,
						show: false
					},
					splitLine: {
						show: false
					},
					axisLine: {
						show: false
					},
					axisTick: {
						show: false
					}
				},
				yAxis: {
					type: "category",
					data: [],
					axisLine: {
						show: false
					},
					splitLine: {
						show: false
					},
					axisTick: {
						show: false,
						alignWithLabel: true
					},
					splitArea: { show: false }
				},
				series: [
					{
						type: "bar",
						data: [],
						itemStyle: {
							normal: {
								label: {
									show: true,
									position: "right",
									textStyle: {
										color: "#2C3FA0",
										fontSize: 12
									}
								}
							}
						}
					}
				]
			}
		}
	},
	watch: {
		sampleTypeStatisticas: {
			handler(newValue, oldValue) {
				const chart =
					echarts.getInstanceByDom(this.$refs.myChart3) ||
					echarts.init(this.$refs.myChart3, "shine")
				window.addEventListener("resize", function () {
					chart.resize()
				})
				var foo = newValue.concat([])
				foo.sort((a, b) => b.value - a.value)
				newValue = foo.slice(0, 20)
				const list = []
				const value = []
				newValue.forEach((item, index) => {
					if (item.name !== null) {
						list.unshift(item.name)
						value.unshift(item.value)
					}
				})
				this.option.series[0].data = value
				this.option.yAxis.data = list
				chart.setOption(this.option)
			},
			deep: true
		}
	},
	methods: {
		loadChart(data) {}
	}
}
</script>
<style lang="less" scoped></style>
