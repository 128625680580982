/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The HSPSDto model module.
 * @module model/HSPSDto
 * @version v1
 */
export class HSPSDto {
  /**
   * Constructs a new <code>HSPSDto</code>.
   * @alias module:model/HSPSDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>HSPSDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/HSPSDto} obj Optional instance to populate.
   * @return {module:model/HSPSDto} The populated <code>HSPSDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new HSPSDto();
      if (data.hasOwnProperty('query'))
        obj.query = ApiClient.convertToType(data['query'], 'String');
      if (data.hasOwnProperty('queryStart'))
        obj.queryStart = ApiClient.convertToType(data['queryStart'], 'Number');
      if (data.hasOwnProperty('queryEnd'))
        obj.queryEnd = ApiClient.convertToType(data['queryEnd'], 'Number');
      if (data.hasOwnProperty('midline'))
        obj.midline = ApiClient.convertToType(data['midline'], 'String');
      if (data.hasOwnProperty('sbjct'))
        obj.sbjct = ApiClient.convertToType(data['sbjct'], 'String');
      if (data.hasOwnProperty('sbjctStart'))
        obj.sbjctStart = ApiClient.convertToType(data['sbjctStart'], 'Number');
      if (data.hasOwnProperty('sbjctEnd'))
        obj.sbjctEnd = ApiClient.convertToType(data['sbjctEnd'], 'Number');
      if (data.hasOwnProperty('bits'))
        obj.bits = ApiClient.convertToType(data['bits'], 'Number');
      if (data.hasOwnProperty('score'))
        obj.score = ApiClient.convertToType(data['score'], 'Number');
      if (data.hasOwnProperty('expect'))
        obj.expect = ApiClient.convertToType(data['expect'], 'Number');
      if (data.hasOwnProperty('strand'))
        obj.strand = ApiClient.convertToType(data['strand'], 'String');
      if (data.hasOwnProperty('frame'))
        obj.frame = ApiClient.convertToType(data['frame'], ['Number']);
      if (data.hasOwnProperty('align_length'))
        obj.alignLength = ApiClient.convertToType(data['align_length'], 'Number');
      if (data.hasOwnProperty('gapCount'))
        obj.gapCount = ApiClient.convertToType(data['gapCount'], 'Number');
      if (data.hasOwnProperty('identicalCount'))
        obj.identicalCount = ApiClient.convertToType(data['identicalCount'], 'Number');
      if (data.hasOwnProperty('positiveCount'))
        obj.positiveCount = ApiClient.convertToType(data['positiveCount'], 'Number');
      if (data.hasOwnProperty('gapPercent'))
        obj.gapPercent = ApiClient.convertToType(data['gapPercent'], 'Number');
      if (data.hasOwnProperty('identicalPercent'))
        obj.identicalPercent = ApiClient.convertToType(data['identicalPercent'], 'Number');
      if (data.hasOwnProperty('positivePercent'))
        obj.positivePercent = ApiClient.convertToType(data['positivePercent'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {String} query
 */
HSPSDto.prototype.query = undefined;

/**
 * @member {Number} queryStart
 */
HSPSDto.prototype.queryStart = undefined;

/**
 * @member {Number} queryEnd
 */
HSPSDto.prototype.queryEnd = undefined;

/**
 * @member {String} midline
 */
HSPSDto.prototype.midline = undefined;

/**
 * @member {String} sbjct
 */
HSPSDto.prototype.sbjct = undefined;

/**
 * @member {Number} sbjctStart
 */
HSPSDto.prototype.sbjctStart = undefined;

/**
 * @member {Number} sbjctEnd
 */
HSPSDto.prototype.sbjctEnd = undefined;

/**
 * @member {Number} bits
 */
HSPSDto.prototype.bits = undefined;

/**
 * @member {Number} score
 */
HSPSDto.prototype.score = undefined;

/**
 * @member {Number} expect
 */
HSPSDto.prototype.expect = undefined;

/**
 * @member {String} strand
 */
HSPSDto.prototype.strand = undefined;

/**
 * @member {Array.<Number>} frame
 */
HSPSDto.prototype.frame = undefined;

/**
 * @member {Number} alignLength
 */
HSPSDto.prototype.alignLength = undefined;

/**
 * @member {Number} gapCount
 */
HSPSDto.prototype.gapCount = undefined;

/**
 * @member {Number} identicalCount
 */
HSPSDto.prototype.identicalCount = undefined;

/**
 * @member {Number} positiveCount
 */
HSPSDto.prototype.positiveCount = undefined;

/**
 * @member {Number} gapPercent
 */
HSPSDto.prototype.gapPercent = undefined;

/**
 * @member {Number} identicalPercent
 */
HSPSDto.prototype.identicalPercent = undefined;

/**
 * @member {Number} positivePercent
 */
HSPSDto.prototype.positivePercent = undefined;

