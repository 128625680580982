/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {UserSexEnum} from './UserSexEnum';

/**
 * The UserCenterInfoBody model module.
 * @module model/UserCenterInfoBody
 * @version v1
 */
export class UserCenterInfoBody {
  /**
   * Constructs a new <code>UserCenterInfoBody</code>.
   * @alias module:model/UserCenterInfoBody
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>UserCenterInfoBody</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserCenterInfoBody} obj Optional instance to populate.
   * @return {module:model/UserCenterInfoBody} The populated <code>UserCenterInfoBody</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserCenterInfoBody();
      if (data.hasOwnProperty('Name'))
        obj.name = ApiClient.convertToType(data['Name'], 'String');
      if (data.hasOwnProperty('Sex'))
        obj.sex = UserSexEnum.constructFromObject(data['Sex']);
      if (data.hasOwnProperty('Password'))
        obj.password = ApiClient.convertToType(data['Password'], 'String');
      if (data.hasOwnProperty('Phone'))
        obj.phone = ApiClient.convertToType(data['Phone'], 'String');
      if (data.hasOwnProperty('Title'))
        obj.title = ApiClient.convertToType(data['Title'], 'String');
      if (data.hasOwnProperty('University'))
        obj.university = ApiClient.convertToType(data['University'], 'String');
      if (data.hasOwnProperty('Institution'))
        obj.institution = ApiClient.convertToType(data['Institution'], 'String');
      if (data.hasOwnProperty('Avator'))
        obj.avator = ApiClient.convertToType(data['Avator'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} name
 */
UserCenterInfoBody.prototype.name = undefined;

/**
 * @member {module:model/UserSexEnum} sex
 */
UserCenterInfoBody.prototype.sex = undefined;

/**
 * @member {String} password
 */
UserCenterInfoBody.prototype.password = undefined;

/**
 * @member {String} phone
 */
UserCenterInfoBody.prototype.phone = undefined;

/**
 * @member {String} title
 */
UserCenterInfoBody.prototype.title = undefined;

/**
 * @member {String} university
 */
UserCenterInfoBody.prototype.university = undefined;

/**
 * @member {String} institution
 */
UserCenterInfoBody.prototype.institution = undefined;

/**
 * @member {String} avator
 */
UserCenterInfoBody.prototype.avator = undefined;

