<template>
	<div class="main-panel">
		<div class="left-panel">
			<div class="statistic-panel">
				<paperdata
					:info="info"
					:favorite="favorite"
					@favoritechange="favoriteChange"
					:isLogin="isLogin"
				></paperdata>
			</div>
		</div>
		<div class="list-panel">
			<papercontent
				:content="content"
				:title="title"
				:comments="comments"
				@commentSub="comment"
				:commentStr="commentStr"
				:figureList="figureList"
				:productList="productList"
			></papercontent>
		</div>
	</div>
</template>

<script>
import paperdata from "./detail/paperdata.vue"
import papercontent from "./detail/papercontent.vue"
import { ApiClient, ArticleApi, UserCenterApi } from "../../api"
import Cookie from "js-cookie"
import Swal from "sweetalert2"

export default {
	data() {
		return {
			articleApi: new ArticleApi(ApiClient.instance),
			usercenterApi: new UserCenterApi(ApiClient.instance),
			articleId: "",
			favorite: false, // 是否已收藏
			info: {
				paperid: "", // 文章id
				publishDate: "", // 发表时间
				magazineName: "", // 杂志名称
				journalNo: "", // 期刊号
				author: "", // 作者
				relatedPatent: "", // 相关专利
				fullpaperLink: "" // 全文链接
			},
			content: "",
			commentStr: "",
			title: "", // 文章内容
			comments: [], // 文章评论
			figureList: [],
			productList: [],
			isLogin: false
		}
	},
	methods: {
		getArticleInfo() {
			this.articleApi.apiArticleIdGet(
				this.articleId,
				(error, data, response) => {
					if (error) {
						console.log(error)
					} else {
						console.log(data.result)
						var result = data.result
						this.content = result._abstract || ""
						this.title = result.title || ""
						this.comments = result.comments
						this.$delete(result, "comments")
						this.info = result
					}
				}
			)
		},
		getFigure() {
			this.articleApi.apiArticleArticlefigureGet({ articleid: this.articleId }, (err, data, resp) => {
				if (err) {
					console.log(err)
				}
				if (data.success) {
					// console.log(data)
					this.figureList = data.result.figures.map(item => {
						var res = item
						res.path = process.env.VUE_APP_IMG_API + "article/" + res.path
						return res
					})
					// console.log(this.figureList)
				}
			})
		},
		getProduct() {
			this.articleApi.apiArticleArticleproductGet({ articleid: this.articleId }, (err, data, resp) => {
				if (err) {
					console.log(err)
				}
				if (data.success) {
					// console.log(data)
					this.productList = data.result.products
					// console.log(this.productList)
				}
			})
		},
		// 添加收藏
		addFavorite() {
			this.usercenterApi.apiUserCenterFavoritePost(
				{
					collectionID: this.articleId,
					collectionType: 2
				},
				(e, data, resp) => {
					this.getFavoriteStatus()
				}
			)
		},
		// 取消收藏
		removeFavorite() {
			this.usercenterApi.apiUserCenterUnfavoriteDelete(
				this.articleId,
				2,
				null,
				() => {
					this.getFavoriteStatus()
				}
			)
		},
		// 查询文章收藏状态
		getFavoriteStatus() {
			this.usercenterApi.apiUserCenterCollectedGet(
				this.articleId,
				(e, data, resp) => {
					if (data.success) {
						this.favorite = data.result
					}
				}
			)
		},
		favoriteChange(val) {
			if (val === 1) {
				this.addFavorite()
			} else {
				this.removeFavorite()
			}
		},
		// 提交评论
		commentSubmit(opts) {
			// console.log(opts)
			opts.commentType = 0
			this.usercenterApi.apiUserCenterCommentPost(
				opts,
				(error, data, response) => {
					console.log("评论提交成功")
					// console.log(response)
					if (error) {
						console.log(error)
					} else {
						Swal.fire("发布成功，请等待管理员审核！").then(() => {
							this.commentStr = ""
							this.getComments()
						})
					}
				}
			)
		},
		comment(data) {
			// console.log("detail页面接收到评论信息")
			var opts = {
				articleID: this.articleId,
				parentID: data.parentID,
				userID: null,
				content: data.commentStr
			}
			// console.log(opts)
			this.commentSubmit(opts)
		},
		getComments() {
			this.articleApi.apiArticleCommentsGet(
				{ articleid: this.articleId },
				(error, data, response) => {
					// console.log("评论查询成功")
					// console.log(response)
					if (error) {
						console.log(error)
					} else {
						// console.log(data)
						this.comments = data.result.comments
					}
				}
			)
		}
	},
	created: function () {
		if (this.$route.params.id) {
			this.articleId = this.$route.params.id
			var user = Cookie.getJSON("user_info")
			if (user?.account) {
				this.isLogin = true
			}
			this.getArticleInfo()
			if (this.isLogin) {
				this.getFavoriteStatus()
			}
			this.getFigure()
			this.getProduct()
		} else {
			alert("文章信息获取失败！")
			this.$router.go(-1)
		}
	},
	components: {
		paperdata,
		papercontent
	}
}
</script>

<style lang="less" scoped>
.main-panel {
	width: 1170px;
	height: 100%;
	min-height: 100vh;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
}

.left-panel {
	width: 290px;
	height: 236px;
}

.statistic-panel {
	height: 100%;
	width: 100%;
	background-color: #ffffff;
}

.filter-panel {
	width: 100%;
	height: 100%;
	margin-top: 20px;
	background-color: #ffffff;
}

.list-panel {
	width: 860px;
	height: 100%;
	margin-left: 20px;
}
</style>
