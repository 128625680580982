/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {AptamerForContrast} from './AptamerForContrast';
import {SequenceInfo} from './SequenceInfo';

/**
 * The SequenceListDto model module.
 * @module model/SequenceListDto
 * @version v1
 */
export class SequenceListDto {
  /**
   * Constructs a new <code>SequenceListDto</code>.
   * @alias module:model/SequenceListDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>SequenceListDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SequenceListDto} obj Optional instance to populate.
   * @return {module:model/SequenceListDto} The populated <code>SequenceListDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SequenceListDto();
      if (data.hasOwnProperty('sequenceInfo'))
        obj.sequenceInfo = ApiClient.convertToType(data['sequenceInfo'], [SequenceInfo]);
      if (data.hasOwnProperty('aptamerInfo'))
        obj.aptamerInfo = ApiClient.convertToType(data['aptamerInfo'], [AptamerForContrast]);
    }
    return obj;
  }
}

/**
 * @member {Array.<module:model/SequenceInfo>} sequenceInfo
 */
SequenceListDto.prototype.sequenceInfo = undefined;

/**
 * @member {Array.<module:model/AptamerForContrast>} aptamerInfo
 */
SequenceListDto.prototype.aptamerInfo = undefined;

