<template>
	<!-- <div id="exportDia">
			<el-dialog
				title="用户协议"
				:visible="dialogShow"
				@close="dialogShow=false"
				width="350px"
			>
				<div><p>&nbsp;&nbsp;&nbsp;&nbsp;The person who associated a work with this deed has dedicated the work to the public domain by waiving all of his or her rights to the work worldwide under copyright law, including all related and neighboring rights, to the extent allowed by law.
You can copy, modify, distribute and perform the work, even for commercial purposes, all without asking permission.</p></div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialogShow=false">不同意</el-button>
					<el-button type="primary">同意并继续</el-button>
				</div>
			</el-dialog>
		</div> -->
	<div class="useragreement">
		<div class="useragreement-container" @click.stop="isolateClick()">
			<div class="title">用户协议</div>
			<div style="text-align: left; padding: 0 20px 20px 20px;">
				<p>
					&nbsp;&nbsp;&nbsp;&nbsp;The person who associated a work with this
					deed has dedicated the work to the public domain by waiving all of his
					or her rights to the work worldwide under copyright law, including all
					related and neighboring rights, to the extent allowed by law. You can
					copy, modify, distribute and perform the work, even for commercial
					purposes, all without asking permission.
				</p>
			</div>
			<div style="margin-bottom: 20px;">
				<el-button @click="$parent.UserAgreement(false)">不同意</el-button>
				<el-button type="primary" @click="$parent.UserAgreement(true)"
					>同意并继续</el-button
				>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	mounted() {},
	computed: {},
	methods: {
		isolateClick() {
			// do nothing
		}
	}
}
</script>

<style lang="less" scoped>
.useragreement-container {
	width: 400px;
	height: 100%;
	display: inline-block;
	text-align: center;
	border-radius: 6px;
	background-color: #ffffff;
}
.title {
	margin: 30px auto 20px auto;
	line-height: 20px;
	.c-font-size-16();
	.c-font-family-PF();
	font-weight: 500;
	color: #333333;
}

.container {
	background: #ffffff;
	box-shadow: 0px 0px 6px 0px rgba(4, 0, 0, 0.07);
	padding: 60px 70px;
	.entity {
		display: flex;
		// width: 470px;
		height: 40px;
		margin-bottom: 20px;
		.label {
			display: flex;

			width: 70px;
			.c-font-size-14();
			.c-font-family-PF();
			font-weight: 500;
			color: #333333;

			margin-right: 30px;
			align-items: center;
			justify-content: flex-end;
		}
		.value {
			display: flex;
			align-items: center;
			width: 400px;
		}

		.btn {
			display: flex;
			justify-content: center;
			button {
				width: 300px;
			}
		}
	}
}
</style>
