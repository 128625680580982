var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-panel main-list" }, [
    _c("div", { staticClass: "left-panel" }, [
      _c(
        "div",
        { staticClass: "statistic-panel" },
        [
          _c("doughnut-chart", {
            attrs: {
              dataList: _vm.statistic,
              totalDesc: "国家统计",
              isDataFixed: "true",
            },
            on: { click: _vm.countrySelect },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-panel" },
        [
          _c("paperfilter", {
            attrs: { filerInfo: _vm.filerInfo },
            on: { filterchang: _vm.getFilter },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "list-panel" },
      [
        _c("div", { staticClass: "source-claim" }, [
          _vm._v("（数据来源于美国FDA）"),
        ]),
        _c("paperlist", {
          attrs: {
            orderBy: _vm.filerInfo.orderby,
            datalist: _vm.datalist,
            resultAmount: _vm.resultAmount,
          },
        }),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              attrs: {
                id: "pagination",
                "current-page": _vm.pageIndex,
                "page-size": _vm.pageSize,
                "page-sizes": [10, 25, 50],
                layout: "prev, pager, next, jumper, sizes",
                total: _vm.resultAmount,
              },
              on: {
                "current-change": _vm.queryChange,
                "size-change": _vm.sizeChange,
                "prev-click": _vm.queryChange,
                "next-click": _vm.queryChange,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }