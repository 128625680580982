/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {ReturnHSPSDto} from './ReturnHSPSDto';

/**
 * The SequenceInfo model module.
 * @module model/SequenceInfo
 * @version v1
 */
export class SequenceInfo {
  /**
   * Constructs a new <code>SequenceInfo</code>.
   * @alias module:model/SequenceInfo
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>SequenceInfo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SequenceInfo} obj Optional instance to populate.
   * @return {module:model/SequenceInfo} The populated <code>SequenceInfo</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SequenceInfo();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('count'))
        obj.count = ApiClient.convertToType(data['count'], 'Number');
      if (data.hasOwnProperty('length'))
        obj.length = ApiClient.convertToType(data['length'], 'Number');
      if (data.hasOwnProperty('querySequence'))
        obj.querySequence = ApiClient.convertToType(data['querySequence'], 'String');
      if (data.hasOwnProperty('sbjctSequence'))
        obj.sbjctSequence = ApiClient.convertToType(data['sbjctSequence'], 'String');
      if (data.hasOwnProperty('hsps'))
        obj.hsps = ApiClient.convertToType(data['hsps'], [ReturnHSPSDto]);
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
SequenceInfo.prototype.id = undefined;

/**
 * @member {String} name
 */
SequenceInfo.prototype.name = undefined;

/**
 * @member {Number} count
 */
SequenceInfo.prototype.count = undefined;

/**
 * @member {Number} length
 */
SequenceInfo.prototype.length = undefined;

/**
 * @member {String} querySequence
 */
SequenceInfo.prototype.querySequence = undefined;

/**
 * @member {String} sbjctSequence
 */
SequenceInfo.prototype.sbjctSequence = undefined;

/**
 * @member {Array.<module:model/ReturnHSPSDto>} hsps
 */
SequenceInfo.prototype.hsps = undefined;

