<template>
    <div class="main-panel main-list">
        <div class="list-panel">
            <el-row :style="{marginTop: (index > 0 ? 20 : 0) + 'px'}" class="dynamicItem" v-for="(i, index) of dataList" :key="index">
                <el-row class="timeRow">
                    {{ i.time }}
                </el-row>
                <div class="titleRow ellipsisContent" @click="toDetail(i.id)">{{ i.title }}</div>
                <el-row class="contentRow">
                    <div class="img" v-if="i.relativeImgPath">
                        <img :src="imgPath + i.relativeImgPath" />
                    </div>
                    <div class="authorcontent">
                        <el-row class="author ellipsisContent">
                            {{ i.authorsStr }}
                        </el-row>
                        <el-row class="content ellipsisContent" v-html="i._abstract"></el-row>
                    </div>
                </el-row>
            </el-row>
            <el-row class="pagination">
                <el-pagination
                    id="pagination"
                    :current-page="pageIndex"
                    :page-size="pageSize"
                    :page-sizes="[10, 25, 50]"
                    layout="prev, pager, next, jumper, sizes"
                    :total="totalCount"
                    @current-change="queryChange"
                    @size-change="sizeChange"
                    @prev-click="queryChange"
                    @next-click="queryChange"
                >
                </el-pagination>
            </el-row>
        </div>
    </div>
</template>

<script>
import { ApiClient, FeedsArticleApi } from "../../api"
export default {
	data() {
		return {
			pageIndex: 1,
			pageSize: 10,
			totalCount: 0,
			dataList: [],
			feedsArticleApi: new FeedsArticleApi(ApiClient.instance),
			imgPath: ""
		}
	},
	created() {
		this.imgPath = process.env.VUE_APP_IMG_API + "feeds_article_img/"
		this.getList()
	},
	methods: {
		queryChange(val) {
			this.pageIndex = val
			this.getList()
		},
		sizeChange(val) {
			this.pageSize = val
			this.getList()
		},
		toDetail(id) {
			this.$router.push({ path: "/articledynamic/" + id })
		},
		getList() {
			this.feedsArticleApi.apiFeedsArticleQueryGet(
				{
					size: this.pageSize,
					index: this.pageIndex
				},
				(err, data, resp) => {
					if (err) {
						console.log(err)
					}
					if (data.success) {
						this.dataList = []
						if (data.result.records) {
							var nowTime = new Date().getTime()
							this.dataList = data.result.records.map(i => {
								var info = i
								var aus = ""
								if (i.authors) {
									JSON.parse(i.authors).forEach((str, index) => {
										aus += (index > 0 ? "，" : "") + str
									})
								}
								this.$set(info, "authorsStr", aus)

								this.$set(info, "time", "")
								var hours = Math.floor((nowTime - i.createdDate * 1000) / (1000 * 60 * 60))
								if (hours === 0) {
									hours = 1
								}
								if (hours < 24) {
									info.time = hours + "小时前"
								} else if (hours >= 24 && hours < 48) {
									info.time = "一天前"
								} else if (hours >= 48 && hours < 72) {
									info.time = "二天前"
								} else {
									info.time = this.$options.filters.dateFormat(new Date(i.createdDate * 1000), "YYYY-MM-DD")
								}

								return info
							})
						}
						this.totalCount = data.result.total
					}
				}
			)
		}
	}
}
</script>

<style lang="less" scoped>
.main-panel {
    .list-panel {
        width: 100%;
        margin-left: 0;

        .dynamicItem {
            width: 1163px;
            height: 392px;
            background: #FFFFFF;
            border-radius: 5px 5px 5px 5px;
            padding: 20px 25px 0 26px;

            .timeRow {
                font-size: 16px;
                font-weight: 400;
                color: #999999;
            }

            .titleRow {
                margin-top: 20px;
                -webkit-line-clamp: 2;
                font-size: 22px;
                font-weight: bold;
                color: #3463ea;
                cursor: pointer;
            }

            .contentRow {
                margin-top: 20px;
                .img {
                    margin-right: 34px;
                    width: 432px;
                    height: 219px;
                    position: relative;
                    float: left;
                    text-align: center;
                    img {
                        height: 100%;
                        max-width: 100%;
                        background: #E2E2E2;
                        border-radius: 5px 5px 5px 5px;
                        opacity: 1;
                        border: 1px solid #707070;
                        background-repeat: no-repeat;
                    }
                }

                .authorcontent {
                    font-weight: 400;
                    font-size: 16px;
                    .author {
                        -webkit-line-clamp: 1;
                        color: #999;
                    }

                    .content {
                        margin-top: 15px;
                        -webkit-line-clamp: 8;
                        color: #333;
                    }
                }
            }
        }

        .pagination {
            margin: 50px 0 70px 0;
        }
    }
}
</style>
