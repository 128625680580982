/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ContrastInfo model module.
 * @module model/ContrastInfo
 * @version v1
 */
export class ContrastInfo {
  /**
   * Constructs a new <code>ContrastInfo</code>.
   * @alias module:model/ContrastInfo
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ContrastInfo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ContrastInfo} obj Optional instance to populate.
   * @return {module:model/ContrastInfo} The populated <code>ContrastInfo</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ContrastInfo();
      if (data.hasOwnProperty('queryStratCode'))
        obj.queryStratCode = ApiClient.convertToType(data['queryStratCode'], 'Number');
      if (data.hasOwnProperty('queryEndCode'))
        obj.queryEndCode = ApiClient.convertToType(data['queryEndCode'], 'Number');
      if (data.hasOwnProperty('sbjctStartCode'))
        obj.sbjctStartCode = ApiClient.convertToType(data['sbjctStartCode'], 'Number');
      if (data.hasOwnProperty('sbjctEndCode'))
        obj.sbjctEndCode = ApiClient.convertToType(data['sbjctEndCode'], 'Number');
      if (data.hasOwnProperty('align1'))
        obj.align1 = ApiClient.convertToType(data['align1'], ['String']);
      if (data.hasOwnProperty('middle'))
        obj.middle = ApiClient.convertToType(data['middle'], ['String']);
      if (data.hasOwnProperty('align2'))
        obj.align2 = ApiClient.convertToType(data['align2'], ['String']);
    }
    return obj;
  }
}

/**
 * @member {Number} queryStratCode
 */
ContrastInfo.prototype.queryStratCode = undefined;

/**
 * @member {Number} queryEndCode
 */
ContrastInfo.prototype.queryEndCode = undefined;

/**
 * @member {Number} sbjctStartCode
 */
ContrastInfo.prototype.sbjctStartCode = undefined;

/**
 * @member {Number} sbjctEndCode
 */
ContrastInfo.prototype.sbjctEndCode = undefined;

/**
 * @member {Array.<String>} align1
 */
ContrastInfo.prototype.align1 = undefined;

/**
 * @member {Array.<String>} middle
 */
ContrastInfo.prototype.middle = undefined;

/**
 * @member {Array.<String>} align2
 */
ContrastInfo.prototype.align2 = undefined;

