<template>
	<div class="detail-container">
		<div id="sequence-detail" class="sequence-detail detail-block">
			<div class="bar">序列详情</div>
			<div class="content">
				<div class="left">
					<img
						v-if="sequnce.secondaryStructureFigure"
						:src="`${imgHost}/${sequnce.secondaryStructureFigure}`"
						class="figure"
					/>
					<img
						v-if="!sequnce.secondaryStructureFigure"
						src="@/assets/sequence-default2.png"
						class="figure defaultImg"
					/>
				</div>
				<div class="right">
					<div class="top">
						<div class="title" v-html="sequnce.mainName"></div>
						<div
							class="sm-btn"
							@click="addFavorite"
							v-if="!favorite && isLogin"
						>
							收 藏
						</div>
						<div
							class="sm-btn"
							@click="removeFavorite"
							v-if="favorite && isLogin"
						>
							已收藏
						</div>
					</div>
					<div class="bottom">
						<div class="entity">
							<div class="name">其他名称：</div>
							<div class="value otherName">{{ sequnce.otherName }}</div>
						</div>
						<!-- <div class="entity">
							<div class="name">靶标名称：</div>
							<div class="value">{{ sequnce.targetName }}</div>
						</div>
						<div class="entity">
							<div class="name">靶标种类：</div>
							<div class="value">{{ sequnce.targetTypeDescription }}</div>
						</div> -->
						<div class="entity">
							<div class="name">适体类型：</div>
							<div class="value">{{ sequnce.aptamerType }}</div>
						</div>
						<!-- <div class="entity">
							<div class="name">亲 和 力：</div>
							<div class="value">{{ sequnce.affinity }}</div>
						</div>
						<div class="entity">
							<div class="name">结合条件：</div>
							<div class="value">{{ sequnce.bindingConditions }}</div>
						</div> -->
						<div class="entity">
							<div class="name">折叠程序：</div>
							<div class="value">
								{{ sequnce.foldingProgram }}
							</div>
						</div>
						<div>
							<el-table
								:data="sequnce.targetInfos"
								height="350px"
								border
								style="width: 100%;"
							>
								<el-table-column align="center" prop="target" label="靶标名称">
									<template slot-scope="{ row }">
										<p v-html="row.target || '-'"></p>
									</template>
								</el-table-column>
								<el-table-column
									align="center"
									prop="targetType"
									label="靶标种类"
								>
									<template slot-scope="{ row }">
										{{ row.targetType || "-" }}
									</template></el-table-column
								>
								<el-table-column align="center" prop="sample" label="样本名称">
									<template slot-scope="{ row }">
										{{ row.sample || "-" }}
									</template></el-table-column
								>
								<el-table-column align="center" prop="affinity" label="亲和力(nM)">
									<template slot-scope="{ row }">
										{{ (row.affinity === "0" || row.affinity === 0) ? "-" : row.affinity }}
									</template></el-table-column
								>
								<el-table-column
									align="center"
									prop="bindingConditions"
									label="结合条件"
								>
									<template slot-scope="{ row }">
										<p v-html="row.bindingConditions || '-'"></p> </template
								></el-table-column>
							</el-table>
						</div>
						<div class="score">
							<div class="value">{{ sequnce.score || 0 }}</div>
							<div class="name">评分</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="sequence-info" class="sequence-info detail-block">
			<div class="bar">序列信息</div>
			<div class="content">
				<div class="entity">
					<div class="name">Sequence：</div>
					<div class="value">{{ sequnce.sequence }}</div>
				</div>
				<div class="entity">
					<div class="name">长度：</div>
					<div class="value">{{ sequnce.aptamerLength }}</div>
				</div>
				<div class="entity">
					<div class="name">GC含量：</div>
					<div class="value">{{ sequnce.gcContent ? (parseFloat(sequnce.gcContent) * 100).toFixed(1) : "-" }}%</div>
				</div>
				<div class="entity">
					<div class="name">分子量：</div>
					<div class="value">{{ sequnce.molecularWeight ? parseFloat(sequnce.molecularWeight).toFixed(1) : "-" }}</div>
				</div>
				<!-- <div class="entity">
					<div class="name">μg/OD260：</div>
					<div class="value">{{ sequnce.oD260 }}</div>
				</div> -->
			</div>
		</div>
		<div id="discoverer" class="discoverer detail-block">
			<div class="bar">发现者</div>
			<div class="content">
				<a v-if="sequnce.articleID"
					href="javascript:void(0)"
					@click="toDetail('paper', sequnce.articleID)"
				>
					{{ discoverInfo }}</a
				>
				<div v-else style="text-align:center">暂无数据</div>
			</div>
		</div>
		<div id="relevant-article" class="relevant-article detail-block">
			<div class="bar">相关文献</div>
			<div class="statisticalChart">
				<ul>
					<li>
						<div v-show="countryStatis.length<=0" class="noData">
							<img src="../../assets/nodata.png" />
							<p>暂无数据</p>
						</div>
						<ScreenMethodChart v-show="countryStatis.length>0"
							:dataList="countryStatis"
							totalDesc="国别"
						></ScreenMethodChart>
					</li>
					<li>
						<div v-show="sampleStatis.length<=0" class="noData">
							<img src="../../assets/nodata.png" />
							<p>暂无数据</p>
						</div>
						<ScreenMethodChart v-show="sampleStatis.length>0"
							:dataList="sampleStatis"
							totalDesc="样本"
						></ScreenMethodChart>
					</li>
					<li>
						<div v-show="usageStatis.length<=0" class="noData">
							<img src="../../assets/nodata.png" />
							<p>暂无数据</p>
						</div>
						<ScreenMethodChart v-show="usageStatis.length>0"
							:dataList="usageStatis"
							totalDesc="应用"
						></ScreenMethodChart>
					</li>
				</ul>
			</div>
			<div class="content">
				<div class="item" v-for="item in articles.records" :key="item.articleID">
					<a
						href="javascript:void(0)"
						@click="toDetail('paper', item.articleID)"
						>{{ item | relatedArticleFormat }}</a
					>
				</div>
				<div v-if="articles.total == 0" style="text-align:center">暂无数据</div>
				<div v-if="articles.total > 5" class="more-btn" @click="toList('paper')">查看更多</div>
			</div>
		</div>
		<div id="relevant-patent" class="relevant-patent detail-block">
			<div class="bar">相关专利</div>
			<div class="content">
				<div class="item" v-for="item in patents.records" :key="item.patentID">
					<a
						href="javascript:void(0)"
						@click="toDetail('patent', item.patentID)"
						>{{ item.title }}</a
					>
					<!-- {{ item.value }} -->
				</div>
				<div v-if="patents.total == 0" style="text-align:center">暂无数据</div>
				<div v-if="patents.total > 5" class="more-btn" @click="toList('patent')">查看更多</div>
			</div>
		</div>
		<div class="flxed-box">
			<!-- 跟随滚动高亮实现太复杂，暂时不实现-->
			<a class="nav" id="sequence-detail-nav" href="#sequence-detail"
				>序列详情</a
			>
			<a class="nav" id="sequence-info-nav" href="#sequence-info">序列信息</a>
			<a class="nav" id="discoverer-nav" href="#discoverer">发现者</a>
			<a class="nav" id="relevant-article-nav" href="#relevant-article"
				>相关文献</a
			>
			<a class="nav" id="relevant-patent-nav" href="#relevant-patent"
				>相关专利</a
			>
		</div>
	</div>
</template>

<script>
import { ApiClient, AptamerApi, UserCenterApi } from "../../api"
import ScreenMethodChart from "../../components/charts/screenMethodChart.vue"
import Cookie from "js-cookie"

export default {
	data() {
		return {
			aptamerApi: new AptamerApi(ApiClient.instance),
			usercenterApi: new UserCenterApi(ApiClient.instance),
			sequnce: {},
			articles: [],
			patents: [],
			favorite: false,
			countryStatis: [],
			sampleStatis: [],
			usageStatis: [],
			isLogin: false
		}
	},
	mounted() {
		var user = Cookie.getJSON("user_info")
		if (user?.account) {
			this.isLogin = true
		}
		this.getAptamerDetail()
		if (this.isLogin) {
			this.getFavoriteStatus()
		}
		this.loadCountryStatistcs()
		this.loadSampleStatistcs()
		this.loadUsagesStatistcs()
	},
	computed: {
		aptamerId() {
			return this.$route.params.id
		},
		imgHost() {
			return process.env.VUE_APP_IMG_API
		},
		discoverInfo() {
			var str = ""
			if (this.sequnce && this.sequnce.articleID) {
				if (this.sequnce.discoverer) {
					str += this.sequnce.discoverer
				}
				if (this.sequnce.articleTitle) {
					str += " \"" + this.sequnce.articleTitle + "\" "
				}
				if (this.sequnce.journal) {
					str += this.sequnce.journal + ". "
				}
				if (this.sequnce.issn) {
					str += this.sequnce.issn + "."
				}
			}
			return str
		}
	},
	methods: {
		getAptamerDetail() {
			this.aptamerApi.apiAptamerIdGet(this.aptamerId, (e, data, resp) => {
				if (data.success) {
					var articleInfo = data.result.sequenceDetail
					// 处理序列名称展示
					articleInfo.aptamerName = articleInfo.mainName
					this.sequnce = articleInfo
					// eslint-disable-next-line no-useless-escape
					const pattern = /(?<type>\*{1,2})(?<item>[A-Za-z0-9\-\+]+)(\1)?/g
					var matches = this.sequnce.aptamerName.matchAll(pattern)
					for (const match of matches) {
						var type = match.groups.type
						if (type === "**") {
							this.sequnce.aptamerName = this.sequnce.aptamerName.replace(
								match[0],
								"<sup>" + match.groups.item + "</sup>"
							)
							this.sequnce.mainName = this.sequnce.mainName.replace(
								match[0],
								"<sup>" + match.groups.item + "</sup>"
							)
						} else {
							this.sequnce.aptamerName = this.sequnce.aptamerName.replace(
								"<sub>" + match.groups.item + "</sub>"
							)
							this.sequnce.mainName = this.sequnce.mainName.replace(
								match[0],
								"<sub>" + match.groups.item + "</sub>"
							)
						}
					}
					this.sequnce.targetInfos.forEach((item) => {
						// eslint-disable-next-line no-useless-escape
						const pattern = /(?<type>\*{1,2})(?<item>[A-Za-z0-9\-\+]+)(\1)?/g
						if (item.target) {
							var match = item.target.matchAll(pattern)
							for (const mat of match) {
								var targetType = mat.groups.type
								if (targetType === "**") {
									item.target = item.target.replace(
										mat[0],
										"<sup>" + mat.groups.item + "</sup>"
									)
								} else {
									item.target = item.target.replace(
										mat[0],
										"<sub>" + mat.groups.item + "</sub>"
									)
								}
							}
						}
						if (item.bindingConditions) {
							var matches = item.bindingConditions.matchAll(pattern)
							for (const match of matches) {
								var type = match.groups.type
								if (type === "**") {
									item.bindingConditions = item.bindingConditions.replace(
										match[0],
										"<sup>" + match.groups.item + "</sup>"
									)
								} else {
									item.bindingConditions = item.bindingConditions.replace(
										match[0],
										"<sub>" + match.groups.item + "</sub>"
									)
								}
							}
						}
					})
					this.articles = data.result.relatedArticle
					console.log(this.articles)
					this.patents = data.result.relatedPatent
					console.log(this.patents)
				}
			})
		},
		addFavorite() {
			this.usercenterApi.apiUserCenterFavoritePost(
				{
					collectionID: this.aptamerId,
					collectionType: 0
				},
				(e, data, resp) => {
					this.getFavoriteStatus()
				}
			)
		},
		removeFavorite() {
			this.usercenterApi.apiUserCenterUnfavoriteDelete(
				this.aptamerId,
				0,
				null,
				() => {
					this.getFavoriteStatus()
				}
			)
		},
		getFavoriteStatus() {
			this.usercenterApi.apiUserCenterCollectedGet(
				this.aptamerId,
				(e, data, resp) => {
					if (data.success) {
						this.favorite = data.result
					}
				}
			)
		},
		toList(url) {
			this.$router.push("/" + url + "?aptamerID=" + this.aptamerId)
		},
		toDetail(url, id) {
			this.$router.push("/" + url + "/" + id)
		},
		loadCountryStatistcs() {
			this.aptamerApi.apiAptamerRelationAricleCountryDataStatisticsGet(
				{ aptamerId: this.aptamerId },
				(error, data, response) => {
					if (error) {
						console.log(error)
					}
					if (data.success) {
						this.countryStatis = data.result
					}
				}
			)
		},
		loadSampleStatistcs() {
			this.aptamerApi.apiAptamerRelationAricleSampleDataStatisticsGet(
				{ aptamerId: this.aptamerId },
				(error, data, response) => {
					if (error) {
						console.log(error)
					}
					if (data.success) {
						this.sampleStatis = data.result
					}
				}
			)
		},
		loadUsagesStatistcs() {
			this.aptamerApi.apiAptamerRelationAricleUsageDataStatisticsGet(
				{ aptamerId: this.aptamerId },
				(error, data, response) => {
					if (error) {
						console.log(error)
					}
					if (data.success) {
						this.usageStatis = data.result
					}
				}
			)
		}
	},
	components: {
		ScreenMethodChart
	}
}
</script>

<style lang="less" scoped>
.otherName {
	// height: 200px;
	overflow-y: auto;
	max-height: 200px;
}

.detail-container {
	margin: 10px auto;
	width: 1171px;
	.detail-block {
		background: #ffffff;
		margin-bottom: 50px;

		.bar {
			height: 40px;
			background-color: #3463ea;
			color: #ffffff;
			text-align: center;
			line-height: 40px;
		}

		.content {
			position: relative;
			.c-font-size-14();
			.c-font-family-PF();
			font-weight: 500;
			color: #333333;
			padding: 60px 50px;

			a {
				text-decoration: underline;
			}

			.more-btn {
				cursor: pointer;
				width: 160px;
				height: 40px;
				line-height: 40px;
				margin: 0 auto;
				text-align: center;
				font-size: 15px;
				font-weight: 500;
				color: #416deb;
				border: 1px solid #416deb;
			}
		}
	}

	.sequence-detail {
		.content {
			display: flex;
			.left {
				width: 383px;
				height: 500px;
				border: 1px solid #DBDBDB;
				position: relative;
				img {
					height: auto;
					width: 100%;
				}

				.defaultImg {
					width: auto;
					height: auto;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
			.right {
				margin-left: 70px;
				width: 600px;

				.top {
					position: relative;
					// height: 70px;
					border-bottom: 3px solid #333333;

					.title {
						width: calc(100% - 60px);
						min-height: 31px;
						line-height: 31px;
						font-size: 32px;
						.c-font-family-PF();
						font-weight: bold;
						color: #3463ea;
					}
					.sm-btn {
						position: absolute;
						width: 60px;
						height: 30px;
						top: 0;
						right: 0;
						line-height: 30px;
						.c-font-size-14();
						.c-font-family-PF();
						font-weight: 400;
						color: #3463ea;
						text-align: center;

						cursor: pointer;
						border: 1px solid #3463ea;
						border-radius: 2px;
					}
				}

				.bottom {
					position: relative;
					margin-top: 40px;
					.entity {
						display: flex;
						margin-bottom: 25px;

						.name {
							width: 75px;
						}

						.value {
							width: 430px;
							.c-font-size-14();
							font-weight: 400;
							color: #333333;

							display: -webkit-box;
						}
					}

					.score {
						position: absolute;
						top: 0;
						right: 0;
						width: 30px;
						height: 35px;
						.value {
							color: #3463ea;
							font-size: 24px;
						}
						.name {
							color: #333333;
							.c-font-size-12();
							text-align: center;
						}
					}
				}
			}
		}
	}

	.sequence-info {
		.content {
			display: grid;
			grid-template-columns: repeat(2, 500px);
			grid-column-gap: 80px;
			grid-row-gap: 60px;

			.entity {
				display: flex;
				// min-width: 575px;
				.name {
					width: 200px;
					margin-right: 25px;
				}
				.value {
					width: 400px;
					word-break: break-all;
					word-wrap: break-word;
				}
			}
		}
	}

	.discoverer {
		.content {
			.c-font-size-14();
			font-weight: 500;
			color: #416deb;
		}
	}
	.relevant-article {
		min-height: 480px;
		.content {
			padding: 10px 50px !important;
			.item {
				margin-bottom: 20px !important;
				text-align: left;
			}
		}
		.statisticalChart {
			width: 100%;
			height: 300px;

			ul {
				width: 96%;
				height: 100%;
				display: flex;
				justify-content: space-between;
				list-style: none;
				margin: 20px auto 20px auto;
				li {
					width: 355px;
					height: 100%;
					border: 1px solid #dbdbdb;
					position: relative;

					.noData {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);

						p {
							text-align: center;
							margin-top: 30px;
							font-size: 14px;
							font-weight: 400;
							line-height: 20px;
							color: #BFBFBF;
						}
					}
				}
			}
		}
	}
	.relevant-article,
	.relevant-patent {
		.content {
			position: relative;
			.item {
				margin-bottom: 30px;
				.c-font-size-14();
				font-weight: 500;
				color: #416deb;
			}
		}
	}

	.flxed-box {
		position: fixed;
		top: 150px;
		left: calc(1170px + 20px + (100% - 1170px) / 2);
		width: 140px;
		height: 335px;
		padding: 60px 20px;
		background: #ffffff;
		.nav {
			display: block;
			cursor: pointer;
			height: 16px;
			line-height: 16px;
			.c-font-size-16();
			.c-font-family-PF();
			font-weight: 400;
			color: #333333;
			margin-bottom: 35px;
			text-decoration: none;
		}
		.nav:last-child {
			margin-bottom: 0px;
		}

		.active {
			position: relative;
			color: #416deb;
		}
		.active::before {
			display: block;
			position: absolute;
			left: -10px;
			top: 6px;
			transform: translateY(-25%);
			content: "";
			border-width: 6px;
			border-style: solid;
			border-color: transparent transparent transparent #416deb;
		}
	}
}
</style>
