/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {ContrastInfo} from './ContrastInfo';

/**
 * The ReturnHSPSDto model module.
 * @module model/ReturnHSPSDto
 * @version v1
 */
export class ReturnHSPSDto {
  /**
   * Constructs a new <code>ReturnHSPSDto</code>.
   * @alias module:model/ReturnHSPSDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ReturnHSPSDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ReturnHSPSDto} obj Optional instance to populate.
   * @return {module:model/ReturnHSPSDto} The populated <code>ReturnHSPSDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ReturnHSPSDto();
      if (data.hasOwnProperty('score'))
        obj.score = ApiClient.convertToType(data['score'], 'Number');
      if (data.hasOwnProperty('expect'))
        obj.expect = ApiClient.convertToType(data['expect'], 'Number');
      if (data.hasOwnProperty('strand'))
        obj.strand = ApiClient.convertToType(data['strand'], 'String');
      if (data.hasOwnProperty('alignCount'))
        obj.alignCount = ApiClient.convertToType(data['alignCount'], 'Number');
      if (data.hasOwnProperty('gapCount'))
        obj.gapCount = ApiClient.convertToType(data['gapCount'], 'Number');
      if (data.hasOwnProperty('identicalCount'))
        obj.identicalCount = ApiClient.convertToType(data['identicalCount'], 'Number');
      if (data.hasOwnProperty('gapPercent'))
        obj.gapPercent = ApiClient.convertToType(data['gapPercent'], 'Number');
      if (data.hasOwnProperty('identicalPercent'))
        obj.identicalPercent = ApiClient.convertToType(data['identicalPercent'], 'Number');
      if (data.hasOwnProperty('identityLength'))
        obj.identityLength = ApiClient.convertToType(data['identityLength'], 'Number');
      if (data.hasOwnProperty('identitySuccesLength'))
        obj.identitySuccesLength = ApiClient.convertToType(data['identitySuccesLength'], 'Number');
      if (data.hasOwnProperty('identity'))
        obj.identity = ApiClient.convertToType(data['identity'], 'Number');
      if (data.hasOwnProperty('rangeOne'))
        obj.rangeOne = ApiClient.convertToType(data['rangeOne'], 'Number');
      if (data.hasOwnProperty('rangeOneEnd'))
        obj.rangeOneEnd = ApiClient.convertToType(data['rangeOneEnd'], 'Number');
      if (data.hasOwnProperty('rangeTwo'))
        obj.rangeTwo = ApiClient.convertToType(data['rangeTwo'], 'Number');
      if (data.hasOwnProperty('rangeEnd'))
        obj.rangeEnd = ApiClient.convertToType(data['rangeEnd'], 'Number');
      if (data.hasOwnProperty('contractResult'))
        obj.contractResult = ApiClient.convertToType(data['contractResult'], [ContrastInfo]);
    }
    return obj;
  }
}

/**
 * @member {Number} score
 */
ReturnHSPSDto.prototype.score = undefined;

/**
 * @member {Number} expect
 */
ReturnHSPSDto.prototype.expect = undefined;

/**
 * @member {String} strand
 */
ReturnHSPSDto.prototype.strand = undefined;

/**
 * @member {Number} alignCount
 */
ReturnHSPSDto.prototype.alignCount = undefined;

/**
 * @member {Number} gapCount
 */
ReturnHSPSDto.prototype.gapCount = undefined;

/**
 * @member {Number} identicalCount
 */
ReturnHSPSDto.prototype.identicalCount = undefined;

/**
 * @member {Number} gapPercent
 */
ReturnHSPSDto.prototype.gapPercent = undefined;

/**
 * @member {Number} identicalPercent
 */
ReturnHSPSDto.prototype.identicalPercent = undefined;

/**
 * @member {Number} identityLength
 */
ReturnHSPSDto.prototype.identityLength = undefined;

/**
 * @member {Number} identitySuccesLength
 */
ReturnHSPSDto.prototype.identitySuccesLength = undefined;

/**
 * @member {Number} identity
 */
ReturnHSPSDto.prototype.identity = undefined;

/**
 * @member {Number} rangeOne
 */
ReturnHSPSDto.prototype.rangeOne = undefined;

/**
 * @member {Number} rangeOneEnd
 */
ReturnHSPSDto.prototype.rangeOneEnd = undefined;

/**
 * @member {Number} rangeTwo
 */
ReturnHSPSDto.prototype.rangeTwo = undefined;

/**
 * @member {Number} rangeEnd
 */
ReturnHSPSDto.prototype.rangeEnd = undefined;

/**
 * @member {Array.<module:model/ContrastInfo>} contractResult
 */
ReturnHSPSDto.prototype.contractResult = undefined;

