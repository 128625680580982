var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main-panel" }, [
      _c("div", { staticClass: "top-bar" }, [
        _c("img", { attrs: { src: require("../../assets/intro-1.png") } }),
      ]),
      _c("div", { staticClass: "section-whatis" }, [
        _c("div", { staticClass: "title" }, [_vm._v(" 什么是核酸适体 ")]),
        _c("div", { staticClass: "body" }, [
          _vm._v(
            " Aptamer，中文名被译为核酸适体、核酸适配体或适配子，是指单链的DNA或RNA通过自身卷曲折叠形成特定的三维结构，类似抗体的可变区， 能特异识别靶标分子并与之结合的一类核酸分子。长度通常在15-80个碱基之间。与抗体相比，它具有一系列独特的性质。 如核酸适体可以体外筛选、可化学合成、可通过PCR扩增、可进行高通量测序、序列自带标签的属性、可以设计改造等。 广泛应用于检测、成像、诊断、治疗、药物递送、分子机器、合成生物学等领域，具有不可替代的作用。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "section-theory" }, [
        _c("div", { staticClass: "image" }, [
          _c("img", { attrs: { src: require("../../assets/intro-2.png") } }),
        ]),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "title" }, [_vm._v(" 筛选技术原理 ")]),
          _c("div", { staticClass: "body" }, [
            _vm._v(
              " 核酸适体可以通过体外筛选获得。首先利用化学合成技术合成一个中间带有25-60个随机序列的文库，溶解于特定的缓冲体系中， 经过变复性折叠后，与特定的靶标混合孵育，洗去不结合的分子，保留下来的分子经过PCR扩增，再次制备单链，重复进行上面的筛选过程。 经过若干轮次之后，可以获得与靶标结合的富集文库。经高通量测序，生信分析等，即可获得候选序列，进一步合成验证， 即可获得想要的核酸适体。 "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "section-method" }, [
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "title" }, [_vm._v(" 常用方法 ")]),
          _c("div", { staticClass: "body" }, [
            _vm._v(
              " 适体促发现生物标记技术是一项应用在发现生物标记的技术。适体促发现生物标记技术用使用多回的制造适体 或者制造适体堆来与细胞上不同的分子标记物结合并能促进侦测生物标记。 "
            ),
          ]),
        ]),
        _c("div", { staticClass: "image" }, [
          _c("img", { attrs: { src: require("../../assets/intro-3.png") } }),
        ]),
      ]),
      _c("div", { staticClass: "middle-bar" }, [
        _c("img", { attrs: { src: require("../../assets/intro-4.png") } }),
      ]),
      _c("div", { staticClass: "section-discovery" }, [
        _c("div", { staticClass: "title" }, [_vm._v(" 药物发现 ")]),
        _c("div", { staticClass: "body" }, [
          _vm._v(
            " 现在在应用适体方面的治疗归功于美国的食品药物管理机构 (FDA)核准第一个适体药物用来治疗老人黄斑部病变 (AMD)称为治斑剂(Macugen)。 此外，NeoVentures生技公司已经成功的商业化第一个以适体为基础用来分析谷物霉菌毒素(mycotoxins)的诊断平台。 许多家公司纷纷开发适体与适体抗体来取代抗体。未修饰的适体在血液中会马上被清除，半生期从几分钟到几小时不等， 主要因为肾脏的核酸酶的降解与清除，结果造成适体与生俱来的低分子重。目前未修饰的适体主要应用在治疗短暂停留的情况， 如血液凝固;或是治疗器官，像眼睛这种允许适体的局部传送。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "section-teaching" }, [
        _c("div", { staticClass: "title" }, [_vm._v(" 教学视频 ")]),
        _c("div", { staticClass: "video" }, [
          _c("img", { attrs: { src: require("../../assets/intro-5.png") } }),
          _c("p", [_vm._v("COMING SOON...")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }