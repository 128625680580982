var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main" }, [
    _c("div", { staticClass: "top-tab" }, [
      _c("ul", [
        _c(
          "li",
          {
            class: { select: _vm.num === 1 },
            on: {
              click: function ($event) {
                return _vm.active(1)
              },
            },
          },
          [_vm._v("技术交流")]
        ),
        _c(
          "li",
          {
            class: { select: _vm.num === 2 },
            on: {
              click: function ($event) {
                return _vm.active(2)
              },
            },
          },
          [_vm._v("学习交流")]
        ),
        _c(
          "li",
          {
            class: { select: _vm.num === 3 },
            on: {
              click: function ($event) {
                return _vm.active(3)
              },
            },
          },
          [_vm._v("会议交流")]
        ),
        _c(
          "li",
          {
            class: { select: _vm.num === 4 },
            on: {
              click: function ($event) {
                return _vm.active(4)
              },
            },
          },
          [_vm._v("需求发布")]
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "content-area" },
      [_c("Content", { attrs: { isLogin: _vm.isLogin } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }