/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {ClinicalDetailDtoResponse} from '../model/ClinicalDetailDtoResponse';
import {ClinicalListDtoResponse} from '../model/ClinicalListDtoResponse';
import {SearchTypeEnum} from '../model/SearchTypeEnum';

/**
* Clinical service.
* @module api/ClinicalApi
* @version v1
*/
export class ClinicalApi {

    /**
    * Constructs a new ClinicalApi. 
    * @alias module:api/ClinicalApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiClinicalIdGet operation.
     * @callback moduleapi/ClinicalApi~apiClinicalIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ClinicalDetailDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 临床详情查询接口
     * @param {String} id 临床主键ID
     * @param {module:api/ClinicalApi~apiClinicalIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiClinicalIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiClinicalIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClinicalDetailDtoResponse;

      return this.apiClient.callApi(
        '/api/Clinical/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiClinicalQueryGet operation.
     * @callback moduleapi/ClinicalApi~apiClinicalQueryGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ClinicalListDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 临床列表查询接口
     * @param {module:model/SearchTypeEnum} searchType 
     * @param {Object} opts Optional parameters
     * @param {String} opts.keyWord 
     * @param {Number} opts.pageIndex 
     * @param {Number} opts.pageSize 
     * @param {String} opts.orderBy 
     * @param {String} opts.title 
     * @param {String} opts.conditions 
     * @param {String} opts.organization 
     * @param {String} opts.descriptiveInformation 
     * @param {String} opts.aptamerType 
     * @param {String} opts.country 
     * @param {String} opts.recruitmentStatus 
     * @param {Number} opts.index 
     * @param {Number} opts.size 
     * @param {module:api/ClinicalApi~apiClinicalQueryGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiClinicalQueryGet(searchType, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'searchType' is set
      if (searchType === undefined || searchType === null) {
        throw new Error("Missing the required parameter 'searchType' when calling apiClinicalQueryGet");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'KeyWord': opts['keyWord'],'PageIndex': opts['pageIndex'],'PageSize': opts['pageSize'],'OrderBy': opts['orderBy'],'Title': opts['title'],'Conditions': opts['conditions'],'Organization': opts['organization'],'DescriptiveInformation': opts['descriptiveInformation'],'AptamerType': opts['aptamerType'],'Country': opts['country'],'RecruitmentStatus': opts['recruitmentStatus'],'SearchType': searchType,'Index': opts['index'],'Size': opts['size']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ClinicalListDtoResponse;

      return this.apiClient.callApi(
        '/api/Clinical/query', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}