import Vue from "vue"
import VueRouter from "vue-router"
import FrontPage from "../views/frontpage/index.vue"
import Introduction from "../views/introduction/index.vue"
import Team from "../views/team/index.vue"
import Clinical from "../views/clinical/index.vue"
import Project from "../views/project/project_index.vue"
import ProjectDetail from "../views/project/project_detail.vue"
import Patent from "../views/patent/index.vue"
import PatentDetail from "../views/patent/detail.vue"
import AptamerList from "../views/aptamer/list.vue"
import AptamerDetail from "../views/aptamer/detail.vue"
import PaperList from "../views/paper/index.vue"
import PaperDetail from "../views/paper/detail.vue"
import user from "../views/user/index.vue"
import favorites from "../views/user/favorites/index.vue"
import Sequence from "../views/Sequence/index.vue"
import ClinicalDetail from "../views/clinical/detail.vue"
import Analysistool from "../views/Analysistool/index.vue"
import myupload from "../views/user/myupload/index.vue"
import LargeScreen from "../views/largeScreen/index.vue"
import LiteratureLearning from "../views/LiteratureLearning/index.vue"
import LiteratureLearningDetail from "../views/LiteratureLearning/detail.vue"
import AptamerFilterPage from "../views/IntelligentFiltering/search.vue"
import IntelligentFiltering from "../views/IntelligentFiltering/index.vue"
import AptamerEvaluationPage from "../views/AptamerEvaluation/index.vue"
import AptamerEvaluationList from "../views/AptamerEvaluation/list.vue"
import AptamerEvaluationDetail from "../views/AptamerEvaluation/detail.vue"
import CommunicationPlatform from "../views/CommunicationPlatform/index.vue"
import TrainingCourse from "../views/TrainingCourse/index.vue"
import CelatedTopics from "../views/CommunicationPlatform/detail/CelatedTopics.vue"
import ArticleDynamic from "../views/ArticleDynamic/list.vue"
import DynamicDetail from "../views/ArticleDynamic/detail.vue"
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
	if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject) }
	return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		name: "首页",
		meta: {
			default_db_type: "0"
		},
		component: FrontPage
	},
	{
		path: "/introduction",
		name: "适体简介",
		meta: {
			default_db_type: "0",
			root: "核酸适体数据系统"
		},
		component: Introduction
	},
	{
		path: "/team",
		name: "团队介绍",
		meta: {
			default_db_type: "3",
			root: "核酸适体教育与科研服务系统"
		},
		component: Team
	},
	{
		path: "/clinical",
		name: "临床进展",
		meta: {
			default_db_type: "2",
			root: "核酸适体数据系统"
		},
		component: Clinical
	},
	{
		path: "/clinical/:id",
		name: "临床详情",
		meta: {
			default_db_type: "2",
			detail: true,
			root: "核酸适体数据系统"
		},
		component: ClinicalDetail
	},
	{
		path: "/project",
		name: "科研项目",
		meta: {
			default_db_type: "4",
			root: "核酸适体数据系统"
		},
		component: Project
	},
	{
		path: "/project/:id",
		name: "项目详情",
		meta: {
			default_db_type: "4",
			detail: true,
			root: "核酸适体数据系统"
		},
		component: ProjectDetail
	},
	{
		path: "/patent",
		name: "重点专利",
		meta: {
			default_db_type: "5",
			root: "核酸适体数据系统"
		},
		component: Patent
	},
	{
		path: "/patent/:id",
		name: "专利详情",
		meta: {
			default_db_type: "5",
			detail: true,
			root: "核酸适体数据系统"
		},
		component: PatentDetail
	},
	{
		path: "/aptamerdb",
		name: "Aptamer数据库",
		meta: {
			default_db_type: "0",
			root: "核酸适体数据系统"
		},
		component: AptamerList
	},
	{
		path: "/aptamerdb/:id",
		name: "Aptamer详情",
		meta: {
			default_db_type: "0",
			detail: true,
			root: "核酸适体数据系统"
		},
		component: AptamerDetail
	},
	{
		path: "/paper",
		name: "文献资料",
		meta: {
			default_db_type: "1",
			root: "核酸适体数据系统"
		},
		component: PaperList
	},
	{
		path: "/paper/:id",
		name: "文献详情",
		component: PaperDetail,
		meta: {
			default_db_type: "1",
			detail: true,
			root: "核酸适体数据系统"
		}
	},
	{
		path: "/usercenter",
		name: "个人中心",
		meta: {
			default_db_type: "0"
		},
		component: user,
		children: [
			{
				path: "info",
				name: "个人信息",
				component: () => import("@/views/user/info/index.vue")
			},
			{
				path: "favorites",
				name: "收藏列表",
				component: favorites,
				children: [
					{
						path: "aptamer",
						name: "适体收藏",
						component: () =>
							import("@/views/user/favorites/components/aptamer.vue")
					},
					{
						path: "article",
						name: "论文收藏",
						component: () =>
							import("@/views/user/favorites/components/article.vue")
					},
					{
						path: "clinical",
						name: "临床进展收藏",
						component: () =>
							import("@/views/user/favorites/components/clinical.vue")
					},
					{
						path: "patent",
						name: "专利收藏",
						component: () =>
							import("@/views/user/favorites/components/patent.vue")
					},
					{
						path: "team",
						name: "团队收藏",
						component: () =>
							import("@/views/user/favorites/components/team.vue")
					},
					{
						path: "project",
						name: "项目收藏",
						component: () =>
							import("@/views/user/favorites/components/project.vue")
					}
				]
			},
			{
				path: "myupload",
				name: "我的上传",
				component: myupload,
				children: [
					{
						path: "seq",
						name: "提交序列",
						component: () => import("@/views/user/myupload/Sequence.vue")
					},
					{
						path: "topic",
						name: "发布话题",
						component: () => import("@/views/user/myupload/Topic.vue")
					},
					{
						path: "comments",
						name: "我的评论",
						component: () => import("@/views/user/myupload/Comments.vue")
					}
				]
			}
		]
	},
	{
		path: "/sequence",
		name: "序列对比",
		meta: {
			default_db_type: "0"
		},
		component: Sequence
	},
	{
		path: "/LargeScreen",
		name: "数据大屏",
		meta: {
			default_db_type: "0"
		},
		component: LargeScreen
	},
	{
		path: "/IntelligentFiltering",
		name: "核酸适体智能筛选列表",
		meta: {
			default_db_type: "1"
		},
		component: IntelligentFiltering
	},
	{
		path: "/AptamerFilterPage",
		meta: {
			default_db_type: "1"
		},
		component: AptamerFilterPage
	},
	{
		path: "/AptamerEvaluationPage",
		meta: {
			default_db_type: "0"
		},
		component: AptamerEvaluationPage
	},
	{
		path: "/AptamerEvaluationList",
		name: "核酸适体评价系统列表",
		meta: {
			default_db_type: "0"
		},
		component: AptamerEvaluationList
	},
	{
		path: "/AptamerEvaluationList/:id",
		name: "得分计算",
		meta: {
			default_db_type: "0",
			detail: true
		},
		component: AptamerEvaluationDetail
	},
	{
		path: "/LiteratureLearning",
		name: "文献学习",
		meta: {
			default_db_type: "1",
			root: "核酸适体教育与科研服务系统"
		},
		component: LiteratureLearning
	},
	{
		path: "/LiteratureLearning/:id",
		name: "文献学习详情",
		component: LiteratureLearningDetail,
		meta: {
			default_db_type: "2",
			detail: true,
			root: "核酸适体教育与科研服务系统"
		}
	},
	{
		path: "/CommunicationPlatform",
		name: "交流平台",
		component: CommunicationPlatform,
		meta: {
			default_db_type: "0",
			root: "核酸适体教育与科研服务系统"
		}
	},
	{
		path: "/CommunicationPlatform/:id",
		name: "相关话题",
		component: CelatedTopics,
		meta: {
			default_db_type: "1",
			detail: true,
			root: "核酸适体教育与科研服务系统"
		}
	},
	{
		path: "/TrainingCourse",
		name: "培训及课程",
		component: TrainingCourse,
		meta: {
			default_db_type: "0",
			root: "核酸适体教育与科研服务系统"
		}
	},
	{
		path: "/analysistool",
		name: "分析工具",
		meta: {
			default_db_type: "0",
			root: "核酸适体数据系统"
		},
		component: Analysistool
	},
	{
		path: "/articledynamic",
		name: "文献动态",
		meta: {
			default_db_type: "0"
		},
		component: ArticleDynamic
	},
	{
		path: "/articledynamic/:id",
		name: "文献动态详情",
		meta: {
			default_db_type: "1",
			detail: true
		},
		component: DynamicDetail
	},
	{
		// 没有没有匹配到的路由，则重定向到首页
		path: "*",
		meta: {
			default_db_type: "0"
		},
		redirect: "/"
	}
]

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
})

export default router
