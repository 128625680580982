/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The AptamerExportItemDto model module.
 * @module model/AptamerExportItemDto
 * @version v1
 */
export class AptamerExportItemDto {
  /**
   * Constructs a new <code>AptamerExportItemDto</code>.
   * @alias module:model/AptamerExportItemDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AptamerExportItemDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AptamerExportItemDto} obj Optional instance to populate.
   * @return {module:model/AptamerExportItemDto} The populated <code>AptamerExportItemDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AptamerExportItemDto();
      if (data.hasOwnProperty('aptamerName'))
        obj.aptamerName = ApiClient.convertToType(data['aptamerName'], 'String');
      if (data.hasOwnProperty('targetName'))
        obj.targetName = ApiClient.convertToType(data['targetName'], 'String');
      if (data.hasOwnProperty('affinity'))
        obj.affinity = ApiClient.convertToType(data['affinity'], 'String');
      if (data.hasOwnProperty('citedCount'))
        obj.citedCount = ApiClient.convertToType(data['citedCount'], 'Number');
      if (data.hasOwnProperty('publishedDate'))
        obj.publishedDate = ApiClient.convertToType(data['publishedDate'], 'Date');
      if (data.hasOwnProperty('journal'))
        obj.journal = ApiClient.convertToType(data['journal'], 'String');
      if (data.hasOwnProperty('aptamerType'))
        obj.aptamerType = ApiClient.convertToType(data['aptamerType'], 'String');
      if (data.hasOwnProperty('finderName'))
        obj.finderName = ApiClient.convertToType(data['finderName'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} aptamerName
 */
AptamerExportItemDto.prototype.aptamerName = undefined;

/**
 * @member {String} targetName
 */
AptamerExportItemDto.prototype.targetName = undefined;

/**
 * @member {String} affinity
 */
AptamerExportItemDto.prototype.affinity = undefined;

/**
 * @member {Number} citedCount
 */
AptamerExportItemDto.prototype.citedCount = undefined;

/**
 * @member {Date} publishedDate
 */
AptamerExportItemDto.prototype.publishedDate = undefined;

/**
 * @member {String} journal
 */
AptamerExportItemDto.prototype.journal = undefined;

/**
 * @member {String} aptamerType
 */
AptamerExportItemDto.prototype.aptamerType = undefined;

/**
 * @member {String} finderName
 */
AptamerExportItemDto.prototype.finderName = undefined;

