<template>
	<div
		class="chart-con"
		ref="chart"
	></div>
</template>
<script>
import * as echarts from "echarts"
export default {
	name: "ScreenMethodChart",
	// allowedClick: 是否click事件
	props: ["dataList", "totalDesc", "allowedClick", "dataType"],
	data() {
		return {
			option: {
				tooltip: {
					trigger: "item",
					formatter: "{b} : {c}"
				},
				color: [
					"#2C3FA0",
					"#CB332A",
					"#F4B200",
					"#6D4BB2",
					"#10BADF",
					"#FF9000",
					"#08A216"
				],
				title: {
					text: "",
					left: "center",
					top: "42%",
					textStyle: {
						color: "#333333",
						fontWeight: "bold",
						fontSize: 22,
						align: "center"
					}
				},
				graphic: {
					type: "text",
					left: "center",
					top: "53%",
					style: {
						text: "",
						textAlign: "center",
						fill: "#333",
						fontSize: 12
					}
				},
				// legend: {
				// 	bottom: "0%",
				// 	left: "center",
				// 	formatter: function (data) {
				// 		if (this.dataList.length !== 1 && data.length > 10) {
				// 			return data.slice(0, 10) + "..."
				// 		} else {
				// 			return data
				// 		}
				// 	}
				// },
				series: [
					{
						type: "pie",
						radius: ["40%", "52%"],
						labelLine: {
							normal: {
								show: true
							}
						},
						itemStyle: {
							borderRadius: 0,
							borderColor: "#fff",
							borderWidth: 2
						},
						label: {
							normal: {
								formatter: function (data) {
									return data.percent.toFixed(0) + "%"
								}
							}
						},
						data: []
					}
				]
			}
		}
	},
	watch: {
		dataList: function (newList, oldList) {
			const chart =
				echarts.getInstanceByDom(this.$refs.chart) ||
				echarts.init(this.$refs.chart, "shine")
			window.addEventListener("resize", function () {
				chart.resize()
			})
			this.option.title.text = newList.reduce(
				(sum, e) => sum + Number(e.value || 0),
				0
			)

			this.option.legend = {
				bottom: "0%",
				left: "center",
				formatter: function (data) {
					if (newList.length !== 1 && data.length > 10) {
						return data.slice(0, 10) + "..."
					} else {
						return data
					}
				}
			}

			this.option.graphic.style.text = this.totalDesc
			// 深拷贝副本 防止排序时影响原数组
			var foo = newList.concat([])
			foo.sort((a, b) => b.value - a.value)
			if (foo.length > 5) {
				var foo1 = foo.slice(0, 5)
				var foo2 = foo.slice(5, foo.length)
				var other = {
					name: "Others",
					value: foo2.reduce((sum, e) => sum + Number(e.value || 0), 0)
				}
				foo1.push(other)
				foo = foo1
			}
			if (foo.length > 0) {
				foo = foo.map((item) => {
					item.name = item.name || "UnKnown"
					return item
				})
			}
			this.option.series[0].data = foo
			chart.setOption(this.option)

			if (this.allowedClick) {
				chart.on("legendselectchanged", (params) => {
					chart.dispatchAction({
						type: "legendSelect",
						name: params.name
					})

					this.pieSelect(newList, chart, params.name)
				})

				chart.on("pieselectchanged", (selected) => {
					console.log(selected)
					console.log("1a")
				})
				chart.on("click", (params) => {
					this.pieSelect(newList, chart, params.name)
				})
			}
		},
		totalDesc: function (old, newDesc) {
			const chart =
				echarts.getInstanceByDom(this.$refs.chart) ||
				echarts.init(this.$refs.chart, "shine")

			this.option.graphic.style.text = this.totalDesc
			chart.setOption(this.option)
		}
	},
	mounted() {},
	methods: {
		loadChart(data) {},
		pieSelect(dataList, chart, selectName) {
			var res = [selectName]
			// if (selectName === "others") {
			// 	return
			// } else {
			// 	res = [selectName]
			// }

			this.curPiePartIndex = toggleSingleHighlight(
				chart,
				selectName,
				this.curPiePartIndex
			)
			if (this.curPiePartIndex === -1) {
				res = []
			}
			this.$emit("click", { res: res, dataType: this.dataType })

			function toggleSingleHighlight(chart, name, curPiePartIndex) {
				// First at all, clear current highlight
				chart.dispatchAction({
					type: "downplay",
					seriesIndex: 0
				})

				// Get the dataIndex with the given name
				var nameIndex = chart
					.getOption()
					.series[0].data.map((p) => p.name)
					.indexOf(name)

				if (nameIndex === curPiePartIndex) {
					return -1
				} else {
					// Set highlight
					chart.dispatchAction({
						type: "highlight",
						seriesIndex: 0,
						dataIndex: nameIndex
					})
					return nameIndex
				}
			}
		}
	}
}
</script>

<style scoped lang="less">
.chart-con {
	width: 100%;
	height: 100%;

	// /deep/ div {
	// 	width: 100%;
	// 	height: 100%;

	// 	canvas {
	// 		width: 100%;
	// 		height: 100%;
	// 	}
	// }
}
</style>
