/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ArticleProduct model module.
 * @module model/ArticleProduct
 * @version v1
 */
export class ArticleProduct {
  /**
   * Constructs a new <code>ArticleProduct</code>.
   * @alias module:model/ArticleProduct
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ArticleProduct</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ArticleProduct} obj Optional instance to populate.
   * @return {module:model/ArticleProduct} The populated <code>ArticleProduct</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ArticleProduct();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('catalogNum'))
        obj.catalogNum = ApiClient.convertToType(data['catalogNum'], 'String');
      if (data.hasOwnProperty('company'))
        obj.company = ApiClient.convertToType(data['company'], 'String');
      if (data.hasOwnProperty('brand'))
        obj.brand = ApiClient.convertToType(data['brand'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
ArticleProduct.prototype.id = undefined;

/**
 * @member {String} catalogNum
 */
ArticleProduct.prototype.catalogNum = undefined;

/**
 * @member {String} company
 */
ArticleProduct.prototype.company = undefined;

/**
 * @member {String} brand
 */
ArticleProduct.prototype.brand = undefined;

/**
 * @member {String} name
 */
ArticleProduct.prototype.name = undefined;

