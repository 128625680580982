/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {BooleanResponse} from '../model/BooleanResponse';
import {ReferenceTypeEnum} from '../model/ReferenceTypeEnum';
import {SearchTypeEnum} from '../model/SearchTypeEnum';
import {SubmittedSequenceDetailDtoResponse} from '../model/SubmittedSequenceDetailDtoResponse';
import {SubmittedSequenceListDtoResponse} from '../model/SubmittedSequenceListDtoResponse';

/**
* SubmittedSequence service.
* @module api/SubmittedSequenceApi
* @version v1
*/
export class SubmittedSequenceApi {

    /**
    * Constructs a new SubmittedSequenceApi. 
    * @alias module:api/SubmittedSequenceApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiSubmittedSequenceBatchuploadPost operation.
     * @callback moduleapi/SubmittedSequenceApi~apiSubmittedSequenceBatchuploadPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BooleanResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 批量上传
     * @param {Object} opts Optional parameters
     * @param {Blob} opts.file 
     * @param {module:api/SubmittedSequenceApi~apiSubmittedSequenceBatchuploadPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiSubmittedSequenceBatchuploadPost(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        'file': opts['file']
      };

      let authNames = ['Bearer'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain'];
      let returnType = BooleanResponse;

      return this.apiClient.callApi(
        '/api/SubmittedSequence/batchupload', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiSubmittedSequenceIdDelete operation.
     * @callback moduleapi/SubmittedSequenceApi~apiSubmittedSequenceIdDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BooleanResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 删除提交的序列
     * @param {String} id 
     * @param {module:api/SubmittedSequenceApi~apiSubmittedSequenceIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiSubmittedSequenceIdDelete(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiSubmittedSequenceIdDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;

      return this.apiClient.callApi(
        '/api/SubmittedSequence/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiSubmittedSequenceIdGet operation.
     * @callback moduleapi/SubmittedSequenceApi~apiSubmittedSequenceIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SubmittedSequenceDetailDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 用户提交的序列详情
     * @param {String} id 
     * @param {module:api/SubmittedSequenceApi~apiSubmittedSequenceIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiSubmittedSequenceIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiSubmittedSequenceIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SubmittedSequenceDetailDtoResponse;

      return this.apiClient.callApi(
        '/api/SubmittedSequence/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiSubmittedSequenceQueryGet operation.
     * @callback moduleapi/SubmittedSequenceApi~apiSubmittedSequenceQueryGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SubmittedSequenceListDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 一般查询、高级查询
     * @param {module:model/SearchTypeEnum} searchType 
     * @param {Object} opts Optional parameters
     * @param {String} opts.keyWord 
     * @param {String} opts.name 
     * @param {String} opts.type 
     * @param {String} opts.target 
     * @param {String} opts.affinity 
     * @param {String} opts.userID 
     * @param {Number} opts.index 
     * @param {Number} opts.size 
     * @param {module:api/SubmittedSequenceApi~apiSubmittedSequenceQueryGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiSubmittedSequenceQueryGet(searchType, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'searchType' is set
      if (searchType === undefined || searchType === null) {
        throw new Error("Missing the required parameter 'searchType' when calling apiSubmittedSequenceQueryGet");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'KeyWord': opts['keyWord'],'name': opts['name'],'type': opts['type'],'target': opts['target'],'Affinity': opts['affinity'],'UserID': opts['userID'],'SearchType': searchType,'Index': opts['index'],'Size': opts['size']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SubmittedSequenceListDtoResponse;

      return this.apiClient.callApi(
        '/api/SubmittedSequence/query', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiSubmittedSequenceUpdatePost operation.
     * @callback moduleapi/SubmittedSequenceApi~apiSubmittedSequenceUpdatePostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BooleanResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 修改上传序列信息
     * @param {Object} opts Optional parameters
     * @param {String} opts.ID 
     * @param {String} opts.name 
     * @param {String} opts.type 
     * @param {String} opts.target 
     * @param {String} opts.targetType 
     * @param {String} opts.affinity 
     * @param {String} opts.screenMethod 
     * @param {String} opts.sampleType 
     * @param {String} opts.appliedteChnology 
     * @param {String} opts.combiningCondition 
     * @param {String} opts.sequence 
     * @param {module:model/ReferenceTypeEnum} opts.referenceType 
     * @param {String} opts.referenceInfo 
     * @param {module:api/SubmittedSequenceApi~apiSubmittedSequenceUpdatePostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiSubmittedSequenceUpdatePost(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'ID': opts['ID'],'Name': opts['name'],'Type': opts['type'],'Target': opts['target'],'TargetType': opts['targetType'],'Affinity': opts['affinity'],'ScreenMethod': opts['screenMethod'],'SampleType': opts['sampleType'],'AppliedteChnology': opts['appliedteChnology'],'CombiningCondition': opts['combiningCondition'],'Sequence': opts['sequence'],'ReferenceType': opts['referenceType'],'ReferenceInfo': opts['referenceInfo']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;

      return this.apiClient.callApi(
        '/api/SubmittedSequence/update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}