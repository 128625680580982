var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-panel main-list" }, [
    _c("div", { staticClass: "left-panel" }, [
      _c(
        "div",
        { staticClass: "statistic-panel" },
        [
          _c("doughnut-chart", {
            attrs: {
              dataList: _vm.countryStatis,
              totalDesc: "适体总数",
              chartParam: _vm.chartParam,
              isDataFixed: "true",
            },
            on: { click: _vm.countrySelect },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "statistic-panel" },
        [
          _c("doughnutChart", {
            attrs: {
              dataList: _vm.methodStatis,
              totalDesc: "筛选方法",
              chartParam: _vm.methodchartParam,
              isDataFixed: "true",
            },
            on: { click: _vm.methodSelect },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-panel" },
        [_c("aptamerfilter", { attrs: { aptamerTypeFilters: _vm.candidate } })],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "list-panel" },
      [
        _c("aptamerlist", {
          attrs: { aptamers: _vm.aptamers, resultAmount: _vm.resultAmount },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }