<template>
	<div class="team-list-panel">
		<div class="top-bar-container">
			<div>
				<div class="result">
					<ul class="tab-tilte" style="margin: 0px;">
						<li :class="{ active: showIndex == 1 }" @click="showIndex = 1">
							摘要
						</li>
						<li :class="{ active: showIndex == 2 }" @click="showIndex = 2">
							说明书
						</li>
						<li :class="{ active: showIndex == 3 }" @click="showIndex = 3">
							权利要求书
						</li>
						<li :class="{ active: showIndex == 4 }" @click="showIndex = 4">
							图片
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!--摘要-->
		<div class="team-list" v-show="showIndex == 1">
			<div class="team-container">
				<div class="team" v-html="content._abstract"></div>
			</div>
		</div>
		<!--说明书-->
		<div class="team-list" v-show="showIndex == 2">
			<div class="team-container">
				<div class="team" v-html="content.instructions"></div>
			</div>
		</div>
		<!--权利要求书-->
		<div class="team-list" v-show="showIndex == 3">
			<div class="team-container">
				<div class="team" v-html="content.claims"></div>
			</div>
		</div>
		<!--图片-->
		<div class="team-list" v-show="showIndex == 4">
			<div class="team-container">
				<div class="team">
					<viewer v-if="content.figures.length > 0" :images="content.figures">
						<img
							v-for="(src, index) in content.figures"
							:src="src"
							:key="index"
							class="imgs"
						/>
					</viewer>
					<div v-else>暂无图片信息！</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import "viewerjs/dist/viewer.css"
// import $ from "jquery"
export default {
	props: ["content"],
	data() {
		return {
			showIndex: 1,
			photo: [
				"./img/frontpage-team.92e8db51.png",
				"./img/frontpage-team.92e8db51.png",
				"./img/frontpage-team.92e8db51.png",
				"./img/frontpage-team.92e8db51.png",
				"./img/frontpage-team.92e8db51.png",
				"./img/frontpage-team.92e8db51.png",
				"./img/frontpage-team.92e8db51.png",
				"./img/frontpage-team.92e8db51.png",
				"./img/frontpage-team.92e8db51.png"
			]
		}
	},
	methods: {},
	mounted() {}
}
</script>

<style lang="less" scoped>
.imgs {
	margin: 10px;
	width: 256px;
	height: 256px;
}

.tab-tilte {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	list-style: none;
	white-space: nowrap;
	transition: width 0.5s ease;
}

.tab-tilte li {
	padding: 10px 20px;
	.c-font-size-14();
	.c-font-family-PF();
	font-weight: bolder;
	line-height: 20px;
	color: #333333;
}

.active {
	border-bottom: 2px solid #3463ea;
}

.imgpanel {
	width: 80px;
	height: 80px;
	margin: 16px 0 0 16px;
}

.top-bar-container {
	width: 100%;
	background-color: #ffffff;
	display: inline-block;
}

.result {
	.c-font-size-12();
	.c-font-family-PF();
	line-height: 16px;
	color: #666666;
	display: flex;
	flex-direction: row;
}

.result .value {
	color: #3463ea;
	margin: 0 6px;
}

.team-list {
	width: 100%;
}

.team-container {
	width: 100%;
	display: inline-flex;
	background-color: #ffffff;
	margin-top: 16px;
}

.team {
	width: 97%;
	margin: 16px;
	font-family: PingFang-SC-Medium;
	.c-font-size-14();
	word-wrap: break-word;
}

.team .title {
	.c-font-size-12();
	color: #3463ea;
	line-height: 20px;
	text-align: left;
}

.team .title a {
	text-decoration: none;
	.c-font-size-16();
}
.team .title a:visited {
	color: #3463ea;
}

.team .entry a:visited {
	color: #3463ea;
}

.team .location {
	.c-font-size-12();
	line-height: 20px;
	display: flex;
	flex-direction: row;
	text-align: left;
}

.team .location .entry {
	display: flex;
	flex-direction: row;
	margin-right: 20px;
}

.team .location .entry .value {
	margin-left: 6px;
}

.team .link {
	display: flex;
	flex-direction: row;
	.c-font-size-12();
	line-height: 20px;
}

.team .link .url {
	margin-left: 6px;
}

.pagination {
	display: flex;
	justify-content: center;
	margin: 10px auto;
}

/deep/ .patent-image-container {
	a {
		pointer-events: none;
	}
}

/deep/ .patent-family-member {
	a {
		pointer-events: none;
	}
}
</style>
