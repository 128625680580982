var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item-list-panel" }, [
    _c("div", { staticClass: "top-bar-container" }, [
      _c("div", { staticClass: "top-bar" }, [
        _c("div", { staticClass: "result" }, [
          _c("div", { staticClass: "label count-desc" }, [
            _c("div", { staticClass: "label" }, [_vm._v("当前结果")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(" " + _vm._s(_vm.resultAmount) + " "),
            ]),
            _c("div", { staticClass: "unit" }, [_vm._v("条")]),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "item-list" },
      [
        _vm._l(_vm.teams, function (team, i) {
          return _c("div", { key: i, staticClass: "item-container" }, [
            _c(
              "div",
              {
                staticClass: "item",
                on: {
                  mouseover: function ($event) {
                    return _vm.mouseOver(team)
                  },
                  mouseleave: function ($event) {
                    return _vm.mouseLeave(team)
                  },
                },
              },
              [
                (team.isOver || team.isFavorite) && _vm.isLogin
                  ? _c("div", { staticClass: "fav-con" }, [
                      !team.isFavorite
                        ? _c(
                            "div",
                            {
                              staticClass: "sm-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.addFavorite(team)
                                },
                              },
                            },
                            [_vm._v(" 收 藏 ")]
                          )
                        : _vm._e(),
                      team.isFavorite
                        ? _c(
                            "div",
                            {
                              staticClass: "sm-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.removeFavorite(team)
                                },
                              },
                            },
                            [_vm._v(" 已收藏 ")]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "location" }, [
                  _c("div", { staticClass: "entity entity-title" }, [
                    _vm._v(" " + _vm._s(team.name) + " "),
                  ]),
                  _c("div", { staticClass: "entity-line" }, [
                    _c("div", { staticClass: "entity" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("所在单位："),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _vm._v(" " + _vm._s(team.institution) + " "),
                      ]),
                    ]),
                    _c("div", { staticClass: "entity" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("所属国家："),
                      ]),
                      _c("div", { staticClass: "value" }, [
                        _vm._v(" " + _vm._s(team.country.name) + " "),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "entity" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("课题组链接："),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.toUrl(team.url)
                            },
                          },
                        },
                        [_vm._v(_vm._s(team.url))]
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ])
        }),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              attrs: {
                id: "pagination",
                "current-page": _vm.pageIndex,
                "page-size": _vm.pageSize,
                "page-sizes": [10, 50, 100],
                layout: "prev, pager, next, jumper,sizes",
                total: _vm.resultAmount,
              },
              on: {
                "current-change": _vm.handlePageChange,
                "size-change": _vm.changeSize,
              },
            }),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }