var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item-list-panel" }, [
    _c("div", { staticClass: "top-bar-container" }, [
      _c("div", { staticClass: "top-bar" }, [
        _c("div", { staticClass: "result" }, [
          _c("div", { staticClass: "label count-desc" }, [
            _vm.isSelect
              ? _c(
                  "div",
                  { staticClass: "chk-select" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "checkbox",
                        on: { change: _vm.checkAllChange },
                        model: {
                          value: _vm.isCheckAll,
                          callback: function ($$v) {
                            _vm.isCheckAll = $$v
                          },
                          expression: "isCheckAll",
                        },
                      },
                      [_vm._v("全选")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "choosepanel" }, [
              _c(
                "a",
                {
                  staticClass: "select-switch",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      _vm.isSelect = !_vm.isSelect
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.isSelect ? "取消选择" : "选择"))]
              ),
            ]),
            _c("div", { staticClass: "label" }, [_vm._v("当前结果")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(" " + _vm._s(_vm.resultAmount) + " "),
            ]),
            _c("div", { staticClass: "unit" }, [_vm._v("条")]),
          ]),
          _c("div", { staticClass: "textLabel" }, [
            _c(
              "a",
              {
                staticClass: "btn-export",
                attrs: { href: "#" },
                on: { click: _vm.openDialog },
              },
              [
                _c("img", {
                  attrs: { src: require("../../../assets/导出.png") },
                }),
                _c("i", { staticClass: "lbl-export" }),
                _vm._v("导出结果 "),
              ]
            ),
            _c("div", { staticClass: "orders" }, [
              _c(
                "div",
                { staticClass: "orders-container" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "order-type",
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.orderBy,
                        callback: function ($$v) {
                          _vm.orderBy = $$v
                        },
                        expression: "orderBy",
                      },
                    },
                    _vm._l(_vm.orderOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "item-list" },
      [
        _c(
          "el-checkbox-group",
          {
            model: {
              value: _vm.selectData,
              callback: function ($$v) {
                _vm.selectData = $$v
              },
              expression: "selectData",
            },
          },
          _vm._l(_vm.datalist, function (pat, i) {
            return _c("div", { key: i, staticClass: "item-container" }, [
              _c("div", { staticClass: "item" }, [
                _vm.isSelect
                  ? _c(
                      "div",
                      { staticClass: "checkbox" },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { label: pat.articleID, name: "record" },
                            on: { change: _vm.selectChange },
                          },
                          [_vm._v(" ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "location" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "entity entity-title",
                        attrs: { to: "/LiteratureLearning/" + pat.articleID },
                      },
                      [_vm._v(_vm._s(pat.articleTitle || ""))]
                    ),
                    _c("div", { staticClass: "entity" }, [
                      _c("div", { staticClass: "value" }, [
                        _vm._v(" " + _vm._s(pat.author || "") + " "),
                      ]),
                    ]),
                    _c("div", { staticClass: "entity" }, [
                      _c("div", { staticClass: "value" }, [
                        _vm._v(
                          " " +
                            _vm._s(pat.journal) +
                            "、" +
                            _vm._s(
                              _vm._f("dateFormat")(
                                pat.publishedDate,
                                "YYYY-MM-DD"
                              )
                            ) +
                            ";  " +
                            _vm._s(pat.issn) +
                            "， doi: " +
                            _vm._s(pat.doi) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "entity" }, [
                      _c("div", { staticClass: "value value1" }, [
                        _vm._v(" " + _vm._s(pat._abstract) + " "),
                      ]),
                    ]),
                    _c("div", { staticClass: "item-comment" }, [
                      _c("div", { staticClass: "comment" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("引用频次："),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(" " + _vm._s(pat.citedCount || 0) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "comment" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("参考文献："),
                        ]),
                        _c("div", { staticClass: "value" }, [
                          _vm._v(" " + _vm._s(pat.referenceCount || 0) + " "),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
            ])
          }),
          0
        ),
      ],
      1
    ),
    _c(
      "div",
      { attrs: { id: "exportDia" } },
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: "导出结果",
              visible: _vm.openDialogShow,
              width: "650px",
            },
            on: { close: _vm.closeDialog },
          },
          [
            _c(
              "div",
              { staticClass: "radioDiv" },
              [
                _vm._v(" 选择导出方法： "),
                _c(
                  "el-radio",
                  {
                    attrs: { label: 0 },
                    model: {
                      value: _vm.exportFileType,
                      callback: function ($$v) {
                        _vm.exportFileType = $$v
                      },
                      expression: "exportFileType",
                    },
                  },
                  [_vm._v("RIS格式")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: 2 },
                    model: {
                      value: _vm.exportFileType,
                      callback: function ($$v) {
                        _vm.exportFileType = $$v
                      },
                      expression: "exportFileType",
                    },
                  },
                  [_vm._v("CSV")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "radioDiv" }, [_vm._v("选择导出记录：")]),
            _c(
              "div",
              { staticClass: "radioDiv" },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { disabled: _vm.selectData.length == 0, label: 1 },
                    model: {
                      value: _vm.exportType,
                      callback: function ($$v) {
                        _vm.exportType = $$v
                      },
                      expression: "exportType",
                    },
                  },
                  [_vm._v("当前选择的")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "radioDiv" },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: 2 },
                    model: {
                      value: _vm.exportType,
                      callback: function ($$v) {
                        _vm.exportType = $$v
                      },
                      expression: "exportType",
                    },
                  },
                  [_vm._v("当前页面所有记录")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "radioDiv" },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: 3 },
                    model: {
                      value: _vm.exportType,
                      callback: function ($$v) {
                        _vm.exportType = $$v
                      },
                      expression: "exportType",
                    },
                  },
                  [
                    _vm._v(" 选择记录 "),
                    _c("el-input-number", {
                      staticStyle: { width: "200px" },
                      attrs: {
                        disabled: _vm.exportType != 3,
                        "step-strictly": "",
                        step: 1,
                        "controls-position": "right",
                        min: 1,
                      },
                      model: {
                        value: _vm.startNo,
                        callback: function ($$v) {
                          _vm.startNo = $$v
                        },
                        expression: "startNo",
                      },
                    }),
                    _vm._v(" 至 "),
                    _c("el-input-number", {
                      staticStyle: { width: "200px" },
                      attrs: {
                        disabled: _vm.exportType != 3,
                        "step-strictly": "",
                        step: 1,
                        "controls-position": "right",
                        min: _vm.startNo,
                        max: _vm.startNo + 499,
                      },
                      model: {
                        value: _vm.endNo,
                        callback: function ($$v) {
                          _vm.endNo = $$v
                        },
                        expression: "endNo",
                      },
                    }),
                    _c("br"),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "26px",
                          color: "#c0c0c0",
                        },
                      },
                      [_vm._v("单次导出上限500条")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.exportData } },
                  [_vm._v("确定")]
                ),
                _c("el-button", { on: { click: _vm.closeDialog } }, [
                  _vm._v("取消"),
                ]),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }