<template>
	<div class="register">
		<div class="register-container" @click.stop="isolateClick()">
			<div class="modalClose" @click="close"></div>
			<div class="title">账号注册</div>
			<div class="container">
				<div class="entity">
					<div class="label required">帐号</div>
					<div class="value">
						<el-input
							v-model="account"
							maxlength="15"
							autocomplete="off"
							placeholder="请输入帐号"
						></el-input>
					</div>
				</div>
				<div>
					<div class="inputHint">输入长度不小于6且不超过15</div>
				</div>
				<div class="entity">
					<div class="label required">名称</div>
					<div class="value">
						<el-input
							v-model="name"
							maxlength="50"
							autocomplete="off"
							placeholder="请输入名称"
						></el-input>
					</div>
				</div>
				<div>
					<div class="inputHint">输入长度不超过50</div>
				</div>
				<div class="entity">
					<div class="label">性别</div>
					<div class="value">
						<el-radio-group v-model="sex">
							<el-radio :label="0">男</el-radio>
							<el-radio :label="1">女</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="entity">
					<div class="label required">密码</div>
					<div class="value">
						<el-input
							type="password"
							autocomplete="off"
							v-model="password"
							maxlength="15"
							placeholder="请输入密码"
						></el-input>
					</div>
				</div>
				<div>
					<div class="inputHint">
						输入长度不小于6且不超过15，且至少包含数字、字母、特殊字符中的两种
					</div>
				</div>
				<div class="entity" style="margin-bottom: 20px;">
					<div class="label required">手机号</div>
					<div class="value">
						<el-input
							v-model="phone"
							maxlength="11"
							autocomplete="off"
							placeholder="请输入手机号"
						></el-input>
					</div>
				</div>
				<div class="entity">
					<div class="label required">职称</div>
					<div class="value">
						<el-input
							v-model="title"
							maxlength="50"
							autocomplete="off"
							placeholder="请输入职称"
						></el-input>
					</div>
				</div>
				<div>
					<div class="inputHint">输入长度不超过50</div>
				</div>
				<div class="entity">
					<div class="label required">所属学院</div>
					<div class="value">
						<el-input
							v-model="university"
							maxlength="50"
							autocomplete="off"
							placeholder="请输入所属学院"
						></el-input>
					</div>
				</div>
				<div>
					<div class="inputHint">输入长度不超过50</div>
				</div>
				<div class="entity">
					<div class="label required">所属单位</div>
					<div class="value">
						<el-input
							v-model="institution"
							maxlength="50"
							autocomplete="off"
							placeholder="请输入所属单位"
						></el-input>
					</div>
				</div>
				<div>
					<div class="inputHint">输入长度不超过50</div>
				</div>
				<!-- <div class="entity verification-code" style="margin-bottom: 20px;">
					<div class="label required">验证码</div>
					<div class="value">
						<el-input
							class="img-code"
							v-model="imgcode"
							maxlength="4"
							autocomplete="off"
							placeholder="请输入验证码"
						></el-input>
						<img @click="refreshCaptcha" :src="captcha" alt="点击刷新" />
					</div>
				</div> -->
				<div class="entity verification-code" style="margin-bottom: 20px;">
					<div class="label required">短信码</div>
					<div class="value">
						<el-input
							class="sms-code"
							v-model="smscode"
							maxlength="100"
							autocomplete="off"
							placeholder="请输入短信码"
						></el-input>
						<el-button type="primary" @click="sendSms()">{{
							buttonText
						}}</el-button>
					</div>
				</div>
				<div>
					<div class="label"></div>
					<div class="value btn">
						<el-button style="width: 300px;" type="primary" @click="submit()"
							>注册</el-button
						>
					</div>
					<div class="label">
						<el-checkbox v-model="isAccept"></el-checkbox> 我已阅读并接受
						<a href="javascript:void(0)" @click="$parent.toUserAgreement()"
							>用户协议</a
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ApiClient, UserCenterApi } from "../api"
import Swal from "sweetalert2"

export default {
	data() {
		return {
			account: "",
			avator: "",
			name: "",
			sex: 0,
			password: "",
			phone: "",
			title: "",
			university: "",
			institution: "",
			smscode: "",
			imgcode: "",
			t: 0,
			api: new UserCenterApi(ApiClient.instance),
			isAccept: true,
			totalTime: 0,
			buttonText: "获取短信码",
			timer: 0
		}
	},
	mounted() {
		// this.refreshCaptcha()
	},
	computed: {
		captcha() {
			return `${process.env.VUE_APP_API_ENDPOINT}/api/UserCenter/captcha?t=${this.t}`
		}
	},
	methods: {
		close() {
			this.$parent.closeRegister()
		},
		submit(event) {
			const _this = this

			if (_this.isAccept) {
				if (!this.account) {
					this.$message({ message: "账号不能为空！", type: "error" })
					return
				}
				if (this.account.length < 6 || this.account.length > 15) {
					this.$message({ message: "账号长度输入不正确", type: "error" })
					return
				}
				if (!this.name) {
					this.$message({ message: "名称不能为空！", type: "error" })
					return
				}
				if (this.name.length > 50) {
					this.$message({ message: "名称长度输入不正确", type: "error" })
					return
				}
				if (!this.password) {
					this.$message({ message: "密码不能为空！", type: "error" })
					return
				}
				if (this.password.length < 6 || this.password.length > 15) {
					this.$message({ message: "密码长度输入不正确", type: "error" })
					return
				}
				if (!this.phone) {
					this.$message({ message: "手机号不能为空！", type: "error" })
					return
				}
				var phoneRe = /^1\d{10}$/
				if (!phoneRe.test(this.phone)) {
					this.$message({ message: "手机号格式输入错误！", type: "error" })
					return
				}
				if (!this.title) {
					this.$message({ message: "职称不能为空！", type: "error" })
					return
				}
				if (this.title.length > 50) {
					this.$message({ message: "职称长度输入不正确", type: "error" })
					return
				}
				if (!this.university) {
					this.$message({ message: "所属学院不能为空！", type: "error" })
					return
				}
				if (this.university.length > 50) {
					this.$message({ message: "所属学院长度输入不正确", type: "error" })
					return
				}
				if (!this.institution) {
					this.$message({ message: "所属单位不能为空！", type: "error" })
					return
				}
				if (this.institution.length > 50) {
					this.$message({ message: "所属单位长度输入不正确", type: "error" })
					return
				}
				// if (!this.imgcode) {
				// 	this.$message({ message: "验证码不能为空！", type: "error" })
				// 	return
				// }
				if (!this.smscode) {
					this.$message({ message: "短信码不能为空！", type: "error" })
					return
				}
				this.api.apiUserCenterSigninPost(
					{
						account: this.account,
						password: this.password,
						nickName: this.name,
						sex: this.sex,
						phone: this.phone,
						title: this.title,
						university: this.university,
						institution: this.institution,
						smsCode: this.smscode
					},
					(e, data, resp) => {
						if (resp.ok && data.success) {
							Swal.fire("注册成功").then(() => window.location.reload())
						} else {
							var err = this.$options.filters.getResponseErrors(resp)
							Swal.fire({ icon: "error", html: err })
						}
					}
				)
			} else {
				_this.$message.error("请认真阅读用户协议并勾选同意！")
			}
		},
		isolateClick() {
			// do nothing
		},
		refreshCaptcha() {
			this.t = Date.now()
		},
		sendSms() {
			if (this.totalTime > 0) {
				return
			}
			// if (!this.imgcode || !this.phone) {
			// 	Swal.fire({
			// 		text: "请输入手机号和图形验证码！",
			// 		icon: "error"
			// 	})
			// 	return
			// }
			if (!this.phone) {
				Swal.fire({
					text: "请输入手机号！",
					icon: "error"
				})
				return
			}
			var phoneRe = /^1\d{10}$/
			if (!phoneRe.test(this.phone)) {
				Swal.fire({
					text: "手机号格式输入错误！",
					icon: "error"
				})
				return
			}

			// 暂时移除图片验证码
			// var captchaSha1 = Cookies.get("captacha_value")
			var captchaSha1 = ""
			this.api.apiUserCenterSmscaptchaGet(
				this.phone,
				{ captcha: this.imgcode, captchaSha1: captchaSha1 },
				(e, data, resp) => {
					Swal.fire(data.result)
					if (data.result === "短信码已发送") {
						this.totalTime = 60
						this.timer = window.setInterval(() => {
							this.buttonText = this.totalTime + "s后重新发送"
							this.totalTime--
							if (this.totalTime < 0) {
								this.totalTime = 0
								this.buttonText = "获取短信码"
								window.clearInterval(this.timer)
							}
						}, 1000)
					}
				}
			)
		}
	}
}
</script>

<style lang="less" scoped>
.to-useragreement-panel {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(200, 200, 200, 0.5);
	z-index: 100;
	display: flex;
}

.inputHint {
	margin: 5px 0 10px 110px;
}

.to-useragreement-panel .useragreement {
	margin: auto;
}
.useragreement-container {
	width: 350px;
	height: 350px;
	display: inline-block;
	text-align: center;
	border-radius: 6px;
	background-color: #ffffff;
}

.register-container {
	width: 700px;
	height: 820px;
	display: inline-block;
	text-align: center;
	border-radius: 6px;
	background-color: #ffffff;
	overflow: auto;
	position: relative;
}

.title {
	margin: 20px auto 20px auto;
	line-height: 20px;
	font-size: 20px;
	.c-font-family-PF();
	font-weight: 500;
	color: #333333;
}

.container {
	background: #ffffff;
	box-shadow: 0px 0px 6px 0px rgba(4, 0, 0, 0.07);
	padding: 20px 70px;
	.entity {
		display: flex;
		// width: 470px;
		height: 40px;
		// margin-bottom: 20px;
		.label {
			display: flex;

			width: 70px;
			.c-font-size-14();
			.c-font-family-PF();
			font-weight: 500;
			color: #333333;

			margin-right: 30px;
			align-items: center;
			justify-content: flex-end;
		}
		.value {
			display: flex;
			align-items: center;
			width: 400px;
		}

		.btn {
			display: flex;
			justify-content: center;
			button {
				width: 300px;
			}
		}
	}

	.verification-code {
		.value {
			.sms-code,
			.img-code {
				width: 300px !important;
				margin-right: 5px;
			}
			img,
			button {
				width: 120px;
			}
		}
	}
}
</style>
