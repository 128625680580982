var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "side-float-container" }, [
    _c("div", { staticClass: "side-float-panel" }, [
      _c("div", { staticClass: "message-trigger" }, [
        _c("img", {
          attrs: { src: require("../assets/message.png") },
          on: {
            click: function ($event) {
              return _vm.openModal()
            },
          },
        }),
      ]),
      _c(
        "div",
        {
          staticClass: "slide-top",
          on: {
            click: function ($event) {
              return _vm.slideToTop()
            },
          },
        },
        [_c("img", { attrs: { src: require("../assets/arrow-up.png") } })]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }