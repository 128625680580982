/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {VideoManagerItemDto} from './VideoManagerItemDto';

/**
 * The VideoManagerListDto model module.
 * @module model/VideoManagerListDto
 * @version v1
 */
export class VideoManagerListDto {
  /**
   * Constructs a new <code>VideoManagerListDto</code>.
   * @alias module:model/VideoManagerListDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>VideoManagerListDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/VideoManagerListDto} obj Optional instance to populate.
   * @return {module:model/VideoManagerListDto} The populated <code>VideoManagerListDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new VideoManagerListDto();
      if (data.hasOwnProperty('records'))
        obj.records = ApiClient.convertToType(data['records'], [VideoManagerItemDto]);
      if (data.hasOwnProperty('total'))
        obj.total = ApiClient.convertToType(data['total'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Array.<module:model/VideoManagerItemDto>} records
 */
VideoManagerListDto.prototype.records = undefined;

/**
 * @member {Number} total
 */
VideoManagerListDto.prototype.total = undefined;

