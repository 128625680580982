/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ClinicalDetailRecruitmentDto model module.
 * @module model/ClinicalDetailRecruitmentDto
 * @version v1
 */
export class ClinicalDetailRecruitmentDto {
  /**
   * Constructs a new <code>ClinicalDetailRecruitmentDto</code>.
   * @alias module:model/ClinicalDetailRecruitmentDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ClinicalDetailRecruitmentDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ClinicalDetailRecruitmentDto} obj Optional instance to populate.
   * @return {module:model/ClinicalDetailRecruitmentDto} The populated <code>ClinicalDetailRecruitmentDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ClinicalDetailRecruitmentDto();
      if (data.hasOwnProperty('recruitmentStatus'))
        obj.recruitmentStatus = ApiClient.convertToType(data['recruitmentStatus'], 'String');
      if (data.hasOwnProperty('estimatedEnrollment'))
        obj.estimatedEnrollment = ApiClient.convertToType(data['estimatedEnrollment'], 'String');
      if (data.hasOwnProperty('estimatedStudyCompletionDate'))
        obj.estimatedStudyCompletionDate = ApiClient.convertToType(data['estimatedStudyCompletionDate'], 'String');
      if (data.hasOwnProperty('eligibilityCriteria'))
        obj.eligibilityCriteria = ApiClient.convertToType(data['eligibilityCriteria'], 'String');
      if (data.hasOwnProperty('ages'))
        obj.ages = ApiClient.convertToType(data['ages'], 'String');
      if (data.hasOwnProperty('contacts'))
        obj.contacts = ApiClient.convertToType(data['contacts'], 'String');
      if (data.hasOwnProperty('listedLocationCountries'))
        obj.listedLocationCountries = ApiClient.convertToType(data['listedLocationCountries'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} recruitmentStatus
 */
ClinicalDetailRecruitmentDto.prototype.recruitmentStatus = undefined;

/**
 * @member {String} estimatedEnrollment
 */
ClinicalDetailRecruitmentDto.prototype.estimatedEnrollment = undefined;

/**
 * @member {String} estimatedStudyCompletionDate
 */
ClinicalDetailRecruitmentDto.prototype.estimatedStudyCompletionDate = undefined;

/**
 * @member {String} eligibilityCriteria
 */
ClinicalDetailRecruitmentDto.prototype.eligibilityCriteria = undefined;

/**
 * @member {String} ages
 */
ClinicalDetailRecruitmentDto.prototype.ages = undefined;

/**
 * @member {String} contacts
 */
ClinicalDetailRecruitmentDto.prototype.contacts = undefined;

/**
 * @member {String} listedLocationCountries
 */
ClinicalDetailRecruitmentDto.prototype.listedLocationCountries = undefined;

