/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * Enum class HttpCodeEnum.
 * @enum {Number}
 * @readonly
 */
const HttpCodeEnum = {
  /**
   * value: 100
   * @const
   */
  _100: 100,

  /**
   * value: 200
   * @const
   */
  _200: 200,

  /**
   * value: 300
   * @const
   */
  _300: 300,

  /**
   * value: 500
   * @const
   */
  _500: 500,

  /**
   * Returns a <code>HttpCodeEnum</code> enum value from a JavaScript object name.
   * @param {Object} data The plain JavaScript object containing the name of the enum value.
  * @return {module:model/HttpCodeEnum} The enum <code>HttpCodeEnum</code> value.
   */
  constructFromObject: function(object) {
    return object;
  }
};

export {HttpCodeEnum};