/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ForgetPasswordRequest model module.
 * @module model/ForgetPasswordRequest
 * @version v1
 */
export class ForgetPasswordRequest {
  /**
   * Constructs a new <code>ForgetPasswordRequest</code>.
   * @alias module:model/ForgetPasswordRequest
   * @class
   * @param account {String} 
   * @param phone {String} 
   * @param smsCode {String} 
   * @param password {String} 
   */
  constructor(account, phone, smsCode, password) {
    this.account = account;
    this.phone = phone;
    this.smsCode = smsCode;
    this.password = password;
  }

  /**
   * Constructs a <code>ForgetPasswordRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ForgetPasswordRequest} obj Optional instance to populate.
   * @return {module:model/ForgetPasswordRequest} The populated <code>ForgetPasswordRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ForgetPasswordRequest();
      if (data.hasOwnProperty('account'))
        obj.account = ApiClient.convertToType(data['account'], 'String');
      if (data.hasOwnProperty('phone'))
        obj.phone = ApiClient.convertToType(data['phone'], 'String');
      if (data.hasOwnProperty('smsCode'))
        obj.smsCode = ApiClient.convertToType(data['smsCode'], 'String');
      if (data.hasOwnProperty('password'))
        obj.password = ApiClient.convertToType(data['password'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} account
 */
ForgetPasswordRequest.prototype.account = undefined;

/**
 * @member {String} phone
 */
ForgetPasswordRequest.prototype.phone = undefined;

/**
 * @member {String} smsCode
 */
ForgetPasswordRequest.prototype.smsCode = undefined;

/**
 * @member {String} password
 */
ForgetPasswordRequest.prototype.password = undefined;

