/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The News model module.
 * @module model/News
 * @version v1
 */
export class News {
  /**
   * Constructs a new <code>News</code>.
   * @alias module:model/News
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>News</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/News} obj Optional instance to populate.
   * @return {module:model/News} The populated <code>News</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new News();
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('date'))
        obj._date = ApiClient.convertToType(data['date'], 'String');
      if (data.hasOwnProperty('link'))
        obj.link = ApiClient.convertToType(data['link'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} title
 */
News.prototype.title = undefined;

/**
 * @member {String} _date
 */
News.prototype._date = undefined;

/**
 * @member {String} link
 */
News.prototype.link = undefined;

