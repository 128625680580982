var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item-list-panel" }, [
    _c("div", { staticClass: "top-bar-container" }, [
      _c("div", { staticClass: "top-bar" }, [
        _c("div", { staticClass: "result" }, [
          _c("div", { staticClass: "label count-desc" }, [
            _c("div", { staticClass: "label" }, [_vm._v("当前结果")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(" " + _vm._s(_vm.resultAmount) + " "),
            ]),
            _c("div", { staticClass: "unit" }, [_vm._v("条")]),
          ]),
          _c("div", { staticClass: "textLabel" }, [
            _vm._v("按公布日期倒序排列"),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "item-list" },
      _vm._l(_vm.datalist, function (pat, i) {
        return _c("div", { key: i, staticClass: "item-container" }, [
          _c("div", { staticClass: "item contentItem" }, [
            _c("div", { staticClass: "figure" }, [
              !pat.cover
                ? _c("img", {
                    staticClass: "defaultImg",
                    attrs: { src: require("@/assets/sequence-default2.png") },
                  })
                : _c("img", { attrs: { src: pat.cover } }),
            ]),
            _c(
              "div",
              { staticClass: "location" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "entity entity-title",
                    attrs: { to: "/patent/" + pat.patentID },
                  },
                  [_vm._v(_vm._s(pat.title || ""))]
                ),
                _c("div", { staticClass: "entity-line" }, [
                  _c("div", { staticClass: "entity" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("申请号：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(" " + _vm._s(pat.applicationNumber) + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "entity" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("公布日期：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("dateFormat")(
                              pat.publicationDate,
                              "YYYY-MM-DD"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "entity" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("发明人：")]),
                  _c(
                    "div",
                    {
                      staticClass: "value",
                      staticStyle: {
                        "word-break": "break-all",
                        "word-wrap": "break-word",
                      },
                    },
                    [_vm._v(" " + _vm._s(pat.inventor) + " ")]
                  ),
                ]),
                _c("div", { staticClass: "entity-line" }, [
                  _c("div", { staticClass: "entity" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("专利权人：")]),
                    _c("div", { staticClass: "licensor" }, [
                      _vm._v(" " + _vm._s(pat.licensor) + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "entity" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("专利状态：")]),
                    _c("div", { staticClass: "status" }, [
                      _vm._v(" " + _vm._s(pat.statusDescription) + " "),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "entity abstract" }, [
                  _vm._v(" " + _vm._s(pat._abstract) + " "),
                ]),
              ],
              1
            ),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }