/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {VideoManagerListDtoResponse} from '../model/VideoManagerListDtoResponse';

/**
* VideoManager service.
* @module api/VideoManagerApi
* @version v1
*/
export class VideoManagerApi {

    /**
    * Constructs a new VideoManagerApi. 
    * @alias module:api/VideoManagerApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiVideoManagerVideosGet operation.
     * @callback moduleapi/VideoManagerApi~apiVideoManagerVideosGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/VideoManagerListDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 视频管理列表
     * @param {Object} opts Optional parameters
     * @param {String} opts.title 
     * @param {String} opts.lecturer 
     * @param {Number} opts.index 
     * @param {Number} opts.size 
     * @param {module:api/VideoManagerApi~apiVideoManagerVideosGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiVideoManagerVideosGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'Title': opts['title'],'Lecturer': opts['lecturer'],'Index': opts['index'],'Size': opts['size']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = VideoManagerListDtoResponse;

      return this.apiClient.callApi(
        '/api/VideoManager/Videos', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}