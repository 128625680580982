var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "team-list-panel" }, [
    _c(
      "div",
      { staticClass: "team-list" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.dataList,
              border: "",
              "element-loading-text": "结果加载中。。。",
            },
            on: { "row-click": _vm.alignmentsShow },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "name", align: "center", label: "名称" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("a", {
                        attrs: {
                          href: "javascript:void(0)",
                          title: "点击查看Alignments",
                        },
                        domProps: { innerHTML: _vm._s(scope.row.name || "-") },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "target", align: "center", label: "靶标" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("p", {
                        domProps: { innerHTML: _vm._s(row.target || "-") },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "affinity", align: "center", label: "亲和力" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            row.affinity && parseFloat(row.affinity) !== 0
                              ? row.affinity
                              : "-"
                          ),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "lengh", align: "center", label: "长度" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            row.lengh && row.lengh > 0 ? row.lengh : "-"
                          ),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "similarity",
                align: "center",
                label: "相似度(%)",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.row.similarity && scope.row.similarity > 0
                              ? (scope.row.similarity * 100).toFixed(2)
                              : "-"
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }