<template>
	<div class="searchbox-con">
		<div class="control-con">
			<router-link :to="'/'">
				<img src="../assets/logo.png" class="logo" />
			</router-link>
			<div class="input-con">
				<!-- <input
					type="text"
					class="inputBox"
					v-model="keyword"
					placeholder="请输入靶标名称"
					@keyup.enter="search"
				/> -->
				<!-- <el-select v-model="keyword" popper-class="searchBox"
					class="inputBox" filterable remote :remote-method="remoteList" placeholder="请输入靶标名称">
					<el-option
					v-for="(item, index) in showList"
					:key="index"
					:value="item.name">
					<span v-html="item.value"></span>
					</el-option>
				</el-select> -->
				<el-popover placement="bottom" width="525" trigger="click" :disabled="showList.length <= 0"
				v-model="selectExpress">
                    <div style="width:100%;max-height:200px;overflow-y:auto;">
                        <div v-for="(item,index) of showList" :key="index">
                            <div v-if="index>0" style="width: 100%;border-top: 1px #DFDFDF solid;"></div>
                            <div class="targetSelectOptions" v-html="item.value" @click="selectTarget(item)"></div>
                        </div>
                    </div>
					<input slot="reference" type="text" class="hearderTargetSearchBox" v-model="keyword" placeholder="请输入靶标名称" @keyup.enter="search" />
                </el-popover>
				<button class="btnDoSearch" @click="search">搜索</button>
			</div>
		</div>
	</div>
</template>
<script>
import { ApiClient, AptamerApi } from "../api"
export default {
	name: "SearchBox",
	props: ["db_type"],
	data() {
		return {
			api: new AptamerApi(ApiClient.instance),
			toSearch: false,
			types: [
				{
					db_name: "核酸适体评价系统",
					db_type: "0",
					db_place_holder: "请输入靶标名称",
					db_route: "/AptamerEvaluationList"
				},
				{
					db_name: "核酸适体智能筛选系统",
					db_type: "1",
					db_place_holder: "请输入靶标名称",
					db_route: "/IntelligentFiltering"
				}
			],
			targetList: [],
			showList: [],
			keyword: "",
			cur_type: this.db_type,
			db_place_holder: "",
			selectExpress: false,
			loadTimes: 0
		}
	},
	created() {
		this.getTargets()
	},
	mounted() {
		if (!this.db_type) {
			var dbType = this.$route?.meta?.default_db_type || "0"
			this.cur_type = dbType
		}
		this.setPlaceHolder()
	},
	watch: {
		$route(to, from) {
			var dbType = to?.meta?.default_db_type || "0"
			this.cur_type = dbType
			this.setPlaceHolder()
		},
		keyword() {
			if (this.keyword) {
				// 初始化时设置不打开popover
				if (this.loadTimes > 0) {
					this.selectExpress = true
				} else {
					this.loadTimes++
				}
				this.showList = []
				var list = this.targetList.filter(a => a.formatCol.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0 || a.name.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0)
				list = list.map(a => {
					var res = a
					this.$set(res, "queryIndex", res.formatCol.toLowerCase().indexOf(this.keyword.toLowerCase()))
					this.$set(res, "strLen", res.formatCol.length)
					return res
				})
				list = list.sort((a, b) => a.queryIndex - b.queryIndex || a.strLen - b.strLen)

				this.showList = list
			} else {
				this.showList = JSON.parse(JSON.stringify(this.targetList))
			}
		}
	},
	methods: {
		search() {
			var tar = []
			var _keyword = ""
			if (this.keyword) {
				tar = this.targetList.filter(a => a.formatCol === this.keyword.toLowerCase() || a.name.toLowerCase() === this.keyword.toLowerCase())
				console.log(tar)
				if (!tar || tar.length <= 0) {
					this.$message({ message: "输入靶标未存在！", type: "error" })
					return
				}
				_keyword = encodeURIComponent(tar[0].name)
				// this.$router.push("AptamerEvaluationList?keyword=" + _keyword)
				var route = this.types.find((e) => e.db_type === this.cur_type).db_route
				this.$router.push(route + "?keyword=" + (_keyword || ""))
				window.location.reload()
			} else {
				this.$message({ message: "请输入靶标！", type: "warning" })
			}
		},
		db_type_change() {
			this.setPlaceHolder()
		},
		setPlaceHolder() {
			this.db_place_holder = this.types.find(
				(e) => e.db_type === this.cur_type
			).db_place_holder
		},
		setKeyword() {
			this.keyword = this.$route.query.keyword
		},
		getTargets() {
			this.api.apiAptamerTargetsdataGet((err, data, resp) => {
				if (err) {
					console.log(err)
				}
				if (data?.success && data?.result) {
					this.targetList = this.$options.filters.formatTargetList(data.result)
					// this.showList = this.targetList
					// targetList查询成功之后设置keyWord
					this.setKeyword()
				}
			})
		},
		// remoteList(query) {
		// 	this.keyword = query
		// 	var ele = document.getElementsByClassName("el-select-dropdown__wrap")
		// 	// console.log(ele[0])
		// 	if (ele && ele.length > 0) {
		// 		ele[0].scrollTop = 0
		// 	}
		// 	if (query) {
		// 		this.showList = []
		// 		var list = this.targetList.filter(a => a.formatCol.indexOf(query.toLowerCase()) >= 0)
		// 		list = list.map(a => {
		// 			var res = a
		// 			this.$set(res, "queryIndex", res.formatCol.indexOf(query.toLowerCase()))
		// 			this.$set(res, "strLen", res.formatCol.length)
		// 			return res
		// 		})
		// 		list = list.sort((a, b) => a.queryIndex - b.queryIndex || a.strLen - b.strLen)

		// 		this.showList = list
		// 	} else {
		// 		this.showList = this.targetList
		// 	}
		// },
		selectTarget(val) {
			// console.log(val)
			this.keyword = val.name
		}
	}
}
</script>
<style scoped lang="less">
.searchbox-con {
	width: 1170px;
	height: 96px;
	background: white;
	margin: 20px auto 0px auto;
	display: flex;
	justify-content: center;
	.control-con {
		width: 97%;
		display: flex;
		align-items: center;
		.input-con {
			border-radius: 2px;
			border: 1px solid #3463ea;
			background: #f1f2f7;
			width: 610px;
			height: 46px;
			margin-left: 180px;
			box-sizing: content-box;
			display: flex;
			align-items: stretch;
			position: relative;

			.inputBox {
				width: 100%;
				height: 30px;
				border: none;
				// margin: 8px;
				// margin-left: 15px;
				background: none;
				.c-font-size-16();
			}
			.inputBox:focus {
				outline: none;
			}
			.btnAdv {
				// 暂时隐藏
				cursor: pointer;
				.c-font-size-14();
				margin-left: 10px;
				text-decoration: none;
				color: #3463ea;
				bottom: -20px;
				width: 60px;
				position: absolute;
			}
			.typeSelect {
				width: 164px;
			}

			.btnDoSearch {
				height: 46px;
				width: 100px;
				border: none;
				background: #3462e9;
				color: white;
				cursor: pointer;
				.c-font-size-16();
			}

			/deep/ .el-input__inner {
				border: none;
				background: none;
				// border-left: 1px solid #3463ea !important;
				border-radius: 0;
				.c-font-size-16();
				height: 46px;
			}
		}
	}
}
</style>
