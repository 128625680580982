/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {EvaluateContrastInfoResponse} from '../model/EvaluateContrastInfoResponse';
import {EvaluateContrastResultResponse} from '../model/EvaluateContrastResultResponse';
import {EvaluationOtherInfoReturnResponse} from '../model/EvaluationOtherInfoReturnResponse';
import {OrderByDescEnum} from '../model/OrderByDescEnum';
import {OrderByEnum} from '../model/OrderByEnum';
import {SequenceListDtoResponse} from '../model/SequenceListDtoResponse';

/**
* Sequence service.
* @module api/SequenceApi
* @version v1
*/
export class SequenceApi {

    /**
    * Constructs a new SequenceApi. 
    * @alias module:api/SequenceApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiSequenceEvaluatecontrastGet operation.
     * @callback moduleapi/SequenceApi~apiSequenceEvaluatecontrastGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/EvaluateContrastResultResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 靶标查询序列对比
     * @param {Object} opts Optional parameters
     * @param {String} opts.target 输入序列靶标
     * @param {module:api/SequenceApi~apiSequenceEvaluatecontrastGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiSequenceEvaluatecontrastGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'target': opts['target']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EvaluateContrastResultResponse;

      return this.apiClient.callApi(
        '/api/Sequence/evaluatecontrast', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiSequenceEvaluatecontrastdetailGet operation.
     * @callback moduleapi/SequenceApi~apiSequenceEvaluatecontrastdetailGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/EvaluateContrastInfoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 评价系统序列对比详情
     * @param {Object} opts Optional parameters
     * @param {String} opts.queryID 
     * @param {String} opts.sbjctID 
     * @param {module:api/SequenceApi~apiSequenceEvaluatecontrastdetailGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiSequenceEvaluatecontrastdetailGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'queryID': opts['queryID'],'sbjctID': opts['sbjctID']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EvaluateContrastInfoResponse;

      return this.apiClient.callApi(
        '/api/Sequence/evaluatecontrastdetail', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiSequenceEvaluatecontrastotherinfoGet operation.
     * @callback moduleapi/SequenceApi~apiSequenceEvaluatecontrastotherinfoGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/EvaluationOtherInfoReturnResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 评价系统-序列对比-其他数据
     * @param {Object} opts Optional parameters
     * @param {String} opts.ID 
     * @param {Number} opts.pageIndex 
     * @param {Number} opts.pageSize 
     * @param {module:model/OrderByEnum} opts.orderBy 
     * @param {module:model/OrderByDescEnum} opts.orderDesc 
     * @param {module:api/SequenceApi~apiSequenceEvaluatecontrastotherinfoGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiSequenceEvaluatecontrastotherinfoGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'ID': opts['ID'],'PageIndex': opts['pageIndex'],'PageSize': opts['pageSize'],'OrderBy': opts['orderBy'],'OrderDesc': opts['orderDesc']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = EvaluationOtherInfoReturnResponse;

      return this.apiClient.callApi(
        '/api/Sequence/evaluatecontrastotherinfo', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiSequenceGetseqdataGet operation.
     * @callback moduleapi/SequenceApi~apiSequenceGetseqdataGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SequenceListDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 序列对比查询接口
     * @param {Object} opts Optional parameters
     * @param {String} opts.keyword 输入对比序列号
     * @param {module:api/SequenceApi~apiSequenceGetseqdataGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiSequenceGetseqdataGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'keyword': opts['keyword']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SequenceListDtoResponse;

      return this.apiClient.callApi(
        '/api/Sequence/getseqdata', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}