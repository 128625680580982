<template>
	<div class="filter-panel-container">
		<form @submit.prevent="submit" novalidate="true">
			<div class="main-filter-panel">
				<!-- <div class="filter">
					<div class="label">国家</div>
					<div class="input">
						<input
							type="text"
							name="countryFilter"
							v-model="country"
							maxlength="20"
						/>
					</div>
				</div> -->
				<!-- <div class="filter">
					<div class="label">研究领域</div>
					<div class="input">
						<input
							type="text"
							name="researchAreaFilter"
							v-model="area"
							maxlength="20"
						/>
					</div>
				</div> -->
			</div>
			<div class="funding-panel">
				<div class="label">经费筛选</div>
				<div class="select-panel">
					<el-radio v-model="budgetRange" label="less10"> 小于10万 </el-radio>
					<el-radio v-model="budgetRange" label="10to100">
						10万-100万
					</el-radio>
					<el-radio v-model="budgetRange" label="more100"> 大于100万 </el-radio>
				</div>
			</div>
			<div class="control-panel">
				<div class="submit">
					<button type="submit" @click="submit">筛选</button>
				</div>
				<div class="reset">
					<button type="reset" @click="reset">重置</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
export default {
	data() {
		return {
			country: "",
			area: "",
			budgetRange: ""
		}
	},
	computed: {
		minBudget() {
			if (this.budgetRange === "less10") {
				return null
			}
			if (this.budgetRange === "10to100") {
				return 100000
			}
			if (this.budgetRange === "more100") {
				return 1000000
			}
			return null
		},
		maxBudget() {
			if (this.budgetRange === "less10") {
				return 100000
			}
			if (this.budgetRange === "10to100") {
				return 1000000
			}
			if (this.budgetRange === "more100") {
				return null
			}
			return null
		}
	},
	methods: {
		submit(event) {
			this.$parent.searchType = 2
			this.$parent.replaceUrl()
			event.preventDefault()
		},
		reset() {
			this.country = ""
			this.area = ""
			this.budgetRange = ""
			this.$parent.replaceUrl()
		}
	}
}
</script>

<style scoped lang="less">
.filter-panel-container {
	width: 100%;
	background-color: #ffffff;
	display: inline-block;
}

.main-filter-panel {
	margin: 10px;
	border-bottom: 1px solid #f0f0f0;
}
/deep/.el-radio {
	margin-top: 12px;
}
.filter {
	margin-bottom: 10px;
	text-align: left;
}
input {
	outline: none;
	padding: 0 10px;
}
input:focus {
	border-style: solid;
	border-color: #3463ea;
	box-shadow: 0 0 3px #3463ea;
}
.filter .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	line-height: 20px;
	color: #333333;
}

.filter .input input {
	height: 30px;
	width: 98%;
	background-color: #f1f2f7;
	border-radius: 2px;
	border: none;
	text-indent: 2px;
}

.funding-panel {
	margin: 10px;
	text-align: left;
}

.funding-panel .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	line-height: 20px;
	color: #333333;
}

.funding-panel .select-panel {
	height: 90px;
	display: flex;
	flex-direction: column;
}

.funding-panel .select-panel .input {
	height: 20px;
	margin-top: 10px;
	margin-left: 6px;
	display: flex;
	flex-direction: row;
}

.funding-panel .select-panel .input input {
	margin: auto 4px;
}

.funding-panel .select-panel .input .label {
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	color: #333333;
	line-height: 20px;
}

.control-panel {
	margin: 10px;
	display: flex;
	flex-direction: row;
}

.submit button {
	width: 160px;
	height: 30px;
	color: #ffffff;
	background-color: #3463ea;
	border-radius: 2px;
	border: none;
}

.reset {
	margin-left: auto;
	margin-right: 0;
}

.reset button {
	width: 100px;
	height: 30px;
	color: #333333;
	background-color: #cbcbcb;
	border-radius: 2px;
	border: none;
	text-align: center;
}
</style>
