/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The AptamerForContrast model module.
 * @module model/AptamerForContrast
 * @version v1
 */
export class AptamerForContrast {
  /**
   * Constructs a new <code>AptamerForContrast</code>.
   * @alias module:model/AptamerForContrast
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AptamerForContrast</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AptamerForContrast} obj Optional instance to populate.
   * @return {module:model/AptamerForContrast} The populated <code>AptamerForContrast</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AptamerForContrast();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('lengh'))
        obj.lengh = ApiClient.convertToType(data['lengh'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('sequence'))
        obj.sequence = ApiClient.convertToType(data['sequence'], 'String');
      if (data.hasOwnProperty('target'))
        obj.target = ApiClient.convertToType(data['target'], 'String');
      if (data.hasOwnProperty('similarity'))
        obj.similarity = ApiClient.convertToType(data['similarity'], 'Number');
      if (data.hasOwnProperty('affinity'))
        obj.affinity = ApiClient.convertToType(data['affinity'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
AptamerForContrast.prototype.id = undefined;

/**
 * @member {String} lengh
 */
AptamerForContrast.prototype.lengh = undefined;

/**
 * @member {String} name
 */
AptamerForContrast.prototype.name = undefined;

/**
 * @member {String} sequence
 */
AptamerForContrast.prototype.sequence = undefined;

/**
 * @member {String} target
 */
AptamerForContrast.prototype.target = undefined;

/**
 * @member {Number} similarity
 */
AptamerForContrast.prototype.similarity = undefined;

/**
 * @member {String} affinity
 */
AptamerForContrast.prototype.affinity = undefined;

