<template>
	<div class="whole">
		<div class="search">
			高级搜索
			<img src="@/assets/fork.png" @click="cancel" />
		</div>
		<div class="header">
			<div class="header1">
				<div :class="{ show: int == 0 }" class="field" @click="active(0)">
					字段搜索
				</div>
				<div :class="{ show: int == 1 }" class="field" @click="active(1)">
					序列对比
				</div>
			</div>
		</div>
		<div v-if="int == 0" class="nav">
			<div class="nav1">
				<p>选择数据库 :</p>
				<div class="nav-one">
					<el-select
						class="typeSelect"
						v-model="cur_type"
						@change="db_type_change"
					>
						<el-option
							v-for="t in types"
							:key="t.db_type"
							:label="t.db_name"
							:value="t.db_type"
						></el-option>
					</el-select>
				</div>
			</div>
			<div class="query">
				<p>查询字段 :</p>
				<div v-for="(item, index) in list" :key="index" class="query1">
					<div>
						<img
							src="@/assets/remove.png"
							class="query-img"
							@click="del(item, index)"
						/>
					</div>

					<el-select
						v-if="cur_type == 0"
						v-model="item.name"
						style="width: 290px; margin-right: 10px;"
						@change="changeOpion"
					>
						<el-option
							v-for="t in aptamerCols"
							:key="t.db_type"
							:label="t.db_name"
							:value="t.db_type"
							:disabled="t.db_bled"
						></el-option>
					</el-select>

					<el-select
						v-else-if="cur_type == 1"
						v-model="item.name"
						style="width: 290px; margin-right: 10px;"
						@change="changeOpion"
					>
						<el-option
							v-for="t in articleCols"
							:key="t.db_type"
							:label="t.db_name"
							:value="t.db_type"
							:disabled="t.db_bled"
						></el-option>
					</el-select>

					<el-select
						v-else-if="cur_type == 2"
						v-model="item.name"
						style="width: 290px; margin-right: 10px;"
						@change="changeOpion"
					>
						<el-option
							v-for="t in clinicalCols"
							:key="t.db_type"
							:label="t.db_name"
							:value="t.db_type"
							:disabled="t.db_bled"
						></el-option>
					</el-select>

					<el-select
						v-else-if="cur_type == 3"
						v-model="item.name"
						style="width: 290px; margin-right: 10px;"
						@change="changeOpion"
					>
						<el-option
							v-for="t in teamCols"
							:key="t.db_type"
							:label="t.db_name"
							:value="t.db_type"
							:disabled="t.db_bled"
						></el-option>
					</el-select>

					<el-select
						v-else-if="cur_type == 4"
						v-model="item.name"
						style="width: 290px; margin-right: 10px;"
						@change="changeOpion"
					>
						<el-option
							v-for="t in projectCols"
							:key="t.db_type"
							:label="t.db_name"
							:value="t.db_type"
							:disabled="t.db_bled"
						></el-option>
					</el-select>

					<el-select
						v-else-if="cur_type == 5"
						v-model="item.name"
						style="width: 290px; margin-right: 10px;"
						@change="changeOpion"
					>
						<el-option
							v-for="t in patentCols"
							:key="t.db_type"
							:label="t.db_name"
							:value="t.db_type"
							:disabled="t.db_bled"
						></el-option>
					</el-select>

					<el-input
						placeholder="请输入内容"
						style="width: 600px;"
						v-model="item.value"
					></el-input>
				</div>
			</div>
			<div class="footer">
				<div>
					<el-button
						@click="addList"
						icon="el-icon-circle-plus-outline"
						style="color: #416deb; border: 1px solid #416deb;"
						:disabled="addBled"
						>添加行</el-button
					>
				</div>
				<div>
					<el-button
						@click="remove"
						:disabled="bled"
						style="background: #edeff4;"
						>清除</el-button
					>
					<el-button type="primary" style="width: 180px;" @click="query"
						>查询</el-button
					>
				</div>
			</div>
		</div>
		<div v-if="int == 1" class="nav2">
			<div>
				<p>输入序列 :</p>
				<el-input
					type="textarea"
					v-model="seqstr"
					placeholder="请输入序列"
				></el-input>
			</div>
			<div class="nav-bottom">
				<el-button type="primary" style="width: 180px;" @click="toseq"
					>查询</el-button
				>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "search",
	props: ["toSearch", "db_type"],
	data() {
		return {
			int: 0,
			cur_type: "",
			bled: false,
			props: ["toSearch"],
			seqstr: "",
			list: [
				{
					value: "",
					name: ""
				}
			],
			addBled: false,
			name: "主题",
			aptamerCols: [
				{
					db_name: "适体名称",
					db_type: "name",
					db_bled: false
				},
				{
					db_name: "靶标名称",
					db_type: "targetName",
					db_bled: false
				},
				{
					db_name: "样本名称",
					db_type: "sampleName",
					db_bled: false
				},
				// {
				// 	db_name: "样本类型",
				// 	db_type: "sampletype"
				// },
				{
					db_name: "筛选方法",
					db_type: "methods",
					db_bled: false
				},
				{
					db_name: "发现者",
					db_type: "finder",
					db_bled: false
				}
			],
			articleCols: [
				{
					db_name: "标题",
					db_type: "title",
					db_bled: false
				},
				{
					db_name: "摘要",
					db_type: "abstract",
					db_bled: false
				},
				{
					db_name: "作者",
					db_type: "author",
					db_bled: false
				}
			],
			clinicalCols: [
				{
					db_name: "临床标题",
					db_type: "title",
					db_bled: false
				},
				{
					db_name: "适应症",
					db_type: "conditions",
					db_bled: false
				},
				{
					db_name: "描述性信息",
					db_type: "descriptiveInformation",
					db_bled: false
				},
				{
					db_name: "研究单位",
					db_type: "organization",
					db_bled: false
				}
			],
			teamCols: [
				{
					db_name: "团队名称/负责人名称",
					db_type: "name"
				},
				{
					db_name: "单位名称",
					db_type: "institution",
					db_bled: false
				},
				{
					db_name: "国家",
					db_type: "country",
					db_bled: false
				}
			],
			projectCols: [
				{
					db_name: "项目标题",
					db_type: "title",
					db_bled: false
				},
				{
					db_name: "关键词",
					db_type: "keywords",
					db_bled: false
				},
				{
					db_name: "负责人",
					db_type: "leader",
					db_bled: false
				},
				{
					db_name: "负责人所属机构",
					db_type: "leaderInstitution",
					db_bled: false
				},
				{
					db_name: "合作机构",
					db_type: "sponsorInstitution",
					db_bled: false
				}
			],
			patentCols: [
				{
					db_name: "标题",
					db_type: "title",
					db_bled: false
				},
				{
					db_name: "发明人",
					db_type: "inventor",
					db_bled: false
				},
				{
					db_name: "摘要",
					db_type: "_abstract",
					db_bled: false
				},
				{
					db_name: "专利局",
					db_type: "patentOffice",
					db_bled: false
				}
			],
			types: [
				{
					db_name: "Aptamer数据库",
					db_type: "0",
					db_place_holder: "请输入序列或靶标",
					db_route: "/aptamerdb"
				},
				{
					db_name: "文献资料",
					db_type: "1",
					db_place_holder: "请输入文章标题或关键字",
					db_route: "/paper"
				},
				{
					db_name: "临床进展",
					db_type: "2",
					db_place_holder: "请输入标题",
					db_route: "/clinical"
				},
				{
					db_name: "团队介绍",
					db_type: "3",
					db_place_holder: "请输入负责人或单位",
					db_route: "/team"
				},
				{
					db_name: "科研项目",
					db_type: "4",
					db_place_holder: "请输入项目标题或关键字",
					db_route: "/project"
				},
				{
					db_name: "相关专利",
					db_type: "5",
					db_place_holder: "请输入专利名称",
					db_route: "/patent"
				}
			]
		}
	},
	mounted() {
		this.cur_type = this.db_type
		// if (this.db_type === "") {
		// 	this.db_type = "0"
		// }
		// if (this.name === "主题") {
		// 	this.name = "0"
		// }
	},
	methods: {
		addList() {
			switch (this.cur_type) {
				case "0":
					if (this.list.length + 1 <= this.aptamerCols.length) {
						this.list.push({
							value: "",
							name: ""
						})
					} else {
						this.addBled = true
					}
					break
				case "1":
					if (this.list.length + 1 <= this.articleCols.length) {
						this.list.push({
							value: "",
							name: ""
						})
					} else {
						this.addBled = true
					}
					break
				case "2":
					if (this.list.length + 1 <= this.clinicalCols.length) {
						this.list.push({
							value: "",
							name: ""
						})
					} else {
						this.addBled = true
					}
					break
				case "3":
					if (this.list.length + 1 <= this.teamCols.length) {
						this.list.push({
							value: "",
							name: ""
						})
					} else {
						this.addBled = true
					}
					break
				case "4":
					if (this.list.length + 1 <= this.projectCols.length) {
						this.list.push({
							value: "",
							name: ""
						})
					} else {
						this.addBled = true
					}
					break
				case "5":
					if (this.list.length + 1 <= this.patentCols.length) {
						this.list.push({
							value: "",
							name: ""
						})
					} else {
						this.addBled = true
					}
					break
				default:
					break
			}
			// this.list.push({
			// 	value: "",
			// 	name: ""
			// })
			if (this.list.length > 0) {
				this.bled = false
			}
		},
		changeOpion(val) {
			switch (this.cur_type) {
				case "0":
					if (this.list) {
						this.aptamerCols.forEach((i) => {
							i.db_bled = false
							this.list.forEach((y) => {
								if (y.name === i.db_type) {
									i.db_bled = true
								}
							})
						})
					}
					break
				case "1":
					if (this.list) {
						this.articleCols.forEach((i) => {
							i.db_bled = false
							this.list.forEach((y) => {
								if (y.name === i.db_type) {
									i.db_bled = true
								}
							})
						})
					}
					break
				case "2":
					if (this.list) {
						this.clinicalCols.forEach((i) => {
							i.db_bled = false
							this.list.forEach((y) => {
								if (y.name === i.db_type) {
									i.db_bled = true
								}
							})
						})
					}
					break
				case "3":
					if (this.list) {
						this.teamCols.forEach((i) => {
							i.db_bled = false
							this.list.forEach((y) => {
								if (y.name === i.db_type) {
									i.db_bled = true
								}
							})
						})
					}
					break
				case "4":
					if (this.list) {
						this.projectCols.forEach((i) => {
							i.db_bled = false
							this.list.forEach((y) => {
								if (y.name === i.db_type) {
									i.db_bled = true
								}
							})
						})
					}
					break
				case "5":
					if (this.list) {
						this.patentCols.forEach((i) => {
							i.db_bled = false
							this.list.forEach((y) => {
								if (y.name === i.db_type) {
									i.db_bled = true
								}
							})
						})
					}
					break
				default:
					break
			}
		},
		del(item, i) {
			this.list.splice(i, 1)
			if (this.list.length === 0) {
				this.bled = true
			}
			this.addBled = false
			switch (this.cur_type) {
				case "0":
					if (this.list) {
						this.aptamerCols.forEach((i) => {
							if (item.name === i.db_type) {
								i.db_bled = false
							}
						})
					}
					break
				case "1":
					if (this.list) {
						this.articleCols.forEach((i) => {
							if (item.name === i.db_type) {
								i.db_bled = false
							}
						})
					}
					break
				case "2":
					if (this.list) {
						this.clinicalCols.forEach((i) => {
							if (item.name === i.db_type) {
								i.db_bled = false
							}
						})
					}
					break
				case "3":
					if (this.list) {
						this.teamCols.forEach((i) => {
							if (item.name === i.db_type) {
								i.db_bled = false
							}
						})
					}
					break
				case "4":
					if (this.list) {
						this.projectCols.forEach((i) => {
							if (item.name === i.db_type) {
								i.db_bled = false
							}
						})
					}
					break
				case "5":
					if (this.list) {
						this.patentCols.forEach((i) => {
							if (item.name === i.db_type) {
								i.db_bled = false
							}
						})
					}
					break
				default:
					break
			}
		},
		remove() {
			this.list = []
			this.bled = true
			this.addBled = false
			switch (this.cur_type) {
				case "0":
					this.aptamerCols.forEach((i) => {
						i.db_bled = false
					})
					break
				case "1":
					this.articleCols.forEach((i) => {
						i.db_bled = false
					})
					break
				case "2":
					this.clinicalCols.forEach((i) => {
						i.db_bled = false
					})
					break
				case "3":
					this.teamCols.forEach((i) => {
						i.db_bled = false
					})
					break
				case "4":
					this.projectCols.forEach((i) => {
						i.db_bled = false
					})
					break
				case "5":
					this.patentCols.forEach((i) => {
						i.db_bled = false
					})
					break
				default:
					break
			}
		},
		active(i) {
			this.int = i
		},
		cancel() {
			this.$emit("getTreeData", false)
		},
		query() {
			var routePath = ""
			switch (this.cur_type) {
				case "0":
					routePath = "/aptamerdb?"
					break
				case "1":
					routePath = "/paper?"
					break
				case "2":
					routePath = "/clinical?"
					break
				case "3":
					routePath = "/team?"
					break
				case "4":
					routePath = "/project?"
					break
				case "5":
					routePath = "/patent?"
					break
				default:
					break
			}

			this.list.forEach((item, i) => {
				if (item.name && item.value) {
					routePath +=
						(i !== 0 ? "&" : "") +
						item.name +
						"=" +
						encodeURIComponent(item.value)
				}
			})
			routePath +=
				(this.list.length > 0 ? "&" : "") + "searchtype=1&index=1&size=10"
			this.cancel()
			this.$router.push(routePath)
		},
		// 跳转序列对比
		toseq() {
			if (!this.seqstr) {
				this.$message({ message: "请输入对比的序列！", type: "error" })
				return
			} else if (!/^[ATCGUatcgu]{1,}$/.test(this.seqstr)) {
				this.$message({
					message: "请输入只包含ATCGU大小写字母的序列",
					type: "error"
				})
				return
			}
			this.cancel()
			this.$router.push("/sequence?seq=" + this.seqstr)
		},
		db_type_change() {
			this.list = [{ name: "", value: "" }]
		}
	}
}
</script>
<style scoped lang="less">
.el-input__inner {
	background-color: #67c23a !important; //绿色
}
.whole {
	width: 1170px;
	padding-bottom: 30px;
	margin: 70px auto;
	height: auto;
	.search {
		width: 100%;
		height: 80px;
		font-size: 30px;
		.c-font-family-PF();
		font-weight: 400;
		color: #3463ea;
		text-align: center;
		line-height: 80px;
		position: relative;
		img {
			position: absolute;
			top: 0px;
			right: 60px;
			cursor: pointer;
			width: 20px;
			height: 20px;
		}
	}
	.header {
		width: 900px;
		margin: 0 auto;
		height: 100px;
		margin-top: 40px;
		.header1 {
			display: flex;
			height: 50px;
			border-bottom: 2px solid #3463ea;
			.show {
				width: 86px;
				height: 30px;
				font-size: 20px !important;
				.c-font-family-PF();
				font-weight: 400;
				color: #3463ea !important;
				line-height: 30px;
			}
			.field {
				margin-right: 20px;
				color: #999999;
				.c-font-size-16();
				height: 30px;
				line-height: 30px;
				cursor: pointer;
			}
		}
	}
	.nav2 {
		width: 900px;
		margin: 0 auto;
		p {
			margin-bottom: 20px;
		}
		.nav-bottom {
			text-align: right;
			margin-top: 30px;
		}
	}
	.nav {
		width: 960px;
		margin: 0 auto;
		p {
			margin-bottom: 20px;
		}
		.nav-bottom {
			text-align: right;
			margin-top: 30px;
		}
		.query {
			height: auto;
			p {
				width: 900px;
				margin: 0 auto;
			}
			.query1 {
				display: flex;
				margin-top: 20px;
				align-items: center;
				.query-img {
					width: 15px;
					height: 15px;
					cursor: pointer;
					margin-right: 17px;
				}
			}
		}
		.footer {
			display: flex;
			justify-content: space-between;
			width: 900px;
			margin: 30px auto;
		}
		.nav1 {
			height: 100px;
			width: 900px;
			margin: 0 auto;
		}
		.nav-one {
			margin-top: 20px;
			.el-select > .el-input {
				width: 500px !important;
			}
		}
	}
}
</style>
