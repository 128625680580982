<template>
	<div class="team-list-panel">
		<!--内容-->
		<div class="top-bar-container">
			<div class="top-bar-container-title">{{ title }}</div>
			<div class="result">
				{{ content }}
			</div>
		</div>
		<div class="top-bar-container related-pictures">
			<p>相关图片</p>
			<div class="product-img">
				<viewer :images="figureList">
				<el-carousel ref="img" height="220px" width="100%" :autoplay="true" :interval="3000" arrow="always" indicator-position="outside">
					<el-carousel-item v-for="(item, index) in figureList" :key="index">
						<img style="" :src="item.path" />
					</el-carousel-item>
				</el-carousel>
				</viewer>
			</div>
		</div>
		<div class="top-bar-container related-products">
			<p>相关产品</p>
			<ul>
				<li v-for="(data, index) of productList" :key="index">
					<div class="title"><span></span>{{data.name}}</div>
					<div class="article-number">货号：{{data.catalogNum}}</div>
					<div class="article-number">品牌：{{data.brand}}</div>
				</li>
			</ul>
		</div>
		<!--评论-->
		<div class="team-list">
			<div class="team-container">
				<div class="team" style="position: relative">
					<div class="commentLabel">参与评论</div>
					<div style="border: 1px #DBDBDB black;position: relative;">
						<el-input
							maxlength="1000"
							class="el-input_inner"
							type="textarea"
							placeholder="发表评论..."
							rows="3"
							v-model="commentStr"
							show-word-limit
						>
						</el-input>
						<div class="commentSubmit">
							<div style="width: 50px">
								<div v-if="!isLogin" class="noAvator" style="width: 40px;height: 40px;"></div>
								<img v-else
									style="width: 39px; height: 39px; border-radius: 50%"
									:src="avator"
								/>
							</div>
							<div style="width: 426px">
								<span style="color: #3463ea">{{ nickname }}</span>
							</div>
							<div style="width: 330px; text-align: right">
								<button @click="commentSub">提交评论</button>
							</div>
						</div>
						<div v-if="!isLogin" class="toLogin">
							<button @click="toLigin">&gt;&gt; 登录后开启发布评论 &lt;&lt;</button>
						</div>
					</div>

					<div class="commentLabel">评论区</div>
					<commentPanel
						v-for="(commentInfo, i) in comments"
						@commentSub="getComment"
						:key="i"
						:commentInfo="commentInfo"
						:isFirst="true"
						:index="i"
						:commentStr="commentStr"
						:isLogin="isLogin"
					></commentPanel>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import commentPanel from "../../paper/detail/commentpanel.vue"
import { ApiClient, ArticleApi } from "../../../api"
import Cookie from "js-cookie"
export default {
	props: ["content", "comments", "title", "commentStr", "figureList", "productList"],
	data() {
		return {
			articleApi: new ArticleApi(ApiClient.instance),
			// avator: "",
			nickname: "",
			offsetCount: 0,
			special: [
				{
					picUrl: require("../../../assets/nupack_favicon.png")
				},
				{
					picUrl: require("../../../assets/UNAFold_favicon.png")
				},
				{
					picUrl: require("../../../assets/intro-2.png")
				},
				{
					picUrl: require("../../../assets/intro-3.png")
				}
			],
			isLogin: false
		}
	},
	computed: {
		avator() {
			if (localStorage.getItem("avator")) {
				return `data:image/png;base64,${JSON.parse(
					localStorage.getItem("avator")
				)}`
			} else {
				return null
			}
		}
	},
	mounted() {
		// this.autoSwitch = setInterval(() => {
		// 	// 自动切换
		// 	if (this.offsetCount < this.special.length - 2) {
		// 		this.nextImg()
		// 	} else if (this.special.length - 2 > 0 && this.offsetCount > 0) {
		// 		this.prevImg()
		// 	}
		// }, 2000)
		// if (JSON.parse(localStorage.getItem("avator"))) {
		// 	this.avator =
		// 		"data:image/png;base64," + JSON.parse(localStorage.getItem("avator"))
		// } else {
		// 	this.avator = null
		// }
		// this.nickname = Cookie.getJSON("user_info").nickname
		var user = Cookie.getJSON("user_info")
		if (user?.account) {
			this.isLogin = true
			this.nickname = user.nickname
		}
	},
	methods: {
		prevImg() {
			if (this.figureList.length - 2 > 0 && this.offsetCount > 0) {
				this.offsetCount--
				this.$refs.carouser.style.left = "-" + 355 * this.offsetCount + "px"
			}
		},
		// startAuto() {
		// 	// 鼠标离开，再次自动轮播
		// 	this.autoSwitch = setInterval(() => {
		// 		// 自动切换
		// 		if (this.offsetCount < this.special.length - 2) {
		// 			this.nextImg()
		// 		} else if (this.special.length - 2 > 0 && this.offsetCount > 0) {
		// 			this.prevImg()
		// 		}
		// 	}, 2000)
		// },
		// stopAuto() {
		// 	// 鼠标移动，暂停自动轮播
		// 	clearInterval(this.autoSwitch)
		// },
		nextImg() {
			if (
				this.figureList.length - 2 > 0 &&
				this.offsetCount < this.figureList.length - 2
			) {
				this.offsetCount++
				this.$refs.carouser.style.left = "-" + 355 * this.offsetCount + "px"
			}
		},
		// commentsub
		commentSub() {
			if (this.commentStr) {
				var opts = { parentID: null, commentStr: this.commentStr }
				this.$emit("commentSub", opts)
			}
		},
		getComment(opts) {
			this.$emit("commentSub", opts)
		},
		toLigin() {
			document.getElementById("loginSpan").click()
		}
	},
	components: {
		commentPanel
	}
}
</script>

<style lang="less" scoped>
/deep/ .el-carousel__item {
	text-align: center;

	img {
		cursor: pointer;
	}
}

/deep/ .el-carousel {
	.el-carousel__indicators {
		bottom: 45px;
		left: 62px;

		.el-carousel__indicator--horizontal.is-active button {
			width: 20px;
			height: 7px;
			background: #3462E9;
			opacity: 1;
			border-radius: 4px;
		}

		.el-carousel__indicator--horizontal button {
			width: 7px;
			height: 7px;
			background: #333333;
			border-radius: 50%;
			opacity: 0.5;
		}
	}

	.el-carousel__arrow--right {
		background-image: url("../../../assets/prev-right.png");
		background-size: 36px 36px;
		background-repeat:no-repeat;
		right: 28px !important;
	}

	.el-carousel__arrow--left {
		background-image: url("../../../assets/prev-left.png");
		background-size: 36px 36px;
		background-repeat:no-repeat;
		left: 28px !important;
	}

	.el-carousel__arrow {
		background-color: none !important;
		top: 50% !important;
		transform: translate(0, -100%) !important;

		i {
			display: none !important;
		}
	}
}

.related-pictures {
	height: 320px;
	p {
		width: 80px;
		height: 28px;
		font-size: 20px;
		font-weight: 400;
		color: #333333;
		opacity: 1;
		margin: 18px 0 20px 18px;
	}
}
.related-products {
	// height: 600px;
	p {
		width: 80px;
		height: 28px;
		font-size: 20px;
		font-weight: 400;
		color: #333333;
		opacity: 1;
		margin: 18px 0 20px 18px;
	}
	ul {
		width: 95%;
		margin: 0 auto;
		list-style: none;
		li {
			width: 100%;
			height: 120px;
			background: #ffffff;
			border: 1px solid #dbdbdb;
			opacity: 1;
			border-radius: 0px;
			margin-top: 21px;
			.title {
				display: flex;
				align-items: center;
				width: 100%;
				height: 45px;
				background: #e7f0ff;
				opacity: 1;
				border-radius: 0px;
				font-size: 16px;
				font-weight: 400;
				color: #333333;
				line-height: 45px;
				opacity: 1;
				span {
					display: block;
					width: 5px;
					height: 45px;
					background: #3362e9;
					opacity: 1;
					border-radius: 0px;
					margin-right: 6px;
				}
			}
			.article-number {
				height: 20px;
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				color: #333333;
				opacity: 1;
				margin: 10px 12px;
			}
		}
	}
}
.product-img {
	width: 94%;
	margin: 0 auto;
	// height: 220px;
	// display: flex;
	align-items: center;
	ul {
		width: 710px;
		margin: 0 auto;
		display: flex;
		justify-content: flex-start;
	}
}
.prev-arrow,
.next-arrow {
	cursor: pointer;
	height: 66px;
}
.prev-active,
.next-active {
	cursor: not-allowed;
	background: #c2c2c2;
	width: 40px;
	height: 66px;
}
.list-img {
	width: 710px;
	height: 212px;
	position: relative;
	overflow: hidden;
}
.list-img-wrap {
	position: absolute;
	left: 0;
	top: 0;
	width: auto;
	white-space: nowrap;
	display: flex;
	height: 212px;
}
.single-img {
	flex-shrink: 0;
	width: 335px;
	height: 212px;
	margin: 0 10px;
	border: 1px solid #e5e5e5;
}
.single-img img {
	width: 100%;
	height: 212px;
}

.comFlexDiv {
	display: flex;
	align-items: center;
}

.commentLabel {
	.c-font-size-16();
	margin-top: 10px;
	margin-bottom: 10px;
}

.commentSubmit {
	width: 100%;
	background-color: rgb(239, 239, 239);
	display: flex;
	align-items: center;
	padding: 10px;
}
.commentSubmit button {
	color: #fff;
	background-color: #409eff;
	border: 1px solid #409eff;
	border-radius: 4px;
	padding: 10px 10px;
}

.comBorder {
	border-top: 2px solid rgb(239, 239, 239);
}

.tab-tilte {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	list-style: none;
	white-space: nowrap;
	transition: width 0.5s ease;
}

.tab-tilte li {
	padding: 10px 20px;
	.c-font-size-14();
	.c-font-family-PF();
	font-weight: bolder;
	line-height: 20px;
	color: #333333;
}

.active {
	border-bottom: 2px solid #3463ea;
}

.imgpanel {
	width: 80px;
	height: 80px;
	margin: 16px 0 0 16px;
}

.top-bar-container {
	width: 100%;
	background-color: #ffffff;
	display: inline-block;
	margin-bottom: 16px;
}

.result {
	.c-font-size-18();
	.c-font-family-PF();
	line-height: 30px;
	color: #666666;
	margin: 0px 48px 74px;
}
.top-bar-container-title {
	margin: 5px 20px;
	.c-font-size-24();
	.c-font-family-PF();
	font-weight: 400;
	color: #666666;
	text-align: center;
	margin: 79px 86px 56px;
}
.result .value {
	color: #3463ea;
	margin: 0 6px;
}

.team-list {
	width: 100%;
	min-height: 500px;
}

.team-container {
	width: 100%;
	display: inline-flex;
	background-color: #ffffff;
	margin-top: 16px;
}

.team {
	width: 100%;
	margin: 16px;
	font-family: PingFang-SC-Medium;
	.c-font-size-14();
}

.team .title {
	.c-font-size-12();
	color: #3463ea;
	line-height: 20px;
	text-align: left;
}

.team .title a {
	text-decoration: none;
	.c-font-size-16();
}
.team .title a:visited {
	color: #3463ea;
}

.team .entry a:visited {
	color: #3463ea;
}

.team .location {
	.c-font-size-12();
	line-height: 20px;
	display: flex;
	flex-direction: row;
	text-align: left;
}

.team .location .entry {
	display: flex;
	flex-direction: row;
	margin-right: 20px;
}

.team .location .entry .value {
	margin-left: 6px;
}

.team .link {
	display: flex;
	flex-direction: row;
	.c-font-size-12();
	line-height: 20px;
}

.team .link .url {
	margin-left: 6px;
}

.pagination {
	display: flex;
	justify-content: center;
	margin: 10px auto;
}
</style>
