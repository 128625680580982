/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {PatentDetailDtoResponse} from '../model/PatentDetailDtoResponse';
import {PatentListDtoResponse} from '../model/PatentListDtoResponse';
import {SearchTypeEnum} from '../model/SearchTypeEnum';

/**
* Patent service.
* @module api/PatentApi
* @version v1
*/
export class PatentApi {

    /**
    * Constructs a new PatentApi. 
    * @alias module:api/PatentApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiPatentIdGet operation.
     * @callback moduleapi/PatentApi~apiPatentIdGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PatentDetailDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 专利详情
     * @param {String} id 
     * @param {module:api/PatentApi~apiPatentIdGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiPatentIdGet(id, callback) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiPatentIdGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PatentDetailDtoResponse;

      return this.apiClient.callApi(
        '/api/Patent/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the apiPatentQueryGet operation.
     * @callback moduleapi/PatentApi~apiPatentQueryGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PatentListDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 专利查询  一般查询、高级查询
     * @param {module:model/SearchTypeEnum} searchType 
     * @param {Object} opts Optional parameters
     * @param {String} opts.aptamerID 
     * @param {String} opts.keyWord 
     * @param {String} opts.patentOffice 
     * @param {String} opts.language 
     * @param {String} opts.publishedDate 
     * @param {String} opts.inventionType 
     * @param {String} opts.inventor 
     * @param {String} opts.orderBy 
     * @param {String} opts.title 
     * @param {String} opts._abstract 
     * @param {Number} opts.index 
     * @param {Number} opts.size 
     * @param {module:api/PatentApi~apiPatentQueryGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiPatentQueryGet(searchType, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'searchType' is set
      if (searchType === undefined || searchType === null) {
        throw new Error("Missing the required parameter 'searchType' when calling apiPatentQueryGet");
      }

      let pathParams = {
        
      };
      let queryParams = {
        'AptamerID': opts['aptamerID'],'KeyWord': opts['keyWord'],'PatentOffice': opts['patentOffice'],'Language': opts['language'],'PublishedDate': opts['publishedDate'],'InventionType': opts['inventionType'],'Inventor': opts['inventor'],'OrderBy': opts['orderBy'],'Title': opts['title'],'Abstract': opts['_abstract'],'SearchType': searchType,'Index': opts['index'],'Size': opts['size']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = PatentListDtoResponse;

      return this.apiClient.callApi(
        '/api/Patent/query', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}