var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "issue-release" }, [
      _c("p", { staticClass: "title" }, [_vm._v("发布问题")]),
      _c("div", { staticClass: "release-content" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("div", { staticClass: "tip" }, [_vm._v("标题")]),
            _c("el-input", {
              staticClass: "issue-title",
              attrs: {
                maxlength: "50",
                placeholder: "请输入文章标题",
                disabled: !_vm.isLogin,
              },
              model: {
                value: _vm.title,
                callback: function ($$v) {
                  _vm.title = $$v
                },
                expression: "title",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("div", { staticClass: "tip" }, [_vm._v("正文")]),
            _c("el-input", {
              attrs: {
                type: "textarea",
                rows: 2,
                placeholder: "请输入文章正文",
                resize: "none",
                disabled: !_vm.isLogin,
              },
              model: {
                value: _vm.textarea,
                callback: function ($$v) {
                  _vm.textarea = $$v
                },
                expression: "textarea",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row", staticStyle: { position: "relative" } },
          [
            _c("div", { staticClass: "tip hide" }),
            _c("div", { staticClass: "release-submit" }, [
              _c("div", { staticClass: "release-user" }, [
                _c("img", { attrs: { src: _vm.avator } }),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.userinfo == null ? "" : _vm.userinfo.nickname)
                  ),
                ]),
              ]),
              _c("div", { staticClass: "release", on: { click: _vm.submit } }, [
                _vm._v("发布"),
              ]),
            ]),
            !_vm.isLogin
              ? _c("div", { staticClass: "toLogin" }, [
                  _c("button", { on: { click: _vm.toLigin } }, [
                    _vm._v(">> 登录后开启发布问题 <<"),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "related-topics" }, [
      _c("p", { staticClass: "title" }, [_vm._v("相关话题")]),
      _c("div", { staticClass: "related-content" }, [
        _c(
          "ul",
          _vm._l(_vm.listData, function (item, i) {
            return _c("li", { key: i, staticClass: "row" }, [
              _c("div", { staticClass: "related-content-auto" }, [
                _c(
                  "p",
                  {
                    staticClass: "related-content-title",
                    on: {
                      click: function ($event) {
                        return _vm.sendDetail(item.learnTopicID)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.title) + " ")]
                ),
                _c("div", { staticClass: "related-content-article" }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm._f("ellipsis")(item.content, 150))),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "related-content-read-more",
                      on: {
                        click: function ($event) {
                          return _vm.sendDetail(item.learnTopicID)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "text" }, [_vm._v("阅读全文")]),
                      _c("img", {
                        staticClass: "icon",
                        attrs: { src: require("../../assets/arrow-down.png") },
                      }),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "comment-information" }, [
                  _c("img", {
                    staticClass: "comment-icon",
                    attrs: { src: require("../../assets/comment.png") },
                  }),
                  _c("p", [_vm._v(_vm._s(item.commentCount) + "条 评论")]),
                ]),
              ]),
              _c("div", { staticClass: "related-bottom-line" }),
            ])
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              attrs: {
                id: "pagination",
                "current-page": _vm.pageindex,
                "page-size": _vm.pagesize,
                "page-sizes": [10, 25, 50],
                layout: "prev, pager, next, jumper, sizes",
                total: _vm.listTotal,
              },
              on: {
                "current-change": _vm.queryChange,
                "size-change": _vm.sizeChange,
                "prev-click": _vm.queryChange,
                "next-click": _vm.queryChange,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }