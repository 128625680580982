var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "login",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.isolateClick()
        },
        touchmove: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _vm.containerShow
        ? _c("div", { staticClass: "login-container" }, [
            _c("div", { staticClass: "modalClose", on: { click: _vm.close } }),
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.showDetail ? "序列详情" : "序列上传")),
            ]),
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("适体名称"),
                        ]),
                        _c("el-input", {
                          attrs: {
                            disabled: _vm.canEdit,
                            placeholder: "请输入适体名称",
                            maxlength: "500",
                          },
                          model: {
                            value: _vm.info.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "name", $$v)
                            },
                            expression: "info.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("适体类型"),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: _vm.canEdit,
                              placeholder: "请选择适体类型",
                            },
                            model: {
                              value: _vm.info.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.info, "type", $$v)
                              },
                              expression: "info.type",
                            },
                          },
                          [
                            _c("el-option", { attrs: { value: "DNA" } }, [
                              _vm._v("DNA"),
                            ]),
                            _c("el-option", { attrs: { value: "RNA" } }, [
                              _vm._v("RNA"),
                            ]),
                            _c("el-option", { attrs: { value: "DNA/RNA" } }, [
                              _vm._v("DNA/RNA"),
                            ]),
                            _c("el-option", { attrs: { value: "Peptide" } }, [
                              _vm._v("Peptide"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("靶标名称"),
                        ]),
                        _c("el-input", {
                          attrs: {
                            disabled: _vm.canEdit,
                            placeholder: "请输入靶标名称",
                            maxlength: "500",
                          },
                          model: {
                            value: _vm.info.target,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "target", $$v)
                            },
                            expression: "info.target",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("靶标种类"),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: _vm.canEdit,
                              placeholder: "请选择靶标种类",
                            },
                            model: {
                              value: _vm.info.targetType,
                              callback: function ($$v) {
                                _vm.$set(_vm.info, "targetType", $$v)
                              },
                              expression: "info.targetType",
                            },
                          },
                          [
                            _c("el-option", { attrs: { value: "Protein" } }, [
                              _vm._v("Protein"),
                            ]),
                            _c("el-option", { attrs: { value: "Compound" } }, [
                              _vm._v("Compound"),
                            ]),
                            _c("el-option", { attrs: { value: "Virus" } }, [
                              _vm._v("Virus"),
                            ]),
                            _c("el-option", { attrs: { value: "Cells" } }, [
                              _vm._v("Cells"),
                            ]),
                            _c("el-option", { attrs: { value: "Ion" } }, [
                              _vm._v("Ion"),
                            ]),
                            _c("el-option", { attrs: { value: "Other" } }, [
                              _vm._v("Other"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("亲和力(Kd)"),
                        ]),
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-ruleForm",
                            attrs: {
                              model: _vm.info,
                              "status-icon": "",
                              rules: _vm.rules,
                              "label-width": "100px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "", prop: "pass" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      disabled: _vm.canEdit,
                                      placeholder: "请输入亲和力",
                                      type: "number",
                                    },
                                    nativeOn: {
                                      keypress: function ($event) {
                                        return _vm.checkInput.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.info.affinity,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.info, "affinity", $$v)
                                      },
                                      expression: "info.affinity",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "70px" },
                                        attrs: {
                                          slot: "append",
                                          disabled: _vm.canEdit,
                                          placeholder: "请选择单位",
                                        },
                                        slot: "append",
                                        model: {
                                          value: _vm.selectUnit,
                                          callback: function ($$v) {
                                            _vm.selectUnit = $$v
                                          },
                                          expression: "selectUnit",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { label: "mm", value: 1 },
                                        }),
                                        _c("el-option", {
                                          attrs: { label: "μm", value: 2 },
                                        }),
                                        _c("el-option", {
                                          attrs: { label: "nm", value: 3 },
                                        }),
                                        _c("el-option", {
                                          attrs: { label: "pm", value: 4 },
                                        }),
                                        _c("el-option", {
                                          attrs: { label: "fm", value: 5 },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("筛选方法"),
                        ]),
                        _c("el-input", {
                          attrs: {
                            disabled: _vm.canEdit,
                            placeholder: "请输入筛选方法",
                            maxlength: "500",
                          },
                          model: {
                            value: _vm.info.screenMethod,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "screenMethod", $$v)
                            },
                            expression: "info.screenMethod",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("span", { staticClass: "label" }, [_vm._v("样本")]),
                        _c("el-input", {
                          attrs: {
                            disabled: _vm.canEdit,
                            placeholder: "请输入样本",
                            maxlength: "500",
                          },
                          model: {
                            value: _vm.info.sampleType,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "sampleType", $$v)
                            },
                            expression: "info.sampleType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("span", { staticClass: "label" }, [_vm._v("应用")]),
                        _c("el-input", {
                          attrs: {
                            disabled: _vm.canEdit,
                            placeholder: "请输入应用",
                            maxlength: "500",
                          },
                          model: {
                            value: _vm.info.appliedteChnology,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "appliedteChnology", $$v)
                            },
                            expression: "info.appliedteChnology",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("引用类型"),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: _vm.canEdit,
                              placeholder: "请选择引用类型",
                            },
                            model: {
                              value: _vm.info.referenceType,
                              callback: function ($$v) {
                                _vm.$set(_vm.info, "referenceType", $$v)
                              },
                              expression: "info.referenceType",
                            },
                          },
                          [
                            _c("el-option", { attrs: { value: "DOI" } }, [
                              _vm._v("DOI"),
                            ]),
                            _c("el-option", { attrs: { value: "URL" } }, [
                              _vm._v("URL"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("引用信息"),
                        ]),
                        _c("el-input", {
                          attrs: {
                            disabled: _vm.canEdit,
                            placeholder: "请输入引用信息",
                            maxlength: "500",
                          },
                          model: {
                            value: _vm.info.referenceInfo,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "referenceInfo", $$v)
                            },
                            expression: "info.referenceInfo",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("结合条件"),
                        ]),
                        _c("el-input", {
                          staticClass: "el-input_inner",
                          attrs: {
                            disabled: _vm.canEdit,
                            type: "textarea",
                            placeholder: "请输入结合条件",
                            resize: "none",
                            rows: "3",
                            maxlength: "2000",
                          },
                          model: {
                            value: _vm.info.combiningCondition,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "combiningCondition", $$v)
                            },
                            expression: "info.combiningCondition",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("适体序列"),
                        ]),
                        _c("el-input", {
                          staticClass: "el-input_inner",
                          attrs: {
                            disabled: _vm.canEdit,
                            type: "textarea",
                            resize: "none",
                            placeholder: "请输入适体序列",
                            rows: "3",
                          },
                          model: {
                            value: _vm.info.sequence,
                            callback: function ($$v) {
                              _vm.$set(_vm.info, "sequence", $$v)
                            },
                            expression: "info.sequence",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                !_vm.canEdit
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                            },
                            attrs: { span: 24 },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "batch",
                                on: {
                                  click: function ($event) {
                                    return _vm.batchSubmit()
                                  },
                                },
                              },
                              [_vm._v("批量上传")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.submit()
                                  },
                                },
                              },
                              [_vm._v("确认上传")]
                            ),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.close()
                                  },
                                },
                              },
                              [_vm._v("取消上传")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.batchUploadShow
        ? _c("div", { staticClass: "batchUpload" }, [
            _c("div", { staticClass: "batchUpload-title" }, [
              _vm._v("批量上传"),
            ]),
            _c(
              "div",
              { staticClass: "batchUpload-upload" },
              [
                _c(
                  "el-upload",
                  {
                    ref: "upload",
                    staticClass: "upload-demo",
                    attrs: {
                      disabled: _vm.disabled,
                      "on-change": _vm.handleAvatarChange,
                      accept: "xls,xlsx",
                      drag: "",
                      "auto-upload": false,
                      action: "#",
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("../assets/batchUpload.png") },
                    }),
                    _c("div", { staticClass: "el-upload__text" }, [
                      _vm._v("请导入Excel文件"),
                    ]),
                    _vm.upload.name
                      ? _c("p", [_vm._v(_vm._s(_vm.upload.name))])
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "download" }, [
              !_vm.disabled
                ? _c(
                    "a",
                    {
                      attrs: {
                        href: "/uploadTemplate.xlsx",
                        download: "序列上传模板.xlsx",
                      },
                    },
                    [_vm._v("模板下载")]
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "batchUpload-bottom" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "batch",
                    attrs: { disabled: _vm.disabled },
                    on: {
                      click: function ($event) {
                        return _vm.batchUpload()
                      },
                    },
                  },
                  [_vm._v("序列上传")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", disabled: _vm.disabled },
                    on: {
                      click: function ($event) {
                        return _vm.batchUploadSubmit()
                      },
                    },
                  },
                  [_vm._v("确认上传")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { disabled: _vm.disabled },
                    on: {
                      click: function ($event) {
                        return _vm.close()
                      },
                    },
                  },
                  [_vm._v("取消上传")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }