/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {Clinical} from './Clinical';

/**
 * The Country model module.
 * @module model/Country
 * @version v1
 */
export class Country {
  /**
   * Constructs a new <code>Country</code>.
   * @alias module:model/Country
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>Country</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Country} obj Optional instance to populate.
   * @return {module:model/Country} The populated <code>Country</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Country();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'String');
      if (data.hasOwnProperty('countryName'))
        obj.countryName = ApiClient.convertToType(data['countryName'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('nameZh'))
        obj.nameZh = ApiClient.convertToType(data['nameZh'], 'String');
      if (data.hasOwnProperty('nameEn'))
        obj.nameEn = ApiClient.convertToType(data['nameEn'], 'String');
      if (data.hasOwnProperty('clinicals'))
        obj.clinicals = ApiClient.convertToType(data['clinicals'], [Clinical]);
    }
    return obj;
  }
}

/**
 * @member {String} id
 */
Country.prototype.id = undefined;

/**
 * @member {String} countryName
 */
Country.prototype.countryName = undefined;

/**
 * @member {String} name
 */
Country.prototype.name = undefined;

/**
 * @member {String} nameZh
 */
Country.prototype.nameZh = undefined;

/**
 * @member {String} nameEn
 */
Country.prototype.nameEn = undefined;

/**
 * @member {Array.<module:model/Clinical>} clinicals
 */
Country.prototype.clinicals = undefined;

