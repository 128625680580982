/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {AptamerRelatedArticleList} from './AptamerRelatedArticleList';
import {AptamerRelatedPatentList} from './AptamerRelatedPatentList';
import {AptamerSequenceDetailDto} from './AptamerSequenceDetailDto';

/**
 * The AptamerDetailDto model module.
 * @module model/AptamerDetailDto
 * @version v1
 */
export class AptamerDetailDto {
  /**
   * Constructs a new <code>AptamerDetailDto</code>.
   * @alias module:model/AptamerDetailDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>AptamerDetailDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AptamerDetailDto} obj Optional instance to populate.
   * @return {module:model/AptamerDetailDto} The populated <code>AptamerDetailDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AptamerDetailDto();
      if (data.hasOwnProperty('sequenceDetail'))
        obj.sequenceDetail = AptamerSequenceDetailDto.constructFromObject(data['sequenceDetail']);
      if (data.hasOwnProperty('relatedArticle'))
        obj.relatedArticle = AptamerRelatedArticleList.constructFromObject(data['relatedArticle']);
      if (data.hasOwnProperty('relatedPatent'))
        obj.relatedPatent = AptamerRelatedPatentList.constructFromObject(data['relatedPatent']);
    }
    return obj;
  }
}

/**
 * @member {module:model/AptamerSequenceDetailDto} sequenceDetail
 */
AptamerDetailDto.prototype.sequenceDetail = undefined;

/**
 * @member {module:model/AptamerRelatedArticleList} relatedArticle
 */
AptamerDetailDto.prototype.relatedArticle = undefined;

/**
 * @member {module:model/AptamerRelatedPatentList} relatedPatent
 */
AptamerDetailDto.prototype.relatedPatent = undefined;

