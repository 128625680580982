/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The ArticleItemDto model module.
 * @module model/ArticleItemDto
 * @version v1
 */
export class ArticleItemDto {
  /**
   * Constructs a new <code>ArticleItemDto</code>.
   * @alias module:model/ArticleItemDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>ArticleItemDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ArticleItemDto} obj Optional instance to populate.
   * @return {module:model/ArticleItemDto} The populated <code>ArticleItemDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ArticleItemDto();
      if (data.hasOwnProperty('articleID'))
        obj.articleID = ApiClient.convertToType(data['articleID'], 'String');
      if (data.hasOwnProperty('articleTitle'))
        obj.articleTitle = ApiClient.convertToType(data['articleTitle'], 'String');
      if (data.hasOwnProperty('issn'))
        obj.issn = ApiClient.convertToType(data['issn'], 'String');
      if (data.hasOwnProperty('abstract'))
        obj._abstract = ApiClient.convertToType(data['abstract'], 'String');
      if (data.hasOwnProperty('citedCount'))
        obj.citedCount = ApiClient.convertToType(data['citedCount'], 'Number');
      if (data.hasOwnProperty('referenceCount'))
        obj.referenceCount = ApiClient.convertToType(data['referenceCount'], 'Number');
      if (data.hasOwnProperty('author'))
        obj.author = ApiClient.convertToType(data['author'], 'String');
      if (data.hasOwnProperty('publishedDate'))
        obj.publishedDate = ApiClient.convertToType(data['publishedDate'], 'Date');
      if (data.hasOwnProperty('journal'))
        obj.journal = ApiClient.convertToType(data['journal'], 'String');
      if (data.hasOwnProperty('countryId'))
        obj.countryId = ApiClient.convertToType(data['countryId'], 'String');
      if (data.hasOwnProperty('countryName'))
        obj.countryName = ApiClient.convertToType(data['countryName'], 'String');
      if (data.hasOwnProperty('doi'))
        obj.doi = ApiClient.convertToType(data['doi'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} articleID
 */
ArticleItemDto.prototype.articleID = undefined;

/**
 * @member {String} articleTitle
 */
ArticleItemDto.prototype.articleTitle = undefined;

/**
 * @member {String} issn
 */
ArticleItemDto.prototype.issn = undefined;

/**
 * @member {String} _abstract
 */
ArticleItemDto.prototype._abstract = undefined;

/**
 * @member {Number} citedCount
 */
ArticleItemDto.prototype.citedCount = undefined;

/**
 * @member {Number} referenceCount
 */
ArticleItemDto.prototype.referenceCount = undefined;

/**
 * @member {String} author
 */
ArticleItemDto.prototype.author = undefined;

/**
 * @member {Date} publishedDate
 */
ArticleItemDto.prototype.publishedDate = undefined;

/**
 * @member {String} journal
 */
ArticleItemDto.prototype.journal = undefined;

/**
 * @member {String} countryId
 */
ArticleItemDto.prototype.countryId = undefined;

/**
 * @member {String} countryName
 */
ArticleItemDto.prototype.countryName = undefined;

/**
 * @member {String} doi
 */
ArticleItemDto.prototype.doi = undefined;

