var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("Header", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !(_vm.path === "/largescreen"),
            expression: "!(path === '/largescreen')",
          },
        ],
      }),
      _vm.path.indexOf("/intelligentfiltering") < 0 &&
      _vm.path.indexOf("/aptamerevaluationlist") < 0 &&
      _vm.path != "/largescreen" &&
      _vm.path != "/aptamerevaluationpage" &&
      _vm.path != "/aptamerfilterpage"
        ? _c("SearchBox", { ref: "searchbox", attrs: { db_type: _vm.db_type } })
        : _vm._e(),
      (_vm.path.indexOf("/intelligentfiltering") >= 0 ||
        _vm.path.indexOf("/aptamerevaluationlist") >= 0) &&
      _vm.path != "/largescreen"
        ? _c("TargetSearchBox")
        : _vm._e(),
      _c("Breadcrumb", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              !(_vm.path === "/largescreen") &&
              !(_vm.path === "/aptamerevaluationpage") &&
              !(_vm.path === "/aptamerfilterpage"),
            expression:
              "!(path === '/largescreen') && !(path === '/aptamerevaluationpage') && !(path === '/aptamerfilterpage')",
          },
        ],
      }),
      _c("router-view", { staticClass: "main-body" }),
      _c("Footer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              !(_vm.path === "/largescreen") &&
              !(_vm.path === "/aptamerevaluationpage") &&
              !(_vm.path === "/aptamerfilterpage"),
            expression:
              "!(path === '/largescreen') && !(path === '/aptamerevaluationpage') && !(path === '/aptamerfilterpage')",
          },
        ],
        attrs: { isLogin: _vm.isLogin },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                !(_vm.path === "/largescreen") &&
                !(_vm.path === "/aptamerevaluationpage") &&
                !(_vm.path === "/aptamerfilterpage"),
              expression:
                "!(path === '/largescreen') && !(path === '/aptamerevaluationpage') && !(path === '/aptamerfilterpage')",
            },
          ],
          staticClass: "side-float",
        },
        [_c("SideFloat", { attrs: { isLogin: _vm.isLogin } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }