<template>
	<div class="team-list-panel">
		<div class="team-list">
			<el-table
				:data="dataList"
				border
				style="width: 100%"
				v-loading="loading"
				element-loading-text="结果加载中。。。"
				@row-click="alignmentsShow"
			>
				<el-table-column prop="name" align="center" label="名称">
					<template slot-scope="scope">
						<a
							href="javascript:void(0)"
							v-html="scope.row.name || '-'"
							title="点击查看Alignments"
						></a>
					</template>
				</el-table-column>
				<el-table-column prop="target" align="center" label="靶标">
					<template slot-scope="{ row }">
						<p v-html="row.target || '-'"></p>
					</template>
				</el-table-column>
				<el-table-column prop="affinity" align="center" label="亲和力">
					<template slot-scope="{ row }">
						<p
							v-html="
								row.affinity && parseFloat(row.affinity) !== 0
									? row.affinity
									: '-'
							"
						></p>
					</template>
				</el-table-column>
				<el-table-column prop="lengh" align="center" label="长度">
					<template slot-scope="{ row }">
						<p v-html="row.lengh && row.lengh > 0 ? row.lengh : '-'"></p>
					</template>
				</el-table-column>
				<el-table-column prop="similarity" align="center" label="相似度(%)">
					<template slot-scope="scope">
						{{
							scope.row.similarity && scope.row.similarity > 0
								? (scope.row.similarity * 100).toFixed(2)
								: "-"
						}}
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
export default {
	props: ["dataList", "loading"],
	data() {
		return {}
	},
	methods: {
		alignmentsShow(row, column, event) {
			this.$emit("optionselected", row.id)
		}
	}
}
</script>

<style lang="less" scoped>
.tableHeader {
	background-color: #bbc3d8;
}

.team-list {
	width: 100%;
}
</style>

<style lang="less" scoped>
/* 自定义el-table样式 */
/deep/ .el-table__header thead tr th {
	background-color: #3463e9;
	color: #ffffff;
}

/deep/ .el-table__body-wrapper .el-table__row {
	cursor: pointer;
}

.el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
	border-color: #c2d0f9;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
	background-color: #c2d0f9;
}

.el-table__body-wrapper .el-table td {
	padding: 5px 0px;
}
</style>
