<template>
    <div class="systemPage evaluationBgimg">
        <div class="contentPanel">
            <div class="sysTitle">核酸适体评价系统</div>
            <div class="inputPanel">
                <!-- <el-select v-model="keyword" popper-class="pageSearchBox"
					filterable remote :remote-method="remoteList" placeholder="请输入靶标名称">
					<el-option
					v-for="(item, index) in showList"
					:key="index"
					:value="item.name">
					<span v-html="item.value"></span>
					</el-option>
				</el-select> -->
				<el-popover placement="bottom" width="618" trigger="click" :disabled="showList.length <= 0"
				v-model="selectExpress">
                    <div style="width:100%;max-height:200px;overflow-y:auto;">
                        <div v-for="(item,index) of showList" :key="index">
                            <div v-if="index>0" style="width: 100%;border-top: 1px #DFDFDF solid;"></div>
                            <div class="targetSelectOptions" v-html="item.value" @click="selectTarget(item)"></div>
                        </div>
                    </div>
					<input slot="reference" type="text" class="targetSearchInputBox" v-model="keyword" placeholder="请输入靶标名称" @keyup.enter="search" />
                </el-popover>
                <button @click="toList"><img src="../../assets/search2.png" />搜索</button>
            </div>
        </div>
    </div>
</template>

<script>
import { ApiClient, AptamerApi } from "../../api"
export default {
	data() {
		return {
			api: new AptamerApi(ApiClient.instance),
			targetList: [],
			showList: [],
			keyword: "",
			remoteKeyword: "",
			selectExpress: false
		}
	},
	created() {
		this.getTargets()
	},
	methods: {
		getTargets() {
			this.api.apiAptamerTargetsdataGet((err, data, resp) => {
				if (err) {
					console.log(err)
				}
				if (data?.success && data?.result) {
					this.targetList = this.$options.filters.formatTargetList(data.result)
					// console.log(this.targetList)
					this.showList = this.targetList
				}
			})
		},
		selectTarget(val) {
			// console.log(val)
			this.keyword = val.name
		},
		toList() {
			var tar = []
			var _keyword = ""
			if (this.keyword) {
				tar = this.targetList.filter(a => a.formatCol === this.keyword.toLowerCase() || a.name.toLowerCase() === this.keyword.toLowerCase())
				console.log(tar)
				if (!tar || tar.length <= 0) {
					this.$message({ message: "输入靶标未存在！", type: "error" })
					return
				}
				_keyword = encodeURIComponent(tar[0].name)
				this.$router.push("AptamerEvaluationList?keyword=" + _keyword)
			} else {
				this.$message({ message: "请输入靶标！", type: "warning" })
			}
		}
		// remoteList(query) {
		// 	// this.remoteKeyword = query
		// 	this.keyword = query
		// 	var ele = document.getElementsByClassName("el-select-dropdown__wrap")
		// 	// console.log(ele[0])
		// 	if (ele && ele.length > 0) {
		// 		ele[0].scrollTop = 0
		// 	}
		// 	if (query) {
		// 		this.showList = []
		// 		var list = this.targetList.filter(a => a.formatCol.indexOf(query.toLowerCase()) >= 0)
		// 		list = list.map(a => {
		// 			var res = a
		// 			this.$set(res, "queryIndex", res.formatCol.indexOf(query.toLowerCase()))
		// 			this.$set(res, "strLen", res.formatCol.length)
		// 			return res
		// 		})
		// 		list = list.sort((a, b) => a.queryIndex - b.queryIndex || a.strLen - b.strLen)

		// 		this.showList = list
		// 	} else {
		// 		this.showList = this.targetList
		// 	}
		// 	// console.log(this.showList)
		// }
	},
	watch: {
		keyword() {
			if (this.keyword) {
				this.selectExpress = true
				this.showList = []
				var list = this.targetList.filter(a => a.formatCol.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0 || a.name.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0)
				list = list.map(a => {
					var res = a
					this.$set(res, "queryIndex", res.formatCol.toLowerCase().indexOf(this.keyword.toLowerCase()))
					this.$set(res, "strLen", res.formatCol.length)
					return res
				})
				list = list.sort((a, b) => a.queryIndex - b.queryIndex || a.strLen - b.strLen)

				this.showList = list
			} else {
				this.showList = JSON.parse(JSON.stringify(this.targetList))
			}
		}
	}
}
</script>

<style lang="less" scoped>
.evaluationBgimg {
	background-image: url("../../assets/evaluationbgimg.png");
}
</style>
