var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item-list-panel" }, [
    _c("div", { staticClass: "top-bar-container" }, [
      _c("div", { staticClass: "top-bar" }, [
        _c("div", { staticClass: "result" }, [
          _c("div", { staticClass: "label count-desc" }, [
            _c("div", { staticClass: "label" }, [_vm._v("当前结果")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(" " + _vm._s(_vm.resultAmount) + " "),
            ]),
            _c("div", { staticClass: "unit" }, [_vm._v("条")]),
          ]),
          _c("div", { staticClass: "textLabel" }, [
            _vm._v("按公布日期倒序排列"),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "item-list" },
      _vm._l(_vm.datalist, function (item, i) {
        return _c("div", { key: i, staticClass: "item-container" }, [
          _c("div", { staticClass: "item" }, [
            _c(
              "div",
              { staticClass: "location" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "entity entity-title",
                    staticStyle: { "text-decoration": "none" },
                    attrs: { to: "/clinical/" + item.id, title: item.title },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm._f("ellipsis")(item.title, 90)) + " "
                    ),
                  ]
                ),
                _c("div", { staticClass: "entity-line" }, [
                  _c("div", { staticClass: "entity" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("招募状态：")]),
                    _c(
                      "div",
                      {
                        staticClass: "value",
                        class:
                          item.recruitmentStatus === "Terminated"
                            ? "statusColor"
                            : item.recruitmentStatus === "Recruiting"
                            ? "RecruitingColor"
                            : "",
                      },
                      [_vm._v(" " + _vm._s(item.recruitmentStatus) + " ")]
                    ),
                  ]),
                  _c("div", { staticClass: "entity" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("适应症：")]),
                    _c("div", {
                      staticClass: "value conditions",
                      domProps: { innerHTML: _vm._s(item.conditions) },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "entity-line" }, [
                  _c("div", { staticClass: "entity" }, [
                    _c("div", { staticClass: "value" }, [
                      _vm._v("研究单位：" + _vm._s(item.organization)),
                    ]),
                  ]),
                  _c("div", { staticClass: "entity" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("开始日期：")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("dateFormat")(
                              item.studyStartDate,
                              "YYYY-MM-DD"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }