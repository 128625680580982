<template>
	<div class="searchbox-con">
		<div class="control-con">
			<router-link :to="'/'">
				<img src="../assets/logo.png" class="logo" />
			</router-link>
			<div class="input-con">
				<input
					type="text"
					class="inputBox"
					v-model="keyword"
					placeholder="请输入关键字"
					@keyup.enter="search"
				/>
				<el-select
					class="typeSelect"
					v-model="cur_type"
					@change="db_type_change"
				>
					<el-option
						v-for="t in types"
						:key="t.db_type"
						:label="t.db_name"
						:value="t.db_type"
					></el-option>
				</el-select>
				<button class="btnDoSearch" @click="search">搜索</button>
				<div class="btnAdv" @click="search1">高级搜索</div>
			</div>
			<div v-if="toSearch" class="to-search-panel">
				<Search
					:toSearch="toSearch"
					:db_type="cur_type"
					@getTreeData="testData"
				></Search>
			</div>
		</div>
	</div>
</template>
<script>
import Search from "./search.vue"
export default {
	name: "SearchBox",
	props: ["db_type"],
	components: {
		Search
	},
	data() {
		return {
			toSearch: false,
			types: [
				{
					db_name: "Aptamer数据库",
					db_type: "0",
					db_place_holder: "请输入序列或靶标",
					db_route: "/aptamerdb"
				},
				{
					db_name: "文献资料",
					db_type: "1",
					db_place_holder: "请输入文章标题或关键字",
					db_route: "/paper"
				},
				{
					db_name: "临床进展",
					db_type: "2",
					db_place_holder: "请输入标题",
					db_route: "/clinical"
				},
				{
					db_name: "团队介绍",
					db_type: "3",
					db_place_holder: "请输入负责人或单位",
					db_route: "/team"
				},
				{
					db_name: "科研项目",
					db_type: "4",
					db_place_holder: "请输入项目标题或关键字",
					db_route: "/project"
				},
				{
					db_name: "相关专利",
					db_type: "5",
					db_place_holder: "请输入专利名称",
					db_route: "/patent"
				}
			],
			keyword: "",
			cur_type: this.db_type,
			db_place_holder: ""
		}
	},
	mounted() {
		if (this.db_type === "") {
			this.cur_type = this.$route?.meta?.default_db_type || "0"
		}
		this.setPlaceHolder()
		this.setKeyword()
	},
	watch: {
		$route(to, from) {
			console.log("searchbox route change,route path:" + to.path)
			var dbType = to?.meta?.default_db_type || "0"
			this.cur_type = dbType
			this.setPlaceHolder()
		}
	},
	methods: {
		search() {
			if (this.keyword) {
				var route = this.types.find((e) => e.db_type === this.cur_type).db_route
				var _keyword = encodeURIComponent(this.keyword)

				console.log(route, _keyword)
				this.$router.push(route + "?keyword=" + (_keyword || "") + "&searchtype=0")
			} else {
				this.$message({ message: "情输入关键字！", type: "warning" })
			}
		},
		db_type_change() {
			this.setPlaceHolder()
		},
		setPlaceHolder() {
			this.db_place_holder = this.types.find(
				(e) => e.db_type === this.cur_type
			).db_place_holder
		},
		search1() {
			this.toSearch = true
		},
		testData(data) {
			this.toSearch = data
		},
		setKeyword() {
			this.keyword = this.$route.query.keyword
		}
	}
}
</script>
<style scoped lang="less">
.searchbox-con {
	width: 100%;
	height: 96px;
	background: white;
	margin: 0px auto 20;
	display: flex;
	justify-content: center;
	.control-con {
		width: 1170px;
		display: flex;
		align-items: center;
		.input-con {
			border-radius: 2px;
			border: 1px solid #3463ea;
			background: #f1f2f7;
			width: 600px;
			height: 46px;
			margin-left: 273px;
			box-sizing: content-box;
			display: flex;
			align-items: stretch;
			position: relative;

			.inputBox {
				width: 323px;
				height: 30px;
				border: none;
				margin: 8px;
				margin-left: 15px;
				background: none;
				.c-font-size-16();
			}
			.inputBox:focus {
				outline: none;
			}
			.btnAdv {
				// 暂时隐藏
				cursor: pointer;
				.c-font-size-14();
				margin-left: 10px;
				text-decoration: none;
				color: #3463ea;
				bottom: -20px;
				width: 60px;
				position: absolute;
			}
			.typeSelect {
				width: 164px;
			}

			.btnDoSearch {
				height: 46px;
				width: 88px;
				border: none;
				background: #3462e9;
				color: white;
				cursor: pointer;
				.c-font-size-16();
			}

			/deep/ .el-input__inner {
				border: none;
				background: none;
				border-left: 1px solid #3463ea !important;
				border-radius: 0;
				.c-font-size-16();
				height: 46px;
			}
		}
	}
}
.to-search-panel {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(255, 255, 255, 0.9);
	z-index: 1000;
	display: flex;
}
</style>
