/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.33
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The EvaluationOtherInfo model module.
 * @module model/EvaluationOtherInfo
 * @version v1
 */
export class EvaluationOtherInfo {
  /**
   * Constructs a new <code>EvaluationOtherInfo</code>.
   * @alias module:model/EvaluationOtherInfo
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EvaluationOtherInfo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EvaluationOtherInfo} obj Optional instance to populate.
   * @return {module:model/EvaluationOtherInfo} The populated <code>EvaluationOtherInfo</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EvaluationOtherInfo();
      if (data.hasOwnProperty('articleID'))
        obj.articleID = ApiClient.convertToType(data['articleID'], 'String');
      if (data.hasOwnProperty('iFs'))
        obj.iFs = ApiClient.convertToType(data['iFs'], 'Number');
      if (data.hasOwnProperty('doi'))
        obj.doi = ApiClient.convertToType(data['doi'], 'String');
      if (data.hasOwnProperty('similar'))
        obj.similar = ApiClient.convertToType(data['similar'], 'Number');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('journalName'))
        obj.journalName = ApiClient.convertToType(data['journalName'], 'String');
      if (data.hasOwnProperty('publishDate'))
        obj.publishDate = ApiClient.convertToType(data['publishDate'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} articleID
 */
EvaluationOtherInfo.prototype.articleID = undefined;

/**
 * @member {Number} iFs
 */
EvaluationOtherInfo.prototype.iFs = undefined;

/**
 * @member {String} doi
 */
EvaluationOtherInfo.prototype.doi = undefined;

/**
 * @member {Number} similar
 */
EvaluationOtherInfo.prototype.similar = undefined;

/**
 * @member {String} title
 */
EvaluationOtherInfo.prototype.title = undefined;

/**
 * @member {String} journalName
 */
EvaluationOtherInfo.prototype.journalName = undefined;

/**
 * @member {String} publishDate
 */
EvaluationOtherInfo.prototype.publishDate = undefined;

