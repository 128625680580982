var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-panel" }, [
    _c("div", { staticClass: "top-bar" }, [
      _c("div", [
        _vm._v("序列对比 "),
        _c(
          "a",
          { attrs: { href: "javascript:void(0)" }, on: { click: _vm.goBack } },
          [_vm._v("<< 返回结果列表")]
        ),
      ]),
    ]),
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "loading" },
          [_c("Loading", { attrs: { promptText: "对比结果加载中。。。" } })],
          1
        )
      : _vm.data.hsps && _vm.data.hsps.length > 0 && !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "recommended" },
          [
            _c(
              "el-row",
              { staticClass: "aptamerName", staticStyle: { margin: "0" } },
              [
                _c("div", { staticClass: "line" }),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.aptamerDetail },
                  },
                  [_vm._v(_vm._s(_vm.data.info.name))]
                ),
                _c("span", [_vm._v("对比详情")]),
              ]
            ),
            _c(
              "el-row",
              {
                staticClass: "fontSize16",
                staticStyle: { "margin-top": "10px" },
              },
              [
                _vm._v(
                  " Sequence ID: " +
                    _vm._s(_vm.data.info.aptamerID) +
                    "   Length: " +
                    _vm._s(_vm.data.info.length) +
                    "  Number of Matches: " +
                    _vm._s(_vm.data.hsps.length) +
                    " "
                ),
              ]
            ),
            _vm._l(_vm.data.hsps, function (contrastInfo, i) {
              return _c(
                "el-row",
                { key: i, staticClass: "contrast fontSize14" },
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "fontSize16",
                      staticStyle: {
                        "margin-bottom": "10px",
                        "margin-top": "20px",
                      },
                    },
                    [
                      _vm._v(
                        " Query：" +
                          _vm._s(contrastInfo.queryStart) +
                          " to " +
                          _vm._s(contrastInfo.queryEnd) +
                          "    Sbjct：" +
                          _vm._s(contrastInfo.sbjctStart) +
                          " to " +
                          _vm._s(contrastInfo.sbjctEnd) +
                          " "
                      ),
                    ]
                  ),
                  _c("el-row", { staticClass: "contrastInfo" }, [
                    _c("div", { staticStyle: { width: "205px" } }, [
                      _c("p", [_vm._v("Score")]),
                      _c("p", [_vm._v(_vm._s(contrastInfo.score))]),
                    ]),
                    _c("div", { staticStyle: { width: "124px" } }, [
                      _c("p", [_vm._v("Expect")]),
                      _c("p", [
                        _vm._v(
                          _vm._s(parseFloat(contrastInfo.expect).toFixed(2))
                        ),
                      ]),
                    ]),
                    _c("div", { staticStyle: { width: "207px" } }, [
                      _c("p", [_vm._v("Identities")]),
                      _c("p", [
                        _vm._v(
                          _vm._s(contrastInfo.identicalCount) +
                            " / " +
                            _vm._s(contrastInfo.alignLength) +
                            " (" +
                            _vm._s(
                              (contrastInfo.identicalPercent * 100).toFixed(2)
                            ) +
                            "%)"
                        ),
                      ]),
                    ]),
                    _c("div", { staticStyle: { width: "174px" } }, [
                      _c("p", [_vm._v("Gaps")]),
                      _c("p", [
                        _vm._v(
                          _vm._s(contrastInfo.gapCount) +
                            " / " +
                            _vm._s(contrastInfo.alignLength) +
                            " (" +
                            _vm._s((contrastInfo.gapPercent * 100).toFixed(2)) +
                            "%)"
                        ),
                      ]),
                    ]),
                    _c("div", { staticStyle: { width: "110px" } }, [
                      _c("p", [_vm._v("Strand")]),
                      _c("p", [_vm._v(_vm._s(contrastInfo.strand))]),
                    ]),
                  ]),
                  _vm._l(contrastInfo.contractResult, function (con, j) {
                    return _c(
                      "el-row",
                      { key: j },
                      [
                        _c(
                          "el-row",
                          { staticClass: "thanInfo" },
                          [
                            _c("div", { staticClass: "name" }, [
                              _vm._v("Query"),
                            ]),
                            _vm._l(con.align1, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "value" },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                          ],
                          2
                        ),
                        _c(
                          "el-row",
                          { staticClass: "thanInfo" },
                          [
                            _c("div", { staticClass: "name" }),
                            _vm._l(con.middle, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "value" },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                          ],
                          2
                        ),
                        _c(
                          "el-row",
                          { staticClass: "thanInfo" },
                          [
                            _c("div", { staticClass: "name" }, [
                              _vm._v("Sbjct"),
                            ]),
                            _vm._l(con.align2, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "value" },
                                [_vm._v(_vm._s(item))]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            }),
          ],
          2
        )
      : _c(
          "div",
          { staticClass: "nodata" },
          [
            _c("el-row", { staticClass: "aptamerName" }, [
              _c("div", { staticClass: "line" }),
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: { click: _vm.aptamerDetail },
                },
                [_vm._v(_vm._s(_vm.data.info.name))]
              ),
              _c("span", [_vm._v("对比详情")]),
            ]),
            _vm._m(0),
          ],
          1
        ),
    _vm._m(1),
    _c(
      "div",
      { staticStyle: { width: "100%" } },
      [
        _c(
          "el-table",
          {
            staticClass: "dataTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.otherInfo,
              border: "",
              "cell-style": { borderColor: "#dbdbdb" },
              "header-cell-style": { borderColor: "#dbdbdb" },
            },
            on: { "sort-change": _vm.orderChange },
          },
          [
            _c("el-table-column", {
              attrs: { align: "center", prop: "articleID", label: "文献信息" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#3463ea",
                            cursor: "pointer",
                            "text-decoration": "underline",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.articleDetail(row.articleID)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              row.title +
                                ". " +
                                row.journalName +
                                ". " +
                                row.publishDate
                            )
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "180px",
                sortable: "true",
                align: "center",
                prop: "iFs",
                label: "影响因子",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _vm._v(
                        " " + _vm._s(row.iFs ? row.iFs.toFixed(2) : "-") + " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "180px",
                sortable: "true",
                align: "center",
                prop: "similar",
                label: "数据完整度",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ row }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            row.similar
                              ? (row.similar * 100).toFixed(2) + "%"
                              : "-"
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "pagination",
        staticStyle: { "margin-top": "21px", "margin-bottom": "30px" },
      },
      [
        _c("el-pagination", {
          attrs: {
            id: "pagination",
            "current-page": _vm.pageIndex,
            "page-sizes": [10, 20, 50],
            "page-size": _vm.pageSize,
            layout: "prev, pager, next, jumper,sizes",
            total: _vm.total,
          },
          on: {
            "update:currentPage": function ($event) {
              _vm.pageIndex = $event
            },
            "update:current-page": function ($event) {
              _vm.pageIndex = $event
            },
            "update:pageSize": function ($event) {
              _vm.pageSize = $event
            },
            "update:page-size": function ($event) {
              _vm.pageSize = $event
            },
            "current-change": _vm.query,
            "size-change": _vm.sizeChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nodataImg" }, [
      _c("img", { attrs: { src: require("../../assets/nodata2.png") } }),
      _c("p", [_vm._v("暂无数据")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-bar" }, [
      _c("div", [_vm._v("其他数据")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }