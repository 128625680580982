/*
 * AptamerDataBase.Web
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.34
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {FrontpageDynamicListDtoResponse} from '../model/FrontpageDynamicListDtoResponse';

/**
* DynamicManager service.
* @module api/DynamicManagerApi
* @version v1
*/
export class DynamicManagerApi {

    /**
    * Constructs a new DynamicManagerApi. 
    * @alias module:api/DynamicManagerApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the apiDynamicManagerDynamicListGet operation.
     * @callback moduleapi/DynamicManagerApi~apiDynamicManagerDynamicListGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FrontpageDynamicListDtoResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 动态信息列表
     * @param {Object} opts Optional parameters
     * @param {Number} opts.index 
     * @param {Number} opts.size 
     * @param {module:api/DynamicManagerApi~apiDynamicManagerDynamicListGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    apiDynamicManagerDynamicListGet(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'Index': opts['index'],'Size': opts['size']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = FrontpageDynamicListDtoResponse;

      return this.apiClient.callApi(
        '/api/DynamicManager/DynamicList', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}