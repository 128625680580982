<template>
	<div class="main-panel">
		<div class="top">
			<div class="main-image">
				<el-carousel
					ref="img"
					height="350px"
					width="775px"
					:autoplay="true"
					:interval="3000"
					arrow="always"
				>
					<el-carousel-item v-for="(img, index) of feedsArticleList" :key="index">
						<img v-if="img.imgPath"
						class="carouselImg"
							:src="imgPath + img.imgPath"
							@click="toFeedsArticleList"
						/>
						<img v-else src="@/assets/sequence-default2.png" />
						<div class="cover ellipsisContent" style="-webkit-line-clamp: 1">
							<span @click="toFeedsArticleList">{{ img.title }}</span>
						</div>
					</el-carousel-item>
				</el-carousel>
				<div class="moreDynamic" @click="toFeedsArticleList">
					更多文献动态<img src="../../assets/more.png" />
				</div>
			</div>
			<div class="dynamic">
				<el-row class="labelRow"><img src="../../assets/newest.png" />最新动态</el-row>
				<el-row class="noData" v-if="topDynamicList.length === 0 && dynamicList.length === 0">
					<img src="../../assets/nodata2.png" />
					<p>暂无数据</p>
				</el-row>
				<div class="dynamicRow" v-for="(d, index) of topDynamicList" :key="index" @click="openLink(d.link)">
					<img src="../../assets/ding.png" />
					<el-tooltip placement="bottom" effect="light">
						<div class="dynamicTip" slot="content">{{d.content}}</div>
						<span class="title ellipsisContent">{{ d.content }}</span>
					</el-tooltip>
					<span class="time">{{ d.createOn | dateFormat("YYYY-MM-DD") }}</span>
				</div>
				<el-row class="breakLine" v-if="topDynamicList.length > 0"></el-row>
				<el-scrollbar :style="{height: scrollHeight + 'px'}">
					<div class="dynamicRow" v-for="(d, index) of dynamicList" :key="index" @click="openLink(d.link)">
						<el-tooltip placement="bottom" effect="light">
						<div class="dynamicTip" slot="content">{{d.content}}</div>
							<span class="title ellipsisContent">{{ d.content }}</span>
						</el-tooltip>
						<span class="time">{{ d.createOn | dateFormat("YYYY-MM-DD") }}</span>
					</div>
				</el-scrollbar>
			</div>
		</div>
		<div class="bottom">
			<div class="aptamer box">
				<div class="subject-container">
					<div class="top-bar">
						<div class="icon">
							<img src="../../assets/clock.png" />
						</div>
						<div style="margin-left: 4px" class="focused title">最近更新</div>
					</div>
					<div class="aptamer-item-con">
						<span class="btnAptamerL" @click="leftAptamer"
							><img src="../../assets/arrow-up.png" class="arrow-left" alt=""
						/></span>
						<span class="btnAptamerR" @click="rightAptamer"
							><img src="../../assets/arrow-up.png" class="arrow-right" alt=""
						/></span>
						<div
							:class="[
								currentAptamerIndex == i ? 'aptamer-item' : 'aptamer-item hide'
							]"
							v-for="(item, i) in aptamers"
							:key="item.aptamerId"
						>
							<router-link :to="'/aptamerdb/' + item.aptamerID">
								<div class="image">
									<!-- <img :src="defaultAptamerImage" /> -->
									<img :src="`${imgHost}/${item.secondaryStructureFigure}`" />
								</div>
								<div class="name">
									{{ item.aptamerName }}
								</div>
								<div class="journal-title">
									{{ item.journal }}
								</div>
								<div class="description">
									{{ item.articleTitle | ellipsis(80) }}"
									<!-- <span v-if="item.publishedDate != ''">
										{{ item.publishedDate | dateFormat("YYYY-MM-DD") }}</span
									> -->
								</div>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="article box">
				<div class="subject-container">
					<div class="top-bar">
						<div class="icon">
							<img src="../../assets/book.png" />
						</div>
						<div
							style="margin-left: 4px"
							:class="[patentFocused, 'title']"
							@click="focusTypeOfArticle('patent')"
						>
							重点专利
						</div>
						<div
							:class="[articleFocused, 'title']"
							@click="focusTypeOfArticle('article')"
						>
							重要文献
						</div>
					</div>
					<div class="list2" v-if="articleType == 'article'">
						<div class="left-line" />
						<div class="item" v-for="(article, i) in articles" :key="i">
							<!-- <div class="icon">
								<img src="../../assets/article_demo_img.png" />
							</div> -->
							<div class="circle">
								<img src="../../assets/circle.jpg" />
							</div>
							<div class="content">
								<router-link :to="'/paper/' + article.articleID" class="title">
									{{ article.articleTitle | ellipsis(80) }}
								</router-link>
								<span class="date">{{
									article.publishedDate | dateFormat("YYYY/MM")
								}}</span>
							</div>
						</div>
					</div>
					<div class="list2" v-if="articleType == 'patent'">
						<div class="left-line" />
						<div class="item" v-for="(patent, i) in patents" :key="i">
							<!-- <div class="icon">
								<img :src="patent.cover" />
							</div> -->
							<div class="circle">
								<img src="../../assets/circle.jpg" />
							</div>
							<div class="content">
								<router-link :to="'/patent/' + patent.patentID" class="title">
									{{ patent.title | ellipsis(80) }}
								</router-link>
								<span class="date">{{
									patent.publicationDate | dateFormat("YYYY/MM")
								}}</span>
							</div>
						</div>
					</div>
					<div class="navigate">
						<button @click="navToArticle()">查看更多</button>
					</div>
				</div>
			</div>
			<div class="project box">
				<div class="subject-container">
					<div class="top-bar">
						<div class="icon">
							<img src="../../assets/book.png" />
						</div>
						<div
							style="margin-left: 4px"
							:class="[projectFocused, 'title']"
							@click="focusTypeOfProject('project')"
						>
							科研项目
						</div>
						<div
							:class="[trialFocused, 'title']"
							@click="focusTypeOfProject('clinical')"
						>
							临床试验
						</div>
					</div>
					<div class="list2" v-if="projectType == 'project'">
						<div class="left-line" />
						<div class="item" v-for="(project, i) in projects" :key="i">
							<div class="circle">
								<img src="../../assets/circle.jpg" />
							</div>
							<div class="content">
								<router-link :to="'/project/' + project.id" class="title">
									{{ project.title | ellipsis(80) }}
								</router-link>
								<div class="other-info">
									<span class="project-date">{{
										project.beginDate | dateFormat("YYYY/MM")
									}}</span>
									<span class="project-location-label">所在地：</span>
									<span class="location">{{
										project.country == null ? "" : project.country.name
									}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="list2" v-if="projectType == 'clinical'">
						<div class="left-line" />
						<div class="item" v-for="(clinical, i) in clinicals" :key="i">
							<div class="circle">
								<img src="../../assets/circle.jpg" />
							</div>
							<div class="content">
								<router-link :to="'/clinical/' + clinical.id" class="title">
									{{ clinical.title | ellipsis(80) }}
								</router-link>
								<div class="other-info">
									<span class="date">{{
										clinical.studyStartDate | dateFormat("YYYY/MM")
									}}</span>
									<span class="location-label">研究机构：</span>
									<span class="location" :title="clinical.organization">{{
										clinical.organization | ellipsis(20)
									}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="navigate">
						<button @click="navToProject()">查看更多</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	ApiClient,
	AptamerApi,
	PatentApi,
	ArticleApi,
	ProjectApi,
	ClinicalApi,
	SysConfigApi,
	DynamicManagerApi,
	FeedsArticleApi
} from "../../api"

export default {
	data() {
		return {
			articleType: "patent",
			projectType: "project",
			aptamerApi: new AptamerApi(ApiClient.instance),
			patentApi: new PatentApi(ApiClient.instance),
			articleApi: new ArticleApi(ApiClient.instance),
			projectApi: new ProjectApi(ApiClient.instance),
			clinicalApi: new ClinicalApi(ApiClient.instance),
			sysConfigApi: new SysConfigApi(ApiClient.instance),
			dynamicApi: new DynamicManagerApi(ApiClient.instance),
			feedsArticleApi: new FeedsArticleApi(ApiClient.instance),
			aptamers: [],
			clinicals: [],
			patents: [],
			articles: [],
			projects: [],
			defaultTeamName: "谭蔚泓研究组",
			defaultTeamInstitution: "中国科学院肿瘤与基础医学研究所",
			defaultTeamCountry: "中国",
			defaultTeamDescription:
				"谭蔚泓，美国密西根大学物理化学博士，中国科学院物理化学硕士，湖南师范大学化学学士...",
			defaultAptamerName: "Ethanolamine",
			defaultAptamerDescription:
				"Mann, Doerthe, et al. 'In vitro selection of DNA aptamers binding ethanolamine. ' ...",
			currentAptamerIndex: 0,
			feedsArticleList: [],
			swiperOption: {
				loop: true,
				autoplay: {
					delay: 1500,
					stopOnLastSlide: false,
					disableOnInteraction: false
				},
				// 显示分页
				pagination: {
					el: ".swiper-pagination",
					clickable: true // 允许分页点击跳转
				},
				// 设置点击箭头
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev"
				}
			},
			teamInfo: {
				image: "",
				name: "",
				unit: "",
				country: "",
				link: "",
				introduction: ""
			},
			imgPath: "",
			topDynamicList: [],
			dynamicList: []
		}
	},
	filters: {},
	computed: {
		articleFocused() {
			return {
				focused: this.articleType === "article"
			}
		},
		patentFocused() {
			return {
				focused: this.articleType === "patent"
			}
		},
		projectFocused() {
			return {
				focused: this.projectType === "project"
			}
		},
		trialFocused() {
			return {
				focused: this.projectType === "clinical"
			}
		},
		imgHost() {
			return process.env.VUE_APP_IMG_API
		},
		scrollHeight() {
			var hei = 0
			if (this.topDynamicList.length > 0) {
				hei = 349 - this.topDynamicList.length * (22 + 20) - 20 - 2
			} else {
				hei = 349
			}
			return hei
		}
	},
	mounted() {
		this.imgPath = process.env.VUE_APP_IMG_API + "feeds_article_img/"
		this.getFeedsArticle()
		this.getDynamicList()
		this.init()
	},
	methods: {
		openLink(link) {
			window.open(link)
		},
		getTeamInfo() {
			this.sysConfigApi.apiSysConfigQueryGet(
				{ request: 2 },
				(err, data, resp) => {
					if (err) {
						console.error(err)
					}
					if (data) {
						this.teamInfo = JSON.parse(data.result)
					}
				}
			)
		},
		getDynamicList() {
			this.dynamicApi.apiDynamicManagerDynamicListGet(
				{ index: 1, size: 10 },
				(err, data, resp) => {
					if (err) {
						console.error(err)
					}
					if (data) {
						this.dynamicList = data.result.dynamicList
						this.topDynamicList = data.result.topDynamicList
						console.log(this.dynamicList, this.topDynamicList)
					}
				}
			)
		},
		imgNext() {
			this.$refs.img.next()
		},
		imgPrev() {
			this.$refs.img.prev()
		},
		init() {
			const searchType = 0
			this.queryAptamer(searchType)

			this.queryArticle(searchType)
			this.queryPatent(searchType)

			this.queryClinical(searchType)
			this.queryProject(searchType)
		},
		queryAptamer(searchType) {
			this.aptamerApi.apiAptamerQueryGet(
				0,
				0,
				{ index: 1, size: 5 },
				(e, data, resp) => {
					if (data.success) {
						this.aptamers = data.result.records
					}
				}
			)
		},
		queryPatent(searchType) {
			this.patentApi.apiPatentQueryGet(
				searchType,
				{ index: 0, size: 5, orderBy: "" },
				(error, data, response) => {
					if (error) {
						console.log(error)
					}
					if (data.success) {
						this.patents = data.result.records.map((item) => {
							var rec = item
							// rec.cover = rec.cover ? process.env.VUE_APP_IMG_API + rec.cover : null
							rec.cover = process.env.VUE_APP_IMG_API + rec.cover
							return rec
						})
					}
				}
			)
		},
		queryArticle(searchType) {
			this.articleApi.apiArticleQueryGet(
				"date",
				searchType,
				{ index: 1, size: 5 },
				(error, data, response) => {
					if (error) {
						console.log(error)
					}
					if (data.success) {
						this.articles = data.result.records
					}
				}
			)
		},
		queryProject(searchType) {
			this.projectApi.apiProjectQueryGet(
				searchType,
				{ index: 1, size: 5, orderBy: "" },
				(error, data, response) => {
					if (error) {
						console.log(error)
					}
					if (data.success) {
						this.projects = data.result.records
					}
				}
			)
		},
		queryClinical(searchType) {
			this.clinicalApi.apiClinicalQueryGet(
				searchType,
				{ index: 1, size: 5, orderBy: "" },
				(error, data, response) => {
					if (error) {
						console.log(error)
					}
					if (data.success) {
						this.clinicals = data.result.records
					}
				}
			)
		},
		focusTypeOfArticle(focusedType) {
			if (this.articleType === focusedType) {
				return
			}

			this.articleType = focusedType
		},
		focusTypeOfProject(focusedType) {
			if (this.projectType === focusedType) {
				return
			}

			this.projectType = focusedType
		},
		navToTeam() {
			this.$router.push({ path: "/team" })
		},
		navToArticle() {
			if (this.articleType === "article") {
				this.$router.push({ path: "/paper" })
			} else if (this.articleType === "patent") {
				this.$router.push({ path: "/patent" })
			}
		},
		navToProject() {
			if (this.projectType === "project") {
				this.$router.push({ path: "/project" })
			} else if (this.projectType === "clinical") {
				this.$router.push({ path: "/clinical" })
			}
		},
		ellipsis(val, maxLength) {
			if (!val) return ""

			if (val.length > maxLength) {
				return val.slice(0, maxLength) + "..."
			}
			return val
		},
		leftAptamer() {
			var newIndex = this.currentAptamerIndex + 1
			if (newIndex >= 5) {
				newIndex = 0
			}
			this.currentAptamerIndex = newIndex
		},
		rightAptamer() {
			var newIndex = this.currentAptamerIndex - 1
			if (newIndex <= -1) {
				newIndex = 4
			}
			this.currentAptamerIndex = newIndex
		},
		getFeedsArticle() {
			this.feedsArticleApi.apiFeedsArticleQueryGet(
				{
					index: 1,
					size: 3,
					state: 1
				},
				(err, data, resp) => {
					if (err) {
						console.error(err)
					}
					if (data.success) {
						this.feedsArticleList = data.result.records.map(i => {
							return {
								title: i.title,
								imgPath: i.relativeImgPath
							}
						})
					}
				}
			)
		},
		toFeedsArticleList() {
			this.$router.push({ path: "/articledynamic" })
		}
	}
}
</script>

<style scoped lang="less">
.carouselImg {
	height: 100%;
	width: auto;
	cursor: pointer;
	margin: 0 auto;
	border:1px solid #e1e1e1;
}

.carouselPrevButton {
	position: absolute;
	top: 50%;
	z-index: 2;
	transform: translate(0, -100%);
	cursor: pointer;
}

/deep/ .el-carousel {
	background-color: #fff;

	.el-carousel__item {
		text-align: center;
	}

	.el-carousel__indicators {
		bottom: 45px;
		left: 90px;

		.el-carousel__indicator--horizontal.is-active button {
			width: 20px;
			height: 7px;
			background: #3462e9;
			opacity: 1;
			border-radius: 4px;
		}

		.el-carousel__indicator--horizontal button {
			width: 7px;
			height: 7px;
			background: #ffffff;
			border-radius: 50%;
			opacity: 0.5;
		}
	}

	.el-carousel__arrow--right {
		background-image: url("../../assets/prev-right.png");
		background-size: 36px 36px;
		background-repeat: no-repeat;
		right: 28px !important;
	}

	.el-carousel__arrow--left {
		background-image: url("../../assets/prev-left.png");
		background-size: 36px 36px;
		background-repeat: no-repeat;
		left: 28px !important;
	}

	.el-carousel__arrow {
		background-color: none !important;
		top: 50% !important;
		transform: translate(0, -100%) !important;

		i {
			display: none !important;
		}
	}
}

.main-panel {
	width: 1170px;
	margin: 20px auto;
}

.top {
	display: flex;
	flex-direction: row;
}

.main-image {
	position: relative;
	width: 775px;

	.moreDynamic {
		margin-top: 10px;
		height: 30px;
		line-height: 30px;
		font-size: 16px;
		color: #333;
		text-align: right;
		cursor: pointer;

		img {
			width: 10px;
			height: 10px;
			margin-left: 10px;
			vertical-align: middle;
		}
	}
}

// .main-image img {
// 	width: 100%;
// 	height: 100%;
// }

.main-image .cover {
	text-align: left;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 50px;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 2;
}

.main-image .cover span {
	line-height: 50px;
	.c-font-size-18();
	.c-font-family-PF();
	font-weight: 500;
	color: #ffffff;
	margin-left: 20px;
	cursor: pointer;
}

.top-bar {
	height: 28px;
	width: 350px;
	margin: 12px auto;
	display: flex;
	flex-direction: row;
}

.top-bar .icon img {
	width: 16px;
	height: 17px;
	margin: 5px 0;
}

.top-bar .title {
	.c-font-size-16();
	.c-font-family-PF();
	font-weight: 400;
	line-height: 28px;
	color: #b3b3b3;
	margin-left: 20px;
}

.top-bar .title.focused {
	.c-font-size-18();
	color: #444444;
}

.subject-container {
	margin: 8px 15px;
	height: 100%;
}

.aptamer-item-con {
	display: flex;
	flex-direction: row;
	overflow: hidden;
	position: relative;
	.btnAptamerL {
		position: absolute;
		top: 150px;
		left: 5px;
	}
	.btnAptamerR {
		position: absolute;
		top: 150px;
		right: 5px;
	}

	.arrow-left {
		transform: rotate(-90deg);
		cursor: pointer;
	}
	.arrow-right {
		transform: rotate(90deg);
		cursor: pointer;
	}
}

.dynamic {
	width: 380px;
	height: 390px;
	background-color: #ffffff;
	margin-left: 15px;
	padding: 8px 15px;
	position: relative;

	.noData {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		font-size: 14px;
		color: #bfbfbf;

		img {
			margin-bottom: 20px;
		}
	}

	.labelRow {
		color: #333;
		font-size: 18px;
		img {
			width: 18px;
			height: 18px;
			margin-right: 10px;
			vertical-align: middle;
		}
	}

	.breakLine {
		width: 100%;
		border: 1px solid #E1E1E1;
	}

	/deep/ .el-scrollbar__wrap {
		overflow-x: hidden;
		overflow-y: auto;
	}

	.scrollRow {
		height: auto;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.dynamicRow {
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 16px;
		display: flex;
		position: relative;
		cursor: pointer;

		img {
			position: absolute;
			width: 16px;
			height: 16px;
			top: 3px;
		}

		.title {
			color: #666;
			width: 225px;
			margin-right: 5px;
			margin-left: 28px;
			-webkit-line-clamp: 1;
		}

		.time {
			color: #bcbcbc;
			width: 100px;
		}
	}

	.dynamicRow:hover {
		background-color: #e5e5e5;
	}
}

// .team {
// 	width: 380px;
// 	height: 350px;
// 	background-color: #ffffff;
// 	margin-left: 15px;
// }

// .team .top-bar .navigate {
// 	.c-font-size-14();
// 	.c-font-family-PF();
// 	font-weight: 400;
// 	line-height: 28px;
// 	color: #3463ea;
// 	cursor: pointer;
// 	margin-left: auto;
// 	margin-right: 0;
// }

// .team-image img {
// 	width: 100%;
// 	height: 167px;
// 	cursor: pointer;
// }

// .team .name {
// 	margin: 10px 0;
// 	line-height: 28px;
// 	.c-font-size-16();
// 	.c-font-family-PF();
// 	font-weight: 400;
// 	color: #444444;
// }

// .team .location {
// 	display: flex;
// 	flex-direction: row;
// 	.c-font-size-12();
// 	.c-font-family-PF();
// 	font-weight: 400;
// 	color: #999999;
// 	height: 53px;
// }

// .team .institution {
// 	display: flex;
// 	flex-direction: row;
// 	height: 100%;
// 	width: 50%;
// 	.label {
// 		width: 37%;
// 	}
// 	.value {
// 		width: 63%;
// 		text-overflow: -o-ellipsis-lastline;
// 		overflow: hidden;
// 		text-overflow: ellipsis;
// 		display: -webkit-box;
// 		-webkit-line-clamp: 3; //行数需设置
// 		line-clamp: 3;
// 		-webkit-box-orient: vertical;
// 	}
// }

// .team .country {
// 	margin-left: 16px;
// 	display: flex;
// 	flex-direction: row;
// 	height: 100%;
// 	width: 50%;
// 	.label {
// 		width: 37%;
// 	}
// 	.value {
// 		width: 63%;
// 		text-overflow: -o-ellipsis-lastline;
// 		overflow: hidden;
// 		text-overflow: ellipsis;
// 		display: -webkit-box;
// 		-webkit-line-clamp: 3; //行数需设置
// 		line-clamp: 3;
// 		-webkit-box-orient: vertical;
// 	}
// }

// .team .location .value {
// 	margin-left: 4px;
// }

// .team .description {
// 	.c-font-size-14();
// 	.c-font-family-PF();
// 	font-weight: 400;
// 	color: #666666;
// }

.bottom {
	margin-top: 16px;
	display: flex;
	flex-direction: row;
}

.bottom .box {
	width: 380px;
	height: 490px;
	background-color: #ffffff;
}

.aptamer .image {
	height: 300px;
	width: 350px;
	margin: 6px auto;
	text-align: center;
}

.aptamer .image img {
	height: 100%;
}

.aptamer .name {
	margin: 10px 0;
	line-height: 28px;
	.c-font-size-16();
	.c-font-family-PF();
	font-weight: 500;
	color: #444444;
}

.aptamer .journal-title {
	display: flex;
	flex-direction: row;
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	color: #999999;
	margin-bottom: 10px;
}

.aptamer .description {
	.c-font-size-14();
	.c-font-family-PF();
	font-weight: 400;
	color: #666666;
	overflow: hidden;
}

.article {
	margin: 0 15px;
}

.article .top-bar .title {
	cursor: pointer;
}

.list1 {
	margin: 0 0 4px 0;
	display: inline-block;
}

.list1 .item {
	display: flex;
	flex-direction: row;
	margin: 10px 0;
}

.list1 .item .icon {
	width: 64px;
	height: 64px;
}

.list1 .item .icon img {
	width: 64px;
	height: 64px;
	border: 1px solid rgb(165, 165, 165);
}

.list1 .item .content {
	height: 54px;
	line-height: 18px;
	margin: 5px 0 5px 10px;
	.c-font-family-PF();
	font-weight: 400;
}

.list1 .item .content .title {
	.c-font-size-14();
	color: #444444;
}

.list1 .item .content .date {
	.c-font-size-12();
	color: #999999;
	float: right;
	margin-top: 2px;
}

.list1 .item .content .project-date {
	.c-font-size-12();
	color: #999999;
	margin-top: 2px;
}

.list2 .item .content .date {
	.c-font-size-12();
	color: #999999;
	float: right;
	margin-top: 2px;
}
.article .navigate {
	width: 100%;
	text-align: center;
}

.article .navigate button {
	width: 157px;
	height: 34px;
	border: 1px solid #6098f3;
	.c-font-size-14();
	.c-font-family-PF();
	font-weight: 400;
	color: #3463ea;
	cursor: pointer;
	background-color: #ffffff;
	line-height: 34px;
}

.project .top-bar .title {
	cursor: pointer;
}

.list2 {
	position: relative;
	margin: 0 0 4px 0;
	display: inline-block;
}

.list2 .left-line {
	position: absolute;
	top: 24px;
	left: 6px;
	width: 2px;
	height: 346px;
	background-color: #d1ddf0;
}

.list2 .item {
	display: flex;
	flex-direction: row;
	min-height: 64px;
	margin: 10px 0;
}

.list2 .item .circle {
	width: 14px;
	height: 14px;
	margin-top: 4px;
	z-index: 0;
}

.list2 .item .circle img {
	width: 14px;
	height: 14px;
}

.list2 .item .content {
	width: 311px;
	height: 54px;
	line-height: 18px;
	margin: 5px 0 5px 10px;
	.c-font-family-PF();
	font-weight: 400;
}

.list2 .item .content .title {
	.c-font-size-14();
	color: #444444;
}

.list2 .item .content .other-info {
	.c-font-size-12();
	color: #999999;
	margin-top: 4px;
}

.list2 .item .content .other-info .location-label {
	margin-left: 0px;
}

.list2 .item .content .other-info .project-location-label {
	margin-left: 25px;
}

.project .navigate {
	width: 100%;
	text-align: center;
}

.project .navigate button {
	width: 157px;
	height: 34px;
	border: 1px solid #6098f3;
	.c-font-size-14();
	.c-font-family-PF();
	font-weight: 400;
	color: #3463ea;
	cursor: pointer;
	background-color: #ffffff;
	line-height: 34px;
}

.hide {
	display: none;
}
</style>
