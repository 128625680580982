<template>
	<div class="main-panel">
		<div class="top-bar">
			<div>序列对比 <a href="javascript:void(0)" @click="goBack">&lt;&lt; 返回结果列表</a></div>
		</div>
		<div v-if="isLoading" class="loading">
			<Loading promptText="对比结果加载中。。。"></Loading>
		</div>
		<div v-else-if="data.hsps && data.hsps.length > 0 && !isLoading" class="recommended">
			<el-row class="aptamerName" style="margin:0;">
				<div class="line"></div>
				<a href="javascript:void(0)" @click="aptamerDetail">{{ data.info.name }}</a>
				<span>对比详情</span>
			</el-row>
			<el-row class="fontSize16" style="margin-top:10px;">
				Sequence ID: {{ data.info.aptamerID }}&nbsp;&nbsp;&nbsp;Length: {{ data.info.length }} &nbsp;Number of Matches: {{data.hsps.length}}
			</el-row>
			<el-row class="contrast fontSize14" v-for="(contrastInfo, i) of data.hsps" :key="i">
				<el-row class="fontSize16" style="margin-bottom:10px;margin-top:20px;">
					Query：{{contrastInfo.queryStart}} to  {{ contrastInfo.queryEnd }}&nbsp;&nbsp;&nbsp;&nbsp;Sbjct：{{ contrastInfo.sbjctStart }} to {{ contrastInfo.sbjctEnd }}
				</el-row>
				<el-row class="contrastInfo">
					<div style="width:205px">
						<p>Score</p>
						<p>{{contrastInfo.score}}</p>
					</div>
					<div style="width:124px">
						<p>Expect</p>
						<p>{{parseFloat(contrastInfo.expect).toFixed(2)}}</p>
					</div>
					<div style="width:207px">
						<p>Identities</p>
						<p>{{ contrastInfo.identicalCount }} / {{ contrastInfo.alignLength }} ({{ (contrastInfo.identicalPercent * 100).toFixed(2) }}%)</p>
					</div>
					<div style="width:174px">
						<p>Gaps</p>
						<p>{{ contrastInfo.gapCount }} / {{ contrastInfo.alignLength }} ({{ (contrastInfo.gapPercent * 100).toFixed(2) }}%)</p>
					</div>
					<div style="width:110px">
						<p>Strand</p>
						<p>{{contrastInfo.strand}}</p>
					</div>
				</el-row>
				<el-row v-for="(con, j) of contrastInfo.contractResult" :key="j">
					<el-row class="thanInfo">
						<div class="name">Query</div>
						<!-- <div class="code">{{con.queryStratCode}}</div> -->
						<div class="value" v-for="(item, index) of con.align1" :key="index">{{item}}</div>
					</el-row>
					<el-row class="thanInfo">
						<div class="name"></div>
						<!-- <div class="code"></div> -->
						<div class="value" v-for="(item, index) of con.middle" :key="index">{{item}}</div>
					</el-row>
					<el-row class="thanInfo">
						<div class="name">Sbjct</div>
						<!-- <div class="code">{{con.sbjctStartCode}}</div> -->
						<div class="value" v-for="(item, index) of con.align2" :key="index">{{item}}</div>
					</el-row>
				</el-row>
			</el-row>
		</div>
		<div v-else class="nodata">
			<el-row class="aptamerName">
				<div class="line"></div>
				<a href="javascript:void(0)" @click="aptamerDetail">{{ data.info.name }}</a>
				<span>对比详情</span>
			</el-row>
			<div class="nodataImg">
				<img src="../../assets/nodata2.png" />
				<p>暂无数据</p>
			</div>
		</div>
		<div class="top-bar">
			<div>其他数据</div>
		</div>
		<div style="width:100%">
			<el-table
				:data="otherInfo"
				border
				style="width: 100%"
				class="dataTable"
				:cell-style="{borderColor:'#dbdbdb'}"
				:header-cell-style="{borderColor:'#dbdbdb'}"
				@sort-change="orderChange"
			>
				<el-table-column align="center" prop="articleID" label="文献信息">
					<template slot-scope="{ row }">
						<!-- {{row.doi || "-"}} -->
						<span style="color:#3463ea;cursor:pointer;text-decoration:underline;" @click="articleDetail(row.articleID)">{{ row.title + ". " + row.journalName + ". " + row.publishDate }}</span>
					</template>
				</el-table-column>
				<el-table-column width="180px" sortable="true" align="center" prop="iFs" label="影响因子">
					<template slot-scope="{ row }">
						{{ row.iFs ? row.iFs.toFixed(2) : "-" }}
					</template>
				</el-table-column>
				<el-table-column width="180px" sortable="true" align="center" prop="similar" label="数据完整度">
					<template slot-scope="{ row }">
						{{ row.similar ? ((row.similar*100).toFixed(2) + '%') : "-" }}
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="pagination" style="margin-top: 21px;margin-bottom: 30px;">
			<el-pagination
				id="pagination"
				:current-page.sync="pageIndex"
				:page-sizes="[10, 20, 50]"
				:page-size.sync="pageSize"
				layout="prev, pager, next, jumper,sizes"
				@current-change="query"
				@size-change="sizeChange"
				:total="total"
			>
			</el-pagination>
		</div>
	</div>
</template>
<script>
import { ApiClient, SequenceApi } from "../../api"
import Loading from "@/components/Loading.vue"
export default {
	data() {
		return {
			sequenceApi: new SequenceApi(ApiClient.instance),
			data: {},
			otherInfo: [],
			pageIndex: 1,
			pageSize: 10,
			total: 0,
			orderBy: 1,
			orderDesc: 1,
			isLoading: true
		}
	},
	components: {
		Loading
	},
	mounted() {
		// console.log(this.$route.query, this.$route.params)
		this.getContrastInfo()
		this.getOtherInfo()
	},
	methods: {
		getContrastInfo() {
			var queryId = this.$route.params.id
			var sbjctId = this.$route.query.sbjctid
			if (queryId && sbjctId) {
				this.isLoading = true
				this.sequenceApi.apiSequenceEvaluatecontrastdetailGet(
					{ queryID: queryId, sbjctID: sbjctId },
					(err, data, resp) => {
						if (err) {
							console.log(err)
						}
						if (data && data.success) {
							this.data = data.result
							console.log(this.data)
							this.bindList()
						}
						this.isLoading = false
					}
				)
			}
		},
		bindList() {
			if (this.data) {
				if (this.data.hsps) {
					this.data.hsps.forEach((item, index) => {
						this.$set(this.data.hsps[index], "contractResult", [])
						var leftqueryStrat = item.queryStart - 1
						var leftsbjStart = item.sbjctStart - 1
						// 拼接左侧占位字符
						// console.log(leftqueryStrat, leftsbjStart)
						// console.log(item.query, item.sbjct)
						console.log(this.data)
						var diff = 0
						if (leftqueryStrat < leftsbjStart) {
							if (leftqueryStrat !== 0) {
								item.query = this.data.info.sequence.substr(0, leftqueryStrat) + item.query
							}
							diff = leftsbjStart - leftqueryStrat
							for (var i = 0; i < diff; i++) {
								item.query = " " + item.query
							}
							for (i = 0; i < leftsbjStart; i++) {
								item.midline = " " + item.midline
							}
							item.sbjct = this.data.info.sbjSequence.substr(0, leftsbjStart) + item.sbjct
						} else if (leftqueryStrat > leftsbjStart) {
							if (leftsbjStart !== 0) {
								item.sbjct = this.data.info.sbjSequence.substr(0, leftsbjStart) + item.sbjct
							}
							diff = leftqueryStrat - leftsbjStart
							for (i = 0; i < diff; i++) {
								item.sbjct = " " + item.sbjct
							}
							for (i = 0; i < leftqueryStrat; i++) {
								item.midline = " " + item.midline
							}
							item.query = this.data.info.sequence.substr(0, leftqueryStrat) + item.query
						}

						// 拼接右侧占位字符
						item.query = item.query + this.data.info.sequence.substr(item.queryEnd)
						item.sbjct = item.sbjct + this.data.info.sbjSequence.substr(item.sbjctEnd)
						// console.log(item)

						var maxLen = item.query.length > item.sbjct.length ? item.query.length : item.sbjct.length
						if (maxLen <= 60) {
							const contrastRes = {
								queryStratCode: item.queryStart,
								sbjctStartCode: item.sbjctStart,
								align1: item.query.split(""),
								middle: item.midline.split(""),
								align2: item.sbjct.split("")
							}

							this.data.hsps[index].contractResult.push(contrastRes)
						} else {
							var times = Math.ceil(maxLen / 60)
							// console.log(times, item)
							for (i = 0; i < times; i++) {
								var contrastRes = {
									queryStratCode: item.queryStart + (i * 60),
									sbjctStartCode: item.sbjctStart + (i * 60),
									align1: item.query.slice((i * 60), (i + 1) * 60).split(""),
									middle: item.midline.slice((i * 60), (i + 1) * 60).split(""),
									align2: item.sbjct.slice((i * 60), (i + 1) * 60).split("")
								}

								this.data.hsps[index].contractResult.push(contrastRes)
							}
						}
					})
					// console.log(this.data.hsps)
				}
			}
		},
		goBack() {
			window.history.go(-1)
		},
		getOtherInfo() {
			if (this.$route.params.id) {
				this.sequenceApi.apiSequenceEvaluatecontrastotherinfoGet(
					{
						ID: this.$route.params.id,
						pageIndex: this.pageIndex,
						pageSize: this.pageSize,
						orderBy: this.orderBy,
						orderDesc: this.orderDesc
					},
					(err, data, resp) => {
						// console.log(resp, data, err)
						if (err) {
							console.log(err)
						}
						if (data?.success && data?.result) {
							console.log(data, data.result)
							this.otherInfo = data.result.records
							this.total = data.result.total ? parseInt(data.result.total) : 0
							// console.log(this.otherInfo)
						}
					})
			}
		},
		sizeChange(val) {
			this.pageSize = val
			this.getOtherInfo()
		},
		query() {
			this.getOtherInfo()
		},
		orderChange(order) {
			this.orderBy = (order.prop === "iFs" ? 6 : (order.prop === "similar" ? 5 : 0))
			this.orderDesc = order.order === "descending" ? 1 : 0
			this.getOtherInfo()
		},
		articleDetail(id) {
			this.$router.push("/paper/" + id)
		},
		aptamerDetail() {
			console.log(this.data.info)
			this.$router.push("/aptamerdb/" + this.data.info.aptamerID)
		}
	}
}
</script>
<style lang="less" scoped>
.loading {
	height: 250px;
	width: 100%;
	background-color: #ffffff;
	margin-bottom: 20px;
	position: relative;
}

.nodata {
	background: #FFFFFF;
	border: 1px solid #DBDBDB;
	opacity: 1;
	margin-bottom: 21px;
	width: 1171px;
	height: 278px;
	position: relative;

	.nodataImg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;

		p{
			font-size: 14px;
			font-weight: 400;
			color: #BFBFBF;
			opacity: 1;
			margin-top: 20px;
		}
	}
}

.aptamerName {
	margin:17px 0 0 24px;
	display:flex;
	.line {
		width: 3px;height: 16px;
		background: #3462E9;
		opacity: 1;
		border-radius: 1px;
		margin-top: 5px;
	}

	a {
		margin-left: 16px;
		margin-right: 13px;
		font-size: 18px;
		font-weight: 500;
		color: #0942FF;
		cursor: pointer;
		text-decoration: underline;
	}

	span {
		font-size: 18px;
		font-weight: 500;
		color: #333333;
		opacity: 1;
	}
}

* {
	margin: 0;
	padding: 0;
}
.main-panel {
	height: auto;
	padding-bottom: 20px;
}
.dataTable {
	border: 1px solid #dbdbdb;

	/deep/ .el-table__header-wrapper {
		tr {
			min-height: 49px;
		}
		th {
			background-color: #3463E9;
			color: #FFFFFF;
			font-weight: 400;
			font-size: 16px;
		}
	}

	/deep/ .el-table__body-wrapper {
		tr {
			min-height: 49px;
		}
		.el-table__row td {
			color: #333333;
			font-weight: 400;
			font-size: 16px;
			padding-top: 12px;
			padding-bottom: 12px;
		}
	}
}
.top-bar {
	width: 100%;
	background: #ffffff;
	border: 1px solid #dbdbdb;
	opacity: 1;
	border-radius: 0px;
	margin-bottom: 21px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	p {
		font-size: 12px;
		color: #666666;
		padding-right: 20px;
	}
	div {
		height: 45px;
		font-size: 16px;
		font-weight: 400;
		color: #333333;
		opacity: 1;
		padding: 12px 0 13px 12px;
	}

	a {
		margin-left: 10px;
		.c-font-size-14();
	}
}
.recommended {
	width: 100%;
	// height: 750px;
	background: #ffffff;
	border: 1px solid #dbdbdb;
	opacity: 1;
	border-radius: 0px;
	margin-bottom: 23px;
	color: #333333;
	font-weight: 400;
	padding: 20px 0 30px 41px;

	.title {
		font-weight: 500;
	}

	.contrast {
		.contrastInfo {
			width: 841px;
			height: 74px;
			border: 1px solid #666666;
			border-width: 2px 0;
			opacity: 1;

			div {
				padding-left: 21px;
				display: inline-block;
				p {
					line-height: 37px;
				}
			}
			margin-bottom: 20px;
		}

		.thanInfo {
			.name {
				width: 80px;
				text-align: center;
			}

			.code {
				width: 60px;
			}

			div {
				display: inline-block;
			}

			.value {
				width: 11px;
				text-align: center;
			}
		}
	}
}
</style>
