<template>
	<div class="main">
		<div class="top-tab">
			<ul>
				<li @click="active(1)" :class="{ select: num === 1 }">技术交流</li>
				<li @click="active(2)" :class="{ select: num === 2 }">学习交流</li>
				<li @click="active(3)" :class="{ select: num === 3 }">会议交流</li>
				<li @click="active(4)" :class="{ select: num === 4 }">需求发布</li>
			</ul>
		</div>
		<div class="content-area">
			<Content :isLogin="isLogin"></Content>
		</div>
	</div>
</template>
<script>
import Cookie from "js-cookie"
import { ApiClient, LearnTopicApi } from "../../api"
import Content from "./content.vue"
export default {
	components: {
		Content
	},
	data() {
		return {
			api: new LearnTopicApi(ApiClient.instance),
			num: 1,
			isLogin: false
		}
	},
	mounted() {
		if (Cookie.getJSON("user_info")) {
			this.isLogin = true
		}
	},
	methods: {
		active(i) {
			this.num = i
			this.$children[0].pageindex = 1
			this.$children[0].loadData(1, 10)
		}
	}
}
</script>
<style lang="less" scoped>
* {
	margin: 0;
	padding: 0;
}
ul {
	list-style: none;
}
.select {
	border-bottom: 4px solid #3462e9;
}
.main {
	width: 1170px;
	height: auto;
	padding-bottom: 20px;
	margin: 0 auto;
	.content-area {
		width: 100%;
		height: auto;
	}
	.top-tab {
		width: 100%;
		height: 45px;
		background: #ffffff;
		border: 1px solid #dbdbdb;
		opacity: 1;
		border-radius: 0px;
		ul {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			li {
				width: 80px;
				height: 100%;
				font-size: 16px;
				font-weight: 500;
				color: #333333;
				opacity: 1;
				margin: 0 48px 0 15px;
				cursor: pointer;
				line-height: 43px;
				text-align: center;
			}
		}
	}
}
</style>
