import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		contrastInfo: ""
	},
	mutations: {
		setContrastInfo(state, val) {
			state.contrastInfo = val
		}
	},
	actions: {},
	modules: {},
	getters: {
		contrastInfo: state => {
			return state.contrastInfo
		}
	}
})
