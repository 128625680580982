<template>
	<div class="head-bar" @mouseleave="selectLeave">
		<div class="navigate-container">
			<div
				class="navigate"
				:class="{ active: currentPath === '/' }"
				@click="navigateTo('')"
				style="width: 150px"
			>
				首页
			</div>
			<div
				class="navigate"
				:class="{ active: currentPath.indexOf('introduction') > -1 }"
				@click="navigateTo('introduction')"
				style="width: 122px"
			>
				<el-popover
					placement="bottom"
					popper-class="popper"
					width="122"
					trigger="hover"
				>
					<div class="navigate-select">
						<ul>
							<li @click="navigateTo('introduction')">适体简介</li>
							<li @click="navigateTo('aptamerdb')">Aptamer数据库</li>
							<li @click="navigateTo('paper')">文献资料</li>
							<li @click="navigateTo('patent')">重点专利</li>
							<li @click="navigateTo('project')">科研项目</li>
							<li @click="navigateTo('LargeScreen')">数据统计</li>
							<li @click="navigateTo('analysistool')">分析工具</li>
							<li @click="navigateTo('clinical')">临床进展</li>
						</ul>
					</div>
					<el-button slot="reference"> 核酸适体数据系统 </el-button>
				</el-popover>
			</div>
			<div
				class="navigate"
				:class="{ active: currentPath.indexOf('AptamerEvaluationPage') > -1 }"
				@click="navigateTo('AptamerEvaluationPage')"
				style="width: 122px"
			>
				核酸适体评价系统
			</div>
			<div
				class="navigate"
				:class="{ active: currentPath.indexOf('aptamerfilterpage') > -1 }"
				@click="navigateTo('aptamerfilterpage')"
				style="width: 150px"
			>
				核酸适体智能筛选系统
			</div>
			<div
				class="navigate"
				:class="{ active: currentPath.indexOf('team') > -1 }"
				@click="navigateTo('team')"
				style="width: 192px"
			>
				<el-popover
					placement="bottom"
					popper-class="education"
					width="190"
					trigger="hover"
					style="min-width: 0"
				>
					<div class="fitness-Education">
						<ul>
							<li @click="navigateTo('team')">团队介绍</li>
							<li @click="navigateTo('LiteratureLearning')">文献学习</li>
							<li @click="navigateTo('CommunicationPlatform')">交流平台</li>
							<li @click="navigateTo('TrainingCourse')">培训及课程</li>
						</ul>
					</div>
					<el-button slot="reference"> 核酸适体教育与科研服务系统 </el-button>
				</el-popover>
			</div>
			<div v-if="loggedIn" class="logged-in-panel">
				<div class="image-label cursor" @click="advancedSearch">
					<img src="../assets/search.png" />
					<div class="label">高级搜索</div>
				</div>
				<div class="image-label">
					<img src="../assets/language.png" />
					<div class="label">中文</div>
				</div>
				<div class="user" @click="navigateTo('usercenter')">
					<img src="../assets/user.png" />
				</div>
			</div>
			<div v-if="loggedIn == false" class="not-logged-in-panel">
				<div class="image-label">
					<img src="../assets/language.png" />
					<div class="label">中文</div>
				</div>
				<div class="to-login">
					<section v-if="userinfo == null">
						<span @click="login" id="loginSpan" class="popup">登录</span>
						<!-- <span> / </span>
						<span @click="register" class="popup">注册</span> -->
					</section>
					<!-- <section v-if="userinfo != null">
						<span @click="toUserCenter" class="popup">{{
							userinfo.nickname
						}}</span>
						<span> | </span>
						<span @click="logout" class="popup">退出</span>
					</section> -->
				</div>
			</div>
			<div class="imgUrl" v-if="avator" @mouseover="selectOver">
				<img :src="avator" slot="reference" alt="" />
			</div>
			<div class="selectList" v-if="selectShow" @mouseleave="selectLeave">
				<ul>
					<li @click="toUserCenter">个人中心</li>
					<li @click="ischangePassword = true">修改密码</li>
					<li @click="logout">退出登录</li>
				</ul>
			</div>
		</div>
		<div v-if="toLogin" class="to-login-panel">
			<Login></Login>
		</div>
		<div v-if="toRegister" class="to-register-panel">
			<Register></Register>
		</div>
		<div v-if="toForgetPwd" class="to-forgetpwd-panel">
			<ForgetPwd></ForgetPwd>
		</div>
		<div v-if="isSeqSub" class="to-login-panel">
			<SequenceSub @cancelsub="closeSeqSub"></SequenceSub>
		</div>
		<div
			v-if="showUserAgreement"
			class="to-useragreement-panel"
			@click="showUserAgreement = false"
		>
			<UserAgreement></UserAgreement>
		</div>
		<div id="mation" v-if="ischangePassword" class="to-login-panel">
			<ChangePassword @ischange="showchangePassword"></ChangePassword>
		</div>
		<div class="modal" v-if="showMessageBox">
			<div class="message-box-container" @click.stop="isolateClick()">
				<div class="modalClose" @click="showMessageBox = false">
				</div>
				<div class="title">问题反馈</div>
				<div class="textarea-container">
					<textarea
						v-model="feedback"
						cols="30"
						rows="10"
						placeholder="请输入反馈信息"
					></textarea>
				</div>
				<div class="submit">
					<button @click="submitFeedback()" :disabled="submitDisable">
						提交信息
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ApiClient, UserCenterApi } from "../api"
import Swal from "sweetalert2"
import Login from "./Login.vue"
import Register from "./Register.vue"
import ForgetPwd from "./ForgetPwd.vue"
import Cookie from "js-cookie"
import SequenceSub from "./SequenceSub.vue"
import UserAgreement from "./UserAgreement.vue"
import ChangePassword from "./ChangePassword.vue"

export default {
	data() {
		return {
			databaseShow: false,
			fitnessShow: false,
			selectShow: false,
			ischangePassword: false,
			loggedIn: false,
			toLogin: false,
			toRegister: false,
			userinfo: null,
			path: "",
			toSearch: false,
			api: new UserCenterApi(ApiClient.instance),
			isSeqSub: false, // 是否序列上传
			toForgetPwd: false,
			showUserAgreement: false,
			showMessageBox: false,
			feedback: "",
			submitDisable: false
		}
	},
	components: {
		Login,
		Register,
		SequenceSub,
		ForgetPwd,
		UserAgreement,
		ChangePassword
	},
	watch: {
		$route(to, from) {
			this.path = to.path
		}
	},
	computed: {
		currentPath() {
			return this.$route.path
		},
		avator() {
			if (localStorage.getItem("avator")) {
				return (
					"data:image/png;base64," + JSON.parse(localStorage.getItem("avator"))
				)
			} else {
				return null
			}
		}
	},
	mounted() {
		this.userinfo = Cookie.getJSON("user_info")
		this.path = this.$route.path
	},
	methods: {
		submitFeedback() {
			// console.log("地址信息", this.$route)
			if (!this.feedback) {
				alert("请输入反馈信息")
				return
			}
			this.api.apiUserCenterFeedbackPost(
				{ submittedURL: this.$route.path, content: this.feedback },
				(error, data, resp) => {
					if (error) {
					} else {
						this.feedback = ""
						Swal.fire("提交成功，感谢你的反馈！").then(
							() => (this.showMessageBox = false)
						)
					}
				}
			)
		},
		isolateClick() {

		},
		databaseOver() {
			this.databaseShow = true
		},
		databaseLeave() {
			if (this.databaseShow) {
				return
			}
			this.databaseShow = false
		},
		fitnessOver() {
			this.fitnessShow = true
		},
		fitnessLeave() {
			this.fitnessShow = false
		},
		changePassword(event) {
			var modal = document.getElementById("mation")
			if (event.target === modal) {
				this.ischangePassword = false
			}
		},
		showchangePassword(data) {
			this.ischangePassword = data
		},
		openChange() {
			this.ischangePassword = true
		},
		selectOver() {
			this.selectShow = true
		},
		selectLeave() {
			this.selectShow = false
		},
		navigateTo(page) {
			this.$router.push("/" + page)
		},
		login() {
			this.toLogin = true
		},
		register() {
			this.toRegister = true
		},
		forgetpwd() {
			this.toForgetPwd = true
		},
		closeLogin() {
			this.toLogin = false
		},
		closeRegister() {
			// console.log("进入closeRegister")
			this.toRegister = false
		},
		closeForgetPwd() {
			this.toForgetPwd = false
		},
		logout() {
			this.api.apiUserCenterSignoutPost((e, data, resp) => {
				Cookie.remove("user_info")
				localStorage.removeItem("avator")
				Swal.fire("退出成功！").then(() => window.location.reload())
			})
			this.selectShow = false
		},
		toUserCenter() {
			this.$router.push("/usercenter/info")
			this.selectShow = false
		},
		closeSeqSub() {
			this.isSeqSub = false
		},
		toUserAgreement() {
			this.showUserAgreement = true
		},
		closeUserAgreement() {
			this.showUserAgreement = false
		},
		UserAgreement(val) {
			// console.log("UserAgreement", val, this.$children[this.$children.length - 2])
			this.$children[this.$children.length - 2].isAccept = val
			this.closeUserAgreement()
		},
		changePasswordSubmit(val) {
			console.log(val)
			this.api.apiUserCenterChangepasswordPost(
				{ body: val },
				(e, data, resp) => {
					// console.log("修改密码", e, data)
					if (data && data.success && data.result) {
						Cookie.remove("user_info")
						localStorage.removeItem("avator")
						Swal.fire("密码修改成功，请重新登录").then(() =>
							window.location.reload()
						)
					} else {
						// 注释错误提示，已调用ApiClent错误提示
						// this.$message.error(
						// 	"密码修改失败。原密码错误，或新密码不符合规则。"
						// )
					}
				}
			)
		}
	}
}
</script>

<style lang="less" scoped>
textarea {
	padding: 10px 10px;
	outline: none;
	resize: none;
}
.navigate /deep/.el-button {
	height: 45px;
	border-radius: inherit;
	background: #333333;
	border: none;
	color: #ffffff;
	padding: 0;
}
.el-button:hover {
	background: inherit;
	color: inherit;
}
/deep/.el-button:focus {
	background: #3462e9 !important;
	color: #ffffff !important;
}
/deep/.el-button:active {
	background: #3462e9 !important;
	color: #ffffff !important;
}
.selectList {
	position: absolute;
	top: 45px;
	right: 0;
	width: 80px;
	height: 80px;
	border: 1px solid #e4e7ed;
	.c-font-size-12();
	background: white;
	box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
	z-index: 500;
}
.selectList ul {
	list-style: none;
	width: 100%;
	height: 100%;
}
.selectList ul li {
	width: 100%;
	text-align: center;
	height: 26px;
	line-height: 26px;
	cursor: pointer;
}
.imgUrl {
	width: 30px;
	height: 45px;
	display: flex;
	align-items: center;
	border-radius: 50%;
	margin-right: 22px;
	cursor: pointer;
}
.imgUrl img {
	width: 25px;
	height: 25px;
	border-radius: 50%;
}
.head-bar {
	width: 100%;
	height: 45px;
	background: #333333;
	display: flex;
	justify-content: center;
}

.navigate-container {
	width: 1170px;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	position: relative;
}

.navigate {
	// min-width: 60px;
	line-height: 45px;
	font-weight: 400;
	color: #ffffff;
	margin-right: 45px;
	cursor: pointer;
	text-align: center;
	.c-font-size-14();
	.c-font-family-PF();
}
.education {
	.fitness-Education {
		width: 190px;
		height: 103px;
		background: #ffffff;
		z-index: 200;
		ul {
			list-style: none;
			width: 100%;
			height: 100%;
			cursor: pointer;
			li {
				width: 100%;
				height: 19px;
				font-size: 12px;
				font-weight: 400;
				line-height: 19px;
				padding-left: 10px;
				color: #333333;
				opacity: 1;
				margin-top: 5px;
			}
		}
	}
}

.popper {
	.navigate-select {
		width: 122px;
		height: 193px;
		background: #ffffff;
		opacity: 1;
		z-index: 200;
		ul {
			list-style: none;
			width: 100%;
			height: 100%;
			cursor: pointer;
			li {
				width: 100%;
				height: 19px;
				font-size: 12px;
				font-weight: 400;
				line-height: 19px;
				color: #333333;
				opacity: 1;
				padding-left: 10px;
				margin-top: 5px;
			}
		}
	}
}

.logged-in-panel {
	display: flex;
	flex-direction: row;
	margin-left: auto;
	margin-right: 0;
}

.image-label {
	display: flex;
	flex-direction: row;
	margin-right: 20px;
}

.image-label.cursor {
	cursor: pointer;
}

.image-label img {
	height: 16px;
	width: 16px;
	margin: 7px 5px;
}

.image-label .label {
	line-height: 30px;
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	color: #ffffff;
}

.user {
	cursor: pointer;
}

.user img {
	height: 22px;
	width: 22px;
	margin: 4px;
	cursor: pointer;
}

.not-logged-in-panel {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: auto;
	margin-right: 0;
}

.not-logged-in-panel span {
	line-height: 30px;
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	color: #ffffff;
}

.not-logged-in-panel .popup {
	cursor: pointer;
}

.to-login-panel {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(200, 200, 200, 0.5);
	z-index: 100;
	display: flex;
}

.to-login-panel .login {
	margin: auto;
}

.to-useragreement-panel {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(200, 200, 200, 0.5);
	z-index: 100;
	display: flex;
}

.to-useragreement-panel .useragreement {
	margin: auto;
}

.to-squence-panel {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(200, 200, 200, 0.5);
	z-index: 100;
	display: flex;
}

.to-login-panel .squence {
	margin: auto;
}

.to-register-panel {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(200, 200, 200, 0.5);
	z-index: 100;
	display: flex;
	overflow: auto;
}

.to-register-panel .register {
	margin: auto;
}

.to-forgetpwd-panel {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(200, 200, 200, 0.5);
	z-index: 100;
	display: flex;
}

.to-forgetpwd-panel .forgetpwd {
	margin: auto;
}

.active {
	background: #3462e9 !important;
	color: #ffffff;
	/deep/.el-button {
		background: #3462e9;
	}
}

.navigate {
	/deep/ .el-button {
		height: auto !important;
	}
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(200, 200, 200, 0.5);
	z-index: 100;
	display: flex;

.message-box-container {
	margin: auto;
	width: 460px;
	// height: 276px;
	text-align: center;
	border-radius: 6px;
	background-color: #ffffff;
	position: relative;
}

.message-box-container .title {
	margin: 20px auto 20px auto;
	line-height: 20px;
	font-size: 20px;
	.c-font-family-PF();
	font-weight: 500;
	color: #333333;
}

.message-box-container .textarea-container textarea {
	width: 420px;
	border: 1px solid #e0e0e0;
}

.message-box-container .submit button {
	width: 230px;
	line-height: 30px;
	.c-font-size-12();
	.c-font-family-PF();
	font-weight: 400;
	border: none;
	border-radius: 2px;
	color: #ffffff;
	background-color: #3462e9;
	margin: 20PX auto;
}
}
</style>
