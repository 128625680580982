<template>
	<div class="item-list-panel">
		<div class="top-bar-container">
			<div class="top-bar">
				<div class="result">
					<div class="label count-desc">
						<div v-if="isSelect" class="chk-select">
							<el-checkbox
								v-model="isCheckAll"
								@change="checkAllChange"
								class="checkbox"
								>全选</el-checkbox
							>
						</div>
						<div class="choosepanel">
							<a href="#" @click="isSelect = !isSelect" class="select-switch">{{
								isSelect ? "取消选择" : "选择"
							}}</a>
						</div>
						<div class="label">当前结果</div>
						<div class="value">
							{{ resultAmount }}
						</div>
						<div class="unit">条</div>
					</div>
					<div class="textLabel">
						<a href="#" class="btn-export" @click="openDialog">
							<img src="../../../assets/导出.png" />
							<i class="lbl-export" />导出结果
						</a>
						<div class="orders">
							<div class="orders-container">
								<el-select
									v-model="orderBy"
									class="order-type"
									placeholder="请选择"
								>
									<el-option
										v-for="item in orderOptions"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									>
									</el-option>
								</el-select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="item-list">
			<el-checkbox-group v-model="selectData">
				<div class="item-container" v-for="(pat, i) in datalist" :key="i">
					<div class="item">
						<div v-if="isSelect" class="checkbox">
							<el-checkbox
								@change="selectChange"
								:label="pat.articleID"
								name="record"
								>&nbsp;</el-checkbox
							>
						</div>
						<div class="location">
							<router-link
								:to="'/LiteratureLearning/' + pat.articleID"
								class="entity entity-title"
								>{{ pat.articleTitle || "" }}</router-link
							>
							<div class="entity">
								<div class="value">
									{{ pat.author || "" }}
								</div>
							</div>
							<div class="entity">
								<div class="value">
									{{ pat.journal }}、{{
										pat.publishedDate | dateFormat("YYYY-MM-DD")
									}};&nbsp;&nbsp;{{ pat.issn }}，&nbsp;doi:&nbsp;{{ pat.doi }}
								</div>
							</div>
							<div class="entity">
								<div class="value value1">
									{{ pat._abstract }}
								</div>
							</div>
							<div class="item-comment">
								<div class="comment">
									<div class="label">引用频次：</div>
									<div class="value">
										{{ pat.citedCount || 0 }}
									</div>
								</div>
								<div class="comment">
									<div class="label">参考文献：</div>
									<div class="value">
										{{ pat.referenceCount || 0 }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</el-checkbox-group>
		</div>

		<!--导出弹窗-->
		<div id="exportDia">
			<el-dialog
				title="导出结果"
				:visible="openDialogShow"
				@close="closeDialog"
				width="650px"
			>
				<div class="radioDiv">
					选择导出方法：
					<el-radio v-model="exportFileType" :label="0">RIS格式</el-radio>
					<!-- <el-radio v-model="exportFileType" :label="1">TXT</el-radio> -->
					<el-radio v-model="exportFileType" :label="2">CSV</el-radio>
				</div>
				<div class="radioDiv">选择导出记录：</div>
				<div class="radioDiv">
					<el-radio
						v-model="exportType"
						:disabled="selectData.length == 0"
						:label="1"
						>当前选择的</el-radio
					>
				</div>
				<div class="radioDiv">
					<el-radio v-model="exportType" :label="2">当前页面所有记录</el-radio>
				</div>
				<div class="radioDiv">
					<el-radio v-model="exportType" :label="3">
						选择记录
						<el-input-number
							:disabled="exportType != 3"
							style="width: 200px;"
							v-model="startNo"
							step-strictly
							:step="1"
							controls-position="right"
							:min="1"
						></el-input-number>
						至
						<el-input-number
							:disabled="exportType != 3"
							style="width: 200px;"
							v-model="endNo"
							step-strictly
							:step="1"
							controls-position="right"
							:min="startNo"
							:max="startNo + 499"
						></el-input-number>
						<br /><span
							style="margin-left: 26px; .c-font-size-12(); color: #c0c0c0;"
							>单次导出上限500条</span
						>
					</el-radio>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="exportData">确定</el-button>
					<el-button @click="closeDialog">取消</el-button>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
export default {
	props: ["datalist", "resultAmount", "orderBy"],
	data() {
		return {
			openDialogShow: false,
			startNo: 1,
			endNo: 1,
			orderOptions: [
				{
					label: "发表时间",
					value: 0
				},
				{
					label: "引用频次",
					value: 1
				}
			],
			exportType: "", // 1-导出当前选择数据，2-导出当前页面数据，3-导出指定条数数据(单次不超过500)
			exportFileType: 0, // 导出的文件类型 0-ris，1-txt，2-csv
			isSelect: false, // 是否选择记录
			isCheckAll: false, // 是否全选
			selectData: [] // 选择的记录
		}
	},
	methods: {
		exportData() {
			if (this.exportType) {
				this.$parent.exportArticle(
					this.exportType,
					this.exportFileType,
					this.selectData,
					this.startNo,
					this.endNo
				)
				this.closeDialog()
			} else {
				this.$message.error("请选择需要导出的数据记录")
			}
		},
		closeDialog() {
			this.startNo = 1
			this.endNo = 0
			this.exportType = ""
			// this.exportFileType = 0
			this.openDialogShow = false
			// this.selectData = []
			// this.isSelect = false
			// this.isCheckAll = false
		},
		// 全选按钮改变事件
		checkAllChange() {
			// console.log(this.isCheckAll)
			// this.isCheckAll = !this.isCheckAll
			if (this.isCheckAll) {
				this.datalist.forEach((item) => {
					this.selectData.push(item.articleID)
				})
			} else {
				this.selectData = []
			}
			// console.log(this.selectData)
			// console.log(this.datalist)
		},
		// 多选记录改变事件
		selectChange() {
			// console.log(this.selectData)
			// console.log(this.datalist)
			this.isCheckAll = this.selectData.length === this.datalist.length
		},
		openDialog() {
			if (this.selectData.length === 0) {
				this.exportType = 2
			} else {
				this.exportType = 1
			}
			this.openDialogShow = true
		}
	},
	watch: {
		orderBy: function (newValue, oldValue) {
			this.$emit("orderchange", newValue)
		}
	}
}
</script>

<style lang="less" scoped>
.main-panel .list-panel .item-list-panel .item-list .item-container {
	.item .location {
		.entity .value {
			.c-font-size-14();
		}
		.entity-title {
			.c-font-size-16();
		}
	}
	.item-comment {
		.c-font-size-14();
	}
}
</style>
