var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-panel" }, [
    _c("div", { staticClass: "top" }, [
      _c(
        "div",
        { staticClass: "main-image" },
        [
          _c(
            "el-carousel",
            {
              ref: "img",
              attrs: {
                height: "350px",
                width: "775px",
                autoplay: true,
                interval: 3000,
                arrow: "always",
              },
            },
            _vm._l(_vm.feedsArticleList, function (img, index) {
              return _c("el-carousel-item", { key: index }, [
                img.imgPath
                  ? _c("img", {
                      staticClass: "carouselImg",
                      attrs: { src: _vm.imgPath + img.imgPath },
                      on: { click: _vm.toFeedsArticleList },
                    })
                  : _c("img", {
                      attrs: { src: require("@/assets/sequence-default2.png") },
                    }),
                _c(
                  "div",
                  {
                    staticClass: "cover ellipsisContent",
                    staticStyle: { "-webkit-line-clamp": "1" },
                  },
                  [
                    _c("span", { on: { click: _vm.toFeedsArticleList } }, [
                      _vm._v(_vm._s(img.title)),
                    ]),
                  ]
                ),
              ])
            }),
            1
          ),
          _c(
            "div",
            {
              staticClass: "moreDynamic",
              on: { click: _vm.toFeedsArticleList },
            },
            [
              _vm._v(" 更多文献动态"),
              _c("img", { attrs: { src: require("../../assets/more.png") } }),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dynamic" },
        [
          _c("el-row", { staticClass: "labelRow" }, [
            _c("img", { attrs: { src: require("../../assets/newest.png") } }),
            _vm._v("最新动态"),
          ]),
          _vm.topDynamicList.length === 0 && _vm.dynamicList.length === 0
            ? _c("el-row", { staticClass: "noData" }, [
                _c("img", {
                  attrs: { src: require("../../assets/nodata2.png") },
                }),
                _c("p", [_vm._v("暂无数据")]),
              ])
            : _vm._e(),
          _vm._l(_vm.topDynamicList, function (d, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "dynamicRow",
                on: {
                  click: function ($event) {
                    return _vm.openLink(d.link)
                  },
                },
              },
              [
                _c("img", { attrs: { src: require("../../assets/ding.png") } }),
                _c(
                  "el-tooltip",
                  { attrs: { placement: "bottom", effect: "light" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "dynamicTip",
                        attrs: { slot: "content" },
                        slot: "content",
                      },
                      [_vm._v(_vm._s(d.content))]
                    ),
                    _c("span", { staticClass: "title ellipsisContent" }, [
                      _vm._v(_vm._s(d.content)),
                    ]),
                  ]
                ),
                _c("span", { staticClass: "time" }, [
                  _vm._v(
                    _vm._s(_vm._f("dateFormat")(d.createOn, "YYYY-MM-DD"))
                  ),
                ]),
              ],
              1
            )
          }),
          _vm.topDynamicList.length > 0
            ? _c("el-row", { staticClass: "breakLine" })
            : _vm._e(),
          _c(
            "el-scrollbar",
            { style: { height: _vm.scrollHeight + "px" } },
            _vm._l(_vm.dynamicList, function (d, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "dynamicRow",
                  on: {
                    click: function ($event) {
                      return _vm.openLink(d.link)
                    },
                  },
                },
                [
                  _c(
                    "el-tooltip",
                    { attrs: { placement: "bottom", effect: "light" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "dynamicTip",
                          attrs: { slot: "content" },
                          slot: "content",
                        },
                        [_vm._v(_vm._s(d.content))]
                      ),
                      _c("span", { staticClass: "title ellipsisContent" }, [
                        _vm._v(_vm._s(d.content)),
                      ]),
                    ]
                  ),
                  _c("span", { staticClass: "time" }, [
                    _vm._v(
                      _vm._s(_vm._f("dateFormat")(d.createOn, "YYYY-MM-DD"))
                    ),
                  ]),
                ],
                1
              )
            }),
            0
          ),
        ],
        2
      ),
    ]),
    _c("div", { staticClass: "bottom" }, [
      _c("div", { staticClass: "aptamer box" }, [
        _c("div", { staticClass: "subject-container" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "aptamer-item-con" },
            [
              _c(
                "span",
                { staticClass: "btnAptamerL", on: { click: _vm.leftAptamer } },
                [
                  _c("img", {
                    staticClass: "arrow-left",
                    attrs: {
                      src: require("../../assets/arrow-up.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c(
                "span",
                { staticClass: "btnAptamerR", on: { click: _vm.rightAptamer } },
                [
                  _c("img", {
                    staticClass: "arrow-right",
                    attrs: {
                      src: require("../../assets/arrow-up.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _vm._l(_vm.aptamers, function (item, i) {
                return _c(
                  "div",
                  {
                    key: item.aptamerId,
                    class: [
                      _vm.currentAptamerIndex == i
                        ? "aptamer-item"
                        : "aptamer-item hide",
                    ],
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/aptamerdb/" + item.aptamerID } },
                      [
                        _c("div", { staticClass: "image" }, [
                          _c("img", {
                            attrs: {
                              src: `${_vm.imgHost}/${item.secondaryStructureFigure}`,
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "name" }, [
                          _vm._v(" " + _vm._s(item.aptamerName) + " "),
                        ]),
                        _c("div", { staticClass: "journal-title" }, [
                          _vm._v(" " + _vm._s(item.journal) + " "),
                        ]),
                        _c("div", { staticClass: "description" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("ellipsis")(item.articleTitle, 80)
                              ) +
                              '" '
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ]),
      ]),
      _c("div", { staticClass: "article box" }, [
        _c("div", { staticClass: "subject-container" }, [
          _c("div", { staticClass: "top-bar" }, [
            _vm._m(1),
            _c(
              "div",
              {
                class: [_vm.patentFocused, "title"],
                staticStyle: { "margin-left": "4px" },
                on: {
                  click: function ($event) {
                    return _vm.focusTypeOfArticle("patent")
                  },
                },
              },
              [_vm._v(" 重点专利 ")]
            ),
            _c(
              "div",
              {
                class: [_vm.articleFocused, "title"],
                on: {
                  click: function ($event) {
                    return _vm.focusTypeOfArticle("article")
                  },
                },
              },
              [_vm._v(" 重要文献 ")]
            ),
          ]),
          _vm.articleType == "article"
            ? _c(
                "div",
                { staticClass: "list2" },
                [
                  _c("div", { staticClass: "left-line" }),
                  _vm._l(_vm.articles, function (article, i) {
                    return _c("div", { key: i, staticClass: "item" }, [
                      _vm._m(2, true),
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "title",
                              attrs: { to: "/paper/" + article.articleID },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("ellipsis")(article.articleTitle, 80)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "date" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  article.publishedDate,
                                  "YYYY/MM"
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.articleType == "patent"
            ? _c(
                "div",
                { staticClass: "list2" },
                [
                  _c("div", { staticClass: "left-line" }),
                  _vm._l(_vm.patents, function (patent, i) {
                    return _c("div", { key: i, staticClass: "item" }, [
                      _vm._m(3, true),
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "title",
                              attrs: { to: "/patent/" + patent.patentID },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("ellipsis")(patent.title, 80)) +
                                  " "
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "date" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  patent.publicationDate,
                                  "YYYY/MM"
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
          _c("div", { staticClass: "navigate" }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.navToArticle()
                  },
                },
              },
              [_vm._v("查看更多")]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "project box" }, [
        _c("div", { staticClass: "subject-container" }, [
          _c("div", { staticClass: "top-bar" }, [
            _vm._m(4),
            _c(
              "div",
              {
                class: [_vm.projectFocused, "title"],
                staticStyle: { "margin-left": "4px" },
                on: {
                  click: function ($event) {
                    return _vm.focusTypeOfProject("project")
                  },
                },
              },
              [_vm._v(" 科研项目 ")]
            ),
            _c(
              "div",
              {
                class: [_vm.trialFocused, "title"],
                on: {
                  click: function ($event) {
                    return _vm.focusTypeOfProject("clinical")
                  },
                },
              },
              [_vm._v(" 临床试验 ")]
            ),
          ]),
          _vm.projectType == "project"
            ? _c(
                "div",
                { staticClass: "list2" },
                [
                  _c("div", { staticClass: "left-line" }),
                  _vm._l(_vm.projects, function (project, i) {
                    return _c("div", { key: i, staticClass: "item" }, [
                      _vm._m(5, true),
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "title",
                              attrs: { to: "/project/" + project.id },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("ellipsis")(project.title, 80)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "other-info" }, [
                            _c("span", { staticClass: "project-date" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    project.beginDate,
                                    "YYYY/MM"
                                  )
                                )
                              ),
                            ]),
                            _c(
                              "span",
                              { staticClass: "project-location-label" },
                              [_vm._v("所在地：")]
                            ),
                            _c("span", { staticClass: "location" }, [
                              _vm._v(
                                _vm._s(
                                  project.country == null
                                    ? ""
                                    : project.country.name
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.projectType == "clinical"
            ? _c(
                "div",
                { staticClass: "list2" },
                [
                  _c("div", { staticClass: "left-line" }),
                  _vm._l(_vm.clinicals, function (clinical, i) {
                    return _c("div", { key: i, staticClass: "item" }, [
                      _vm._m(6, true),
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "title",
                              attrs: { to: "/clinical/" + clinical.id },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("ellipsis")(clinical.title, 80)
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "other-info" }, [
                            _c("span", { staticClass: "date" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    clinical.studyStartDate,
                                    "YYYY/MM"
                                  )
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "location-label" }, [
                              _vm._v("研究机构："),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "location",
                                attrs: { title: clinical.organization },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("ellipsis")(
                                      clinical.organization,
                                      20
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
          _c("div", { staticClass: "navigate" }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.navToProject()
                  },
                },
              },
              [_vm._v("查看更多")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top-bar" }, [
      _c("div", { staticClass: "icon" }, [
        _c("img", { attrs: { src: require("../../assets/clock.png") } }),
      ]),
      _c(
        "div",
        { staticClass: "focused title", staticStyle: { "margin-left": "4px" } },
        [_vm._v("最近更新")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("img", { attrs: { src: require("../../assets/book.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "circle" }, [
      _c("img", { attrs: { src: require("../../assets/circle.jpg") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "circle" }, [
      _c("img", { attrs: { src: require("../../assets/circle.jpg") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("img", { attrs: { src: require("../../assets/book.png") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "circle" }, [
      _c("img", { attrs: { src: require("../../assets/circle.jpg") } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "circle" }, [
      _c("img", { attrs: { src: require("../../assets/circle.jpg") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }