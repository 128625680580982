<template>
	<div class="team-list-panel">
		<!--内容-->
		<div class="top-bar-container">
			<div class="top-bar-container-title">{{ title }}</div>
			<div class="result">
				{{ content }}
			</div>
		</div>
		<!--评论-->
		<div class="team-list">
			<div class="team-container">
				<div class="team" style="position: relative;">
					<div class="commentLabel">参与评论</div>
					<div style="border: 1px solid #DBDBDB;position: relative;">
						<el-input
							maxlength="1000"
							class="el-input_inner"
							type="textarea"
							placeholder="发表评论..."
							rows="3"
							v-model="commentStr"
							show-word-limit
						>
						</el-input>
						<div class="commentSubmit">
							<div style="width: 50px;">
								<div v-if="!isLogin" class="noAvator" style="width: 40px;height: 40px;"></div>
								<img v-else
									style="width: 39px; height: 39px; border-radius: 50%;"
									:src="avator"
								/>
							</div>
							<div style="width: 426px;">
								<span style="color: #3463ea;">{{ nickname }}</span>
							</div>
							<div style="width: 330px; text-align: right;">
								<button @click="commentSub">提交评论</button>
							</div>
						</div>
						<div v-if="!isLogin" class="toLogin">
							<button @click="toLigin">&gt;&gt; 登录后开启发布评论 &lt;&lt;</button>
						</div>
					</div>

					<div class="commentLabel">评论区</div>
					<commentPanel
						v-for="(commentInfo, i) in comments"
						@commentSub="getComment"
						:key="i"
						:commentInfo="commentInfo"
						:isFirst="true"
						:index="i"
						:commentStr="commentStr"
						:isLogin="isLogin"
					></commentPanel>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import commentPanel from "./commentpanel.vue"
import { ApiClient, ArticleApi } from "../../../api"
import Cookie from "js-cookie"
export default {
	props: ["content", "comments", "title", "commentStr"],
	data() {
		return {
			articleApi: new ArticleApi(ApiClient.instance),
			avator: "",
			nickname: "",
			isLogin: false
		}
	},
	mounted() {
		if (JSON.parse(localStorage.getItem("avator"))) {
			this.avator =
				"data:image/png;base64," + JSON.parse(localStorage.getItem("avator"))
		} else {
			this.avator = null
		}
		// this.nickname = Cookie.getJSON("user_info").nickname
		var user = Cookie.getJSON("user_info")
		if (user?.account) {
			this.isLogin = true
			this.nickname = user.nickname
		}
	},
	methods: {
		// commentsub
		commentSub() {
			if (this.commentStr) {
				var opts = { parentID: null, commentStr: this.commentStr }
				this.$emit("commentSub", opts)
			}
		},
		getComment(opts) {
			this.$emit("commentSub", opts)
		},
		toLigin() {
			document.getElementById("loginSpan").click()
		}
	},
	components: {
		commentPanel
	}
}
</script>

<style lang="less" scoped>
.imgs {
	margin: 10px;
	width: 256px;
	height: 256px;
}

.comFlexDiv {
	display: flex;
	align-items: center;
}

.commentLabel {
	.c-font-size-16();
	margin-top: 10px;
	margin-bottom: 10px;
}

.commentSubmit {
	width: 100%;
	background-color: rgb(239, 239, 239);
	display: flex;
	align-items: center;
	padding: 10px;

	img{
		width: 40px;
		height: 40px;
		background: #ffffff;
		border: 1px solid #707070;
		border-radius: 50%;
		opacity: 1;
		margin: 0 10px 0 12px;
	}
}
.commentSubmit button {
	color: #fff;
	background-color: #409eff;
	border: 1px solid #409eff;
	border-radius: 4px;
	padding: 10px 10px;
}

.comBorder {
	border-top: 2px solid rgb(239, 239, 239);
}

.tab-tilte {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	list-style: none;
	white-space: nowrap;
	transition: width 0.5s ease;
}

.tab-tilte li {
	padding: 10px 20px;
	.c-font-size-14();
	.c-font-family-PF();
	font-weight: bolder;
	line-height: 20px;
	color: #333333;
}

.active {
	border-bottom: 2px solid #3463ea;
}

.imgpanel {
	width: 80px;
	height: 80px;
	margin: 16px 0 0 16px;
}

.top-bar-container {
	width: 100%;
	background-color: #ffffff;
	display: inline-block;
}

.result {
	.c-font-size-18();
	.c-font-family-PF();
	line-height: 30px;
	color: #666666;
	margin: 0px 48px 74px;
}
.top-bar-container-title {
	margin: 5px 20px;
	.c-font-size-24();
	.c-font-family-PF();
	font-weight: 400;
	color: #666666;
	text-align: center;
	margin: 79px 86px 56px;
}
.result .value {
	color: #3463ea;
	margin: 0 6px;
}

.team-list {
	width: 100%;
	min-height: 500px;
}

.team-container {
	width: 100%;
	display: inline-flex;
	background-color: #ffffff;
	margin-top: 16px;
}

.team {
	width: 100%;
	margin: 16px;
	font-family: PingFang-SC-Medium;
	.c-font-size-14();
}

.team .title {
	.c-font-size-12();
	color: #3463ea;
	line-height: 20px;
	text-align: left;
}

.team .title a {
	text-decoration: none;
	.c-font-size-16();
}
.team .title a:visited {
	color: #3463ea;
}

.team .entry a:visited {
	color: #3463ea;
}

.team .location {
	.c-font-size-12();
	line-height: 20px;
	display: flex;
	flex-direction: row;
	text-align: left;
}

.team .location .entry {
	display: flex;
	flex-direction: row;
	margin-right: 20px;
}

.team .location .entry .value {
	margin-left: 6px;
}

.team .link {
	display: flex;
	flex-direction: row;
	.c-font-size-12();
	line-height: 20px;
}

.team .link .url {
	margin-left: 6px;
}

.pagination {
	display: flex;
	justify-content: center;
	margin: 10px auto;
}
</style>
