var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isFrontPage == false
    ? _c(
        "div",
        { staticClass: "breadcrumb-container" },
        _vm._l(_vm.levelPaths, function (lp, i) {
          return _c("div", { key: i, staticClass: "set" }, [
            _c("span", { staticClass: "breadcrumb" }, [
              _vm._v(_vm._s(lp.name)),
            ]),
            i + 1 < _vm.levelPaths.length
              ? _c("div", { staticClass: "separator" }, [_vm._v(">")])
              : _vm._e(),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }