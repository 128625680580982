<template>
	<div class="main-panel main-list">
		<div class="left-panel">
			<div class="statistic-panel">
				<doughnut-chart
					:dataList="countryStatis"
					totalDesc="适体总数"
					:chartParam="chartParam"
					@click="countrySelect"
					isDataFixed="true"
				></doughnut-chart>
			</div>
			<div class="statistic-panel">
				<doughnutChart
					:dataList="methodStatis"
					totalDesc="筛选方法"
					:chartParam="methodchartParam"
					@click="methodSelect"
					isDataFixed="true"
				></doughnutChart>
			</div>
			<div class="filter-panel">
				<aptamerfilter
					:aptamerTypeFilters="candidate"
				></aptamerfilter>
			</div>
		</div>
		<div class="list-panel">
			<aptamerlist
				:aptamers="aptamers"
				:resultAmount="resultAmount"
			></aptamerlist>
		</div>
	</div>
</template>

<script>
import aptamerlist from "./aptamerlist/index.vue"
import aptamerfilter from "./aptamerfilter/index.vue"
import doughnutChart from "../../components/charts/DoughnutChart.vue"
// import AptamerListDoughnutChart from "../../components/charts/AptamerListDoughnutChart.vue"
import { ApiClient, AptamerApi } from "../../api"
import FileSaver from "file-saver"
import moment from "moment"
import "../../assets/css/list.less"

export default {
	data() {
		return {
			api: new AptamerApi(ApiClient.instance),
			resultAmount: 0,
			aptamers: [],
			searchType: 0,
			countryStatis: [],
			chartParam: { isOther: false, select: [] },
			selectCountry: "",
			candidate: [
				{ description: "DNA", value: "DNA" },
				{ description: "RNA", value: "RNA" },
				{ description: "Peptide", value: "Peptide" },
				{ description: "Unknown", value: "Unknown" },
				{ description: "DNA/RNA", value: "DNA/RNA" }
			],
			methodStatis: [],
			methodchartParam: { isOther: false, select: [] },
			selectMethods: ""
		}
	},
	mounted() {
		this.bindParams()
		this.getAptamer()
	},
	watch: {
		$route(to, from) {
			this.bindParams()
			this.getAptamer()
		}
	},
	computed: {
		// yearFilterRange() {
		// 	var now = new Date()
		// 	return [now.getFullYear() - 10, now.getFullYear()]
		// },
		queryOptions() {
			// children 顺序和components中排序无关，和html中排序有关
			var filter = this.$children[2]
			var list = this.$children[3]
			var querys = this.$route.query
			return {
				searchType: this.searchType,
				keyWord: querys.keyword || querys.keyWord,
				targetName: filter.targetNameFilter,
				aptamerType: filter.aptamerTypeFilter,
				usage: filter.applyFilter,
				sampleType: filter.sampleTypeFilter,
				methods: this.selectMethods,
				fristDiscoveryYear: filter.yearFilter,
				index: list.pager.index,
				size: list.pager.size,
				orderBy: list.orderBy,
				name: filter.name,
				sampleName: filter.sampleName,
				finder: filter.finder,
				isOther: this.chartParam.isOther,
				chartSelect: this.chartParam.select,
				selectCountry: this.selectCountry
			}
		},
		orderBy() {
			var list = this.$children[3]
			// 如果是一般搜索，排序返回默认排序
			return list.orderBy ? list.orderBy : (this.searchType === 0 ? 2 : 0)
		}
	},
	components: {
		aptamerfilter,
		aptamerlist,
		doughnutChart
	},
	methods: {
		getAptamer() {
			// console.log(this.orderBy)
			// console.log(this.searchType)
			// console.log(this.queryOptions)
			this.api.apiAptamerQueryGet(
				this.orderBy,
				this.searchType,
				this.queryOptions,
				(e, data, resp) => {
					if (data.success) {
						// console.log(data)
						this.resultAmount = data.result.total
						this.countryStatis = data.result.countryStatistics
						this.methodStatis = data.result.screenMethodStatistics
						this.aptamers = data.result.records
						console.log(this.aptamers)
					}
				}
			)
		},
		replaceUrl() {
			this.$router.replace({ query: this.queryOptions })
		},
		bindParams() {
			// console.log("进入aptamer bindParams")
			var query = this.$route.query
			var filter = this.$children[2]
			var list = this.$children[3]
			this.selectCountry = query.selectCountry
			this.selectMethods = query.methods
			// console.log(query)
			var countrySelect = []
			this.searchType =
				parseInt(query.searchtype) || parseInt(query.searchType) || 0
			filter.targetNameFilter = query.targetName
			filter.aptamerTypeFilter = query.aptamerType || ""
			filter.applyFilter = query.usage
			filter.sampleTypeFilter = query.sampleType
			filter.methodFilter = query.methods
			filter.name = query.name
			filter.sampleName = query.sampleName
			filter.finder = query.finder
			// list
			list.pager.index = parseInt(query.index) || 1
			list.pager.size = parseInt(query.size) || 10
			if (query.orderBy) {
				list.orderBy = parseInt(query.orderBy)
			} else {
				list.orderBy = this.searchType === 0 ? "" : 0
			}
			// console.log(this.searchType, list.orderBy)
			// list.orderBy = parseInt(query.orderBy) || 0
			if (query.chartSelect?.length > 0) {
				countrySelect.push(query.chartSelect)
			}
			this.chartParam.select = countrySelect
			this.chartParam.isOther = query.isOther === "true"
			this.methodchartParam.select = query.methods || ""
		},
		exportArticle(exportType, exportFileType, selectData, startNo, endNo) {
			var opts = this.queryOptions
			this.$set(opts, "exportType", exportType)
			this.$set(opts, "selectRecords", selectData)
			this.$set(opts, "startNo", startNo)
			this.$set(opts, "endNo", endNo)
			// console.log(opts)

			this.api.apiAptamerExportGet(
				this.orderBy,
				this.searchType,
				opts,
				(error, data, response) => {
					// console.log(response)
					if (error) {
						this.$message.error("导出失败")
					}
					if (data.success) {
						// console.log(data.result)

						var exportData = data.result.exportData
						// console.log(exportData)

						if (exportData.length > 0) {
							var fileName =
								"导出适体资料信息" + moment(new Date()).format("YYYYMMDDHHmmss")

							var content = []
							if (exportFileType !== 2) {
								content.push(
									"AN - 适体名称\nAU - 发现者名称\nTY - 适体类型\nTA - 靶标名称\nAF - 亲和力\nRE - 引用频次\nJO - 杂志\nPT - 发布时间\n\n"
								)
								exportData.forEach((item, index) => {
									var str = ""
									str += "AN  - " + (item.aptamerName || "") + " \n"
									str += "AU  - " + (item.finderName || "") + " \n"
									str += "TY  - " + (item.aptamerType || "") + " \n"
									str += "TA  - " + (item.targetName || "") + " \n"
									str += "AF  - " + (item.affinity || "") + " \n"
									str += "RE  - " + (item.citedCount || "") + " \n"
									str += "JO  - " + (item.journal || "") + " \n"
									str +=
										"PT  - " +
										(item.publishedDate
											? moment(item.publishedDate).format("yyyy-MM-DD")
											: "") +
										"\n"
									str += "\n"
									content.push(str)
								})
							} else {
								content.push(
									"适体名称,发现者名称,适体类型,靶标名称,亲和力,引用频次,杂志,发布时间\n"
								)
								exportData.forEach((item, index) => {
									var str = ""
									str += this.$options.filters.replaceStringForExportCSV(item.aptamerName)
									str += "," + this.$options.filters.replaceStringForExportCSV(item.finderName)
									str += "," + this.$options.filters.replaceStringForExportCSV(item.aptamerType)
									str += "," + this.$options.filters.replaceStringForExportCSV(item.targetName)
									str += "," + (item.affinity || "")
									str += "," + (item.citedCount || "")
									str += "," + this.$options.filters.replaceStringForExportCSV(item.journal)
									str +=
										"," +
										(item.publishedDate
											? moment(item.publishedDate).format("yyyy-MM-DD")
											: "")
									str += "\n"
									content.push(str)
								})
							}
							// console.log(content)
							// var blob = new Blob(content, { type: "text/plain;charset=utf-8" })
							if (exportFileType === 1) {
								// FileSaver.saveAs(blob, fileName + ".txt")
							} else if (exportFileType === 2) {
								FileSaver.saveAs(new Blob(["\ufeff" + content.join("")], { type: "text/csv;charset=gb2312" }), fileName + ".csv")
							} else if (exportFileType === 0) {
								FileSaver.saveAs(new Blob(["\ufeff" + content.join("")], { type: "text/plain;charset=utf-8" }), fileName + ".ris")
								// FileSaver.saveAs(blob, fileName + ".ris")
							}
							this.$message.success("导出成功")
						} else {
							this.$message.warning("导出失败！未查询到数据！")
						}
					}
				}
			)
		},
		countrySelect(arg) {
			// if (!arg.isOther) {
			this.searchType = 2
			// this.chartParam = arg
			console.log(arg)
			this.selectCountry = arg?.select[0]
			this.replaceUrl()
			// }
		},
		methodSelect(arg) {
			// if (!arg.isOther) {
			this.searchType = 2
			// this.methodchartParam = arg
			this.selectMethods = arg?.select[0]
			// console.log(this.methodchartParam)
			this.replaceUrl()
			// }
		}
	}
}
</script>

<style lang="less" scoped>
.chartSelect {
	height: 30px;
	line-height: 30px;
	text-align: center;

	a.all {
		text-decoration: none;
	}
	a.country {
		text-decoration: none;
		cursor: text;
	}
}
</style>
